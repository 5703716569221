import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { MenuItem } from '../shared/components/header/header.component';
import { CustomerType, LoginStatus, UserService } from 'shared-lib';

const MENU_ITEMS:MenuItem[] = [
    {translation: 'ACCOUNT.MANAGEMENT.title', route: '/accountverwaltung'},
    {translation: 'ACCOUNT.RECLAMATIONS.newReclamation', route: '/produkte/start'},
    {translation: 'ACCOUNT.RECLAMATIONS.title', route: '/servicemeldungen'}];

@Injectable()
export class MenuItemsService {
    private menuItems:BehaviorSubject<MenuItem[]> = new BehaviorSubject<MenuItem[]>([]);

    constructor(private User:UserService) {
        User.getCurrentUserStatus().subscribe(
            (status:LoginStatus) => {
                status?.customerType === CustomerType.Guest  || sessionStorage.getItem('registrationIncomplete')
                    ? this.hideAllMenuItems()
                    : this.showDefaultMenuItems();
                status?.customerType === CustomerType.Private
                    ? MENU_ITEMS[1].route = '/produkte/auswahl-reklamation'
                    : MENU_ITEMS[1].route = '/produkte/start';
            });
    }

    public getMenuItems():Observable<MenuItem[]> {
        return this.menuItems.asObservable();
    }

    public hideAllMenuItems():void {
        this.menuItems.next([]);
    }

    public showDefaultMenuItems():void {
        this.menuItems.next(MENU_ITEMS);
    }
}
