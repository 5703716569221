import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { ApiArrayResponse, ApiResponse, MetaObject, UtilsService } from './utils.service';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { map, mergeMap, tap } from 'rxjs/operators';
import {
    LoginData,
    LoginStatus,
    ReclamationBase,
    SalesChannel,
    StrictHttpParams,
    SupportUser,
    SystemStatus,
    User
} from '../models';
import { Store } from '@ngrx/store';
import { State } from '../../../reducers/root/root.reducer';
import { Router } from '@angular/router';
import { XHttpClient } from './extended-http-client.service';
import { LangUtilsService } from './lang-utils.service';
import { environment } from '../../../../environments/environment';
import * as UserAction from '../reducers/user/user.actions';
import * as AddressAction from '../reducers/address/address.actions';
import * as EvaluationAction from '../reducers/evaluation/evaluation.actions';
import * as ProductsAction from '../reducers/products/products.actions';
import * as RegistrationAction from '../reducers/registration/register.actions';
import { add } from '@zxing/library/es2015/core/aztec/encoder/TokenHelpers';


/**
 * Service to handle Login, Logout, Session Storage,
 * Forget Password, Reset Password, Update Account, Registration etc
 */
@Injectable({
    providedIn: 'root'
})
export class UserService {
    private currentStatusSubject:BehaviorSubject<LoginStatus>;
    private storageItems:string[] = ['currentUser', 'evaluation', 'products', 'address', 'salesChannelOptions'];
    private alreadyFetchedAuthStatus:boolean = false;
    private storedRoute:string;

    /**
     *
     * @param {Router} router
     * @param {XHttpClient} xHttp
     * @param {UtilsService} Utils
     * @param {LangUtilsService} LangUtils
     * @param {Store<State>} store
     */
    constructor(private router:Router,
                private xHttp:XHttpClient,
                private Utils:UtilsService,
                private LangUtils:LangUtilsService,
                private store:Store<State>) {
        this.currentStatusSubject = new BehaviorSubject<any>(JSON.parse(sessionStorage.getItem('currentUser'))); // null
    }

    public setCurrUser(value) {
        this.currentStatusSubject.next(value);
    }

    /**
     * Get the current User's status
     */
    public getCurrentUserStatus():Observable<LoginStatus> {
        return this.currentStatusSubject.asObservable();
    }

    /**
     * get the current User's Status value
     */
    public get currentUserStatusValue():LoginStatus {
        return this.currentStatusSubject.value;
    }

    /**
     * Get the current user's details
     */
    public getCurrentUser():Observable<User & MetaObject> {
        return this.Utils.stepIntoData(this.xHttp.get<ApiResponse<User>>(this.Utils.replace(environment.api.user.current)));
    }

    /**
     * Log the user in
     *
     * @param user
     */
    private login(user:LoginData):Observable<HttpResponse<Object>> {
        const body = new FormData();

        body.append('username', user.username);
        body.append('password', user.password);
        if (user.reCaptchaResponse) {
            body.append('recaptcha-response', user.reCaptchaResponse);
        }

        return this.xHttp.post<any>(this.Utils.replace(environment.api.user.login), body, {
            observe: 'response',
            ignoreUnauthorized: true,
            ignoreGlobalErrorHandler: resp => resp.errors && resp.errors.some(err => err.code === 'ERROR_NOT_VERIFIED')
        });
    }


    /**
     * Log the user in
     *
     * @param user
     */
    private guestLogin(user:LoginData):Observable<HttpResponse<Object>> {
        this.clearAllData();  // clear any data that might be present from a previous session
        const body = new FormData();

        body.append('username', user.username);
        if (user.reCaptchaResponse) {
            body.append('recaptcha-response', user.reCaptchaResponse);
        }

        return this.xHttp.post<any>(this.Utils.replace(environment.api.user.guestLogin), body, {
            observe: 'response',
            ignoreUnauthorized: true,
            ignoreGlobalErrorHandler: resp => resp.errors && resp.errors.some(err => err.code === 'ERROR_NOT_VERIFIED')
        });
    }


    /**
     * Log the user in and update Storage Items & Stored Route
     * @param user
     * @param guest
     */
    public loginAndUpdateStoredData(user:LoginData, guest?:boolean):Observable<void> {
        const loginRequest:Observable<HttpResponse<Object>> = guest ? this.guestLogin(user) : this.login(user);
        return loginRequest
            .pipe(
                map((res:HttpResponse<Object>) => {
                    if (res.body && res.body['data']) {
                        this.storedRoute = res.body['data'].redirectUrl;
                        if (res.body['data'].salesChannels) {
                            sessionStorage.setItem('salesChannels', JSON.stringify(res.body['data'].salesChannels));
                        }
                        if (res.body['data'].supportCreated) {
                            sessionStorage.setItem('supportCreated', res.body['data'].supportCreated);
                        }
                        if (res.body['data'].registrationIncomplete) {
                            sessionStorage.setItem('redirectUrl', res.body['data'].redirectUrl);
                            sessionStorage.setItem('registrationIncomplete', res.body['data'].registrationIncomplete);
                        }
                    }
                    return res.ok;
                }),
                mergeMap((loggedIn:boolean) => {
                    if (loggedIn) {
                        return this.currentLoggedInStatus();
                    } else {
                        this.storageItems.forEach(
                            item => this.clearSessionStorage(item)
                        );
                        return of({loggedIn: false});
                    }
                }),
                map(() => null)
            );
    }

    /**
     * Log the user out
     */
    public logout():Observable<void> {
        const logout:Observable<void> = this.xHttp.post<void>(this.Utils.replace(environment.api.user.logout), {ignoreUnauthorized: true});
        return logout.pipe(
            tap(() => {
                this.clearAllData();
            }));
    }

    /**
     * Clear all data
     */
    private clearAllData():void {
        this.store.dispatch(new ProductsAction.DeleteAllProductAction());
        this.store.dispatch(new ProductsAction.EnterSerialsLaterAction(false));
        this.store.dispatch(new EvaluationAction.DeleteReclamationAction());
        this.store.dispatch(new AddressAction.DeleteAddressAction());
        this.store.dispatch(new UserAction.DeleteUserDetailsAction());
        this.store.dispatch(new RegistrationAction.DeleteAllFormData());
        sessionStorage.removeItem('salesChannels');
        sessionStorage.removeItem('currentUser');
        sessionStorage.removeItem('registrationIncomplete');
        sessionStorage.removeItem('redirectUrl');
        this.LangUtils.clearAndReloadTranslations();
        this.currentStatusSubject.next(null);
    }

    /**
     * Set Session Storage Item
     *
     * @param status
     */
    public setSessionStorageItem(status:LoginStatus):void {
        sessionStorage.setItem('currentUser', JSON.stringify(status));
        this.currentStatusSubject.next(status);
    }

    /**
     * Clear Session Storage
     *
     * @param item
     */
    public clearSessionStorage(item:string):void {
        sessionStorage.removeItem(item);
        this.currentStatusSubject.next(null);
    }

    /**
     * get the current logged in status of the user
     */
    public currentLoggedInStatus():Observable<LoginStatus> {
        return this.xHttp.get<ApiResponse<LoginStatus>>(this.Utils.replace(environment.api.user.status), {ignoreUnauthorized: true})
            .pipe(
                map(resp => {
                    if (!resp || typeof resp.data === 'undefined') {
                        return null;
                    }
                    return resp.data;
                }),
                tap((status:LoginStatus) => {
                    if (!!this.currentUserStatusValue && !!this.currentUserStatusValue.username && !status.username) {
                        status['username'] = this.currentUserStatusValue.username;
                    }
                    this.alreadyFetchedAuthStatus = true;
                    this.clearSessionStorage('currentUser');
                    this.updateUserStatus(status);
                })
            );
    }

    /**
     * Get the Regex for the password
     */
    public passwordRegExp():Observable<RegExp> {
        return this.Utils.stepIntoData(
            this.xHttp.get<ApiResponse<RegExp>>(this.Utils.replace(environment.api.user.passwordRegEx), {ignoreUnauthorized: true})
        );
    }

    /**
     * Check the User's Status when navigating to different pages
     *
     * @param urlToNavigateTo
     * @param mustBeLoggedIn
     */
    public checkUserStatus(urlToNavigateTo:string, mustBeLoggedIn:boolean):Observable<boolean> {
        return (this.alreadyFetchedAuthStatus ? of(this.currentUserStatusValue) : this.currentLoggedInStatus())
            .pipe(
                map(response => {
                    if (!response || typeof response === 'undefined') {
                        return false;
                    }
                    return response.loggedIn;
                }),
                map(loggedIn => {
                    if (mustBeLoggedIn && !loggedIn) {
                        if (urlToNavigateTo != null) {
                            this.router.navigateByUrl(urlToNavigateTo, {replaceUrl: true});
                        } else {
                            this.router.navigateByUrl('/', {replaceUrl: true});
                        }
                        return false;
                    } else if (!mustBeLoggedIn && loggedIn) {
                        if (urlToNavigateTo != null) {
                            this.router.navigateByUrl(urlToNavigateTo, {replaceUrl: true});
                        } else {
                            this.router.navigateByUrl('/produkte', {replaceUrl: true});
                        }
                        return false;
                    } else if (loggedIn && sessionStorage.getItem('registrationIncomplete')) {
                        this.router.navigateByUrl('/registration/geschaeftskunden-typ', {replaceUrl: true});
                    }
                    return true;
                })
            );
    }

    /**
     * Request an Email to be sent to the User to Reset their password
     *
     * @param usermail
     */
    public requestPasswordResetEmail(usermail:string):Observable<any> {
        const headers = new HttpHeaders({'Content-Type': 'application/x-www-form-urlencoded'});
        const body = new StrictHttpParams().set('username', usermail);
        return this.xHttp.post<any>(this.Utils.replace(environment.api.user.passwordReset), body, {
            headers,
            observe: 'response',
            ignoreUnauthorized: true
        });
    }

    /**
     * Request to reset the user's password
     *
     * @param token
     */
    public passwordResetTokenStatus(token:string):Observable<any> {
        return this.xHttp.get<any>(this.Utils.replace(environment.api.user.passwordResetTokenStatus, token), {ignoreUnauthorized: true});
    }

    /**
     * Request a Confirmation Email to finish Registration
     *
     * @param usermail
     * @param password
     */
    public requestConfirmationEmail(usermail:string, password:string):Observable<any> {
        const headers = new HttpHeaders({'Content-Type': 'application/x-www-form-urlencoded'});
        const body = new StrictHttpParams().set('username', usermail).set('password', password);
        return this.xHttp.post<any>(this.Utils.replace(environment.api.user.requestConfirmation), body,
            {headers: headers, observe: 'response', ignoreUnauthorized: true});
    }

    /**
     * Reset the Password with the Token Provided
     *
     * @param token
     * @param password
     */
    public passwordResetWithToken(token:string, password:string):Observable<User> {
        return this.xHttp.post<any>(
            this.Utils.replace(environment.api.user.passwordResetWithToken, token), {'password': password}, {ignoreUnauthorized: true}
        );
    }

    /**
     * Force the user to reset their password if the user was created by support
     *
     * @param username
     * @param password
     */
    public passwordResetForced(username:string, password:string):Observable<User> {
        return this.xHttp.post<any>(
            this.Utils.replace(environment.api.user.passwordResetForced),
            {'username': username, 'password': password}, {ignoreUnauthorized: true}
        );
    }

    /**
     * Save the current route from the url
     */
    public saveCurrentRoute():void {
        this.storedRoute = this.router.url;
    }

    /**
     * Reroute according to either what the storedRoute is or what the defaultRoute is set to
     *
     * @param defaultRoute
     */
    public restoreSavedRoute(defaultRoute:string):void {
        const route:string = this.storedRoute;
        if (route && route !== '/') {
            this.storedRoute = null;
            this.router.navigateByUrl(route);
        } else {
            this.router.navigateByUrl(defaultRoute);
        }
    }

    /**
     * Request to the BE to update the User's Acc info
     *
     * @param user
     */
    public updateAccountInfo(user:User):Observable<User> {
        return this.xHttp.post<User>(this.Utils.replace(environment.api.user.current), user);
    }

    /**
     * Update the user's logged in status
     *
     * @param status
     */
    public updateUserStatus(status:LoginStatus):void {
        this.setSessionStorageItem(status);
    }

    /**
     * Request to the BE with the missing data for Registering the user
     *
     * @param user
     */
    public completeRegistration(user:User):Observable<User> {
        return this.xHttp.post<User>(this.Utils.replace(environment.api.user.completeReg), user);
    }

    public getUserDetails(userId:string):Observable<User & MetaObject> {
        return this.Utils.stepIntoData(this.xHttp.get<ApiResponse<User>>(this.Utils.replace(environment.api.user.details, userId)));
    }

    /**
     * Get the User List from the Backend
     *
     * @param {number} pageLength
     * @param {number} page
     * @param {string} q
     */
    public getUserList(pageLength:number, page:number, q?:string):Observable<User[]> {
        if (!q) {
            q = '';
        }
        return this.Utils.stepIntoData(this.xHttp.get(this.Utils.replace(environment.api.user.userList, page, pageLength, q)));
    }

    public getAdminUserList(pageLength:number, page:number, q?:string):Observable<User[]> {
        if (!q) {
            q = '';
        }
        return this.Utils.stepIntoData(this.xHttp.get(this.Utils.replace(environment.api.user.userAdminList, page, pageLength, q)));
    }

    /**
     * Get a user's reclamations from backend
     * @param userId
     * @param pageLength
     * @param page
     */
    public getReclamations(userId:string, pageLength:number, page:number):Observable<ReclamationBase[] & MetaObject> {
        return this.Utils.stepIntoData
        (this.xHttp.get<ApiArrayResponse<ReclamationBase>>
            (this.Utils.replace(environment.api.user.reclamations, userId, page, pageLength))
        );
    }


    /**
     * Get the list of Reclamations
     *
     * @param {number} pageLength
     * @param {number} page
     * @param {string} q
     */
    public getAllReclamations(pageLength:number, page:number, q?:string):Observable<ReclamationBase[] & MetaObject> {
        if (!q) {
            q = '';
        }
        return this.Utils.stepIntoData
        (this.xHttp.get<ApiArrayResponse<ReclamationBase>>
            (this.Utils.replace(environment.api.user.allReclamations, page, pageLength, q))
        );
    }

    /**
     * Create the User in the Support App
     *
     * @param {User} user
     * @param {boolean} noWarn
     */
    public createUser(user:User, noWarn:boolean):Observable<User & MetaObject> {
        const params:HttpParams = new HttpParams().set('noWarn', noWarn.toString());
        return this.Utils.stepIntoData(this.xHttp.post<ApiArrayResponse<User>>(environment.api.user.current, user, {params}));
    }

    /*
     * Get the User's Address details
     */
    public getUserAddressDetails(address:string):Observable<string> {
        return this.getCurrentUser().pipe(mergeMap((userData:User) => {
            if (userData.addressInformation) {
                address = userData.addressInformation.address
                    + ' ' + userData.addressInformation.zip + ' ' + userData.addressInformation.city;
            } else {
                address = '-';
            }
            this.store.dispatch(new AddressAction.SaveAddressAction(address));
            return of(address);
        }));
    }

    public getSystemStatus():Observable<SystemStatus> {
        return this.xHttp.get<SystemStatus>(environment.api.systemStatus);
    }

    public createAdminUser(user:SupportUser):Observable<any> {
        return this.Utils.stepIntoData(this.xHttp.post<ApiResponse<any>>(environment.api.user.createSupportUser, user));
    }

    public getAdminUserDetails(userId:string):Observable<SupportUser & MetaObject> {
        return this.Utils.stepIntoData(this.xHttp.get<ApiResponse<SupportUser>>(this.Utils.replace(environment.api.user.supportUser, userId)));
    }

    public editAdminUser(userId:string, user:SupportUser):Observable<SupportUser & MetaObject> {
        return this.Utils.stepIntoData(this.xHttp.post<ApiResponse<SupportUser>>(this.Utils.replace(environment.api.user.supportUser, userId), user));
    }

    public deleteUser(userId:string):Observable<any> {
        return this.xHttp.delete(this.Utils.replace(environment.api.user.supportUser, userId));
    }

    public getAdminOrgUnits():Observable<any> {
        return this.Utils.stepIntoData(this.xHttp.get(environment.api.user.supportUserOrgUnits));
    }
}
