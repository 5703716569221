import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../shared';
import { ShopConfirmationComponent } from './components/shop/shop-confirmation.component';
import { NonShopConfirmationComponent } from './components/nonshop/nonshop-confirmation.component';
@NgModule({
    declarations: [
        ShopConfirmationComponent,
        NonShopConfirmationComponent
    ],
    imports: [
        CommonModule,
        SharedModule
    ],
    exports: [
        ShopConfirmationComponent,
        NonShopConfirmationComponent
    ]
})
export class ConfirmationModule {
}
