import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';
import { Params } from '@angular/router';

/**
 * Model for the API response
 */
export interface ApiResponse<T> {
    meta?:any | null;
    data?:T | T[] | null;
    errors?:ApiError[];
}

/**
 * Model for Api error
 */
export interface ApiError {
    status?:number;
    code?:string;
    title?:string;
    detail?:string;
}

/**
 * Array of Api Response
 */
export interface ApiArrayResponse<T> extends ApiResponse<T> {
    data?:T[];
}

/**
 * Meta Object Model
 */
export interface MetaObject {
    $meta?:any;
}

@Injectable({
    providedIn: 'root'
})
export class UtilsService {

    constructor() {
    }

    /**
     * Utility that can be used to create an Observable intended for use with the rp-busy-btn.
     *
     * This is special because the Observable returned immediately emits a value and flatMaps it to the one returned by
     * the specified function. This is done because we want baseObs to be called when the Observable is subscribed on.
     *
     * @param baseObs Function used to create the Observable
     *
     * @return Observable intended to be provided to a rp-busy-btn
     */
    public createActionObs<T>(baseObs:() => Observable<T>):Observable<T> {
        return of({})
            .pipe(mergeMap(() => baseObs()));
    }

    /**
     * Replaces occurrences '{[0-9]}' with the specified args
     *
     * @param {string} url The URL to replace params in
     * @param args         The params to replace with
     *
     * @returns {string} String containing the replaced params
     */
    public replace(url:string, ...args:any[]):string {
        let counter:number = 0;

        for (const arg of args) {
            url = url.replace(`{${counter}}`, encodeURIComponent(arg));
            counter++;
        }

        return url;
    }

    public replaceText(mainText: string, replacer: string[]) {
      let counter: number = 0;
      for (const replace of replacer) {
        mainText = mainText.replace(`{${counter}}`, replace);
        counter++;
      }
      return mainText;
    }


    /*
Order matters when declaring the following typings. Compiler takes first match.
So we have to declare the most concrete one first.
 */

    public stepIntoData<T>(response:Observable<ApiArrayResponse<T>>):Observable<T[] & MetaObject>;

    public stepIntoData<T>(response:Observable<ApiResponse<T>>):Observable<T & MetaObject>;

    public stepIntoData<T>(response:ApiArrayResponse<T>):T[] & MetaObject;

    public stepIntoData<T>(response:ApiResponse<T>):T & MetaObject;

    /**
     * Returns the property data from the provided ApiResponse. If an Observable is provided an Observable is returned
     * which returns the property data from the resolved value. Overloads with more concrete exist.
     *
     * @param {ApiResponse<T> | Observable<ApiResponse<T>>} response The object or Observable
     *
     * @returns {Observable<T[] | T> | T[] | T} The contents of the data property. Returns null if response or the data property were empty
     */
    public stepIntoData<T>(response:ApiResponse<T> | Observable<ApiResponse<T>>)
        :Observable<T & MetaObject | T[] & MetaObject> | T & MetaObject | T[] & MetaObject | null {
        if (response instanceof Observable) {
            return <Observable<T>>response
                .pipe(map(resp => {
                    if (!resp || typeof resp.data === 'undefined') {
                        return null;
                    }
                    const data = resp.data;
                    if (resp.meta) {
                        return Object.defineProperty(data, '$meta', {
                            value: resp.meta,
                            writable: false,
                            enumerable: false,
                            configurable: true
                        });
                    }
                    return data;
                }));
        }
        if (!response || !response.data) {
            return null;
        }
        if (response.meta) {
            return Object.defineProperty(response.data, '$meta', {
                value: response.meta,
                writable: false,
                enumerable: false,
                configurable: true
            });
        }
        return response.data;
    }

    /**
     * Adds the p param to the provided target if currentParams contains p with value 1 or if currentPage is greater than 1
     *
     * @param target                 The object to add the param on
     * @param {Params} currentParams Current router params
     * @param {number} currentPage   Current page
     */
    public addPageToRouterParams(target:any, currentParams:Params, currentPage:number, q?:string) {
        if ((currentParams && currentParams.p === 1) || currentPage > 1) {
            target.p = currentPage;
        }
        if (q) {
            target.q = q;
        }
    }

    /**
     * Checks if the current browser is IE.
     * @returns {boolean} Returns true if browser is IE else false.
     */
    public detectInternetExplorer():boolean {
        return /msie\s|trident\//i.test(navigator.userAgent.toLowerCase());
    }

    /**
     * Checks if user is on a mobile device.
     * @returns {boolean}
     */
    public detectMobileDevice():boolean {
        return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
    }

    public arrayHasDuplicates(arr) {
        return new Set(arr).size !== arr.length;
    }
}
