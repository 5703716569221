<div class="row notice-banner py-3 " *ngIf="!fromSupport && notice">
    <div class="col-16 m-auto" [innerHTML]="notice">
    </div>
</div>
<nav class="navbar mb-2 mx-3 mx-md-auto px-0" [ngClass]="{'support': distinguishService?.support}">
    <span class="navbar-brand align-self-start">
        <ng-container *ngIf="logo">
            <ng-container *ngIf="!loggedIn; else internalLink">
                <a [href]="logo.link" target="_blank">
                    <img [src]="logo.src" [alt]="logo.alt">
                </a>
            </ng-container>
            <ng-template #internalLink>
                <a [routerLink]="logo.link">
                    <img [src]="logo.src" [alt]="logo.alt">
                </a>
            </ng-template>
        </ng-container>
    </span>
    <ng-container *ngIf="loggedIn">
        <div ngbDropdown class="dropdown-menu-username d-md-block text-secondary mx-2" [autoClose]="true">
            <p class="nav-username cursor-pointer" (click)="toggleMenu()">
                <a class="d-flex align-items-center" ngbDropdownToggle>
                    <span class="pt-0 pt-lg-1 mr-2 text-black d-none d-md-inline">
                        <span *ngIf="guest">{{'ACCOUNT.NAV.guest' | translate }}</span>
                        <span>{{username}}</span>
                    </span>
                    <i class="fa fa-2x fa-user-circle-o mr-2"></i>
                </a>
            </p>
            <div ngbDropdownMenu aria-labelledby="desktopDropdown"
                 class="dropdown-menu-desktop d-none d-md-block -bg-light">
                <button *ngFor="let menuItem of menuItems | async" ngbDropdownItem
                        (click)="navigateTo(menuItem.route)">{{menuItem.translation | translate}}</button>
                <button ngbDropdownItem (click)="open()">{{logoutButtonTranslation | translate}}</button>
            </div>

            <div class="dropdown-menu-mobile bg-white px-4 border-0 position-fixed" id="navbar"
                 [ngClass]="{ 'show': isShown }">
                <ul class="navbar-nav">
                    <li class="nav-item active text-truncate py-3 border-md-b px-2 px-lg-0">
                        <span class="pt-0 pt-lg-1 text-black mr-2 font-weight-bold">
                            <span *ngIf="guest">{{'ACCOUNT.NAV.guest' | translate }}</span>
                            <span *ngIf="!guest">{{username}}</span>
                        </span>
                    </li>
                    <li *ngFor="let menuItem of menuItems | async" (click)="navigateTo(menuItem.route)"
                        class="nav-item py-2 px-2 px-lg-0 border-bottom">
                        <span>{{menuItem.translation | translate}}</span>
                        <i class="fa fa-angle-right float-right"></i>
                    </li>
                    <li class="nav-item py-2 px-2 px-lg-0 border-bottom" (click)="open()">
                        <span>{{ logoutButtonTranslation | translate}}</span>
                        <i class="fa fa-angle-right float-right"></i>
                    </li>
                </ul>
            </div>
        </div>
    </ng-container>
    <ng-container *ngIf="!loggedIn && allowedUrl() && !distinguishService.support">
        <button class="d-md-none btn btn-default text-secondary font-weight-normal"
                [routerLink]="'/login'"> {{'REGISTRATION.LOGIN.title' |translate}}</button>
        <button class="d-none d-md-block btn btn-tertiary font-weight-normal"
                [routerLink]="'/login'"> {{'REGISTRATION.LOGIN.title' |translate}}</button>
    </ng-container>
</nav>


