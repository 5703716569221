<ng-container *ngIf="detectors && detectors.length > 0">
    <div class="row my-3">
        <h3>{{"PRODUCTS.CONFIRMATION.PRODUCTLISTS.GENERAL.title" | translate}}</h3>
    </div>
    <div class="row">
        <div class="col-5 px-1 d-none d-sm-block"></div>
        <div class="col-7 col-sm-2 text-right px-1 font-weight-bold">
            {{"PRODUCTS.CONFIRMATION.PRODUCTLISTS.GENERAL.TABLEHEADERS.count" | translate}}
        </div>
        <div class="col-5 text-right px-1 font-weight-bold">
            {{"PRODUCTS.CONFIRMATION.PRODUCTLISTS.GENERAL.TABLEHEADERS.installationYear" | translate}}
        </div>
    </div>
    <div *ngFor="let detector of detectors" class="row py-2 border-top">
        <div class="col-5 px-1 font-weight-bold">
            {{"PRODUCTS.IDENTIFICATION.SELECTION_VALUES.PRODUCT_GROUP." + detector.groupType | translate}}
        </div>
        <div class="col-2 text-right px-1">
            {{detector.count}}
        </div>
        <div class="col-5 text-right px-1">
            {{detector.installationYear}}
        </div>
    </div>
</ng-container>