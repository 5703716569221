<div class="container">
    <div class="col-12 col-md-10 mx-auto">
        <div class="row">
            <sl-back-navigation [buttonText]="'RECLAMATION_START.DETECTOR_RECLAMATION.backLinkText' | translate">
            </sl-back-navigation>
        </div>
        <div class="row py-3">
            <h1>{{'REGISTRATION.LABELS.address' | translate}}</h1>
        </div>

        <div class="row">
            <p class="lead">{{'REGISTRATION.ADDRESS_INFORMATION.lead' | translate}}</p>
        </div>

        <div class="row py-3">
            <sl-address-form class="w-100" (next)="next($event)" [storedAddressData]="storedAddressData"
                             [customerType]="customerType" [calledFrom]="RegistrationComponents.ADDRESS">
            </sl-address-form>
        </div>
    </div>
</div>