<div class="container">
    <div class="col-12 col-md-10 mx-auto" *ngIf="!loading && reclamation">
        <div class="row flex-column mt-3">
            <sl-back-navigation class="mt-2"
                                [buttonText]="'RECLAMATION_START.DETECTOR_RECLAMATION.backLinkText' | translate"
                                [icon]="'fa-arrow-left'">
            </sl-back-navigation>
        </div>
        <div class="row my-3 flex-column">
            <h1>
                {{'ACCOUNT.RECLAMATIONS.DETAILS.title' | translate:{serviceReportNr: reclamationNumber} }}
            </h1>
            <p class="subline">
                {{'ACCOUNT.RECLAMATIONS.DETAILS.subTitle' | translate:{
                createdAt: reclamation.createdAt | date : 'dd.MM.yyyy',
                objectName: reclamation.objectName }
                }}
            </p>
        </div>

        <div *ngIf="isSupportApp && reclamation.completedLate" class="row info-outlet-wrapper">
            <div class="d-flex justify-content-center">
                <span class="text-muted ml-2">{{'ACCOUNT.RECLAMATIONS.SERIAL_NUMBER_INPUT_TOOLTIP_TIMEOUT.text' | translate }}</span>
            </div>
        </div>

        <ng-container *ngIf="reclamation.customerType === CustomerType.Commercial">
            <div class="row mb-2" *ngIf="reclamation.objectType">
                <div class="mr-2 colon-after">{{'ACCOUNT.RECLAMATIONS.DETAILS.objectTyp' | translate}}</div>
                <div>{{('PRODUCTS.IDENTIFICATION.SELECTION_VALUES.OBJECT_TYPES.' + reclamation.objectType | translate)}}</div>
            </div>
        </ng-container>

        <div class="row mt-4">
            <div class="mr-2 colon-after">{{'ACCOUNT.RECLAMATIONS.DETAILS.supplySource' | translate}}</div>
            <div>{{reclamation.supplySource ? ('PRODUCTS.IDENTIFICATION.SELECTION_VALUES.PLACE_OF_PURCHASE.' + reclamation.supplySource | translate) : '-'}}</div>
        </div>

        <sl-reported-serials-list [serials]="reclamation?.serials"></sl-reported-serials-list>
        <sl-reported-detectors-list [detectors]="reclamation?.detectors"></sl-reported-detectors-list>

        <div class="row mt-4">
            <div class="col-12 p-0">
                <!--Info Outlet Serial Numbers Missing-->
                <div class="info-outlet-wrapper " *ngIf="!reclamation.finished">
                    <div class="d-flex justify-content-center info-outlet-warning">
                    <span class="ml-2">{{'ACCOUNT.RECLAMATIONS.DETAILS.serialNumbersMissing' | translate}} <a
                            [routerLink]="[isReklaApp ? '../seriennummern/'+reclamation.id : '../../seriennummern/'+reclamation.id ]">
                    <span>{{'ACCOUNT.RECLAMATIONS.DETAILS.addSerialNumbersNow' | translate}}</span>
                    </a></span>
                    </div>
                </div>
            </div>

        </div>

        <sl-cost-product-list [detectors]="reclamation?.detectorsToExchange" [free]="true"></sl-cost-product-list>

        <sl-cost-product-list [detectors]="reclamation?.detectorsToBuy" [free]="false"></sl-cost-product-list>

        <sl-payment-details [paymentInformation]="reclamation.paymentInformation"></sl-payment-details>

        <div class="row mt-4 mb-3 pl-1 align-items-center">
            <div class="col-12 col-sm-6  pl-0">
                <sl-back-navigation *ngIf="userId" [route]="'/user/reclamations/'+userId"
                                    [buttonText]="'USER.RECLAMATIONS.BUTTONS.backToList' | translate"></sl-back-navigation>
                <sl-back-navigation *ngIf="!userId" [buttonText]="'ACCOUNT.RECLAMATIONS.DETAILS.backBtn' | translate"
                ></sl-back-navigation>
            </div>
        </div>
    </div>
</div>
