<rp-header [loggedIn]="loggedIn"
           [guest]="guest"
           [username]="username"
           [notice]="notice"
           [menuItems]="menuItemsService.getMenuItems()"></rp-header>

<div class="container-fluid h-100">
    <!--MAIN-->
    <div class="row h-100">
        <!--MAIN CONTENT-->
        <div id="main-content" class="mx-auto px-3 px-sm-5 px-md-0 d-flex flex-column">
            <div class="sticky-footer-main-content">
                <router-outlet></router-outlet>
            </div>

            <!--FOOTER-->
            <rp-footer class="mt-5 sticky-footer-footer"></rp-footer>

            <!--CONTACT-->
            <rp-hvg-contact></rp-hvg-contact>
        </div>
    </div>
</div>
