import { HttpParameterCodec, HttpParams, HttpUrlEncodingCodec } from '@angular/common/http';

/**
 * Strictly encodes all key/values. Default implementation(HttpUrlEncodingCodec) replaces some encoded values(like %2B)
 * with the decoded values(e.g. %2B -> +). This can cause issues(when decoding a + is replaced with a space!), which
 * is why this class simply encodes the key/value using encodeURIComponent without decoding certain values again.
 */
export class StrictHttpUrlEncodingCodec extends HttpUrlEncodingCodec {

    encodeKey(k:string):string {
        return encodeURIComponent(k);
    }

    encodeValue(v:string):string {
        return encodeURIComponent(v);
    }
}

/**
 * Class that uses a StrictHttpUrlEncodingCodec by default(instead of HttpUrlEncodingCodec).
 */
export class StrictHttpParams extends HttpParams {
    constructor(options?: {
        fromString?: string;
        encoder?: HttpParameterCodec; }) {
        if(!options) {
            options = {};
        }
        if(!options.encoder) {
            options.encoder = new StrictHttpUrlEncodingCodec();
        }
        super(options);
    }
}
