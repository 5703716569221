import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbDropdownModule, NgbModalModule, NgbPopoverModule } from '@ng-bootstrap/ng-bootstrap';
import { SharedRoutingModule } from './shared-routing.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TextareaComponent } from './components/textarea/textarea.component';
import { PopoverComponent } from './components/popover/popover.component';
import { FooterComponent } from './components/footer/footer.component';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { PasswordComponent } from './components/password/password.component';
import { HeaderComponent } from './components/header/header.component';
import { TranslateModule } from '@ngx-translate/core';
import { SharedLibModule } from 'shared-lib';
import { PaymentLogosComponent } from './components/footer/payment-logos/payment-logos.component';

@NgModule({
    declarations: [
        TextareaComponent,
        PopoverComponent,
        FooterComponent,
        SidebarComponent,
        PasswordComponent,
        HeaderComponent,
        PaymentLogosComponent
    ],
    imports: [
        CommonModule,
        FormsModule,
        NgbPopoverModule,
        NgbModalModule,
        ReactiveFormsModule,
        SharedRoutingModule,
        NgbDropdownModule,
        TranslateModule,
        SharedLibModule
    ],
    exports: [
      FooterComponent,
      PaymentLogosComponent,
        PopoverComponent,
        TextareaComponent,
        SidebarComponent,
        PasswordComponent,
        HeaderComponent,
        TranslateModule,
    ]
})
export class SharedModule {
}
