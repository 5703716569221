<div class="d-flex flex-column w-100">
    <sl-form-item  
      [value]="value ? value : ''" 
      (change)="change($event)" 
      [inputType]="FormInputType.TEXTAREA" 
      [formGroup]="formGroup" 
      [controlName]="controlName"
      [labelText]="label" 
      [helperText]="helpText"
      [placeholderText]="placeholder">
    </sl-form-item>
</div>
