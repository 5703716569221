<div class="container">
    <div class="col-12 col-md-10 mx-auto my-3">
        <div class="row">
            <sl-back-navigation class="mt-2"
                                [route]="distinguishService.support ? 'produkte/start/'+userId : 'produkte/auswahl-reklamation/'"
                                [buttonText]="'RECLAMATION_START.DETECTOR_RECLAMATION.backLinkText' | translate"
                                [icon]="'fa-arrow-left'"></sl-back-navigation>
        </div>
        <div class="row my-3 py3">
            <h1>{{'PRODUCTS.IDENTIFICATION.NO_SERIALS.title' | translate}}</h1>
            <div [innerHTML]="'PRODUCTS.IDENTIFICATION.NO_SERIALS.lead' | translate"></div>
        </div>

        <div class="row my-3 py3">
            <h3>{{'PRODUCTS.IDENTIFICATION.NO_SERIALS.subTitle' | translate}}</h3>
            <div [innerHTML]="'PRODUCTS.IDENTIFICATION.NO_SERIALS.subLead' | translate" ></div>
        </div>

        <div *ngFor="let product of products; let i = index;trackBy: trackByFn" class="pb-3">
            <sl-no-serials-form *ngIf="customerType"
                                (productToDelete)="deleteProduct($event)"
                                (updatedProduct)="updateProduct($event)"
                                [formSelectionValues]="dropDownValues | async"
                                [productIndex]="i"
                                [product]="product"
                                [products]="products"
                                [totalProducts]="products.length - 1"
                                [address]="address"
                                [customerType]="customerType"
                                attr.id="form-{{i}}">
            </sl-no-serials-form>
        </div>

        <div class="row mt-3 justify-content-start">
            <button (click)="proceedToNext()"
                    class="btn btn-primary mr-2"
                    type="submit">
                <span>{{'PRODUCTS.IDENTIFICATION.BUTTONS.nextStep' | translate}}</span>
            </button>
            <button (click)="newProduct()"
                    class="btn btn-tertiary mt-3 mt-md-0">
                <span>{{'PRODUCTS.IDENTIFICATION.BUTTONS.moreProducts' | translate}}</span>
            </button>
        </div>
    </div>
</div>
