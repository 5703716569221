import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { CustomerType, FormInputType, FormSelectionObjects, ObjectInformationDetailed, Product } from '../../models';

@Component({
    selector: 'sl-product-form-object-group',
    templateUrl: './product-form-object-group.component.html',
    styleUrls: []
})
export class ProductFormObjectGroupComponent implements OnInit {

    @Input() product: Product;
    @Input() address: string;
    @Input() productForm: FormGroup;
    @Input() searchOff: boolean;
    @Input() customerType: CustomerType;
    @Input() formSelectionValues: FormSelectionObjects;

    public CustomerType = CustomerType;

    constructor(private formBuilder: FormBuilder) {
    }

    ngOnInit(): void {
        if (this.customerType !== CustomerType.Guest) {
            this.productForm = this.formBuilder.group(
                {
                    objectType: [this.customerType === CustomerType.Commercial
                        ? this.product?.objectType
                        : 1029,
                        Validators.required],
                    objectName: [
                        this.customerType === CustomerType.Commercial ? this.product?.objectName
                            : this.address,
                        [Validators.required, Validators.maxLength(80)]]
                }
            );
        }else {
            this.productForm = this.formBuilder.group({});
        }

        if (this.customerType === CustomerType.Commercial) {
            this.productForm.addControl('numberRange', new FormControl(this.product?.numberRange, Validators.required));
        } else {
            this.productForm.addControl('number', new FormControl(this.product?.number, {
                validators: [Validators.required, Validators.min(1), Validators.max(10000)]
            }));
        }
    }

    /**
     * @ignore
     */
    ngOnChanges(changes: SimpleChanges): void {
        if (!this.product?.objectName && this.customerType === CustomerType.Commercial) {
            return;
        }
        if ('address' in changes && this.customerType !== CustomerType.Commercial && this.productForm) {
            this.productForm.controls['objectName'].setValue(this.address);            
        }
        if ('product' in changes && this.productForm) {
            this.decideSetValueObjectName();
        }
    }

    private decideSetValueObjectName() {
        if (this.customerType === CustomerType.Commercial) {
            this.productForm.controls['objectName']?.setValue(this.product?.objectName);
        } else {
            this.productForm.controls['objectName']?.setValue(this.address);
        }
    }

    get FormInputType() {
        return FormInputType;
    }

    /**
     * Callback for object chosen in search slot
     * @param object
     */
    public objectChosen(object: ObjectInformationDetailed | Product) {
        if (this.customerType === CustomerType.Commercial) {
            if (object && object.objectName && this.productForm.get('objectName').value !== object.objectName) {
                this.productForm.get('objectName').setValue(object.objectName);
            }
        }
        this.productForm.get('objectType').setValue(object ? object.objectType : null);
        this.customerType === CustomerType.Commercial
            ? this.productForm.get('numberRange').setValue(object ? object.numberRange : null)
            : this.productForm.get('number').setValue(object ? object.number : null);
    }
}
