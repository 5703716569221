<div class="container">
    <div class="col-12 col-md-10 mx-auto">
        <div class="row">
            <sl-back-navigation [buttonText]="'RECLAMATION_START.DETECTOR_RECLAMATION.backLinkText' | translate">
            </sl-back-navigation>
        </div>

        <div class="row py-3">
            <h3>{{"REGISTRATION.LICENSE.title" | translate}}</h3>
        </div>

        <div class="row">
            <p class="lead">
                {{"REGISTRATION.LICENSE.leadText" | translate}}
            </p>
        </div>

        <div class="py-3">
            <form [formGroup]="licenseForm">
                <sl-form-item [formGroup]="licenseForm"
                              [controlName]="'businessCustomerType'"
                              [inputType]="FormInputType.RADIO"
                              [hasBorder]="true"
                              [selectionValues]="options"></sl-form-item>
            </form>
        </div>
        <div class="row">
            <button class="btn btn-primary" (click)="submit()" type="submit">
                {{'REGISTRATION.BUTTONS.nextButton' | translate}}
            </button>
        </div>
    </div>
</div>