import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl, FormGroup } from '@angular/forms';
import { DropdownValue, FormInputType } from '../../models';
import { CheckboxOption } from '../../models';

@Component({
    selector: 'sl-form-item',
    templateUrl: './form-item.component.html',
    styles: []
})
export class FormItemComponent implements OnInit {
    @Input() inputType:FormInputType = FormInputType.TEXT;
    @Input() formGroup:FormGroup;
    @Input() controlName:string;
    @Input() helperText:string;
    @Input() labelText:string;
    @Input() placeholderText:string;
    @Input() errorText:string;
    @Input() isReadOnly:boolean;
    @Input() dropdownValues:DropdownValue;
    @Input() textAreaRows:number;
    @Input() selectionValues:CheckboxOption[];
    @Input() submitted:boolean;
    @Input() isInnerHtmlLabel: boolean;
    @Input() isInnerHtmlHelper: boolean;
    @Input() hasBorder: boolean;
    @Input() value: string | boolean;

    public control:AbstractControl;
    public FormInputType = FormInputType;
    public showPassword:boolean;

    constructor() {
    }

    ngOnInit():void {
        this.control = this.formGroup.get(this.controlName);
    }

    ngOnChanges():void {
        this.control = this.formGroup.get(this.controlName);
    }

    public getErrors() {
        return Object.keys(this.control.errors);
    }

}
