<form [formGroup]="formGroup" *ngIf="control">
    <div class="form-row align-items-end mt-2 mb-3">
        <div class="col-12" [ngSwitch]="inputType">
            <input *ngSwitchDefault
                   [ngClass]="{
                          'is-valid': control && (control.dirty || control.touched) && !control.errors,
                          'is-invalid': control && (control.dirty || control.touched || submitted) && control.errors
                          }"
                   [formControlName]="controlName"
                   [id]="controlName"
                   [name]="controlName"
                   [readOnly]="isReadOnly"
                   [type]="inputType"
                   class="form-control"
                   placeholder="{{placeholderText | translate}}"/>

            <input *ngSwitchCase="FormInputType.PASSWORD"
                   [name]="controlName"
                   [ngClass]="{
                            'is-valid': control && (control.dirty || control.touched) && !control.errors,
                            'is-invalid': control && (control.dirty || control.touched || submitted) && control.errors
                            }"
                   [formControlName]="controlName"
                   [readOnly]="isReadOnly"
                   [type]="showPassword ? 'text' : 'password'"
                   class="form-control"
                   [id]="controlName"
                   placeholder="{{placeholderText | translate}}"/>

            <textarea *ngSwitchCase="FormInputType.TEXTAREA"
                      [formControlName]="controlName"
                      [id]="controlName"
                      [name]="controlName"
                      [readOnly]="isReadOnly"
                      [rows]="textAreaRows || 3"
                      [value]="value"
                      [ngClass]="{
                          'is-valid': control && (control.dirty || control.touched) && !control.errors,
                          'is-invalid': control && (control.dirty || control.touched || submitted) && control.errors
                          }"
                      class="w-100 form-control"
                      placeholder="{{placeholderText | translate}}"></textarea>

            <select *ngSwitchCase="FormInputType.DROPDOWN"
                    class="form-control"
                    [formControlName]="controlName"
                    [id]="controlName"
                    [name]="controlName"
                    [ngClass]="{
                'is-valid': control && (control.dirty || control.touched || submitted) && !control.errors,
                'is-invalid': control && (control.touched || control.dirty || control.value || submitted) && control.invalid && control.errors}">
                <option disabled value="null">{{placeholderText | translate}}</option>
                <option *ngFor="let dropdown of dropdownValues | keyvalue"
                        [value]="dropdown.key">{{dropdown.value | translate}}</option>
            </select>

            <ng-container *ngSwitchCase="FormInputType.CHECKBOX">
                <div *ngFor="let selectionValue of selectionValues; let i = index"
                     [ngClass]="{'pb-3': i !== selectionValues.length - 1}"
                     class="form-row">
                    <div class="form-check form-check-inline">
                        <input class="form-check-input"
                               type="checkbox"
                               [formControlName]="controlName"
                               [name]="controlName"
                               [id]="selectionValue.value"
                               [readOnly]="isReadOnly"
                               [value]="selectionValue.value"
                               [ngClass]="{
                'is-valid': (control.dirty || control.touched || submitted) && !control.errors,
                'is-invalid': (control.touched || control.dirty || control.value || submitted) && control.invalid && control.errors}"/>
                        <label *ngIf="!isInnerHtmlLabel" class="form-label"
                               [for]="selectionValue.value">
                            {{selectionValue.labelText | translate}}
                        </label>
                        <label *ngIf="isInnerHtmlLabel" class="form-label"
                               [for]="selectionValue.value" [innerHtml]="selectionValue.labelText">
                        </label>
                    </div>
                </div>
            </ng-container>

            <ng-container *ngSwitchCase="FormInputType.RADIO">
                <div *ngFor="let selectionValue of selectionValues; let i = index"
                     [ngClass]="{'pb-3': i !== selectionValues.length - 1 && hasBorder, 'row' : !hasBorder, 'form-row': hasBorder}"
                     class="form-row">
                    <div class="form-check form-check-inline"
                         [ngClass]="{'col-12 border p-3' : hasBorder}">
                        <input class="form-check-input"
                               type="radio"
                               [formControlName]="controlName"
                               [name]="controlName"
                               [id]="selectionValue.id"
                               [readOnly]="isReadOnly"
                               [value]="selectionValue.value"
                               [ngClass]="{
                'is-valid': control && (control.dirty || control.touched || submitted) && !control.errors,
                'is-invalid': control && (control.touched || control.dirty || control.value || submitted) && control.invalid && control.errors}"/>
                        <label class="form-label"
                               [for]="selectionValue.id">
                            {{selectionValue.labelText | translate}}
                        </label>
                    </div>
                </div>
            </ng-container>

            <ng-container *ngIf="!isReadOnly && labelText">
                <label [ngClass]="{
                   'form-label-upper': control && (control.value && control.value !== null && control.value !== ''),
                   'form-label': control && (!control.value || control.value === null || control.value === '')
                   }"
                       [for]="controlName">
                    {{labelText | translate}}
                </label>
            </ng-container>
            <div *ngSwitchCase="FormInputType.PASSWORD" id="toggle" class="inner-addon right-addon">
                <i alt="show" class="fa fa-eye eye-show" (click)="showPassword = !showPassword"
                   [class.hide]="showPassword"></i>
                <i alt="hide" class="fa fa-eye-slash eye-hide" (click)="showPassword = !showPassword"
                   [class.hide]="!showPassword"></i>
            </div>
        </div>
        <div *ngIf="control && (control.dirty || control.touched || submitted) && control.errors"
             class="invalid-feedback pl-2 pt-1 d-block">
            <ng-container *ngFor="let error of getErrors()">
                <div>{{'GENERAL.VALIDATION_ERRORS.' + error | translate}}</div>
            </ng-container>
        </div>
        <div *ngIf="helperText && !isInnerHtmlHelper"
             class="form-control-feedback pl-2 pt-1 d-block">
            {{helperText | translate}}
        </div>
        <div *ngIf="helperText && isInnerHtmlHelper"
             class="form-control-feedback pl-2 pt-1 d-block"
             [innerHtml]="helperText">
        </div>
    </div>
</form>
