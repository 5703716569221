import { Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import {
    CustomerType,
    DistinguishSupportService,
     productActions, ReclamationDetailed,
    ReclamationService, RoutePathName,
    User
} from '../../../shared';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { State } from '../../../../reducers/root/root.reducer';
import { Subject } from 'rxjs';
import { concatMap, takeUntil } from 'rxjs/operators';
import * as evaluationActions from '../../../shared/reducers/evaluation/evaluation.actions';
import { Serial } from '../../../shared/models/product.model';

@Component({
    selector: 'sl-late-reclamation-confirmation',
    templateUrl: './late-reclamation-confirmation.component.html',
    styles: [],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class LateReclamationConfirmationComponent implements OnInit {
    public reclamationNumber:string;
    public products:Serial[] = [];
    public loadingData:boolean;

    private _unsubscribe = new Subject();
    private user:User;

    constructor(private router:Router,
                private route:ActivatedRoute,
                private store:Store<State>,
                private distinguishSupportService:DistinguishSupportService,
                private reclamationService:ReclamationService,
                private CD:ChangeDetectorRef) {
    }

    ngOnInit():void {
        if (this.distinguishSupportService.support) {
            this.user = JSON.parse(sessionStorage.getItem('user'));
        } else {
            this.user = JSON.parse(sessionStorage.getItem('currentUser'));
        }
        this.getReclamationDetails();
    }

    ngOnDestroy():void {
        this._unsubscribe.next();
        this._unsubscribe.complete();
    }

    public userReclamations() {
        this.store.dispatch(new productActions.DeleteAllProductAction());
        this.store.dispatch(new evaluationActions.DeleteReclamationAction());
        this.router.navigate([this.distinguishSupportService.support
            ? `${RoutePathName.USER}/${RoutePathName.RECLAMATIONS}/${this.user.id}`
            : `${RoutePathName.SERVICE_MESSAGE}`
        ]);
    }

    public newReclamation() {
        this.store.dispatch(new productActions.DeleteAllProductAction());
        this.store.dispatch(new evaluationActions.DeleteReclamationAction());
        this.router.navigate([this.distinguishSupportService.support
            ? `${RoutePathName.PRODUCT}/${RoutePathName.START}/${this.user.id}`
            : `${RoutePathName.PRODUCT}/${RoutePathName.START}`
        ]);
    }

    private getReclamationDetails() {
        this.route.params.pipe(
            concatMap((params:Params) => this.reclamationService.getReclamation(params.id)),
            takeUntil(this._unsubscribe))
            .subscribe((reclamation: ReclamationDetailed) => {
                this.products = reclamation.serials
                if (!this.products || this.products.length <= 0) {
                    const reroute:string = this.distinguishSupportService.support
                        ? this.user
                            ? '/user/reclamations/' + this.user.id
                            : '/user'
                        : this.user?.customerType === CustomerType.Commercial
                            ? '/produkte/start'
                            : '/produkte/auswahl-reklamation';
                    this.router.navigate([reroute]);
                }
                 else {
                    this.loadingData = true;
                    this.CD.markForCheck();
                }
                this.reclamationNumber = this.reclamationService.calculateReclamationNumber(reclamation);
            });
    }

}
