import { Action } from '@ngrx/store';

export const SAVE_ADDRESS = '[Address] Save Address';
export const DELETE_ADDRESS = '[Address] Delete Address';

/**
 * Call this action when you want to Save an Address
 */
export class SaveAddressAction implements Action {
    readonly type = SAVE_ADDRESS;

    /**
     * @param {string} payload
     */
    constructor(public payload:string) {
    }
}

/**
 * Call this action when you want to Delete an Address
 */
export class DeleteAddressAction implements Action {
    readonly type = DELETE_ADDRESS;

    /**
     * @ignore
     */
    constructor() {
    }

}

export type AddressActions = SaveAddressAction | DeleteAddressAction;
