<div class="container" *ngIf="showCopy; else form">
    <div class="col-12 col-md-10 mx-auto">
        <ng-container>
            <div class="row">
                <sl-back-navigation [buttonText]="'RECLAMATION_START.DETECTOR_RECLAMATION.backLinkText' | translate">
                </sl-back-navigation>
            </div>
            
            <div class="row py-3">
                <h3>{{"REGISTRATION.BUSINESS_CUSTOMER_TYPE.title" | translate}}</h3>
            </div>

            <div class="row">
                <p class="lead">
                    {{"REGISTRATION.BUSINESS_CUSTOMER_TYPE.leadText" | translate}}
                </p>
            </div>
        </ng-container>
        <ng-container *ngTemplateOutlet="form"></ng-container>
        <div class="row">
            <button class="btn btn-primary" (click)="submit()" type="submit">
                {{'REGISTRATION.BUTTONS.nextButton' | translate}}
            </button>
        </div>
    </div>
</div>

<ng-template #form>
    <div class="py-3">
        <form [formGroup]="formGroup">
            <sl-form-item [formGroup]="formGroup"
                          [controlName]="'businessCustomerType'"
                          [inputType]="FormInputType.RADIO"
                          [hasBorder]="true"
                          [selectionValues]="options"></sl-form-item>
        </form>
    </div>
</ng-template>