<div class="row py-3" [routerLink]="routeUrl" slFocus >
    <div class="col-12 px-0">
        <div class="card cursor-pointer">
            <div class="card-body">
                <div class="card-title">
                    <ng-container *ngIf="biggerTitle; else normalTitle">
                        <h3 class="d-flex justify-content-between">
                            {{titleText | translate}}
                            <i class="fa fa-angle-right" aria-hidden="true"></i></h3>
                    </ng-container>
                    <ng-template #normalTitle>
                        <h5 class="d-flex justify-content-between">
                            {{titleText | translate}}
                            <i class="fa fa-angle-right" aria-hidden="true"></i>
                        </h5>
                    </ng-template>
                </div>
                <div></div>
                <div class="card-text">
                    <div [innerHTML]="mainText | translate"></div>
                </div>
            </div>
        </div>
    </div>
</div>
