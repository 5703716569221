import { Injectable } from '@angular/core';

/**
 * Service that distingushes whether the library is used in support app or in rekla app
 */
@Injectable()

export class DistinguishSupportService {
    private _isSupport:boolean;

    constructor() {
    }

    get support():boolean {
        return this._isSupport;
    }

    set support(isSupport:boolean) {
        this._isSupport = isSupport;
    }
}
