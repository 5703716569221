import {
    Component,
    OnInit,
    ChangeDetectionStrategy,
    Input,
    ChangeDetectorRef
} from '@angular/core';
import { Subject, Observable, EMPTY, of } from 'rxjs';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import {
    FormDataService,
    FormInputType,
    InfoBannerType,
    LoginData,
    LoginPageLang,
    RecaptchaService,
    User,
    UtilsService,
    UserService,
    RegisterAddress,
    AddressInformation,
    RegisterAccount,
    registerActions, DistinguishSupportService
} from '../../../../shared';
import { mergeMap, takeUntil, tap, take } from 'rxjs/operators';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ForgotPassModalComponent } from '../forgot-pass-modal/forgot-pass-modal.component';
import { Registration } from '../../../../shared/reducers/registration/register.reducer';
import { Store } from '@ngrx/store';
import { State } from '../../../../../reducers/root/root.reducer';


@Component({
    selector: 'sl-login-form-control',
    templateUrl: './login-form-control.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class LoginFormControlComponent extends LoginPageLang implements OnInit {

    @Input() redirectRoute:string;
    @Input() showAlternativeOptions:boolean = true;
    public InputType = FormInputType;
    public loginForm:FormGroup;
    public bannerTextKey:string;
    public bannerType:InfoBannerType;
    public showBanner:boolean;
    public requestLoginAction:Observable<unknown>;
    public clickedByEnter: boolean = false;
    private unsubscribe$ = new Subject<any>();

    constructor(
        public Recaptcha:RecaptchaService,
        private utilsService:UtilsService,
        private userService:UserService,
        private formDataService:FormDataService,
        private CD:ChangeDetectorRef,
        private ngbModal:NgbModal,
        private store:Store<State>,
        private distinguishSupportService:DistinguishSupportService) {
        super();
    }

    ngOnDestroy():void {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    ngOnInit():void {
        this.createForm();
        this.login();
        this.CD.markForCheck();
    }

    get email() {
        return this.loginForm.get('email');
    }

    get password() {
        return this.loginForm.get('password');
    }

    private login() {
        this.requestLoginAction = this.utilsService.createActionObs(() => {
            this.clickedByEnter = false;
            if (!this.loginForm.valid) {
                console.log('got here');
                this.formDataService.markControlsTouched(this.loginForm);
                return EMPTY;
            }

            if (this.email.errors) {
                // this.handleError('EMAIL_ERROR');
                return EMPTY;
            }
            if (this.password.errors) {
                // this.handleError('PASSWORD_ERROR');
                return EMPTY;
            }
            return this.makeLoginCall();
        });
    }

    public onEnterPressed() {
        this.clickedByEnter = true;
    }

    private makeLoginCall():Observable<unknown> {
        return this.Recaptcha.getToken('login', true).pipe(
            mergeMap(response => {
                return this.userService.loginAndUpdateStoredData(LoginFormControlComponent.getLoginObj(this.email.value, this.password.value, response),
                    false);
            }),
            mergeMap(() => {
                return sessionStorage.getItem('registrationIncomplete') ? this.userService.getCurrentUser() : of(null);
            }),
            this.makeLoginResponse(),
            takeUntil(this.unsubscribe$));
    }

    private createForm() {
        this.loginForm = new FormGroup(LoginFormControlComponent.getLoginFormControl());
    }

    private static getLoginFormControl() {
        return {
            email: new FormControl(null),
            password: new FormControl(null)
        };
    }

    private makeLoginResponse() {
        return tap(
            (user) => {
                if (user) {
                    this.saveToStore(user);
                }
                this.userService.restoreSavedRoute(this.redirectRoute);
            },
            (err) => {
                this.decideTypeOfBEError(err);
            }
        );
    }

    private decideTypeOfBEError(err:any) {
        this.onWithWrongEmailAndPass(err);
        this.onWithRightEmailAndPass(err);
    }

    private onWithRightEmailAndPass(err:any) {
        if (LoginFormControlComponent.isErrorStatus403(err) && LoginFormControlComponent.getErrorCode(err)) {
            this.handleError(err?.error?.errors[0]?.code);
        }
    }

    private onWithWrongEmailAndPass(err:any) {
        if (LoginFormControlComponent.isErrorStatus401(err)) {
            this.handleError('NOT_AUTHORIZED');
        }
    }

    private static isErrorStatus401(err:any):boolean {
        return err?.status === 401;
    }

    private static getErrorCode(err:any):string {
        return err?.error?.errors[0]?.code;
    }

    private static isErrorStatus403(err:any):boolean {
        return err?.status === 403;
    }

    private static getLoginObj(email:string, password:string, response:string):LoginData {
        return {
            username: email,
            password,
            reCaptchaResponse: response
        };
    }

    private handleError(code:any) {
        this.setBannerState(InfoBannerType.DANGER);
        this.getBannerErrorText(code);
    }

    private getBannerErrorText(code:any) {
        switch (code) {
            case 'ERROR_NOT_VERIFIED':
                this.bannerTextKey = this.LoginPage.email_not_verified;
                break;
            case 'EMAIL_ERROR':
                this.bannerTextKey = this.LoginPage.email_error;
                break;
            case 'PASSWORD_ERROR':
                this.bannerTextKey = this.LoginPage.password_error;
                break;
            case 'NOT_AUTHORIZED':
                this.bannerTextKey = this.LoginPage.not_authorized;
                break;
            case 'ERROR_INVALID_CAPTCHA':
                this.bannerTextKey = 'ERRORS.ERROR_ACCESS_DENIED.text';
                break;
            default:
                this.bannerTextKey = this.LoginPage.login_failed;
                break;
        }
    }

    private setBannerState(type:InfoBannerType):void {
        this.bannerType = type;
        this.showBanner = true;
    }

    private saveToStore(user:User) {
        const addressInformation:AddressInformation = user.addressInformation;
        const address:RegisterAddress = {
            street: addressInformation.address,
            addressExtension: addressInformation.address2,
            city: addressInformation.city,
            country: addressInformation.country,
            zip: addressInformation.zip,
            phone: user.phone,
            customerNumber: user.customerNumber,
            company: user.company
        };
        const account:RegisterAccount = {
            salutation: user.salutation,
            firstName: user.firstname,
            lastName: user.lastname,
            email: user.email
        };

        const registrationData:Registration = {
            customerType: user.customerType,
            account: account,
            address: address,
            businessCustomerType: user.businessCustomerType,
            externalData: null
        };
        this.store.dispatch(new registerActions.AddUserInfo(registrationData));
    }

    public openEmailForgotModal() {
        this.createForm();
        this.email.updateValueAndValidity();
        this.ngbModal.open(ForgotPassModalComponent,
            {
                centered: true,
                ariaLabelledBy: 'modal-basic-title',
                backdropClass: 'modal-backdrop'
            });
    }

}
