/**
 * Add the base and the keys together
 * @param obj
 * @param base
 */
export function processObjRec(obj:any, base:string) {
    for (const key of Object.keys(obj)) {
        switch (typeof obj[key]) {
            case 'string':
                obj[key] = base + obj[key];
                break;
            case 'object':
                processObjRec(obj[key], base);
                break;
        }
    }
}

/**
 * Set the default language
 */
export const defaultLang = 'de';

/**
 * Consts for Path
 */
export const PATH = 'v1.39/';
/**
 * Const for Host
 */
export const HOST = '';

/**
 * Consts for Endpoints
 */
export const API = {
    langFile: 'system/lang',
    product: {
        serialCheck: 'serial/{0}/check'
    },
    submission: {
        evaluation: 'reclamation/type',
        reclamation: 'reclamation'
    },
    image: {
        imageURL: 'article/{0}/image'
    },
    user: {
        registration: 'user/register',
        login: 'login',
        logout: 'logout',
        status: 'user/status',
        current: 'user',
        object: 'user/object',
        passwordRegEx: 'user/password-regex',
        passwordReset: 'user/password-reset',
        passwordResetTokenStatus: 'user/password-reset/{0}/status',
        passwordResetWithToken: 'user/password-reset/{0}',
        requestConfirmation: 'user/email/confirm',
        feedback: 'user/feedback',
        feedbackShown: 'user/feedback-shown',
        completeReg: 'user/complete-registration-data'
    },
    reclamation: {
        list: 'reclamation',
        details: 'reclamation/{0}'
    }
};
