import { Component, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { EMPTY, Observable, Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';
import { UserService, UtilsService } from 'shared-lib';
import { InfoBannerType } from 'shared-lib';

/**
 * Component to allow the user to reset their password
 *
 * @alias PasswordResetComponent
 */
@Component({
    selector: 'rp-password-reset',
    templateUrl: './password-reset.component.html',
    styles: []
})
export class PasswordResetComponent implements OnInit, OnDestroy {

    public passwordRegExp: RegExp;
    public passwordResetForm: FormGroup;
    public passwordResetAction: Observable<any>;
    public submitted: boolean;
    public formCreated: boolean = false;
    public bannerType: InfoBannerType;
    public showBanner: boolean = false;
    public bannerTextKey: any;
    private supportCreated: boolean;
    private username: string;
    private _unsubscribe = new Subject();
    private token: string;


    public onPatchMainForm(event) {
        this.passwordResetForm.get('password').patchValue(Object(event?.value).password);
        this.passwordResetForm.get('passwordConfirmation').patchValue(Object(event?.value).passwordConfirmation);

    }

    /**
     * Getter for password field on the passwordResetForm
     */
    get password(): AbstractControl {
        return this.passwordResetForm.get('password');
    }

    /**
     *
     * @param {FormBuilder} formBuilder
     * @param {UserService} userService
     * @param {ActivatedRoute} route
     * @param {Router} router
     * @param {UtilsService} utilsService
     * @param {ToastsService} toasts
     */
    constructor(private formBuilder: FormBuilder,
        private userService: UserService,
        private route: ActivatedRoute,
        private router: Router,
        private utilsService: UtilsService
    ) {

        this.getUrlParams();

        this.passwordResetAction = this.utilsService.createActionObs(() => {
            this.submitted = true;

            if (this.passwordResetForm.invalid) {
                if (this.checkPasswords(this.passwordResetForm)) {
                    this.handleError('ERROR_NOT_SAME');
                }
                return EMPTY;
            }
            if (this.supportCreated) {
                return this.userService.passwordResetForced(this.username, this.password.value).pipe(
                    this.onTap(),
                    takeUntil(this._unsubscribe)
                );
            } else {
                return this.userService
                    .passwordResetWithToken(this.token, this.password.value)
                    .pipe(
                        this.onTap(),
                        takeUntil(this._unsubscribe)
                    );
            }

        });
    }



    /**
     * @ignore
     */
    ngOnInit() {
        this.createForm();
    }

    /**
     * @ignore
     */
    ngOnDestroy(): void {
        this._unsubscribe.next();
        this._unsubscribe.complete();
    }

    /**
     * Get the Params from the URL
     */
    public getUrlParams() {
        this.route.queryParams.subscribe(
            params => {
                if (params && params['token']) {
                    this.token = params['token'];
                    this.getPasswordResetTokenStatus(this.token);
                } else if (sessionStorage.getItem('supportCreated')) {
                    this.supportCreated = (sessionStorage.getItem('supportCreated') === 'true');
                    this.username = this.userService.currentUserStatusValue.username;
                } else {
                    this.router.navigate(['']);
                }
            }
        );
    }

    private setBannerState(type: InfoBannerType): void {
        this.bannerType = type;
        this.showBanner = true;
    }

    private handleError(code: string) {
        this.setBannerState(InfoBannerType.DANGER);
        switch (code) {
            case 'ERROR_INVALID_INPUT':
                this.bannerTextKey = 'PASSWORD_RESET.TOAST.invalidInput';
                break;
            case 'ERROR_EXPIRED':
                this.bannerTextKey = 'PASSWORD_RESET.TOAST.expiredLink';
                break;
            case 'ERROR_NOT_SAME':
                this.bannerTextKey = 'PASSWORD_RESET.TOAST.notSame';
                break;
            default:
                this.bannerTextKey = 'ERROR.common';
                break;
        }
    }

    private checkPasswords(group: FormGroup) {
        const password = group.controls['passwords'].get('password').value;
        const confirmPassword = group.controls['passwords'].get('passwordConfirmation').value;

        return password === confirmPassword ? null : { notSame: true };
    }

    private onTap() {
        return tap(
            res => {
                if (res) {
                    this.router.navigate(['']);
                }
            }, (e) => {
                this.handleError(e.error.errors[0].code);
            }
        );
    }

    /**
     * Create the Form to Reset the Password
     */
    private createForm() {
        this.passwordResetForm = this.formBuilder.group(
            {
                password: [null, Validators.required],
                passwordConfirmation: [null, Validators.required]
            });
        this.formCreated = true;
    }

    /**
     * Request the Token from the Backend for Resetting the Password
     *
     * @param {string} token
     * @returns void
     */
    private getPasswordResetTokenStatus(token: string): void {
        this.userService.passwordResetTokenStatus(token).subscribe(
            status => {
                if (status.data === 'expired') {
                    this.handleError('ERROR_EXPIRED');
                    this.router.navigate(['/']);
                }
            }
        );
    }
}
