<div class="contactflyout d-none d-lg-block" [class.active]="isActive">
    <button type="button" name="button" tabindex="-1" class="contactflyout--action" (click)="toggleIsActive()">
        <ng-container *ngTemplateOutlet="buttonIcons"></ng-container>
        <span class="button__text">{{'GENERAL.CONTACT.title' | translate}}</span>
    </button>
    <div class="contactflyout--content">
        <div class="address">
            <section class="container">
                <header>
                    <h2 class="colon-after">{{'GENERAL.CONTACT.title' | translate}}</h2>
                </header>
                <ng-container *ngTemplateOutlet="address"></ng-container>
            </section>
        </div>
        <div class="tel">
            <section class="container">
                <ng-container *ngTemplateOutlet="tel"></ng-container>
            </section>
        </div>
    </div>
</div>
<div class="d-lg-none">
    <button type="button" name="button" tabindex="-1" class="contactflyout--action-md" (click)="showModal()"
            [class.active]="isActive">
        <ng-container *ngTemplateOutlet="buttonIcons"></ng-container>
    </button>
</div>

<ng-template #buttonIcons>
    <i class="icon icon--telefon">
        <svg>
            <use href="#svg-icon-telefon">
                <svg id="svg-icon-telefon" viewBox="0 0 28.35 28.35" width="100%" height="100%">
                    <title>{{'GENERAL.CONTACT.phoneLabel' | translate}}</title>
                    <path class="st3"
                          d="M19.22,22.49c-0.69,0-1.53-0.17-2.45-0.52c-2.01-0.76-4.2-2.26-6.17-4.22c-1.97-1.97-3.47-4.16-4.23-6.17  c-0.69-1.83-0.69-3.34,0-4.02c0.1-0.1,0.2-0.2,0.3-0.31c0.63-0.66,1.34-1.4,2.28-1.37c0.65,0.03,1.28,0.43,1.92,1.23  c1.9,2.36,1.04,3.21,0.05,4.18l-0.17,0.17c-0.16,0.16-0.47,0.91,2.38,3.76c0.93,0.93,1.72,1.61,2.36,2.02  c0.4,0.26,1.11,0.65,1.41,0.36l0.18-0.18c0.97-0.99,1.82-1.84,4.18,0.06c0.8,0.64,1.2,1.27,1.23,1.92c0.04,0.94-0.71,1.65-1.37,2.28  c-0.11,0.1-0.22,0.2-0.31,0.3C20.46,22.31,19.91,22.49,19.22,22.49L19.22,22.49z"></path>
                </svg>
            </use>
        </svg>
    </i>
    <i class="icon icon--close">
        <svg>
            <use href="#svg-icon-close">
                <svg id="svg-icon-close" viewBox="0 0 20 19" width="100%" height="100%">
                    <title>{{'GENERAL.BUTTONS.close' | translate}}</title>
                    <g fill="none" fill-rule="evenodd" stroke="#FFF" stroke-linecap="square"
                       transform="rotate(45 14.274 8.218)">
                        <path stroke-width="1.5" d="M12.5,0.5 L12.5,23.5"></path>
                        <path stroke-width="1.5" d="M12,1 L12,24" transform="rotate(90 12 12.5)"></path>
                    </g>
                </svg>
            </use>
        </svg>
    </i>
</ng-template>

<ng-template #address>
    <div class="ce-textmedia row">
        <div class="col-12 col-md-6">
            <p>
                {{'GENERAL.CONTACT.company' | translate}}<br>
                {{'GENERAL.CONTACT.street' | translate}}<br>
                {{'GENERAL.CONTACT.zip' | translate}}&nbsp;{{'GENERAL.CONTACT.city' | translate}}
                <br>
            </p>
        </div>
        <div class="col-12 col-md-6">
            <p>{{'GENERAL.CONTACT.fax' | translate}}<br>
                <span>
                    <a target="_blank" href="https://www.hekatron-brandschutz.de/kontakt">{{'GENERAL.CONTACT.website' | translate}}</a>
                </span>
            </p>
        </div>
    </div>
</ng-template>

<ng-template #tel>
    <div class="ce-textmedia row">
        <div class="col-12">
            <p>
                <a href="tel:{{'GENERAL.CONTACT.phone' | translate}}"
                   title="{{'GENERAL.CONTACT.phoneTitle' | translate}}"
                   class="external-link-new-window"
                   tabindex="-1">{{'GENERAL.CONTACT.phone' | translate}}</a>
            </p>
        </div>
    </div>
</ng-template>

<ng-template #contactDetails let-modal let-d="dismiss">
    <div class="modal-header">
        <h2 class="colon-after">{{'GENERAL.CONTACT.title' | translate}}</h2>
        <button type="button" class="close" aria-label="Close" (click)="d('close')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body contactdetails">
        <div class="contactflyout--content">
            <div class="address">
                <ng-container *ngTemplateOutlet="address"></ng-container>
            </div>
            <div class="tel">
                <ng-container *ngTemplateOutlet="tel"></ng-container>
            </div>
        </div>
    </div>
</ng-template>