<div class="container">
    <div class="col-12 col-md-10 mx-auto">
        <div class="row py-2">
            <div class="col-12 col-xxl-6 mx-auto d-flex align-items-center p-0">
                    <span class="pr-3" [innerHTML]="'GENERAL.FOOTER_LINKS.imprint' | translate"></span>
                    <span class="pr-3" [innerHTML]="'GENERAL.FOOTER_LINKS.dataPrivacy' | translate"></span>
            </div>
            <div class="col-12 col-xxl-6 d-flex align-items-center justify-content-start justify-content-xxl-end p-0">
                <rp-payment-logos class="w-100"></rp-payment-logos>
            </div>
        </div>
    </div>

</div>


