import { TranslateLoader } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { XHttpClient } from 'shared-lib';
import { environment } from '../../environments/environment';

/**
 * Extension of the TranslateLoader class
 */
export class CustomTranslateLoader extends TranslateLoader {

    /**
     *
     * @param {XHttpClient} http
     */
    constructor(private http:XHttpClient) {
        super();
    }

    /**
     * Requests the language file from BE
     */
    getTranslation():Observable<any> {
        return this.http.get(environment.api.langFile);
    }
}
