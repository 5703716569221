import { Component, ElementRef, OnInit, ViewChild, Input, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { EMPTY, Observable, Subject } from 'rxjs';
import { take, tap, mergeMap } from 'rxjs/operators';

import { BusinessCustomerType, CheckboxOption, Feedback, LoginStatus } from '../../models';
import { FormDataService, UserService, UtilsService } from '../../services';

/**
 * This component contains the modal that will enable the user to send in their feedback on the whole process
 * of doing a reclamation
 */
@Component({
    selector: 'sl-feedback-modal',
    templateUrl: './feedback-modal.component.html',
    styles: []
})
export class FeedbackModalComponent implements OnInit {
    @Input() feedbackShown:boolean;
    @ViewChild('surveyModal', {static: true}) surveyModal:ElementRef;

    public surveyForm:FormGroup;
    public feedbackAction:Observable<any>;
    public feedbackSent:boolean;
    private feedback:Feedback;
    private checkFeedbackShown:boolean = false;

    /**
     * This component requires FormDataService, UserService, NgbModal, FormBuilder, UtilsService
     *
     * @param {FormDataService} formDataService
     * @param {UserService} User
     * @param {NgbModal} modalService
     * @param {FormBuilder} formBuilder
     * @param {UtilsService} utilsService
     */
    constructor(private formDataService:FormDataService,
                private User:UserService,
                private modalService:NgbModal,
                private formBuilder:FormBuilder,
                private utilsService:UtilsService) {
    }

    /**
     * @ignore
     */
    ngOnInit() {
        this.feedbackShowCheck();
    }

    ngOnChanges(changes:SimpleChanges):void {
        if ('feedbackShown' in changes && this.feedbackShown === false) {
            this.feedbackShowCheck();
        }
    }

    private feedbackShowCheck() {
        if (this.checkFeedbackShown === false) {
            this.shouldShowFeedbackModal();
        }
    }

    /**
     * Create the form that lets the user complete the satisfaction survey
     */
    private createForm() {
        this.surveyForm = this.formBuilder.group({
            rating: ['', Validators.required],
            message: ['', Validators.maxLength(500)]
        });
    }

    /**
     * When the user is ready they can send their feedback on the reclamation process
     */
    private submitFeedback():Observable<any> {
        if (!this.surveyForm.valid) {
            return EMPTY;
        }
        this.feedback = {
            rating: this.surveyForm.controls['rating'].value,
            message: this.surveyForm.controls['message'].value !== ''
                ? this.surveyForm.controls['message'].value : null
        };

        return this.formDataService.sendFeedback(this.feedback).pipe(take(1), tap(res => {
            this.feedbackSent = true;
        }));
    }

    /**
     * This function decides whether the user should be shown the
     * feedback modal or not depending on if they have already seen it or not.
     * If it is to be shown then a request to the BE is sent to not show it in future and
     * the information that the feedback has been shown is stored
     * (for reclamations that succeed without logging out between reclamations)
     */
    private shouldShowFeedbackModal() {
        // We need to use a delay here because ExpressionChangedAfterItHasBeenCheckedError is being triggered otherwise
        const userStatus$:Observable<LoginStatus> = this.User.currentLoggedInStatus();
        const checkUserStatusAndShowFeedbackIfNecessary$:Observable<LoginStatus> = userStatus$.pipe(
            take(1),
            mergeMap(status => {
                if ((status.feedbackShown !== undefined && status.feedbackShown === false) || this.feedbackShown === false) {
                    this.checkFeedbackShown = true;
                    this.createForm();
                    this.feedbackAction = this.utilsService.createActionObs(() => this.submitFeedback());
                    setTimeout(() => {
                        this.modalService.open(this.surveyModal, {
                            centered: true,
                            ariaLabelledBy: 'modal-basic-title',
                            size: 'lg'
                        });
                    });
                    return this.formDataService.sendFeedbackShown().pipe(take(1));
                }
                return EMPTY;
            })
        );
        checkUserStatusAndShowFeedbackIfNecessary$.subscribe(() => {
            const status:LoginStatus = this.User.currentUserStatusValue;
            status.feedbackShown = true;
            this.User.updateUserStatus(status);
        });
    }
}


