import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    Output,
    SimpleChanges
} from '@angular/core';
import {Observable, Unsubscribable} from 'rxjs';
import {tap} from 'rxjs/operators';

/**
 * Button for sending requests to the backend that will show a busy icon when the request is processing
 */
@Component({
    selector: 'sl-busy-btn',
    templateUrl: './busy-btn.component.html',
    styles: [],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BusyBtnComponent implements OnChanges, OnDestroy {
    private static readonly DEFAULT_CLASSES: string =
        'btn btn-primary btn-truncate d-flex flex-row justify-content-center align-items-center';

    @Input() action: Observable<any>;
    @Input() classes: string = 'btn btn-primary';
    @Input() type: 'button' | 'submit' | 'reset' = 'button';
    @Input() disabled: boolean = false;
    @Input() tabindex: number;
    @Input() cancelClickOnDisabled: boolean = true;
    @Input() finishActionOnComplete: boolean = true;
    @Input() clickedByEnter: boolean = false;
    @Output() actionPending: EventEmitter<boolean> = new EventEmitter();

    public actionIsPending: boolean = false;
    public finalClasses: string = BusyBtnComponent.DEFAULT_CLASSES;

    private _currentAction: Unsubscribable;

    /**
     *
     * @param {ChangeDetectorRef} CD
     */
    constructor(private CD: ChangeDetectorRef) {
    }

    private onClickedByEnter() {
        document.getElementById("button").click();
    }

    /**
     * Actions to take when Inputs change
     *
     * @param changes
     */
    ngOnChanges(changes: SimpleChanges): void {
        if ('clickedByEnter' in changes) {
            if (this.clickedByEnter) {
                this.onClickedByEnter();
            }
        }
        if ('action' in changes) {
            this.resetAction();
        }
        if ('disabled' in changes) {
            if (this.cancelClickOnDisabled && changes['disabled'].currentValue) {
                this.resetAction();
            }
        }
        if ('classes' in changes) {
            this.calculateFinalClasses(changes['classes'].currentValue);
        }
    }

    /**
     * @ignore
     */
    ngOnDestroy(): void {
        this.resetAction();
    }

    /**
     * Action to take when the button is clicked
     */
    public buttonClicked(): void {
        this.resetAction();
        if (this.disabled || !this.action) {
            return;
        }

        this.setActionPending(true);
        this._currentAction = this.action.pipe(
            tap(() => {
                    if (!this.finishActionOnComplete) {
                        this.resetAction();
                    }
                },
                () => this.resetAction(),
                () => this.resetAction()))
            .subscribe();
    }

    /**
     * reset the Action when it completes
     */
    private resetAction(): void {
        if (this.actionIsPending) {
            this.setActionPending(false);
            if (this._currentAction) {
                this._currentAction.unsubscribe();
                this._currentAction = null;
            }
        }
    }

    /**
     * Set the Action to Pending when the request is sent
     *
     * @param val
     */
    private setActionPending(val: boolean): void {
        if (this.actionIsPending !== val) {
            this.actionIsPending = val;
            this.actionPending.emit(val);
            this.CD.markForCheck();
        }
    }

    /**
     * Calculate what the final class of the button will be for styling
     *
     * @param classes
     */
    private calculateFinalClasses(classes: string): void {
        let newClasses: string = !!classes ? classes : '';

        if (newClasses.indexOf('btn') === -1) {
            newClasses = BusyBtnComponent.DEFAULT_CLASSES + ' ' + newClasses;
        }

        if (this.finalClasses !== newClasses) {
            this.finalClasses = newClasses;
            this.CD.markForCheck();
        }
    }
}
