import { Component } from '@angular/core';
import { SVGIconNames, TitleIcon, StepsInfoLang} from 'shared-lib';
@Component({
    selector: 'rp-start-page',
    templateUrl: './start-page.component.html',
    styleUrls: [
        './start-page.component.scss'
    ]
})
export class StartPageComponent extends StepsInfoLang{
    public titleIcons: TitleIcon[] =
        [
            [this.stepsInfo.createAccount, SVGIconNames.USER_ACCOUNT],
            [this.stepsInfo.recordDeftDetect, SVGIconNames.DETECTORS_CLAIM],
            [this.stepsInfo.determCompens, SVGIconNames.DETECTORS_SELECT],
            [this.stepsInfo.exchangeReceived, SVGIconNames.USER_DELIVERY],
        ];

    constructor() {
      super();
    }
}
