import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { UtilsService, XHttpClient } from '../../services';
import { HttpParams } from '@angular/common/http';
import { ApiResponse } from '../../models/api.model';
import { environment } from '../../../../../environments/environment';
import { Product, Serial, SerialMassClaim, SerialMassClaimResponse } from '../../models';

@Injectable({
    providedIn: 'root'
})
export class SerialService {

    constructor(private Utils:UtilsService,
                private Http:XHttpClient) {
    }

    /**
     *  Method that handles sending of serial numbers in bulk to the back end.
     *  Used by both the support employee and a business customer at the rekla portal
     * @param formData
     * @param noWarn
     * @param errorResultingInWarning
     */
    public massClaimSerialNumbers(formData:SerialMassClaim, noWarn?:boolean, errorResultingInWarning?:string):Observable<SerialMassClaimResponse > {
        const params:HttpParams = new HttpParams().set('noWarn', noWarn ? 'true' : 'false');
        const post:Observable<ApiResponse<SerialMassClaimResponse >> = this.Http.post(environment.api.product.serialMassClaim, formData,
            {
                params: params, ignoreGlobalErrorHandler: (request) =>
                    errorResultingInWarning ? request.errors[0].code === errorResultingInWarning : false
            });
        return this.Utils.stepIntoData(post);
    }


}
