<div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">
        {{data.title| translate}}
    </h4>
</div>
<div class="modal-body">
    {{data.message| translate}}
</div>
<div class="modal-footer">
    <div class="w-100 d-flex justify-content-start">
        <button type="button" id="accept" class="btn btn-secondary mr-2" (click)="accept()">
            {{data.acceptButtonText| translate}}</button>
        <button type="button" id="dismiss" class="btn btn-outline-tertiary ml-2" (click)="dismiss()">
            {{data.dismissButtonText| translate}}</button>
    </div>
</div>
