import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AccountDetailsComponent } from './components/account-details/account-details.component';
import { TranslateModule } from '@ngx-translate/core';
import { ScannerService } from '../shared/services';

@NgModule({
    declarations: [AccountDetailsComponent],
    imports: [
        CommonModule,
        TranslateModule
    ],
    exports: [AccountDetailsComponent]
})
export class UserModule {
}
