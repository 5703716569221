import {Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Store} from '@ngrx/store';
import {mergeMap, take, takeUntil, tap} from 'rxjs/operators';
import {EMPTY, Observable, Subject} from 'rxjs';
import {ActivatedRoute, Router} from '@angular/router';
import {
    CheckboxOption,
    CheckTypeResponse,
    CheckTypeShopResponse,
    CustomerType,
    DistinguishSupportService,
    FlagRedirect,
    FormDataService,
    FormInputType,
    FormSelectionObjects,
    InfoBannerType,
    MassSerialCheck,
    MassSerialItem,
    NoSerialsMainFormComponent,
    Product,
    ProductReclamationComponents,
    ProductsState,
    ProductValidityEnum,
    ProductViabilityName,
    RecaptchaService,
    ReclamationDetailed,
    ReclamationEvaluation,
    RoutePathName,
    SerialEntryForm,
    User,
    UserDetailsService,
    UserService,
    UtilsService,
    ValidationService
} from '../../../shared';
import {State} from '../../../../reducers/root/root.reducer';
import {NavigationUtilsService} from '../../../shared/services/navigation-utils.service';
import * as EvaluationAction from '../../../shared/reducers/evaluation/evaluation.actions';
import * as ProductsAction from '../../../shared/reducers/products/products.actions';
import {FormControl, FormGroup, Validators} from '@angular/forms';

/**
 * Shows the list of defective Products that are to be Reclaimed
 *
 * @alias ProductListComponent
 */
@Component({
    selector: 'sl-product-list',
    templateUrl: './product-list.component.html',
    styles: []
})
export class ProductListComponent implements OnInit, OnDestroy {
    @ViewChild('infoBanner', {static: false, read: ElementRef}) banner: ElementRef;
    @ViewChild('addMoreSerialsForm') addMoreSerialEntryForm: SerialEntryForm;
    @ViewChild('addMoreNoSerialsForm') addMoreNoSerialsForm: NoSerialsMainFormComponent;
    public readonly textAreaLabel: string = 'PRODUCTS.EVALUATION.LABELS.caseNumber';
    public readonly textAreaHelp: string = 'PRODUCTS.SUMMARY.ADDITIONAL_INFO.helper_text';

    public products: Product[] = [];
    public invalidProducts: Product[];
    public unfilteredProducts: Product[] = [];
    public dropDownValues: FormSelectionObjects;
    public actionProceed: Observable<CheckTypeResponse>;
    public actionProceedWarranty: Observable<ReclamationDetailed>;
    public user: User;
    public enterSerialsLater: boolean;
    public ProductViabilityName = ProductViabilityName;
    public FormInputType = FormInputType;
    public formGroup: FormGroup;
    public isWarrantyCase: boolean;
    public bannerTextKey: string;
    public bannerType: InfoBannerType;
    public showBanner: boolean = false;
    public addMore: boolean = false;
    public ProductReclamationComponents = ProductReclamationComponents;
    public InfoBannerType = InfoBannerType;

    private year: number;
    private tooYoung: number = 2;
    private tooOld: number = 10;
    private products$: Observable<ProductsState | any>;
    private endSubscriptions: Subject<void> = new Subject<void>();
    private customerTransactionNumber: string;

    public addSerialsAction: Observable<any>;
    public detectorOptions: CheckboxOption[];

    private _unsubscribe = new Subject();

    get invalidProductsExist(): boolean {
        return this.invalidProducts.length > 0;
    }

    get validProductsExist(): boolean {
        return this.products.length > 0;
    }

    get viableValidProducts(): Product[] {
        return this.unfilteredProducts.filter(product => product.viability === ProductViabilityName.VALID);
    }

    get viableInvalidProducts(): Product[] {
        return this.unfilteredProducts.filter(product => product.viability === ProductViabilityName.INVALID);
    }

    get viableCorruptedProducts(): Product[] {
        return this.unfilteredProducts.filter(product => product.viability === ProductViabilityName.CORRUPTED);
    }

    public onCancel() {
        this.toggleAddMore();
    }

    get backroute(): string {
        return this.enterSerialsLater
            ? this.navigationUtils.addIdToRoute(
                '/produkte/identifizierung-vereinfacht',
                this.route
            )
            : this.navigationUtils.addIdToRoute(
                '/produkte/identifikation',
                this.route
            );
    }

    /**
     *
     * @param {Router} router
     * @param {ActivatedRoute} route
     * @param {FormDataService} formDataService
     * @param {Store<State>} store
     * @param {UtilsService} utils
     * @param {RecaptchaService} recaptchaService
     * @param {DistinguishSupportService} distinguishSupportService
     * @param {UserDetailsService} userDetailsService
     * @param {NavigationUtilsService} navigationUtils
     * @param {UserService} userService
     * @param {ValidationService} validationService
     */
    constructor(private router: Router,
                private route: ActivatedRoute,
                private formDataService: FormDataService,
                private store: Store<State>,
                private utils: UtilsService,
                private recaptchaService: RecaptchaService,
                private distinguishSupportService: DistinguishSupportService,
                private userDetailsService: UserDetailsService,
                private navigationUtils: NavigationUtilsService,
                private userService: UserService,
                private validationService: ValidationService) {
        this.products$ = this.store.select('products');
        this.isWarrantyCase = this.router.url.includes(RoutePathName.WARRANTY_SUMMARY);

        this.actionProceed = this.utils.createActionObs(() => this.proceedToNext());
        this.addSerialsAction = this.utils.createActionObs(() => this.addSerials());
        if (this.isWarrantyCase) {
            this.actionProceedWarranty = this.utils.createActionObs(() => this.proceedToWarrantyConfirmation());
        }
    }


    /**
     * Get the list of Products from the Store and expand them out into a Product list
     */
    ngOnInit() {
        this.createForm();
        this.year = new Date().getFullYear();
        this.getProductFromStore();

        const currUser = JSON.parse(sessionStorage.getItem('currentUser'));
        this.tooYoung = currUser.tooYoung;
        this.tooOld = currUser.tooOld;

        if (this.distinguishSupportService.support) {
            this.userDetailsService
                .getUserFromStoreOrLoadUserUseRoute(this.route, 'id')
                .pipe(takeUntil(this.endSubscriptions))
                .subscribe((user: User) => {
                    this.user = user;
                });
        } else {
            this.user = currUser;
        }
    }

    /**
     * @ignore
     */
    ngOnDestroy(): void {
        this.endSubscriptions.next();
        this.endSubscriptions.complete();
        this.endSubscriptions.unsubscribe();
    }


    public addNoSerialProduct() {
        try {
            this.checkFieldValidations(this.addMoreNoSerialsForm.productForm);
            this.unfilteredProducts.push(this.productFormVals(this.addMoreNoSerialsForm.productForm, this.unfilteredProducts[0]));
            this.store.dispatch(new ProductsAction.SaveProductsAction(this.unfilteredProducts));
            this.calculateProductLists(this.unfilteredProducts);
            this.toggleAddMore();
        } catch (error) {
            this.setInfoBanner(error.message);
        }
    }

    public updateProduct(serialEntryForm: FormGroup, originalProduct: Product, index: number) {
        this.recaptchaService.getToken('serials/check').pipe(
            take(1),
            tap(() => {
                this.showBanner = false;
                this.checkFieldValidations(serialEntryForm);
                this.validationService.checkForDuplicates(serialEntryForm, this.unfilteredProducts, originalProduct, originalProduct.productIndex, index);
                this.checkForGuest(serialEntryForm);
            }),
            mergeMap(response => {
                const serials = serialEntryForm.value?.serial;
                return this.formDataService.checkMassSerials(serials, response, true);
            }))
            .subscribe(
                (res) => {
                    const serialItem = res.valid[0] || res.corrupted[0] || res.invalid[0];
                    const viabilityType = res.valid[0] ? ProductViabilityName.VALID : (res.corrupted[0] ? ProductViabilityName.CORRUPTED : ProductViabilityName.INVALID);
                    this.unfilteredProducts = this.unfilteredProducts
                        .map(product => product.serialNumber === originalProduct.serialNumber ?
                            this.createUpdatedProduct(serialItem, viabilityType, originalProduct, serialEntryForm) : product);

                    this.store.dispatch(new ProductsAction.SaveProductsAction(this.unfilteredProducts));
                },
                (error) => {
                    this.setInfoBanner(error.message);
                });
    }

    public realignProducts() {
        this.unfilteredProducts.forEach((p, i) => {
            p.productIndex = i;
        });
        this.calculateProductLists(this.unfilteredProducts);
    }

    /**
     * Delete product from the list and then update the Store
     *
     * @param {Product} productToDelete
     */
    public deleteProduct(productToDelete: Product) {

        this.realignProducts();
        this.unfilteredProducts.splice(productToDelete.productIndex, 1);
        this.realignProducts();

        this.store.dispatch(new ProductsAction.SaveProductsAction(this.unfilteredProducts));
    }

    /**
     * set the customer's transaction number if it changes
     *
     * @param value
     */
    public onChangeCustomerTransactionNumber(value: string): void {
        this.customerTransactionNumber = value;
    }

    public toggleAddMore() {
        this.addMore = !this.addMore;
    }

    public updateNoSerialProduct(noSerialForm: FormGroup, originalProduct: Product) {
        try {
            this.checkFieldValidations(noSerialForm);
            this.unfilteredProducts = this.unfilteredProducts
                .map(product => product.productIndex === originalProduct.productIndex ?
                    this.productFormVals(noSerialForm, originalProduct, true) : product);
            this.store.dispatch(new ProductsAction.SaveProductsAction(this.unfilteredProducts));
            this.calculateProductLists(this.unfilteredProducts);
        } catch (error) {
            this.setInfoBanner(error.message);
        }
    }

    /**
     * returns whether a product is valid without error code
     * @param product
     */
    private productIsValid(product: Product): boolean {
        return (this.enterSerialsLater && product.installationYear >= this.year - this.tooOld && product.installationYear <= this.year - this.tooYoung) || product.valid === ProductValidityEnum.valid;
    }

    private getProductFromStore() {
        this.products$
            .pipe(takeUntil(this.endSubscriptions))
            .subscribe((products) => {
                this.unfilteredProducts = products.products;
                this.calculateProductLists(this.unfilteredProducts);
                this.dropDownValues = {
                    reasonOfReturn: products.reasonOfReturn,
                    supplySource: products.supplySource,
                    groupType: products.groupType
                };
                this.setDetectorOptions();
                this.enterSerialsLater = products.enterSerialsLater;
                if (!products.products || products.products.length <= 0) {
                    this.user?.customerType === CustomerType.Commercial
                        ? this.router.navigate(['/produkte', 'start'])
                        : this.router.navigate(['/produkte', 'auswahl-reklamation']);
                }
            });
    }

    private createForm() {
        this.formGroup = new FormGroup({
            additionalInfo: new FormControl(null, {
                updateOn: 'blur',
                validators: [Validators.maxLength(400)]
            })
        });
    }

    private productFormVals(productForm: FormGroup, objectForm: Product, keepIndex: boolean = false) {
        return {
            valid: this.checkProductValidity(productForm.value.installationYear),
            number: objectForm.number,
            numberRange: objectForm.numberRange,
            objectName: objectForm.objectName,
            objectType: objectForm.objectType,
            productIndex: keepIndex ? objectForm.productIndex : this.unfilteredProducts.length,
            groupType: productForm.value.groupType,
            installationYear: productForm.value.installationYear,
            count: productForm.value.count,
            supplySource: productForm.value.supplySource,
            isValid: productForm.valid
        };

    }

    private checkProductValidity(installationYear: number): ProductValidityEnum {
        if (installationYear < this.year - this.tooOld) {
            return ProductValidityEnum.invalid_005
        } else if (installationYear > this.year - this.tooYoung) {
            return ProductValidityEnum.invalid_006;
        } else {
            return ProductValidityEnum.valid;
        }
    }

    /**
     * Navigates the user to the next step in the Reclamation process
     *
     * @returns Observable<ReclamationType>
     */
    private proceedToNext(): Observable<CheckTypeResponse> {
        const tooOldOrTooYoungExists = this.decideIfProductIsTooOldTooYoungOrOnlyInvalid();
        if (!tooOldOrTooYoungExists) {
            return this.recaptchaService.getToken('reclamation/check').pipe(
                mergeMap((response) => this.reclamationCall(response)),
                tap(
                    (result: CheckTypeResponse) => {
                        this.reclamationResponse(result);
                    },
                    (err) => {
                        this.setInfoBanner(err.error.errors[0].code);
                    }
                ),
                takeUntil(this.endSubscriptions)
            );
        }
        return EMPTY;
    }

    /**
     * Navigates the user to the confirmation page
     *
     * @returns Observable<ReclamationType>
     */
    private proceedToWarrantyConfirmation(): Observable<ReclamationDetailed> {
        const tooOldOrTooYoungExists = this.decideIfProductIsTooOldTooYoungOrOnlyInvalid();
        if (!tooOldOrTooYoungExists) {
            return this.recaptchaService.getToken('reclamation/create').pipe(
                mergeMap((response) => this.reclamationWarrantyCall(response)),
                tap(
                    (result: ReclamationDetailed) => {
                        this.warrantyResponse(result);
                    },
                    (err) => {
                        this.setInfoBanner(err.error.errors[0].code);
                    }
                ),
                takeUntil(this.endSubscriptions)
            );
        }
        return EMPTY;
    }


    private warrantyResponse(result: ReclamationDetailed) {
        this.store.dispatch(new EvaluationAction.SaveReclamationResponseAction(result));
        if (this.customerTransactionNumber) {
            this.store.dispatch(new EvaluationAction.SaveCustomerNumbersAction(this.customerTransactionNumber));
        }
        this.router.navigate(['/bestaetigung/garantie']);
    }

    private decideOnFeatureDisabled() {
        this.userService
            .currentLoggedInStatus()
            .pipe(take(1))
            .subscribe((res) => {
                this.user.customerType === CustomerType.Commercial
                    ? this.router.navigate(['/produkte', 'start'])
                    : this.router.navigate(['/produkte', 'auswahl-reklamation']);
            });
    }

    private reclamationCall(recaptchaResponse?: string): Observable<CheckTypeResponse> {
        const dataToSend: Product[] = this.enterSerialsLater ?
            this.unfilteredProducts : this.viableValidProducts;

        if (this.distinguishSupportService.support) {
            return this.formDataService.calculateUserReclamationType(
                dataToSend,
                this.user.id,
                this.customerTransactionNumber,
                this.enterSerialsLater
            );
        } else {
            return this.formDataService.calculateReclamationType(
                dataToSend,
                this.customerTransactionNumber,
                recaptchaResponse,
                this.enterSerialsLater
            );
        }
    }

    private reclamationWarrantyCall(recaptchaResponse?: string): Observable<ReclamationDetailed> {
        if (this.isWarrantyCase) {
            return this.formDataService.submitWarrantyReclamation(
                this.viableValidProducts,
                this.customerTransactionNumber,
                this.user.customerType,
                recaptchaResponse
            );
        }
    }

    private makeBannerMessage(type: InfoBannerType, lang: string) {
        this.setBannerState(type);
        this.bannerTextKey = lang;
    }

    private setBannerState(type: InfoBannerType): void {
        this.bannerType = type;
        this.showBanner = true;
    }

    private reclamationResponse(result: CheckTypeResponse): void {
        if (result.type === FlagRedirect.redirectToShop) {
            redirectToShop(result);
            return;
        } else {
            this.continueOnPlatform(result.payload);
        }
    }

    private calculateProductLists(unfilteredProducts: Product[]): void {
        const productsInDisplayFormat: Product[] = this.formDataService.getProductListToDisplay(
            unfilteredProducts
        );
        const validProducts: Product[] = [];
        const inValidProducts: Product[] = [];
        productsInDisplayFormat.forEach((product: Product) => {
            if (this.productIsValid(product)) {
                validProducts.push(product);
            } else {
                inValidProducts.push(product);
            }
        });
        this.products = validProducts;
        this.invalidProducts = inValidProducts;
        if (this.invalidProducts && this.enterSerialsLater) {
            this.invalidProducts.forEach(product => {
                if (product.installationYear < this.year - this.tooOld) {
                    product.valid = ProductValidityEnum.invalid_005;
                } else {
                    product.valid = ProductValidityEnum.invalid_006;
                }
            });
        }
    }

    private continueOnPlatform(evaluation: ReclamationEvaluation) {
        this.store.dispatch(new EvaluationAction.SaveReclamationEvaluationAction(evaluation));
        if (this.customerTransactionNumber) {
            this.store.dispatch(new EvaluationAction.SaveCustomerNumbersAction(this.customerTransactionNumber));
        }
        this.router.navigate(['/einreichung/auswertung']);
    }

    private addSerials() {
        return this.recaptchaService.getToken('serials/check').pipe(
            take(1),
            tap(() => {
                this.showBanner = false;
                this.checkFieldValidations(this.addMoreSerialEntryForm.serialEntryForm);
                this.validationService.checkForDuplicates(this.addMoreSerialEntryForm.serialEntryForm, this.unfilteredProducts);
                this.checkForGuest(this.addMoreSerialEntryForm.serialEntryForm);
            }),
            mergeMap(response => {
                const serials = this.addMoreSerialEntryForm.serialEntryForm.value?.serials;
                return this.formDataService.checkMassSerials(serials, response, true);
            }),
            tap(res => {
                    const products = this.createProductArray(res, this.unfilteredProducts, this.addMoreSerialEntryForm.serialEntryForm);
                    this.unfilteredProducts = products;
                    this.store.dispatch(new ProductsAction.SaveProductsAction(products));
                    this.addMoreSerialEntryForm.serialEntryForm.reset();
                    this.toggleAddMore();
                },
                (error) => {
                    this.setInfoBanner(error.message);
                }),
            takeUntil(this._unsubscribe));
    }

    private checkFieldValidations(form: FormGroup) {
        let valid = true;
        valid = this.formDataService.isValid(form, valid);
        if (!valid) {
            throw new Error('Validation');
        }
    }

    private checkForGuest(form: FormGroup) {
        if (this.user.customerType === CustomerType.Guest) {
            const serials: string = form.value?.serials || form.value?.serial;
            let matches = serials?.match(/[\d|\w]+/ig);
            matches = matches.concat(this.unfilteredProducts.map(product => product.serialNumber));
            if (matches.length > 5) {
                throw new Error('Guest exceeded');
            }
        }
    }

    private createProductArray(serials: MassSerialCheck, products: Product[], serialEntryForm): Product[] {
        Object.keys(serials).forEach(viabilityType => {

            products = serials[viabilityType].map((serial: MassSerialItem, index: number) => ({
                isValid: viabilityType === 'valid',
                number: products[0]?.number,
                numberRange: products[0]?.numberRange,
                objectName: products[0]?.objectName,
                objectType: products[0]?.objectType,
                productImg: serial?.article?.hasImage ? this.formDataService.buildImageURL(serial?.article?.id) : this.formDataService.PLACEHOLDER_IMG_URL,
                productIndex: index,
                productTitle: serial?.article?.type,
                returnReason: serialEntryForm?.value?.returnReason,
                serialNumber: serial?.serial,
                serialNumberIsChecked: true,
                supplySource: serialEntryForm?.value?.supplySource,
                valid: serial?.valid,
                viability: viabilityType,
                groupType: serial?.article?.componentType
            })).concat(products);
        });

        return products;
    }

    private createUpdatedProduct(serial: MassSerialItem, viabilityType: ProductViabilityName, originalProduct: Product, serialEntryForm): Product {
        return {
            isValid: viabilityType === 'valid',
            number: originalProduct.number,
            numberRange: originalProduct.numberRange,
            objectName: originalProduct.objectName,
            objectType: originalProduct.objectType,
            productImg: serial.article?.hasImage ? this.formDataService.buildImageURL(serial.article?.id) : this.formDataService.PLACEHOLDER_IMG_URL,
            productIndex: originalProduct.productIndex,
            productTitle: serial.article?.type,
            returnReason: serialEntryForm.value?.returnReason,
            serialNumber: serial.serial,
            serialNumberIsChecked: true,
            supplySource: serialEntryForm.value?.supplySource,
            valid: serial.valid,
            viability: viabilityType
        };
    }


    private setInfoBanner(error: string) {
        switch (error) {
            case 'ERROR_FEATURE_DISABLED':
                this.makeBannerMessage(InfoBannerType.DANGER, 'PRODUCTS.SUMMARY.featureDisabled');
                this.decideOnFeatureDisabled();
                break;
            case 'ERROR_INVALID_INPUT':
                this.makeBannerMessage(InfoBannerType.DANGER, 'PRODUCTS.SUMMARY.invalidInput');
                break;
            case 'ERROR_INVALID_CAPTCHA':
                this.makeBannerMessage(InfoBannerType.DANGER, 'PRODUCTS.SUMMARY.reCaptchaError');
                break;
            case 'NO_BPNUMBER_FOUND':
                this.makeBannerMessage(InfoBannerType.DANGER, 'PRODUCTS.SUMMARY.noBusinessPartnerNumber');
                break;
            case 'Duplicate':
                this.makeBannerMessage(InfoBannerType.INFO, 'PRODUCTS.IDENTIFICATION.ERRORS.duplicates');
                break;
            case 'Guest exceeded':
                this.makeBannerMessage(InfoBannerType.DANGER, 'PRODUCTS.IDENTIFICATION.ERRORS.guest');
                break;
            case 'Validation':
                // Dont do anything
                break;
            default:
                this.makeBannerMessage(InfoBannerType.DANGER, 'PRODUCTS.IDENTIFICATION.ERRORS.general');
        }
        setTimeout(() => {
            this.banner?.nativeElement.scrollIntoView({behavior: 'smooth'});
        }, 300);
    }

    private setDetectorOptions() {
        this.detectorOptions = [];
        if (this.dropDownValues) {
            Object.keys(this.dropDownValues.groupType).forEach(key => {
                if (key === 'wifiModule' || key === 'other') return;
                this.detectorOptions.push({
                    id: key + '-' + 0,
                    value: key,
                    labelText: this.dropDownValues.groupType[key]
                });
            });
        }
    }

    private decideIfProductIsTooOldTooYoungOrOnlyInvalid(): boolean {
        if (this.invalidProductsExist) {
            let valid005 = 0;
            let valid006 = 0;
            this.unfilteredProducts.forEach(uProd => {
                if (uProd.valid === '005') {
                    valid005++;
                } else if (uProd.valid === '006') {
                    valid006++;
                }
            });
            if (valid005 > 0 || valid006 > 0) {
                this.router.navigate(['/produkte', 'kontakt-support']);  //works for both rekla and support
                return true;
            } else {
                this.setInfoBanner('ERROR_INVALID_INPUT');
                return false;
            }
        }
    }
}

function redirectToShop(result: CheckTypeShopResponse): void {
    const redirectForm = document.createElement('form');
    redirectForm.method = 'POST';
    redirectForm.action = result?.payload?.redirect;
    const data = document.createElement('input');
    data.setAttribute('type', 'text');
    data.setAttribute('name', 'data');
    data.setAttribute('value', result?.payload?.data);
    redirectForm.appendChild(data);
    redirectForm.style.display = 'none';
    redirectForm.append('Content-Type', 'application/x-www-form-urlencoded');
    redirectForm.append(
        'Accept',
        'text/html,application/xhtml+xml,application/xml;q=0.9,image/webp,image/apng,*/*;q=0.8'
    );
    document.body.appendChild(redirectForm);
    redirectForm.submit();
    this.actionProceed.complete();
    this.actionProceedWarranty.complete();
}
