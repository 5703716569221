import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy } from '@angular/core';
import { PlatformLocation } from '@angular/common';
import { take, takeUntil } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { Router } from '@angular/router';
import {
    CustomerType,
    DistinguishSupportService,
    RegisterAccount,
    registerActions,
    RoutePathName,
    PrivateAccountLang, UserService, SystemStatus
} from '../../../shared';
import { State } from '../../../../reducers/root/root.reducer';
import { Subject } from 'rxjs';

@Component({
    selector: 'sl-customer-account-creation',
    templateUrl: './customer-account-creation.component.html',
    styleUrls: [],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class CustomerAccountCreationComponent extends PrivateAccountLang implements OnDestroy {

    public storedAccountData:RegisterAccount;
    public storedUserType:CustomerType;
    public CustomerType = CustomerType;
    public guestEnabled:boolean;

    private _unsubscribe:Subject<any> = new Subject<any>();

    constructor(
        public distinguishService: DistinguishSupportService,
        private router:Router,
        private store:Store<State>,
        private location: PlatformLocation,
        private userService:UserService,
        private changeDetectorRef:ChangeDetectorRef
    ) {
        super();
        this.store.select('register').pipe(take(1)).subscribe(registerStore => {
            this.storedAccountData = registerStore.registrationData.account;
            this.storedUserType = registerStore.registrationData.customerType;
        });
        this.userService.getSystemStatus()
            .pipe(takeUntil(this._unsubscribe))
            .subscribe((status:SystemStatus) => {
                this.guestEnabled = status.guestReclamationAvailable;
                this.changeDetectorRef.markForCheck();
            });
    }

    /**
     * @ignore
     */
    ngOnDestroy():void {
        this._unsubscribe.next();
        this._unsubscribe.complete();
    }

    public next(accountForm:RegisterAccount) {
        this.store.dispatch(new registerActions.AddAccountFormData(accountForm));
        if (this.distinguishService.support) {
            this.router.navigate([`/user/${RoutePathName.REGISTRATION}/${RoutePathName.ADDRESS}`]);
        } else {
            this.router.navigate([`/${RoutePathName.REGISTRATION}/${RoutePathName.ADDRESS}`]);
        }
    }

    public onComplainWithOutSerial() {
        this.store.dispatch(new registerActions.AddUserTypeFormData(CustomerType.Guest));
        this.router.navigate([`/${RoutePathName.REGISTRATION}/${RoutePathName.COMPLAIN_WITH_NO_USER_ACCOUNT}`]);
    }

}
