import { Injectable } from '@angular/core';
import { ProductValidity, ProductValidityEnum, SerialNumberCheck } from '../shared/models/product.model';

@Injectable()
export class ProductFormUtilsService {

    constructor() {
    }

    /**
     * decides serial numbers of which validitys are accepted during product identification
     * @param serialNumberCheck
     */
    public serialNumberIsAccepted(serialNumberCheck:SerialNumberCheck) {
        let isAccepted:boolean;
        switch (serialNumberCheck.data.valid) {
            case 'valid': {
                if (!serialNumberCheck.data.article) {
                    isAccepted = false;
                } else {
                    isAccepted = true;
                }
                break;
            }

            case '002': {
                isAccepted = true;
                break;
            }

            case '003': {
                isAccepted = true;
                break;
            }

            case '001': {
                isAccepted = false;
                break;
            }

            case '005': {
                isAccepted = true;
                break;
            }

            default: {
                isAccepted = false;
                break;
            }
        }
        return isAccepted;

    }

    /**
     * checking whether two products can go together or not e.g. Rauchenmelder with Funkmodul
     */
    public checkProductsMatch(productMatches, firstProductType, secondProductType):boolean {
        return productMatches.includes(firstProductType)
            && productMatches.includes(secondProductType)
            && productMatches.indexOf(firstProductType) !== productMatches.indexOf(secondProductType);
    }

    /**
     * determining the validity of a serial number
     */
    public determineSerialNumberValidity(serialNumberCheck:SerialNumberCheck, storedValidity:ProductValidity):ProductValidity {
        let serialNumberValidity:ProductValidity;

        // check has been performed
        if (serialNumberCheck && serialNumberCheck.data) {
            serialNumberValidity = serialNumberCheck.data.valid;

            // if no check has been performed, use saved validity state
        } else if (storedValidity) {
            serialNumberValidity = storedValidity;

            // else default
        } else {
            serialNumberValidity = ProductValidityEnum.default;
        }
        return serialNumberValidity;
    }
}
