import * as UsersAction from '../reducers/user/user.actions';
import * as AddressActions from '../reducers/address/address.actions';
import { CustomerType, User } from '../models';
import { Observable, of } from 'rxjs';
import { mergeMap, tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { UserService } from './user.service';
import { Store } from '@ngrx/store';
import { State } from '../../../reducers/root/root.reducer';
import { ActivatedRoute, Params } from '@angular/router';


@Injectable({
    providedIn: 'root'
})
export class UserDetailsService {


    constructor(private userService:UserService,
                private store:Store<State>) {
    }

    public getUser(id:string):Observable<User> {
        return this.userService.getUserDetails(id).pipe(
            tap((res:User) => {
                if (res && res.customerType === CustomerType.Private) {
                    const address:string = res.addressInformation ?
                        `${res.addressInformation.address} ${res.addressInformation.zip} ${res.addressInformation.city}` :
                        '';
                    this.store.dispatch(new AddressActions.SaveAddressAction(address));
                }
                this.store.dispatch(new UsersAction.SaveUserDetailsAction(res));
            })
        );
    }

    /**
     * Fetches User from store
     * if there is no user or the stored user has a different user id, requests data from backend.
     * @param id
     */
    public getUserFromStoreOrLoadUser(id:string) {
        return this.store.select('user').pipe(
            mergeMap((user:User) => {
                    return user && user.id && user.id === id ? of(user) : this.getUser(id);
                }
            ));
    }

    /**
     * Fetches User from store using the current route to identify the user
     * if there is no user or the stored user has a different user id, requests data from backend.
     * @param id
     * PLEASE DO NOT FORGET TO UNSUBSCRIBE FROM THIS OBSERVABLE;
     */
    public getUserFromStoreOrLoadUserUseRoute(route:ActivatedRoute, paramName:string):Observable<User> {
        return route.params.pipe(mergeMap((params:Params) => {
                if (params[paramName]) {
                    return this.getUserFromStoreOrLoadUser(params.id);
                }
            }
        ));
    }
}
