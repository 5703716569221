/**
 * Add the base and the keys together
 * @param obj
 * @param base
 */
export function processObjRec(obj:any, base:string) {
    for (const key of Object.keys(obj)) {
        switch (typeof obj[key]) {
            case 'string':

                obj[key] = obj[key].includes(base) ? obj[key] : base + obj[key];
                break;
            case 'object':
                processObjRec(obj[key], base);
                break;
        }
    }
}

/**
 * Set the default language
 */
export const defaultLang = 'de';

/**
 * Consts for Path
 */
export const PATH = 'v1.39/';
/**
 * Const for Host
 */
export const HOST = '';

/**
 * Consts for Endpoints
 */
export const API = {
    langFile: 'system/lang',
    systemStatus: 'system/status',
    product: {
        serialCheck: 'serial/{0}/check',
        serialMassCheck: 'serial/check',
        serialMassClaim: 'serial/mass-claim',
    },
    submission: {
        evaluation: 'reclamation/type',
        reclamation: 'reclamation'
    },
    image: {
        imageURL: 'article/{0}/image'
    },
    user: {
        registration: 'user/register',
        login: 'login',
        guestLogin: 'login?guest=true',
        logout: 'logout',
        status: 'user/status',
        current: 'user',
        object: 'user/object',
        supportObject:'user/{0}/object',
        passwordRegEx: 'user/password-regex',
        passwordReset: 'user/password-reset',
        passwordResetTokenStatus: 'user/password-reset/{0}/status',
        passwordResetWithToken: 'user/password-reset/{0}',
        passwordResetForced: 'user/password-reset/forced',
        requestConfirmation: 'user/email/confirm',
        feedback: 'user/feedback',
        feedbackShown: 'user/feedback-shown',
        completeReg: 'user/complete-registration-data',
        list: 'user',
        userList: 'user?page={0}&pageLength={1}&q={2}',
        userAdminList: 'supportuser?page={0}&pageLength={1}&q={2}',
        reclamations: 'reclamation?userId={0}&page={1}&pageLength={2}',
        allReclamations: 'reclamation?page={0}&pageLength={1}&q={2}',
        reclamationsDetail: 'reclamation/{0}',
        details: 'user/{0}',
        evaluation: 'user/{0}/reclamation/type',
        reclamation: 'user/{0}/reclamation',
        createSupportUser: 'supportuser',
        supportUser: 'supportuser/{0}',
        supportUserOrgUnits: 'supportuser/orgunits'
    },
    reclamation: {
        list: 'reclamation',
        details: 'reclamation/{0}',
        complete: 'reclamation/{0}/complete',
        thankYou: 'reclamation/{0}/thank-you'
    }
};
