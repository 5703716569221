import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import {
    AddressState,
    CustomerType,
    DistinguishSupportService,
    FormDataService,
    FormSelectionObjects,
    InfoBannerType,
    MassSerialCheck,
    MassSerialItem,
    Product,
    ProductFormObjectGroupComponent,
    ProductsState,
    RecaptchaService, registerActions,
    RoutePathName,
    SerialEntryForm,
    User,
    UserDetailsService,
    UserService,
    UtilsService
} from '../../../../shared';
import { Observable, of, Subject } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { State } from '../../../../../reducers/root/root.reducer';
import { NavigationUtilsService } from '../../../../shared/services/navigation-utils.service';
import { mergeMap, take, takeUntil, tap } from 'rxjs/operators';
import * as ProductsAction from '../../../../shared/reducers/products/products.actions';

@Component({
    selector: 'sl-serials-identification',
    templateUrl: './serials-identification.component.html',
    styles: []
})
export class SerialIdentification implements OnInit, OnDestroy {
    @ViewChild('infoBanner', {static: false, read: ElementRef}) banner:ElementRef;
    @ViewChild(SerialEntryForm) serialEntry:SerialEntryForm;
    @ViewChild(ProductFormObjectGroupComponent) objectData:ProductFormObjectGroupComponent;

    private products$:Observable<ProductsState>;
    public products:Product[] = [];

    private address$:Observable<AddressState>;
    public address:string;

    public userId:string;
    public customerType:CustomerType;
    public hideContent:boolean = false;

    public showBanner:boolean = false;
    public isWarrantyCase:boolean;
    public bannerType:InfoBannerType;
    public bannerTextKey:string;

    public checkSerialsAndProceed:Observable<any>;
    public dropDownValues:Observable<FormSelectionObjects>;

    public CustomerType = CustomerType;
    public InfoBannerType = InfoBannerType;
    public RoutePathName = RoutePathName;

    private _unsubscribe = new Subject();

    /**
     * @param {FormDataService} formDataService
     * @param {Router} router
     * @param {UserService} userService
     * @param {Store<State>} store
     * @param {ActivatedRoute} route
     * @param {NavigationUtilsService} navigationUtils
     * @param {RecaptchaService} recaptchaService
     * @param {UtilsService} utils
     * @param {DistinguishSupportService} distinguishService
     * @param {UserDetailsService} userDetails
     */
    constructor(public formDataService:FormDataService,
                private router:Router,
                private store:Store<State>,
                private route:ActivatedRoute,
                private userService:UserService,
                private recaptchaService:RecaptchaService,
                private navigationUtils:NavigationUtilsService,
                private utils:UtilsService,
                private userDetails:UserDetailsService,
                public distinguishService:DistinguishSupportService) {
        this.checkSerialsAndProceed = this.utils.createActionObs(() => this.prepareCheckSerialsAndProceed());
    }

    ngOnInit():void {
        this.getUserDetails();

        this.products$ = this.store.select('products');
        this.address$ = this.store.select('address');

        this.products$.pipe(take(1)).subscribe((products) => {
            this.products = products?.products;
        });

        if(this.customerType !== CustomerType.Guest) {
            this.address$.pipe(mergeMap((state:AddressState) => {
                return this.customerType === CustomerType.Commercial
                    ? of('')
                    : state?.address === ''
                        ? this.userService.getUserAddressDetails(this.address)
                        : of(state?.address);
            }), takeUntil(this._unsubscribe)).subscribe((address:string) => {
                this.address = address;
            });
        }


        this.isWarrantyCase = this.router.url.includes(RoutePathName.WARRANTY_IDENTIFICATION);
    }

    /**
     * @ignore
     */
    ngOnDestroy():void {
        this._unsubscribe.next();
        this._unsubscribe.complete();
    }

    get firstProduct():Product {
        return this.products && this.products[0] || null;
    }

    public prepareCheckSerialsAndProceed() {
       return this.recaptchaService.getToken('serials/check').pipe(
            take(1),
            tap(() => {
                this.checkFieldValidations();
                this.checkForDuplicates();
                this.checkForGuest();
            }),
            mergeMap(response => {
                const serials = this.serialEntry.serialEntryForm.value?.serials;
                return this.formDataService.checkMassSerials(serials, response, true);
            }),
            tap(res => {
                    const products = this.createProductArray(
                        res,
                        this.serialEntry.serialEntryForm.value,
                        this.objectData?.productForm.value
                    );

                    this.store.dispatch(new ProductsAction.SaveProductsAction(products));
                    const nextRoute:string = this.isWarrantyCase
                        ? `/${RoutePathName.PRODUCT}/${RoutePathName.WARRANTY_SUMMARY}`
                        : `/${RoutePathName.PRODUCT}/${RoutePathName.SUMMARY}`;
                    this.router.navigate([this.navigationUtils.addIdToRoute(nextRoute, this.route)]);
                },
                (error) => {
                    this.setInfoBanner(error);
                }),
            takeUntil(this._unsubscribe));
    }

    public navigateBack() {
        if(this.customerType === CustomerType.Guest) {
            this.hideContent = true;
            this.userService.logout().subscribe(() => {
                this.store.dispatch(new registerActions.AddUserTypeFormData(CustomerType.Guest));
                this.router.navigate([`/${RoutePathName.REGISTRATION}/${RoutePathName.COMPLAIN_WITH_NO_USER_ACCOUNT}`]);
            });
        }else {
            const backRoute:string = this.distinguishService.support
                ? `/${RoutePathName.PRODUCT}/${RoutePathName.START}/${this.userId}`
                : this.isWarrantyCase
                    ? `/${RoutePathName.PRODUCT}/${RoutePathName.START}`
                    : `/${RoutePathName.PRODUCT}/${RoutePathName.SELECTION_COMPLAINT}`;
            this.router.navigate([backRoute]);
        }

    }

    private checkFieldValidations() {
        let valid = true;        
        valid = this.formDataService.isValid(this.serialEntry?.serialEntryForm, valid);
        valid = this.formDataService.isValid(this.objectData?.productForm, valid);
        
        if (!valid) {
            throw new Error('Validation');
        }
    }

    private checkForDuplicates() {
        const serials:string = this.serialEntry.serialEntryForm.value?.serials;
        let matches = serials?.match(/[\d|\w]+/ig);
        // If duplicates exist
        if (matches.filter((item, index) => matches.indexOf(item) !== index).length > 0) {
            throw new Error('Duplicate');
        }
    }

    private checkForGuest() {
        if (this.customerType === CustomerType.Guest) {
            const serials:string = this.serialEntry.serialEntryForm.value?.serials;
            let matches = serials?.match(/[\d|\w]+/ig);
            if (matches.length > 5) {
                throw new Error('Guest exceeded');
            }
        }
    }

    private createProductArray(serials:MassSerialCheck, serialForm, objectForm):Product[] {
        let fullProductList = [];
        Object.keys(serials).forEach(viabilityType => {
            fullProductList = serials[viabilityType].map((serial:MassSerialItem, index:number) => ({
                isValid: viabilityType === 'valid',
                number: objectForm?.number,
                numberRange: objectForm?.numberRange,
                objectName: objectForm?.objectName,
                objectType: objectForm?.objectType,
                groupType: serial?.article?.componentType,
                productImg: serial?.article?.hasImage ? this.formDataService.buildImageURL(serial.article?.id) : this.formDataService.PLACEHOLDER_IMG_URL,
                productIndex: index,
                productTitle: serial?.article?.type,
                returnReason: serialForm?.returnReason,
                serialNumber: serial?.serial,
                serialNumberIsChecked: true,
                supplySource: serialForm?.supplySource,
                valid: serial?.valid,
                viability: viabilityType
            })).concat(fullProductList);
        });

        return fullProductList;
    }

    private setInfoBanner(error:Error) {
        switch (error.message) {
            case 'Duplicate':
                this.bannerTextKey = 'PRODUCTS.IDENTIFICATION.ERRORS.duplicates';
                this.bannerType = InfoBannerType.INFO;
                this.showBanner = true;
                break;
            case 'Guest exceeded':
                this.bannerTextKey = 'PRODUCTS.IDENTIFICATION.ERRORS.guest';
                this.bannerType = InfoBannerType.DANGER;
                this.showBanner = true;
                break;
            case 'Validation':
                // Dont do anything
                break;
            default:
                this.bannerTextKey = 'PRODUCTS.IDENTIFICATION.ERRORS.general';
                this.bannerType = InfoBannerType.DANGER;
                this.showBanner = true;
        }
        setTimeout(() => {
            this.banner?.nativeElement.scrollIntoView({behavior: 'smooth'});
        }, 300);
    }

    private getUserDetails() {
        if (this.distinguishService.support) {
            this.userDetails.getUserFromStoreOrLoadUserUseRoute(this.route, 'id')
                .pipe(takeUntil(this._unsubscribe))
                .subscribe((user:User) => {
                    this.customerType = user.customerType;
                    this.userId = user.id;
                    this.dropDownValues = this.formDataService.getDropdowns(this.customerType);
                });
        } else {
            const currentUser = JSON.parse(sessionStorage.getItem('currentUser'));
            this.customerType = currentUser?.customerType;
            this.dropDownValues = this.formDataService.getDropdowns(this.customerType);
        }
    }
}
