<div *ngIf="calledFrom === RegistrationComponents.SUMMARY" class="py-3">
    <h3>{{'REGISTRATION.ADDRESS_INFORMATION.formTitle' | translate}}</h3>
</div>
<form>
    <ng-container *ngIf="customerType === CustomerType.Commercial">
        <div class="row">
            <div class="col-sm">
                <sl-form-item [inputType]="FormInputType.TEXT" [formGroup]="addressForm"
                              [controlName]="'customerNumber'" [labelText]="'REGISTRATION.LABELS.customerNumber'"
                              [placeholderText]="'REGISTRATION.LABELS.customerNumber'"
                              [helperText]="'REGISTRATION.HELP_TEXT.customerNumber'">
                </sl-form-item>
            </div>
        </div>
        <div class="row">
            <div class="col-sm">
                <sl-form-item [inputType]="FormInputType.TEXT" [formGroup]="addressForm" [controlName]="'company'"
                              [labelText]="'REGISTRATION.LABELS.companyName'"
                              [placeholderText]="'REGISTRATION.LABELS.companyName'">
                </sl-form-item>
            </div>
        </div>
    </ng-container>
    <div class="row">
        <div class="col-sm">
            <sl-form-item [inputType]="FormInputType.TEXT" [formGroup]="addressForm" [controlName]="'street'"
                          [labelText]="'REGISTRATION.LABELS.street'"
                          [placeholderText]="'REGISTRATION.PLACEHOLDERS.street'">
            </sl-form-item>
        </div>
    </div>
    <div class="row">
        <div class="col-sm">
            <sl-form-item [inputType]="FormInputType.TEXT" [formGroup]="addressForm" [controlName]="'addressExtension'"
                          [labelText]="'REGISTRATION.LABELS.addressExtension'"
                          [placeholderText]="'REGISTRATION.PLACEHOLDERS.addressExtension'"
                          [helperText]="'REGISTRATION.HELP_TEXT.addressExtension'">
            </sl-form-item>
        </div>
    </div>
    <div class="zip-city-group row">
        <div class="col-sm-4">
            <sl-form-item [inputType]="FormInputType.TEXT" [formGroup]="addressForm" [controlName]="'zip'"
                          [labelText]="'REGISTRATION.LABELS.plz'"
                          [placeholderText]="'REGISTRATION.LABELS.plz'">
            </sl-form-item>
        </div>
        <div class="col-sm-8">
            <sl-form-item [inputType]="FormInputType.TEXT" [formGroup]="addressForm" [controlName]="'city'"
                          [labelText]="'REGISTRATION.LABELS.city'"
                          [placeholderText]="'REGISTRATION.LABELS.city'">
            </sl-form-item>
        </div>
    </div>
    <div class="row" *ngIf="showCountry">
        <div class="col-sm">
            <sl-form-item [inputType]="FormInputType.TEXT" [formGroup]="addressForm" [controlName]="'country'"
                          [labelText]="'REGISTRATION.LABELS.country'"
                          [placeholderText]="'REGISTRATION.LABELS.country'" [isReadOnly]="true"
                          [helperText]="'REGISTRATION.ADDRESS_INFORMATION.country_helptext'">
            </sl-form-item>
        </div>
    </div>
    <div class="row">
        <div class="col-sm">
            <sl-form-item [inputType]="FormInputType.TEXT" [formGroup]="addressForm" [controlName]="'phone'"
                          [labelText]="'REGISTRATION.LABELS.phone'"
                          [placeholderText]="'REGISTRATION.LABELS.phone'">
            </sl-form-item>
        </div>
    </div>
</form>

<ng-container *ngIf="calledFrom === RegistrationComponents.SUMMARY; else next">
    <div class="row pt-3 pb-4">
        <div class="col-sm">
            <button class="btn btn-secondary btn-mobile-block mr-0 mr-sm-3 mb-1 mb-sm-0" (click)="submit()"
                type="submit">
                {{'REGISTRATION.BUTTONS.saveButton'| translate}}
            </button>
            <button class="btn btn-light btn-mobile-block" (click)="onCancel()" type="submit">
                {{'REGISTRATION.BUTTONS.cancelButton' | translate}}
            </button>
        </div>
    </div>
</ng-container>

<ng-template #next>
    <ng-container *ngIf="calledFrom !== RegistrationComponents.COMPLETION && calledFrom !== RegistrationComponents.ACC_INFO">
        <div class="row py-3">
            <div class="col-sm">
                <button class="btn btn-primary" (click)="submit()" type="submit">
                    {{'REGISTRATION.BUTTONS.nextButton' | translate}}
                </button>
            </div>
        </div>
    </ng-container>
</ng-template>

<!-- RESET BUTTON -->
<div class="form-row align-items-end my-3" *ngIf="!showCountry">
    <div class="col-12 col-md-4">
        <button class="btn btn-primary" [disabled]="resetDisabled" (click)="resetToDefault()">{{'GENERAL.BUTTONS.reset' | translate}}</button>
    </div>
</div>