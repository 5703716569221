<div class="container">
    <div class="col-12 col-md-10 mx-auto">
        <div class="row">
            <sl-back-navigation [buttonText]="'RECLAMATION_START.DETECTOR_RECLAMATION.backLinkText' | translate">
            </sl-back-navigation>
        </div>
        <div class="row py-3">
            <h1>{{'REGISTRATION.REGISTRATION_COMPLETION.heading' | translate}}</h1>
        </div>

        <div class="row">
            <p class="lead">{{'REGISTRATION.REGISTRATION_COMPLETION.text' | translate}}</p>
        </div>

        <div class="row py-3" *ngIf="formCreated">
            <form [formGroup]="salesChannelFrom">
                <ng-container *ngIf="saleChannels">
                    <!-- SALES CHANNEL -->
                    <sl-form-item [formGroup]="salesChannelFrom" [controlName]="'salesChannel'" [inputType]="FormInputType.DROPDOWN"
                                  [helperText]="'REGISTRATION.POPOVER_TEXTS.salesChannel'" [placeholderText]="'REGISTRATION.PLACEHOLDER.chooseRequired'"
                                  [labelText]="'REGISTRATION.LABELS.salesChannel'" [dropdownValues]="salesChannelOptions"></sl-form-item>

                    <!-- SUB SEGMENT -->
                    <sl-form-item [formGroup]="salesChannelFrom" [controlName]="'subSegment'" [inputType]="FormInputType.DROPDOWN"
                                  [labelText]="'REGISTRATION.LABELS.subSegment'" [helperText]="'REGISTRATION.POPOVER_TEXTS.subSegment'"
                                  [placeholderText]="'REGISTRATION.PLACEHOLDER.choose'"
                                  [dropdownValues]="subSegments"></sl-form-item>
                </ng-container>
            </form>
        </div>

        <div class="row py-3">
            <button class="btn btn-primary" (click)="submit()" type="submit">
                {{'REGISTRATION.BUTTONS.nextButton' | translate}}
            </button>
        </div>
    </div>
</div>
