import { Component, OnInit, ChangeDetectionStrategy, OnDestroy } from '@angular/core';
import {
  CustomerType,
  DistinguishSupportService,
  Product,
  ProductsState,
  User,
  UserDetailsService
} from '../../../shared';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { State } from '../../../../reducers/root/root.reducer';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'sl-retrieval-order-confirmation',
  templateUrl: './retrieval-order-confirmation.component.html',
  styles: [
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RetrievalOrderConfirmationComponent implements OnInit, OnDestroy {
  public products:Product[] = [];
  public orderNumber:string;
  public customerNumber:string;

  private products$:Observable<ProductsState | any>;
  private _unsubscribe = new Subject();
  private user:User;

  constructor(private router:Router,
              private route:ActivatedRoute,
              private store:Store<State>,
              private distinguishSupportService:DistinguishSupportService,
              private userDetailsService:UserDetailsService) {
    this.products$ = this.store.select('products');

    if (this.distinguishSupportService.support) {
      this.userDetailsService
          .getUserFromStoreOrLoadUserUseRoute(this.route, 'id')
          .pipe(takeUntil(this._unsubscribe))
          .subscribe((user:User) => {
            this.user = user;
          });
    } else {
      this.user = JSON.parse(sessionStorage.getItem('currentUser'));
    }
  }

  ngOnInit(): void {
    this.getProductFromStore();
  }

  /**
   * @ignore
   */
  ngOnDestroy():void {
    this._unsubscribe.next();
    this._unsubscribe.complete();
  }

  public onMyReclamation() {
    this.router.navigate(['/servicemeldungen']);
  }

  public onStartNewReclamation() {
    this.router.navigate(['/produkte/start']);
  }

  private getProductFromStore() {
    this.products$
        .pipe(takeUntil(this._unsubscribe))
        .subscribe((products) => {
          this.products = products.products;
          this.orderNumber = products.orderNumber;
          this.customerNumber = products.customerNumber;
          if (!products.products || products.products.length <= 0) {
            this.user?.customerType === CustomerType.Commercial
                ? this.router.navigate(['/produkte', 'start'])
                : this.router.navigate(['/produkte', 'auswahl-reklamation']);
          }
        });
  }
}
