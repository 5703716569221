import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';

/**
 * Service  to help with displaying Toasts
 */
@Injectable({
    providedIn: 'root'
})
export class ToastsService {

    /**
     *
     * @param {ToastrService} toastr
     * @param {TranslateService} Translate
     */
    constructor(public toastr:ToastrService,
                private Translate:TranslateService) {
    }

    /**
     * Show the Success Toast
     *
     * @param message
     * @param title
     * @param config
     */
    showSuccess(message, title = '', config?) {
        this.Translate.get([message, title])
            .subscribe(trans => {
                this.toastr.success(trans[message], trans[title], config);
            });
    }

    /**
     * Show the Error Toast
     *
     * @param message
     * @param title
     * @param config
     */
    showError(message, title = '', config?) {
        this.Translate.get([message, title])
            .subscribe(trans => {
                this.toastr.error(trans[message], trans[title], config);
            });
    }

    /**
     * Show the Warning Toast
     *
     * @param message
     * @param title
     * @param config
     */
    showWarning(message, title = '', config?) {
        this.Translate.get([message, title])
            .subscribe(trans => {
                this.toastr.warning(trans[message], trans[title], config);
            });
    }

    /**
     * Show the Info Toast
     *
     * @param message
     * @param title
     * @param config
     */
    showInfo(message, title = '', config?) {
        this.Translate.get([message, title])
            .subscribe(trans => {
                this.toastr.info(trans[message], trans[title], config);
            });
    }
}
