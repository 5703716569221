<ng-container *ngIf="videoDeviceIsReady">
    <div class="row no-gutters">
        <div class="col-12">
            <video id="video">
            </video>
            <ng-container *ngIf="showNotReadableError">
                <div>
                    <div class="info-outlet-wrapper">
                        <div class="info-outlet-error d-flex justify-content-center">
                            <span class="ml-2">{{'PRODUCTS.IDENTIFICATION.SCANNER.ERRORS.notReadableError' | translate}}</span>
                        </div>
                    </div>
                </div>
            </ng-container>
        </div>
    </div>

    <ng-container *ngIf="showForm">
        <div class="modal-footer justify-content-start px-0 pt-1 pb-3">
            <form [formGroup]="videoDeviceForm">
                <label for="sourceSelect"
                       class="mb-0 mr-2 colon-after"
                       [ngClass]="{'small font-weight-bold': isMobileDevice}">{{'PRODUCTS.IDENTIFICATION.SCANNER.changeDevice' | translate}}</label>
                <select class="form-control"
                        id="sourceSelect"
                        formControlName="sourceSelect"
                        [class.form-control-sm]="isMobileDevice">
                    <option *ngFor="let device of videoDevices" [value]="device.deviceId">{{device.label}}</option>
                </select>
            </form>
        </div>
    </ng-container>
</ng-container>


