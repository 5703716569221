import { Component, Input, OnInit } from '@angular/core';
import { PaymentIcons } from '../../models/util.model';
import { PaymentInformation } from '../../models/shop.model';

@Component({
    selector: 'sl-payment-details',
    templateUrl: './payment-details.component.html',
    styles: []
})
export class PaymentDetailsComponent implements OnInit {
    @Input() paymentInformation: PaymentInformation;

    constructor() { }
    
    ngOnInit(): void {
    }

    imageSrc(logo: PaymentIcons | string): string {
        if (logo === 'creditcard' || logo === 'visa') {
            logo = PaymentIcons.MASTER_CARD;
        }
        return `${PaymentIcons.LOCATION}${logo}${PaymentIcons.FILE_TYPE}`;
    }
}