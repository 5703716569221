import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Observable } from 'rxjs';
import { InfoBannerType } from '../../models';

@Component({
    selector: 'sl-edit-container',
    templateUrl: './edit-container.component.html'
})
export class EditContainer {
    @Input() title?: string;
    @Input() lead?: string;
    @Input() hasAction: boolean;
    @Input() hasAccept: boolean;
    @Input() hasCancel: boolean;
    @Input() action: Observable<any>

    @Output() accept = new EventEmitter();
    @Output() cancel = new EventEmitter();

    constructor() {}
}