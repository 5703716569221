import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges, ViewChild
} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';
import {ProductFormObjectGroupComponent} from '../product-form-object-group/product-form-object-group.component';
import {
    CheckboxOption,
    CustomerType,
    FormInputType,
    FormSelectionObjects,
    Product,
    ProductValidityEnum
} from '../../models';
import {DistinguishSupportService, ValidationService} from '../../services';

@Component({
    selector: 'sl-no-serials-form',
    templateUrl: './no-serials-form.component.html',
    styles: [],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class NoSerialsFormComponent implements OnInit, OnChanges {
    @ViewChild(ProductFormObjectGroupComponent) objectData: ProductFormObjectGroupComponent;
    @Input() totalProducts: number;
    @Input() product: Product;
    @Input() productIndex: number;
    @Input() products: Product[];
    @Input() formSelectionValues: FormSelectionObjects;
    @Input() address: string;
    @Input() customerType: CustomerType;

    @Output() updatedProduct: EventEmitter<Product> = new EventEmitter<Product>();
    @Output() newForm: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Output() productToDelete: EventEmitter<number> = new EventEmitter<number>();

    public productForm: FormGroup;
    public formCreated: boolean;
    public submitted: boolean = false;
    public searchOff: boolean = false;
    public detectorOptions: CheckboxOption[];
    public CustomerType = CustomerType;
    public FormInputType = FormInputType;

    private tooYoung: number = 2;
    private tooOld: number = 10;
    private endSubscriptions: Subject<void> = new Subject<void>();

    /**
     * These accessors get the Controls for the productForm
     */
    get f() {
        return this.productForm.controls;
    }

    constructor(private formBuilder: FormBuilder,
                private validationService: ValidationService,
                private distinguishSupportService: DistinguishSupportService) {
    }

    ngOnInit(): void {
        const currUser = JSON.parse(sessionStorage.getItem('currentUser'));
        this.tooYoung = currUser.tooYoung;
        this.tooOld = currUser.tooOld;

        this.setDetectorOptions();
        this.formCreated = false;
        this.createForm();
        this.onChanges();
    }

    /**
     * @ignore
     */
    ngOnChanges(changes: SimpleChanges): void {
        if ('formSelectionValues' in changes) {
            this.formCreated = !!this.formSelectionValues;
        }
        if ('product' in changes && this.productForm) {
            Object.keys(this.product).forEach(key => {
                if (this.productForm.get(key) && this.productForm.get(key).value !== this.product[key]) {
                    this.productForm.get(key).setValue(this.product[key]);
                }
            });
        }
    }

    /** Check to see if the form is valid and submitted
     * @returns void
     * **/
    onChanges(): void {
        this.productForm.valueChanges
            .pipe(takeUntil(this.endSubscriptions))
            .subscribe(() => {
                this.updateForm();
            });
    }

    /**
     * Call to action when the user wants to submit the form
     */
    public onSubmit(): void {
        this.submitted = true;
        // stop here if form is invalid
        if (this.productForm.invalid) {
            return;
        }
        this.updateForm();
    }

    /**
     * Call to action when the user wants to create a new form
     */
    public createNewForm(): void {
        this.onSubmit();
        this.newForm.emit(this.productForm.valid);
    }

    /**
     * Call to action when the user wants to delete a product form
     */
    public deleteProduct() {
        this.productToDelete.emit(this.productIndex);
    }

    /**
     * sets the entered value for total detectors to an absolute and rounded number
     * @param {number} value
     */
    public getValueOfTotalDetectors(value: number): void {
        if (this.customerType === CustomerType.Private && this.f.number.dirty) {
            const totalNumber: number = Math.abs(Math.round(value));
            this.f.number.setValue(totalNumber > 0 ? totalNumber : 1);
        }
    }

    /**
     * This function is used to validate the form programmatically and to be called by a parent component.
     * emitEvent needs to be set to false to avoid an update to be emitted to parent component
     * which causes a loop of recheck and update
     */
    public recheckForm(): void {
        this.productForm.updateValueAndValidity({emitEvent: false});
    }

    private setDetectorOptions() {
        this.detectorOptions = [];
        if (this.formSelectionValues) {
            Object.keys(this.formSelectionValues.groupType).forEach(key => {
                if (key === 'other' || key === 'wifiModule') return;
                this.detectorOptions.push({
                    id: key + '-' + this.productIndex,
                    value: key,
                    labelText: this.formSelectionValues.groupType[key]
                });
            });
        }
    }

    /**
     * This function is called when a new form is created
     */
    private createForm(): void {
        this.productForm = this.formBuilder.group(
            {
                groupType: [this.product.groupType, Validators.required],
                installationYear: [this.product.installationYear, [Validators.required, this.validationService.checkYearValidation.bind(this)]],
                count: [this.product.count, [Validators.required, Validators.max(500), Validators.min(1)]],
                supplySource: [this.products[0]?.supplySource, Validators.required]
            }, {validators: [this.validationService.checkDuplicateCombo.bind(this, this.productIndex, this.products)]});
        this.formCreated = !!this.formSelectionValues;
    }

    /**
     * Emit the updated value of the form
     */
    private updateForm() {
        this.updatedProduct.emit(this.productFormVals());
    }

    private productFormVals() {
        return {
            valid: this.checkProductValidity(this.productForm.value.installationYear),
            productIndex: this.productIndex,
            groupType: this.productForm.value.groupType,
            installationYear: this.productForm.value.installationYear,
            count: this.productForm.value.count,
            supplySource: this.productForm.value.supplySource,
            isValid: this.productForm.valid
        };
    }

    private checkProductValidity(installationYear: number): ProductValidityEnum {
        const year = new Date().getFullYear();
        if (installationYear < year - this.tooOld) {
            return ProductValidityEnum.invalid_005
        } else if (installationYear > year - this.tooYoung) {
            return ProductValidityEnum.invalid_006;
        } else {
            return ProductValidityEnum.valid;
        }
    }
}

