<div class="container">
    <div class="col-12 col-md-10 mx-auto">

        <!--back link:-->
        <div *ngIf="customerType !== CustomerType.Private" class="row flex-column mt-3">
            <sl-back-navigation class="mt-2" [route]="userId ? '/user/reclamations/'+userId : 'produkte/start/'"
                                [buttonText]="userId ? ('USER.RECLAMATIONS.BUTTONS.backToList' | translate) : ('RECLAMATION_START.DETECTOR_RECLAMATION.backLinkText' | translate)"
                                [icon]="'fa-arrow-left'"></sl-back-navigation>
        </div>

        <!--title with subtitle:-->
        <div class="row flex-column py-3">
            <h1>{{'RECLAMATION_START.title' | translate}}</h1>
            <p class="subline">{{'RECLAMATION_START.subtitle' | translate}}
            </p>
        </div>
        <!-- start standard reclamation-->
        <div class="row no-gutters">
            <sl-tile class="w-100"
                     [titleText]="'RECLAMATION_START.SERIAL_RECLAMATION.linkText'"
                     [mainText]="'RECLAMATION_START.SERIAL_RECLAMATION.text'"
                     (click)="proceedToProductIdentification()">
            </sl-tile>
        </div>
        <!-- end standard reclamation  -->
        <!-- start reclamation without serial number  -->
        <ng-container *ngIf="reclamationsWithoutSerialsEnabled">
            <div class="row no-gutters">
                <sl-tile class="w-100"
                         [titleText]="'RECLAMATION_START.DETECTOR_RECLAMATION.linkText'"
                         [mainText]="'RECLAMATION_START.DETECTOR_RECLAMATION.text'"
                         (click)="proceedToSimpleIdentifikation()"></sl-tile>
            </div>
        </ng-container>
        <!-- end complaints with out a serial number  -->
        <!-- start bulk entry of serial numbers  -->
        <ng-container *ngIf="businessUser && massClaimAllowed">
            <div class="row no-gutters">
                <sl-tile class="w-100"
                         [titleText]="'RECLAMATION_START.BULK_DETECTOR_RECLAMATION.linkText'"
                         [mainText]="'RECLAMATION_START.BULK_DETECTOR_RECLAMATION.text'"
                         (click)="proceedToSerialnumbersCallOrder()"></sl-tile>
            </div>
            <!-- end Bulk Entry of Serial Numbers  -->
        </ng-container>

        <div class="row flex-column py-3">
            <h5>{{'PRODUCTS.WARRANTY_CHOICE.BOTTOM.title' | translate}}</h5>
            <p class="py-2">{{'PRODUCTS.WARRANTY_CHOICE.BOTTOM.text' | translate}}</p>
            <p>
                <a href="mailto:{{'PRODUCTS.WARRANTY_CHOICE.BOTTOM.email' | translate}}">
                    {{'PRODUCTS.WARRANTY_CHOICE.BOTTOM.email'| translate}}
                </a>
            </p>
            <p>
                <a class="link-undecorated" href="tel:{{'PRODUCTS.WARRANTY_CHOICE.BOTTOM.telephone' | translate}}">
                    {{'PRODUCTS.WARRANTY_CHOICE.BOTTOM.telephone' | translate}}
                </a>
            </p>
        </div>
    </div>
</div>