import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
import { UserService } from './user.service';
import { ToastsService } from './toasts.service';
import { ExtendedHttpRequest } from './extended-http-client.service';

/**
 * Assists with Authentication of the User
 */
@Injectable({
    providedIn: 'root'
})
export class AuthenticationInterceptor implements HttpInterceptor {

    /**
     *
     * @param {Router} router
     * @param {UserService} user
     * @param {ToastsService} Toasts
     */
    constructor(private router:Router,
                private user:UserService,
                private Toasts:ToastsService) {
    }

    /**
     * Intercept a request and check to see if the user has credentials and if not display the relevant error message
     *
     * @param req
     * @param next
     */
    intercept(req:ExtendedHttpRequest<any>, next:HttpHandler):Observable<HttpEvent<any>> {
        return next.handle(req).pipe(
            catchError(err => {
                if (!req.ignoreUnauthorized
                    && err instanceof HttpErrorResponse
                    && err.status === 401) {
                    this.Toasts.showError('ERRORS.NOT_AUTHORIZED.text', 'ERRORS.NOT_AUTHORIZED.title');
                    if (!this.router.url.includes('reclamation/complete')) {
                        this.user.logout().subscribe(() => {
                            this.router.navigate(['/']);
                        });
                    }
                }
                return throwError(err);
            })
        );
    }
}
