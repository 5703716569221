import {
  Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import {
    CustomerType,
    FormDataService,
    FormInputType,
    LangCallSimplifier,
    RegisterAddress,
    RegistrationComponents,
    ValidationService
} from '../../../../shared';
import { distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
    selector: 'sl-address-form',
    templateUrl: './address-form.component.html'
})
export class AddressFormComponent extends LangCallSimplifier implements OnInit {
    @Input() addressForm:FormGroup;
    @Input() storedAddressData:RegisterAddress;
    @Input() calledFrom:RegistrationComponents = null;
    @Input() customerType:CustomerType;
    @Input() showCountry:boolean = true;
    @Output() next:EventEmitter<RegisterAddress> = new EventEmitter();
    @Output() cancel:EventEmitter<RegisterAddress> = new EventEmitter();
    @Output() save:EventEmitter<RegisterAddress> = new EventEmitter();
    @Output() reset:EventEmitter<void> = new EventEmitter();

    public RegistrationComponents = RegistrationComponents;
    public FormInputType = FormInputType;
    public CustomerType = CustomerType;
    public resetDisabled:boolean;

    private unsubscribe$ = new Subject();

    public constructor(
    private validationService: ValidationService,
    private formBuilder: FormBuilder,
    private formDataService: FormDataService
  ) {
      super();
    }

    ngOnInit():void {
        if (!this.addressForm) {
            this.createForm();

            if (this.storedAddressData) {
                this.formDataService.setFormValuesFromStore(this.addressForm, this.storedAddressData);
            }

            this.addressForm.valueChanges
                .pipe(distinctUntilChanged(), takeUntil(this.unsubscribe$))
                .subscribe(() => {
                    this.checkChanges();
                });
        }
    }

    private createForm() {
        this.addressForm = this.formBuilder.group(this.addStandardFormControls());
        if (this.customerType === CustomerType.Commercial) {
            this.addCommercialFormControls();
        }
    }

  private addCommercialFormControls() {
    this.addressForm.addControl('customerNumber', new FormControl(null, {
      updateOn: 'blur',
      validators: [this.validationService.checkCustomerNumber.bind(this)]
    }));
    this.addressForm.addControl('company', new FormControl(null, {
      updateOn: 'blur',
      validators: [this.validationService.customMinLength.bind(this), Validators.required]
    }));
  }

  private addStandardFormControls(): { [key: string]: any } {
    return {
      street: [null, {
        updateOn: 'blur',
          validators: [Validators.required, Validators.maxLength(40), this.validationService.spacesAtStart.bind(this), this.validationService.addressPattern.bind(this)]
      }],
      addressExtension: [null, {
        updateOn: 'blur',
        validators: [Validators.maxLength(40)],
      }],
      zip: [null, {
        validators: [
          Validators.required, Validators.maxLength(5),
          Validators.pattern('[0-9]+'),
          this.validationService.checkNumberLength.bind(this, 5, 'ZIP')
        ],
        updateOn: 'blur'
      }],
      city: [null, {
        updateOn: 'blur',
        validators: [Validators.required, this.validationService.customMinLength.bind(this)],
      }],
      country: ['Deutschland', {
        updateOn: 'blur',
        validators: [Validators.required],
      }],
      phone: [null, {
        updateOn: 'blur',
        validators: [Validators.pattern('^[\\s-+()\\/0-9]+$'), this.validationService.checkNumberLength.bind(this, 6, 'PHONE')],
      }]
    };
  }

    public submit() {
        if (!this.addressForm.valid) {
            Object.keys(this.addressForm.controls).forEach(field => {
                const control = this.addressForm.get(field);
                if (control.errors?.required || (control.errors && !control.errors.required && control.value !== '' && control.value !== null)) {
                    control.markAsTouched({ onlySelf: true });
                }
            });
            return;
        }
        if (this.calledFrom === RegistrationComponents.SUMMARY) {
            this.save.emit(this.addressForm.value);
        } else {
            this.next.emit(this.addressForm.value);
        }
    }

    public onCancel() {
        this.cancel.emit(this.addressForm.value);
    }

    /**
     * Reset values to their default values
     */
    public resetToDefault():void {
        this.reset.emit();
    }

    /**
     * Check if the form has been touched
     */
    private checkChanges() {
        this.resetDisabled = this.addressForm.pristine;
    }
}
