import {Component} from '@angular/core';
import {MenuItemsService} from './services/menu-items.service';
import {CustomerType, UserService} from 'shared-lib';
import {LoginStatus} from "../../../shared-lib/src/lib/modules/shared";

/**
 * AppComponent
 *
 * Main Root Component
 */
@Component({
    selector: 'rp-root',
    templateUrl: './app.component.html',
    styles: []
})
export class AppComponent {

    public username: string;
    public guest: boolean;
    public notice: string;

    /**
     * Accessor that gets whether the user is logged in or not and extracts username and guest status
     */
    public get loggedIn(): boolean {
        const current: LoginStatus = this.userService.currentUserStatusValue;
        this.username = current ? current.username : '';
        this.guest = current ? current.customerType === CustomerType.Guest : false;
        this.notice = current?.notice?.replace(/\n/g, '<br>');
        return current && current.loggedIn;
    }

    /**
     * @param {UserService} userService
     * @param {MenuItemsService} menuItemsService
     */
    constructor(private userService: UserService,
                public menuItemsService: MenuItemsService) {
    }
}
