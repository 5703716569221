import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    HostListener,
    Input,
    OnDestroy,
    OnInit,
    Output,
    ViewChild
} from '@angular/core';
import { Subject } from 'rxjs';
import { FormGroup } from '@angular/forms';
import { map, takeUntil, pairwise } from 'rxjs/operators';
import { SearchService } from '../../services/search.service';
import { ActivatedRoute } from '@angular/router';
import { FormInputType } from '../../models/product.model';

export type SearchType = 'userObject' | '';

/**
 * Component that is a search text field that requests the object names
 * that the user has already used for reclamations
 */
@Component({
    selector: 'sl-search-slot',
    templateUrl: './search-slot.component.html',
    styleUrls: ['./search-slot.component.scss']
})
export class SearchSlotComponent implements OnInit, OnDestroy {
    @Input() controlName:string;
    @Input() controlValue: string | number;
    @Input() searchType:SearchType;
    @Input() parentForm:FormGroup;
    @Input() placeholder:string;
    @Input() searchOff:boolean;
    @Input() filterResult:(res:any[]) => any[];
    @Input() readonly:boolean;
    @Input() tabindex:number;
    @Output() submitSelectedValue:EventEmitter<any> = new EventEmitter();
    @ViewChild('searchTerm', {static: true}) searchInput:ElementRef;

    public items:any[] = [];
    public loading = false;
    public activeNode:number = 0;
    public tempResults;
    public isVisible:boolean = false;
    public userId:string;

    private _unsubscribe = new Subject();
    private resultLength:number;
    private isSearching:boolean = false;
    private valueChanged:boolean = false;

  public get FormInputType() {
    return FormInputType
  }
    /**
     * This accessors gets the inputted control of the inputted form
     */
    get termControl() {
        return this.parentForm.controls[this.controlName];
    }

    constructor(private ER:ElementRef,
                private Search:SearchService,
                private CD:ChangeDetectorRef,
      private route: ActivatedRoute) {      
    }

    /**
     * Click Event Listener to see what the user has selected
     *
     * @param target
     */
    @HostListener('document: click', ['$event.target'])
    onClick(target:HTMLElement) {
        if (!this.isVisible) {
            return;
        }

        let parentFound = false;

        while (target && !parentFound) {
            if (target === this.ER.nativeElement) {
                parentFound = true;
            }

            target = target.parentElement;
        }

        if (!parentFound) {
            this.isVisible = false;
        }
    }

    /**
     * Keydown Event Listener to see what text the user has entered
     *
     * @param e
     */
    @HostListener('keydown', ['$event'])
    keyboardInput(e: KeyboardEvent) {
        if (this.resultLength) {
            if (e.key === 'ArrowDown' && this.activeNode < this.resultLength - 1) {
                this.activeNode++;
            } else if (e.key === 'ArrowUp' && this.activeNode > 0) {
                this.activeNode--;
            } else if (e.key === 'Enter') {
                e.preventDefault();
                e.stopPropagation();
                this.onSubmitSelectedValue(this.tempResults[this.activeNode]);
            }
        } else {
            return;
        }
    }

    /**
     * Keyup Event Listener to see what text the user has entered
     *
     * @param e
     */
    @HostListener('keyup', ['$event'])
    keyEvent(e:KeyboardEvent) {
        if (e.key === 'Backspace' && this.termControl.value) {
            if (this.searchType === 'userObject') {
                return;
            }
            this.clearSearch();
        }
    }

    /**
     * @ignore
     */
    ngOnDestroy():void {
        if (this.searchOff) {
            return;
        }
        this._unsubscribe.next();
        this._unsubscribe.complete();
    }

    /**
     * Subscribe to the different Observables that are used in this component
     */
    ngOnInit():void {
        this.userId = this.route.snapshot.paramMap.get('id');
        if (this.searchOff) {
            return;
        }
           
        this.decideToShowSuggestion();
        this.getSearchResult();
    }


  private getSearchResult() {
    this.Search.slotSearch(this.termControl.valueChanges, this.searchType, this.userId)
      .pipe(map(param => this.filterResult ? this.filterResult(param) : param),
        takeUntil(this._unsubscribe))
      .subscribe(res => {
        this.items = res;
        if (this.valueChanged) { this.isVisible = true; }
        this.tempResults = res;
        this.resultLength = res?.length;
        this.CD.markForCheck();
      });
  }

  private decideToShowSuggestion() {
    this.termControl.valueChanges
      .pipe(pairwise(), takeUntil(this._unsubscribe))
      .subscribe(([prev, next]) => {
        if (next !== prev) {
          this.valueChanged = true;
          this.isSearching = true;
        } else {
          this.isSearching = false;
          this.isVisible = false;
        }
      });
  }

    public clearSearch():void {
        this.tempResults = null;
        this.resultLength = 0;
        this.isVisible = false;
        this.termControl.setValue(null, {emitEvent: false});
        this.searchInput.nativeElement.focus();
        this.submitSelectedValue.emit(null);
    }

    /**
     * Submit the value that the user selects
     *
     * @param item
     */
  public onSubmitSelectedValue(item): void {
        this.isSearching = false;
        this.submitSelectedValue.emit(item);
        this.isVisible = false;
        this.valueChanged = false;
        this.termControl.setValue(item[this.controlValue], {emitEvent: false});
        this.parentForm.updateValueAndValidity();
    }
}

