<div *ngIf="calledFrom === RegistrationComponents.SUMMARY" class="py-3">
    <h3>{{privateCustAddCreation.userProfileEdit| translate}}</h3>
</div>
<form *ngIf="accountForm">
    <div class="row">
        <div class="col-sm">
            <sl-form-item [formGroup]="accountForm" [controlName]="'salutation'" [inputType]="FormInputType.DROPDOWN"
                [placeholderText]="'REGISTRATION.LABELS.salutation'" [labelText]="'REGISTRATION.LABELS.salutation'"
                [dropdownValues]="salutationOptions"></sl-form-item>
        </div>
    </div>
    <div class="first-last-name row">
        <div class="col-sm-6">
            <sl-form-item [inputType]="FormInputType.TEXT" [formGroup]="accountForm" [controlName]="'firstName'"
                [labelText]="'REGISTRATION.LABELS.firstname'" [placeholderText]="'Max'">
            </sl-form-item>
        </div>
        <div class="col-sm-6">
            <sl-form-item [inputType]="FormInputType.TEXT" [formGroup]="accountForm" [controlName]="'lastName'"
                [labelText]="'REGISTRATION.LABELS.lastname'" [placeholderText]="'Mustermann'">
            </sl-form-item>
        </div>
    </div>

    <div class="row">
        <div class="col-sm">
            <sl-form-item [inputType]="FormInputType.TEXT" [formGroup]="accountForm" [controlName]="'email'"
                [labelText]="'REGISTRATION.LABELS.email'" [placeholderText]="'REGISTRATION.PLACEHOLDERS.email'"
                [helperText]="'REGISTRATION.HELP_TEXT.email'">
            </sl-form-item>
        </div>
    </div>

    <div *ngIf="!distinguishService.support" class="row">
        <div class="col-sm">
            <sl-form-item [inputType]="FormInputType.PASSWORD" [formGroup]="accountForm" [controlName]="'password'"
                [labelText]="'REGISTRATION.LABELS.password'">
            </sl-form-item>
            <div class="light">
                {{'REGISTRATION.PRIVATE_CUST_ACC_CREATION.passwordValidationTitle' | translate}}
            </div>
            <ul class="d-flex flex-wrap justify-content-between dashed light">
                <li class="pr-4 mb-1 mb-sm-0">{{privateCustAddCreation.passwordValidation8 | translate}}</li>
                <li class="pr-4 mb-1 mb-sm-0">{{privateCustAddCreation.passwordValidationUpper | translate}}</li>
                <li class="pr-4 mb-1 mb-sm-0">{{privateCustAddCreation.passwordValidationLower | translate}}</li>
                <li class="pr-4 mb-1 mb-sm-0">{{privateCustAddCreation.passwordValidationNum | translate}}
                </li>
            </ul>
        </div>
    </div>

</form>

<ng-container *ngIf="calledFrom === RegistrationComponents.SUMMARY;else next">
    <div class="row pt-3 pb-4">
        <div class="col-sm">
            <button class="btn btn-secondary btn-mobile-block mr-0 mr-sm-3 mb-1 mb-sm-0" (click)="submit()"
                type="submit">
                {{'REGISTRATION.BUTTONS.saveButton'| translate}}
            </button>
            <button class="btn btn-light btn-mobile-block" (click)="onCancel()" type="submit">
                {{'REGISTRATION.BUTTONS.cancelButton' | translate}}
            </button>
        </div>
    </div>
</ng-container>

<ng-template #next>
    <div class="row py-3">
        <div class="col-sm">
            <button class="btn btn-primary" (click)="submit()" type="submit">
                {{'REGISTRATION.BUTTONS.nextButton' | translate}}
            </button>
        </div>
    </div>
</ng-template>