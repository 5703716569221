import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { Store } from '@ngrx/store';
import {  RegisterAccount, registerActions, RegistrationComponents } from '../../../../shared';
import { State } from '../../../../../reducers/root/root.reducer';
import { SummaryPageLang } from '../../../../shared/models/util.model';

@Component({
    selector: 'sl-registration-summary-user-profile',
    templateUrl: './registration-summary-user-profile.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class RegistrationSummaryUserProfileComponent extends SummaryPageLang {
    @Input() account:RegisterAccount;
    @Input() registrationIncomplete:string;

    public isEditMode:boolean = false;
    public RegistrationComponents = RegistrationComponents;

    constructor(private store:Store<State>) {
        super();
    }

    public toggleEdit() {
        this.isEditMode = !this.isEditMode;
    }

    public saveAccount(registerAccount:RegisterAccount) {
        this.toggleEdit();
        this.store.dispatch(new registerActions.AddAccountFormData(registerAccount));
    }
}
