import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Observable } from 'rxjs';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Logo, MenuItem } from './header.model';
import { DistinguishSupportService } from '../../services';
import { AlertModalComponent } from '../alert-modal/alert-modal.component';


@Component({
    selector: 'sl-header',
    templateUrl: './header.component.html',
    styles: [],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class HeaderComponent implements OnInit {
    @Input() loggedIn: boolean;
    @Input() guest: boolean;
    @Input() username: string;
    @Input() isShown: boolean;
    @Input() menuItems: Observable<MenuItem[]>;
    @Input() logo: Logo;
    @Input() bannerText:string;
    @Input() fromSupport:boolean;
    @Input() notice: string;

    @Output() logout: EventEmitter<any> = new EventEmitter<any>();
    @Output() routeTo: EventEmitter<string> = new EventEmitter<string>();

    constructor(private modalService: NgbModal, public distinguishService: DistinguishSupportService) {
    }

    get logoutTranslationType(): string {
        return this.guest ? 'GUEST_LOGOUT' : 'LOGOUT';
    }

    get logoutButtonTranslation(): string {
        return this.guest ? 'ACCOUNT.BUTTONS.guestLogout' : 'ACCOUNT.BUTTONS.logout';
    }

    ngOnInit(): void {
    }

    /**
     * Navigation
     * @param destination
     */
    public navigateTo(destination: string) {
        document.body.style.overflow = 'visible';
        this.routeTo.emit(destination);
        this.isShown = false;
    }

    public allowedUrl(): boolean {
        const win: string = window.location.href;
        return !HeaderComponent.isLoginPath(win);
    }

    private static isLoginPath(win: string) {
        return win.includes('login');
    }
    /**
     * Opens a modal and log the user out
     */
    public open() {
        this.toggleMenu();
        const modalRef: NgbModalRef = this.modalService.open(AlertModalComponent,
            { centered: true, ariaLabelledBy: 'modal-basic-title' });
        modalRef.componentInstance.data = {
            title: 'ACCOUNT.' + this.logoutTranslationType + '.title',
            message: 'ACCOUNT.' + this.logoutTranslationType + '.text',
            dismissButtonText: 'ACCOUNT.' + this.logoutTranslationType + '.cancel',
            acceptButtonText: 'ACCOUNT.' + this.logoutTranslationType + '.logout'
        };
        modalRef.result.then((result) => {
            if (result === 'Abmelden') {
                this.loggedIn = false;
                this.logout.emit();
            }
        }, () => {
        });
    }

    public toggleMenu() {
        this.isShown = !this.isShown;

        const width = window.innerWidth
            || document.documentElement.clientWidth
            || document.body.clientWidth;

        if (this.isShown && width < 768) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'visible';
        }
    }
}
