<table class="table table-striped" *ngIf="list?.length; else noItems">
    <thead>
    <tr>
        <th scope="col"></th>
        <th scope="col">{{'ACCOUNT.RECLAMATIONS.date' | translate}}</th>
        <th scope="col">{{'ACCOUNT.RECLAMATIONS.number' | translate}}</th>
        <th scope="col">{{'ACCOUNT.RECLAMATIONS.transactionNumber' | translate}}</th>
        <th scope="col" colspan="2">{{'ACCOUNT.RECLAMATIONS.objectName' | translate}}</th>
    </tr>
    </thead>
    <tbody>
    <ng-container *ngIf="list?.length; else noItems">
        <tr id="listItemRow" *ngFor="let item of list">
            <td>
                <sl-status-column 
                [isSupportApp]="isSupportApp"
                [item]='item'
                (linkClicked)="onLinkClick($event)"
                >
            </sl-status-column>
            </td>
            <td>{{ item.createdAt | date : 'dd.MM.yyyy' }}</td>
            <td>
                <span>{{getserviceReportNr(item)}}</span>
            </td>
            <td>
                <button class="btn btn-link p-0" *ngIf="linkToDetail && item.transactionNumber; else NotLinked"
                        (click)="onLinkClick(item)">
                    {{getTransactionNumber(item)}}</button>
                <ng-template #NotLinked>{{getTransactionNumber(item)}}</ng-template>
            </td>
            <td>{{item.objectName || '-'}}</td>
            <td>
                <i (click)="onPdfClick(item)" class="fa fa-paperclip text-primary float-right pr-1"></i>
            </td>
        </tr>
    </ng-container>

    <ng-template #noItems>
        <sl-info-outlet title="ACCOUNT.RECLAMATIONS.noData"></sl-info-outlet>
    </ng-template>
    </tbody>
</table>

<div class="col-12 col-md-10 mx-auto" style="position: absolute; left: -9000px; top: 0;" #myDiv id="myDiv">
        <div class="row mt-5 mb-3 flex-column">
            <h1 *ngIf="reclamation">
                {{'ACCOUNT.RECLAMATIONS.DETAILS.title' | translate:{serviceReportNr: reclamationNumber} }}
            </h1>
            <p class="subline">
                {{'ACCOUNT.RECLAMATIONS.DETAILS.subTitle' | translate:{
                createdAt: reclamation?.createdAt | date : 'dd.MM.yyyy',
                objectName: reclamation?.objectName
            } }}
            </p>
        </div>

        <div *ngIf="isSupportApp && reclamation?.completedLate" class="row info-outlet-wrapper">
            <div class="d-flex justify-content-center">
                <span class="text-muted ml-2">{{'ACCOUNT.RECLAMATIONS.SERIAL_NUMBER_INPUT_TOOLTIP_TIMEOUT.text' | translate }}</span>
            </div>
        </div>

        <ng-container *ngIf="reclamation?.customerType === CustomerType.Commercial">
            <div class="row mb-2" *ngIf="reclamation?.objectType">
                <div class="mr-2 colon-after">{{'ACCOUNT.RECLAMATIONS.DETAILS.objectTyp' | translate}}</div>
                <div>{{('PRODUCTS.IDENTIFICATION.SELECTION_VALUES.OBJECT_TYPES.' + reclamation?.objectType | translate)}}</div>
            </div>
        </ng-container>

        <div class="row mt-4">
            <div class="mr-2 colon-after">{{'ACCOUNT.RECLAMATIONS.DETAILS.supplySource' | translate}}</div>
            <div>{{reclamation?.supplySource ? ('PRODUCTS.IDENTIFICATION.SELECTION_VALUES.PLACE_OF_PURCHASE.' + reclamation?.supplySource | translate) : '-'}}</div>
        </div>

        <sl-reported-serials-list [serials]="reclamation?.serials"></sl-reported-serials-list>
        <sl-reported-detectors-list [detectors]="reclamation?.detectors"></sl-reported-detectors-list>
</div>

<div id="download">
    <img #canvas>
</div>

<ng-template #noItems>
    <sl-info-outlet id="emptyListMessage" title="ACCOUNT.RECLAMATIONS.noData"></sl-info-outlet>
</ng-template>
