<ng-container *ngIf="formCreated">
    <form [formGroup]="formGroup">
        <ng-container>
            <div class="form-row align-items-end my-3">
                <div class="col-12">
                    <sl-form-item
                            [formGroup]="formGroup"
                            [controlName]="'password'"
                            [inputType]="inputType.PASSWORD"
                            [labelText]="'REGISTRATION.LABELS.password'">
                    </sl-form-item>
                </div>
            </div>

            <!-- PASSWORD CONFIRMATION -->
            <div class="form-row align-items-end">
                <div class="col-12">
                    <sl-form-item
                            [formGroup]="formGroup"
                            [controlName]="'passwordConfirmation'"
                            [inputType]="inputType.PASSWORD"
                            [labelText]="'REGISTRATION.LABELS.passwordConfirmation'">
                    </sl-form-item>
                </div>
            </div>
            <div class="invalid-feedback pl-1 pb-3 d-block">
                <span *ngIf="((formGroup.touched || formGroup.dirty) && (passwordConfirmation.touched || passwordConfirmation.dirty)) && passwordConfirmation.value && formGroup.hasError('notSame')">
                    {{'GENERAL.VALIDATION_ERRORS.passwordConfirmation' | translate}}
                </span>
            </div>
        </ng-container>
    </form>
</ng-container>
