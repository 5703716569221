<ng-container *ngIf="!editMode; else edit">
    <div class="row product-summary-list mb-3 flex-auto" *ngIf="product">
        <div *ngIf="!enterSerialsLater" class="col-3 col-md-2 pr-0">
            <img class="product-image" alt=""
                 [src]="product.viability !== ProductViabilityName.CORRUPTED && product.productImg
                ? product.productImg
                : 'assets/placeholder-img.jpg'">
        </div>
        <div class="col-7">
            <div class="d-flex flex-wrap-reverse flex-md-nowrap" *ngIf="!enterSerialsLater">
                <h4 class="my-auto mr-2">{{product.productTitle || 'Unbekannt'}}</h4>
                <sl-product-type-label class="mb-2 mb-md-0" *ngIf="product.groupType"
                    [text]="'PRODUCTS.IDENTIFICATION.SELECTION_VALUES.PRODUCT_GROUP.' + product.groupType">
                </sl-product-type-label>
            </div>

            <div *ngIf="product.serialNumber as productSerialNumber">
                SN &nbsp; {{productSerialNumber}}
            </div>

            <div *ngIf="product.viability !== ProductViabilityName.CORRUPTED && enterSerialsLater && dropDownValues?.groupType[product.groupType]">
                <h4>{{(dropDownValues?.groupType[product.groupType] | translate) }}</h4>
            </div>

            <div *ngIf="product.viability !== ProductViabilityName.CORRUPTED && product.installationYear as installationYear">
                {{'PRODUCTS.IDENTIFICATION.LABELS.installationYear' | translate}}:
                {{installationYear}}
            </div>

            <div *ngIf="product.viability !== ProductViabilityName.CORRUPTED && product.count as count">
                {{'PRODUCTS.IDENTIFICATION.LABELS.count' | translate}}:
                {{count}}
            </div>

            <div *ngIf="product.viability !== ProductViabilityName.CORRUPTED &&  dropDownValues?.reasonOfReturn && dropDownValues?.reasonOfReturn[product.returnReason]">
                {{(dropDownValues?.reasonOfReturn[product.returnReason] | translate) }}
            </div>

            <div *ngIf="product.valid !== 'valid'">
                <span class="text-danger">{{("ERRORS.SERIAL_CHECK." + product.valid) | translate}}</span>
            </div>
        </div>

        <div *ngIf="enterSerialsLater" class="col-3 col-md-2">
        </div>

        <div class="col-2 col-md-3 d-flex align-items-end justify-content-end pr-2">
            <div class="align-items baseline">

                <i class="fa fa-pencil text-blue mr-2 icon-button"
                   title="{{'PRODUCTS.SUMMARY.BUTTONS.edit' | translate}}" (click)="toggleEdit()">
                </i>

                <i class="fa fa-trash-o text-blue mr-2 icon-button"
                   title="{{'PRODUCTS.SUMMARY.BUTTONS.delete' | translate}}" (click)="open(deleteProduct, product)">
                </i>

            </div>
        </div>
    </div>
</ng-container>
<ng-template #edit>
    <sl-edit-container [title]="'PRODUCTS.SUMMARY.editTitle'" [hasAccept]="true" [hasCancel]="true"
                       (accept)="updateProduct()" (cancel)="toggleEdit()">
        <ng-container *ngIf="!enterSerialsLater; else noSerials">
            <sl-serial-entry-form class="w-100" [product]="product" [isEditMode]="true" [isSingleMode]="true"
                                  [dropDownValues]="dropDownValues" [showSource]="showSource" [showReturnReason]="showReturnReason">
            </sl-serial-entry-form>
        </ng-container>

        <ng-template #noSerials>
            <sl-no-serials-main-form #addMoreNoSerialsForm class="w-100" [product]="product"
                                     [detectorOptions]="detectorOptions" [formSelectionValues]="dropDownValues">
            </sl-no-serials-main-form>
        </ng-template>
    </sl-edit-container>
</ng-template>


<ng-template #deleteProduct let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">
            {{'PRODUCTS.IDENTIFICATION.REMOVE_PRODUCT.title' | translate}}
        </h4>
    </div>

    <div class="modal-body">
        {{'PRODUCTS.IDENTIFICATION.REMOVE_PRODUCT.text' | translate}}
    </div>

    <div class="modal-footer">
        <div class="w-100 d-flex justify-content-start">
            <button ngbAutofocus type="button" class="btn btn-primary mr-2" (click)="modal.dismiss()">
                {{'PRODUCTS.IDENTIFICATION.REMOVE_PRODUCT.cancel' | translate}}</button>
            <button type="button" class="btn btn-tertiary" (click)="modal.close('delete', product)">
                {{'PRODUCTS.IDENTIFICATION.REMOVE_PRODUCT.confirm' | translate}}</button>
        </div>
    </div>
</ng-template>