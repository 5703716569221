import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { State } from '../../../../reducers/root/root.reducer';
import { EvaluationState, ReclamationDetailed, ReclamationMeta } from '../../../shared/models/evaluation.model';
import { User } from '../../../shared/models/user.model';
import {
    FormDataService,
    DistinguishSupportService,
    InfoBannerType,
    TitleDescIcon
} from '../../../shared';
import * as ProductAction from '../../../shared/reducers/products/products.actions';
import * as EvaluationAction from '../../../shared/reducers/evaluation/evaluation.actions';

/**
 * Here is where the user will be shown the confirmation of their Reclamation with necessary info on
 * what data the user provided
 */
@Component({
    selector: 'sl-nonshop-confirmation',
    templateUrl: './nonshop-confirmation.component.html',
    styles: []
})
export class NonShopConfirmationComponent implements OnInit, OnDestroy {
    public userId: string;
    public currentUser: User
    public reclamationDetails: ReclamationDetailed;

    public feedbackShown: boolean = true;

    public transBase = 'PRODUCTS.WARRANTY_CONFIRMATION.';

    private evaluation$: Observable<EvaluationState>;
    private user$: Observable<User>;

    private endSubscriptions: Subject<void> = new Subject<void>();

    public bannerType = InfoBannerType;

    /**
     * This component requires Store<State>, FormDataService, Router, UserService
     * @param {Store<State>} store
     * @param {FormDataService} formDataService
     * @param {ActivatedRoute} route
     * @param {Router} router
     * @param {UserService} userService
     * @param {ChangeDetectorRef} changeDetectorRef
     * @param {ToastsService} toasts
     * @param {DistinguishSupportService} distinguishService
     * @param {ReclamationService} reclamationService
     */
    constructor(
        private store: Store<State>,
        private formDataService: FormDataService,
        private router: Router,
        public distinguishService: DistinguishSupportService,
    ) {
        this.evaluation$ = this.store.select('evaluation');
        this.user$ = this.store.select('user');
    }


    /**
     * Get the state of the Evaluation from the Store and populate the relevant information from it
     * Find out whether or not the user has seen the survey modal before and if they have not then show it
     */
    ngOnInit() {
        this.getCurrentUser();
        this.evaluation$.pipe(takeUntil(this.endSubscriptions))
            .subscribe((evaluation: EvaluationState & ReclamationMeta) => {
                if (evaluation.$meta) {
                    this.feedbackShown = evaluation.$meta.feedbackShown;
                }

                if (evaluation) {
                    this.reclamationDetails = evaluation.reclamationResponse;
                } else {
                    return this.navigateUser();
                }
            });
    }

    /**
     * @ignore
     */
    ngOnDestroy(): void {
        this.store.dispatch(new ProductAction.EnterSerialsLaterAction(false));
        this.store.dispatch(new ProductAction.DeleteAllProductAction());

        this.endSubscriptions.next();
        this.endSubscriptions.complete();
        this.endSubscriptions.unsubscribe();
    }

    public onMyReclamation() {
        this.router.navigate(['/servicemeldungen']);
    }


    /**
     * If the user presses the button to start a new Reclamation then delete all the
     * info in the store and direct them to the product form page
     */
    public startNewReclamation(): void {
        this.store.dispatch(new ProductAction.EnterSerialsLaterAction(false));
        this.store.dispatch(new ProductAction.DeleteAllProductAction());
        this.store.dispatch(new EvaluationAction.DeleteReclamationAction());
        this.navigateUser();
    }

    public createImgUrl(articleId: string): string {
        if (articleId) {
            return this.formDataService.buildImageURL(articleId);
        } else {
            return this.formDataService.PLACEHOLDER_IMG_URL;
        }
    }

    /**
     * check currentUser Status
     */
    private getCurrentUser() {
        this.user$.subscribe(user => {
            this.userId = user ? user.id : null;
        });
        this.currentUser = JSON.parse(sessionStorage.getItem('currentUser'));
    }

    private navigateUser() {
        if (this.distinguishService.support) {
            this.router.navigate(['/user']);
        } else {
            this.router.navigate(['/produkte/start/']);
        }
    }
}
