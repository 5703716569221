import { AddressInformation, CustomerType } from './user.model';
import { CheckType, Detector, Product, Serial } from './product.model';
import { MetaObject } from '../services';
import { PaymentInformation } from './shop.model';

/**
 * Enum for Reclamation Types
 */
export enum ReclamationTypeCases {
    Contact = 'contact',
    CompleteExchange = 'completeExchange',
    PartialExchange = 'partialExchange',
    NoExchange = 'noExchange'
}

/**
 * Export the ReclamationTypeCases
 */
export type ReclamationType =
    ReclamationTypeCases.Contact
    | ReclamationTypeCases.CompleteExchange
    | ReclamationTypeCases.PartialExchange
    | ReclamationTypeCases.NoExchange;

export interface DetectorsReceived {
    groupType: string;
    detectors: number;
    buyArticleNumber?: string;
    pricePerPiece?: number;
    unit?: string;
    typeDesignation?: string;
}

/**
 * Model for Detectors To Exchange
 */
export interface DetectorsToExchange extends DetectorsReceived {
    exchangeArticleNumber: string;
}

/**
 * Model for Evaluation State
 */
export interface EvaluationState {
    reclamationType: ReclamationType;
    customerTransactionNumber: string;
    reclamationResponse: ReclamationDetailed;
    temporaryId: string;
    detectorsToExchange: DetectorsToExchange[];
    detectorsToBuy: DetectorsReceived[];
    promptForSystemChange: boolean;
}

/**
 * Model for Reclamation Evaluation Result
 */
export interface ReclamationEvaluation {
    checkType?: CheckType;
    detectorsToExchange: DetectorsToExchange[];
    detectorsToBuy: DetectorsReceived[];
    reclamationType: ReclamationType;
    temporaryId?: string;
    promptForSystemChange: boolean;
}

interface Redirect {
    data: string;
    redirect: string;
}

export type CheckTypeResponse = CheckTypeShopResponse | CheckTypePlatformResponse;

export enum FlagRedirect {redirectToShop = 'SHOP', noRedirect = 'NO_SHOP'}

export interface CheckTypeShopResponse {
    type: FlagRedirect.redirectToShop;
    payload: Redirect;
}

export interface CheckTypePlatformResponse {
    type: FlagRedirect.noRedirect;
    payload: ReclamationEvaluation;
}


/**
 * Model for Submitting a Reclamation
 */
export interface ReclamationSubmissionBase {
    id: string;
    customerType: CustomerType;
    transactionNumber: string;
    reclamationType: string;
    addressInformation?: AddressInformation;
    numberRange?: number;
    number?: number;
    supplySource?: string;
    customerTransactionNumber?: string;
}

/**
 * Model for Submitting a Reclamation with serials
 */
export interface ReclamationSubmissionWithSerials extends ReclamationSubmissionBase {
    serials: Product[];
}

/**
 * Model for Submitting a Reclamation with detectors
 */
export interface ReclamationSubmissionWithDetectors extends ReclamationSubmissionBase {
    detectors: Detector[];
}

export interface CreatedBy {
    id?: string;
    username?: string;
    extSystemId?: string;
    customerType?: string;
    salutation?: number;
    businessPartnerNumber?: string;
    addressInformation?: AddressInformation;
    businessCustomerType?: string;
}

/**
 * Model for the Reclamation Base
 */
export interface ReclamationBase {
    id: string;
    customerType?: CustomerType;
    createdAt?: string;
    serviceReportNr?: string;
    transactionNumber?: string;
    objectName?: string;
    finished?: boolean;
    completedLate?: boolean;
    createdBy?: CreatedBy;
    reclamationType?: string;
}

/**
 * Model of a Detailed Reclamation
 */
export interface ReclamationDetailed extends ReclamationBase {
    detectorsToExchange: DetectorsToExchange[];
    customerTransactionNumber: string;
    addressInformation?: AddressInformation;
    billingAddressInformation?: AddressInformation;
    objectType?: number;
    serials: Serial[];
    detectors: Detector[];
    supplySource?: string;
    paymentInformation?: PaymentInformation;
    createdBy?: CreatedBy;
    detectorsToBuy?: DetectorsReceived[];
    containsSerialsToBeReturned?: boolean;
    advertisingGeniusPort?: boolean;
}

/**
 * Meta Model for a Reclamation
 */
export interface ReclamationMeta extends MetaObject {
    meta?: {
        total: number;
        feedbackShown?: boolean
    };
}

/**
 * Model of Feedback given by the User
 */
export interface Feedback {
    rating: number;
    message?: string;
}

export interface ReturnablesStatus {
    exists?: boolean;
    total?: number;
}
