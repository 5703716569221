import { Component, Input } from "@angular/core";
import { FormControl } from "@angular/forms";
import { ProductReclamationComponents } from '../../models';
import { ScannerService } from '../../services';

@Component({
    selector: 'sl-qr-code-button',
    templateUrl: './qr-code-button.component.html'
})
export class QRCodeButton {
  @Input() serialEntryControl: FormControl;
  @Input() calledFrom: ProductReclamationComponents
  public ProductReclamationComponents = ProductReclamationComponents;

    constructor(public scannerService: ScannerService) {
    }
}
