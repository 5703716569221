import { Action } from '@ngrx/store';
import { RegisterAccount, RegisterAddress, BusinessCustomerType, CustomerType, ExternalData } from '../../models';
import { Registration } from './register.reducer';


export const ADD_ACCOUNT = '[register] ADD_ACCOUNT';
export const ADD_ADDRESS = '[register] ADD_ADDRESS';
export const ADD_BUSINESS_CUSTOMER_TYPE = '[register] ADD_BUSINESS_CUSTOMER_TYPE';
export const ADD_LICENSE_OPTION = '[register] ADD_LICENSE_OPTION';
export const ADD_LOGIN = '[register] ADD_USER_TYPE';
export const ADD_USER_TYPE = '[register] ADD_USER_TYPE';
export const ADD_SALES_CHANNEL = '[register] ADD_SALES_CHANNEL';
export const ADD_USER_INFO = '[register] ADD_USER_INFO';
export const DELETE_ACCOUNT = '[register] DELETE_ACCOUNT';
export const DELETE_USER_TYPE = '[register] DELETE_USER_TYPE';
export const DELETE_ADDRESS = '[register] DELETE_ADDRESS';
export const DELETE_INSTALLER_OPTION = '[register] DELETE_INSTALLER_OPTION';
export const DELETE_LICENSE_OPTION = '[register] DELETE_LICENSE_OPTION';
export const DELETE_ALL = '[register] DELETE_ALL';

export class AddAccountFormData implements Action {
    readonly type = ADD_ACCOUNT;

    constructor(public payload:RegisterAccount) {
    }
}

export class AddAddressFormData implements Action {
    readonly type = ADD_ADDRESS;

    constructor(public payload:RegisterAddress) {
    }
}

export class AddBusinessCustomerType implements Action {
    readonly type = ADD_BUSINESS_CUSTOMER_TYPE;

    constructor(public payload:BusinessCustomerType) {
    }
}

export class AddLicenseOptionFormData implements Action {
    readonly type = ADD_LICENSE_OPTION;

    constructor(public payload:boolean) {
    }
}

export class AddUserTypeFormData implements Action {
    readonly type = ADD_USER_TYPE;
    constructor(public payload: CustomerType) {
    }
}

export class AddSalesChannel implements Action {
    readonly type = ADD_SALES_CHANNEL;
    constructor(public payload:ExternalData) {
    }
}

export class AddUserInfo implements Action {
    readonly type = ADD_USER_INFO;
    constructor(public payload:Registration) {
    }
}

export class DeleteUserTypeFormData implements Action {
    readonly type = DELETE_USER_TYPE;
}

export class DeleteAccountFormData implements Action {
    readonly type = DELETE_ACCOUNT;
}

export class DeleteAddressFormData implements Action {
    readonly type = DELETE_ADDRESS;
}

export class DeleteAllFormData implements Action {
  readonly type = DELETE_ALL;
}

export type RegistrationAction =
    | AddAccountFormData
    | AddAddressFormData
    | DeleteAccountFormData
    | DeleteAddressFormData
    | AddBusinessCustomerType
    | AddLicenseOptionFormData
    | AddSalesChannel
    | AddUserInfo
    | AddUserTypeFormData
    | DeleteUserTypeFormData
    | DeleteAllFormData;
