<div class="container">
    <div class="col-12 col-md-10 mx-auto my-3">
        <div class="row">
            <sl-back-navigation class="mt-2"
                                [buttonText]="'RECLAMATION_START.DETECTOR_RECLAMATION.backLinkText' | translate"
                                [icon]="'fa-arrow-left'">
            </sl-back-navigation>
        </div>
    </div>
    <div class="col-12 col-md-10 mx-auto">
        <div *ngIf="showBanner" class="row py-3">
            <sl-info-banner #infoBanner class="w-100" [bannerType]="bannerType" [textKey]="bannerTextKey">
            </sl-info-banner>
        </div>
        <div class="row flex-column my-3">
            <h1>{{'GENERAL.SERIAL_MASS_CLAIM.SUBMISSION.title' | translate: {orderNumber: orderNumber} }}</h1>
            <p class="lead">
                {{'GENERAL.SERIAL_MASS_CLAIM.SUBMISSION.lead' | translate}}
            </p>
        </div>
    </div>
    <div class="col-12 col-md-10 mx-auto">
        <ng-container *ngIf="viableValidProducts.length > 0">
            <div class="row flex-column my-3">
                <!-- <h3>{{'PRODUCTS.SUMMARY.VALID_PRODUCTS.title' | translate}}</h3>
                <p>{{'PRODUCTS.SUMMARY.VALID_PRODUCTS.text' | translate}}</p> -->
            </div>
            <div class="row" *ngFor="let product of paginatedViableValidProducts; let i = index">
                <sl-product-list-item class="product-list-item"
                                      [product]="product" [showErrorMessage]="false"
                                      [showSource]="false" [showReturnReason]="false"
                                      (delete)="deleteProduct($event)"
                                      (update)="updateProduct($event, product, i)">
                </sl-product-list-item>
            </div>
            <div class="d-flex justify-content-between p-2">
                <ngb-pagination *ngIf="viableValidProducts?.length > pageLength"
                                [(page)]="page"
                                [collectionSize]="viableValidProducts?.length"
                                [pageSize]="pageLength"></ngb-pagination>
            </div>
        </ng-container>
        <ng-container *ngIf="viableInvalidProducts.length > 0">
            <div class="row flex-column my-3">
                <h3>{{'PRODUCTS.SUMMARY.NOT_CONSIDERED.title' | translate}}</h3>
                <p>{{'PRODUCTS.SUMMARY.INVALID_PRODUCTS.text' | translate}}</p>
            </div>
            <div class="row" *ngFor="let product of viableInvalidProducts; let i = index">
                <sl-product-list-item class="product-list-item" [product]="product" [showErrorMessage]="true"
                                      [showSource]="false" [showReturnReason]="false"
                                      (delete)="deleteProduct($event)"
                                      (update)="updateProduct($event, product, i)">
                </sl-product-list-item>
            </div>
        </ng-container>

        <ng-container *ngIf="viableCorruptedProducts.length > 0">
            <div class="row flex-column my-3">
                <h3>{{'PRODUCTS.SUMMARY.CORRUPTED_PRODUCTS.title' | translate}}</h3>
                <p>{{'PRODUCTS.SUMMARY.CORRUPTED_PRODUCTS.text' | translate}}</p>
            </div>
            <div class="row" *ngFor="let product of viableCorruptedProducts; let i = index">
                <sl-product-list-item class="product-list-item" [product]="product"
                                      [showSource]="false" [showReturnReason]="false"
                                      [showErrorMessage]="false" (delete)="deleteProduct($event)"
                                      (update)="updateProduct($event, product, i)">
                </sl-product-list-item>
            </div>
        </ng-container>

        <div class="row mt-5" *ngIf="!addMore">
            <button (click)="toggleAddMore()" class="btn btn-tertiary">
                {{'PRODUCTS.SUMMARY.identification_in_summary_title' | translate}}</button>
            <sl-busy-btn [action]="actionProceed" [classes]="'ml-2'">
                <span>{{'PRODUCTS.SUMMARY.BUTTONS.checkReclamation' | translate}}</span>
            </sl-busy-btn>
        </div>
    </div>

    <div *ngIf="addMore" class="mt-5">
        <sl-edit-container [title]="'PRODUCTS.SUMMARY.editTitle'" [hasAction]="true" [hasCancel]="true"
                           [action]="addSerialsAction" (cancel)="toggleAddMore()">
            <sl-serial-entry-form #addMoreSerialsForm class="w-100"
                                  [showSource]="false" [showReturnReason]="false"
                                  [isEditMode]="true" [isSingleMode]="false">
            </sl-serial-entry-form>
        </sl-edit-container>
    </div>
</div>

<ng-template #serialsAlreadyClaimedWarning let-modal>
    <div class="modal-header">
        <h4 class="modal-title"
            id="modal-basic-title-submitted"> {{'GENERAL.SERIAL_MASS_CLAIM.WARNING.title' | translate}}</h4>
    </div>
    <div class="modal-body">
        <ul>
            <li *ngFor="let serialNumber of alreadyClaimedSerials">{{serialNumber}}</li>
        </ul>
        <p class="mt-2">
            {{'GENERAL.SERIAL_MASS_CLAIM.WARNING.textOption' | translate}}
        </p>
        <p class="mt-2">
            {{'GENERAL.SERIAL_MASS_CLAIM.WARNING.text' | translate}}
        </p>
    </div>
    <div class="modal-footer">
        <div class="w-100 d-flex justify-content-start">
            <button (click)="cancelModal()"
                    class="btn btn-tertiary mr-2"
                    tabindex="1"
                    type="button">
                <span>{{'GENERAL.SERIAL_MASS_CLAIM.WARNING.cancel' | translate}}</span>
            </button>
            <sl-busy-btn
                    [action]="submitAlthoughWarnedAction"
                    [type]="'submit'">
                <span>{{'GENERAL.SERIAL_MASS_CLAIM.WARNING.confirm' | translate}}</span>
            </sl-busy-btn>
        </div>
    </div>
</ng-template>
