<div *ngIf="dropDownValues" class="container">
    <div class="col-12 col-md-10 mx-auto">
        <div *ngIf="showBanner" class="row py-3">
            <sl-info-banner #infoBanner class="w-100" [bannerType]="bannerType" [textKey]="bannerTextKey">
            </sl-info-banner>
        </div>
        <div class="row flex-column my-5">
            <h1>{{'PRODUCTS.RECORDED_SERIALS.title' | translate}}</h1>
            <p class="lead">
                {{'PRODUCTS.RECORDED_SERIALS.subTitle' | translate}}
            </p>
        </div>
        <div class="row flex-column my-5">
            <h4>{{'PRODUCTS.RECORDED_SERIALS.serialNumbersAdded' | translate:
                {
                numberAdded: numberOfSerialsInList,
                numberNeeded: serialsMissing,
                reclamationNumber: reclamationNumber
                } }} </h4>
        </div>
    </div>
    <div class="col-12 col-md-10 mx-auto">
        <ng-container *ngIf="validProductsExist">
            <div class="row flex-column my-3">
                <!-- <h3>{{'PRODUCTS.SUMMARY.VALID_PRODUCTS.title' | translate}}</h3>
                <p>{{'PRODUCTS.SUMMARY.VALID_PRODUCTS.text' | translate}}</p> -->
            </div>
            <div class="row" *ngFor="let product of products; let i=index">
                <sl-product-list-item class="product-list-item" [product]="product" [showErrorMessage]="false"
                    [dropDownValues]="dropDownValues" [detectorOptions]="detectorOptions" [showSource]="false"
                    [enterSerialsLater]="false" (delete)="deleteProduct($event)"
                    (update)="updateProduct($event, product, i)">
                </sl-product-list-item>
            </div>
        </ng-container>

        <ng-container *ngIf="invalidProductsExist">
            <div class="row flex-column my-3">
                <h3>{{'PRODUCTS.SUMMARY.INVALID_PRODUCTS.title' | translate}}</h3>
                <p>{{'PRODUCTS.SUMMARY.INVALID_PRODUCTS.text' | translate}}</p>
            </div>
            <div class="row" *ngFor="let product of invalidProducts; let i=index">
                <sl-product-list-item class="product-list-item" [product]="product" [showErrorMessage]="true"
                    [detectorOptions]="detectorOptions" [showSource]="false" [enterSerialsLater]="false"
                    [dropDownValues]="dropDownValues" (delete)="deleteProduct($event)"
                    (update)="updateProduct($event, product, i)">
                </sl-product-list-item>
            </div>
        </ng-container>

        <!-- add more serials button -->
        <div class="row mt-5" *ngIf="!addMore">
            <button *ngIf="products.length < serialsMissing" (click)="toggleAddMore()" class="btn btn-tertiary mr-2">
                {{'PRODUCTS.SUMMARY.identification_in_summary_title' | translate}}
            </button>
 
            <ng-container *ngIf="numberOfSerialsInList === serialsMissing && !isSupportUser; else supportSubmit">
                <button (click)="confirmationModal('user', actionProceed)" class="btn btn-primary btn-mobile-block" [type]="'submit'">
                    <span>{{'PRODUCTS.SUMMARY.BUTTONS.checkReclamation' | translate}}</span>
                </button>
            </ng-container>
            <ng-template #supportSubmit>
                <button *ngIf="distinguishSupportService.support" (click)="confirmationModal('support')" class="btn btn-primary btn-mobile-block"
                    [type]="'submit'">
                    <span>{{'PRODUCTS.SUMMARY.BUTTONS.checkReclamation' | translate}}</span>
                </button>
            </ng-template>

        </div>

        <!-- end add more serials button -->

        <!-- more serials entry form -->
        <div *ngIf="addMore" class="row mt-5">
            <sl-edit-container 
            [title]="'PRODUCTS.SUMMARY.editTitle'" 
            [hasAccept]="true" 
            [hasCancel]="true" 
            (cancel)="toggleAddMore()"
            (accept)="addSerials()"
                >
                <sl-serial-entry-form #addMoreSerialsForm class="w-100" [dropDownValues]="dropDownValues"
                    [isEditMode]="true" [showSource]="false" [isSingleMode]="false">
                </sl-serial-entry-form>
            </sl-edit-container>
        </div>
        <!-- end more serials entry form -->
    </div>
</div>
