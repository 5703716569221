import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

/**
 * This is the component that displays the contact details for Heckatron on the right hand side of the site
 *
 * @alias HvgContactComponent
 */
@Component({
    selector: 'rp-hvg-contact',
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: './hvg-contact.component.html'
})
export class HvgContactComponent {
    @ViewChild('contactDetails', {static: true})
    contactDetails:ElementRef;

    public isActive:boolean = false;

    /**
     * @param {ChangeDetectorRef} CD
     */
    constructor(private CD:ChangeDetectorRef, private modal:NgbModal) {
    }

    /**
     * toggles whether or not the contact details is displayed or not
     *
     * @return void
     */
    public toggleIsActive():void {
        this.isActive = !this.isActive;
        this.CD.markForCheck();
    }

    public showModal() {
        this.toggleIsActive();
        this.modal.open(this.contactDetails, {
            centered: true,
            ariaLabelledBy: 'modal-basic-title'
        }).result.then((result) => {
            this.toggleIsActive();
        }, (reason) => {
            this.toggleIsActive();
        });
    }

}
