<ng-container *ngIf="paymentInformation">
    <ng-container *ngIf="paymentInformation.paymentMethod !== 'free'">
        <div class="row mt-5">
            <h3>{{"PRODUCTS.CONFIRMATION.PAYMENTINFORMATION.PAYMENTTYPE.title" | translate}}</h3>
        </div>
        <div class="row my-3">
            <img [ngStyle]="{'width': '100px'}" class="pr-0" [src]="imageSrc(paymentInformation.paymentMethod)" [alt]="paymentInformation.paymentMethod">
        </div>
    </ng-container>

    <div class="row mb-3 mt-5">
        <h3>{{"PRODUCTS.CONFIRMATION.PAYMENTINFORMATION.PAYMENT.title" | translate}}</h3>
    </div>
    <div class="row justify-content-between">
        <div class="col-8 pl-0">
            {{"PRODUCTS.CONFIRMATION.PAYMENTINFORMATION.PAYMENT.freeproducts" | translate}}
        </div>
        <div class="col-4 pr-0 text-right">
            {{"PRODUCTS.CONFIRMATION.ABBREVATIONS.free" | translate}}
        </div>
    </div>
    <div class="row justify-content-between">
        <div class="col-8 pl-0">
            {{"PRODUCTS.CONFIRMATION.PAYMENTINFORMATION.PAYMENT.chargeableproducts" | translate}}
        </div>
        <div class="col-4 pr-0 text-right">
            {{paymentInformation.total.priceInclVat?.toFixed(2)}} €
        </div>
    </div>
    <div class="row justify-content-between">
        <div class="col-8 pl-0">
            {{"PRODUCTS.CONFIRMATION.PAYMENTINFORMATION.PAYMENT.shippingcost" | translate}}
        </div>
        <div class="col-4 pr-0 text-right">
            {{"PRODUCTS.CONFIRMATION.ABBREVATIONS.free" | translate}}
        </div>
    </div>
    
    <div class="row border-top mt-2"></div>
    
    <div class="row justify-content-between font-weight-bold my-2">
        <div class="col-8 pl-0">
            {{"PRODUCTS.CONFIRMATION.PAYMENTINFORMATION.PAYMENT.total" | translate}}
        </div>
        <div class="col-4 pr-0 text-right">
            {{paymentInformation.total.priceInclVat?.toFixed(2)}} €
        </div>
    </div>
    <div class="row justify-content-between">
        <div class="col-8 pl-0">
            {{"PRODUCTS.CONFIRMATION.PAYMENTINFORMATION.PAYMENT.totalgross" | translate}}
        </div>
        <div class="col-4 pr-0 text-right">
            {{paymentInformation.total.netPrice?.toFixed(2)}} €
        </div>
    </div>
    <div class="row justify-content-between">
        <div class="col-8 pl-0">
            {{"PRODUCTS.CONFIRMATION.PAYMENTINFORMATION.PAYMENT.incvat" | translate}}
        </div>
        <div class="col-4 pr-0 text-right">
            {{paymentInformation.total.vat?.toFixed(2)}} €
        </div>
    </div>
</ng-container>