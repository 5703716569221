import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalData } from '../../models';

@Component({
  selector: 'sl-alert-modal',
  templateUrl: './alert-modal.component.html',
  styleUrls: [],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AlertModalComponent {
  @Input() data: ModalData;
  constructor(private activeModal: NgbActiveModal) { }

  public dismiss() {
    this.activeModal.dismiss();
  }

  public accept() {
    this.activeModal.close('Abmelden');
  }

}
