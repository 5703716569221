import { Component, Input, OnInit } from '@angular/core';
import { Detector } from '../../models/product.model';

@Component({
    selector: 'sl-reported-detectors-list',
    templateUrl: './reported-detectors-list.component.html',
    styles: []
})
export class ReportedDetectorsList implements OnInit {
    @Input() detectors: Detector[];

    constructor() { }

    ngOnInit(): void {
    }
}