<div class="container">
    <div class="col-12 col-md-10 mx-auto">
        <div class="row">
            <sl-back-navigation [buttonText]="'RECLAMATION_START.DETECTOR_RECLAMATION.backLinkText' | translate">
            </sl-back-navigation>
        </div>

        <div class="row py-3">
            <h1>{{'RECLAMATION_START.RECLAMATION_WITHOUT_ACCOUNT.title' | translate}}</h1>
        </div>
        <div class="row">
            <p class="lead">
                {{'RECLAMATION_START.RECLAMATION_WITHOUT_ACCOUNT.detail'| translate}}
            </p>
        </div>


        <div class="row">
            <!-- form -->
            <sl-anonymous-login-form [redirectRoute]="redirectRoute"></sl-anonymous-login-form>
            <!-- end form -->
        </div>

        <div class="row pt-3 " [innerHTML]="getSmallText()"></div>
    </div>
</div>