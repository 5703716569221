import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef, OnDestroy,
    OnInit,
    ViewChild
} from '@angular/core';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { Observable, of, Subject } from 'rxjs';
import {
    SerialService
} from '../../../shared/services/serial/serial.service';
import { catchError, map, mergeMap, take, takeUntil, tap } from 'rxjs/operators';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import {
    ToastsService,
    UtilsService,
    FormInputType,
    RecaptchaService,
    RoutePathName,
    FormDataService,
    MassSerialCheck,
    Product,
    MassSerialItem,
    SerialMassClaimResponse,
    SerialMassClaim,
    InfoBannerType,
    DistinguishSupportService, CustomerType, ProductsState
} from '../../../shared';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import * as ProductsAction from '../../../shared/reducers/products/products.actions';
import { Store } from '@ngrx/store';
import { State } from '../../../../reducers/root/root.reducer';
import { NavigationUtilsService } from '../../../shared/services/navigation-utils.service';
import { SaveMassSerialsClaimAction } from '../../../shared/reducers/products/products.actions';

@Component({
    selector: 'sl-serial-mass-claim',
    templateUrl: './serial-mass-claim.component.html',
    styles: []
})
export class SerialMassClaimComponent implements OnInit, OnDestroy {
    @ViewChild('infoBanner', {static: false, read: ElementRef}) banner:ElementRef;

    public backRoute:string = '';
    public form:FormGroup;
    public submitAction:Observable<any>;
    public submitAlthoughWarnedAction:Observable<SerialMassClaimResponse>;
    public alreadyClaimedSerials:string[] = [];
    public invalidSerials:string = '';
    public comingFromRekla:boolean;
    public FormInputType = FormInputType;
    public bannerTextKey:string;
    public bannerType:InfoBannerType;
    public showBanner:boolean = false;

    public serialNumbers:string = '';
    private orderNumber:string;
    private customerNumber:string;
    private products$:Observable<ProductsState | any>;
    private _unsubscribe = new Subject();

    constructor(private Utils:UtilsService,
                private router:Router,
                private CD:ChangeDetectorRef,
                private recaptchaService:RecaptchaService,
                private store:Store<State>,
                private route:ActivatedRoute,
                private navigationUtils:NavigationUtilsService,
                public formDataService:FormDataService,
                private distinguishSupportService:DistinguishSupportService) {
        this.submitAction = this.Utils.createActionObs(() => this.prepareCheckSerialsAndProceed());
        // check if the component is used from rekla
        this.comingFromRekla = !this.distinguishSupportService.support;
        this.backRoute = this.comingFromRekla ? '/produkte/auswahl-reklamation' : '/user';
        this.products$ = this.store.select('products');
    }

    get serialNumbersControl():AbstractControl {
        return this.form.controls.serialNumbers;
    }

    get orderNumberControl():AbstractControl {
        return this.form.controls.orderNumber;
    }

    get customerNumberControl():AbstractControl {
        return this.form.controls.customerNumber;
    }

    ngOnInit():void {
        this.getProductFromStore();
    }

    /**
     * @ignore
     */
    ngOnDestroy():void {
        this._unsubscribe.next();
        this._unsubscribe.complete();
    }

    public prepareCheckSerialsAndProceed() {
        return this.recaptchaService.getToken('serials/check').pipe(
            tap(() => {
                this.checkFieldValidations(this.form);
                this.checkForDuplicates();
            }),
            mergeMap(response => {
                const serials = this.serialNumbersControl.value;
                return this.formDataService.checkMassSerials(serials, response, true);
            }),
            tap(res => {
                    const products = this.createProductArray(res);

                    this.store.dispatch(new ProductsAction.SaveMassSerialsClaimAction(this.collectFormData(products)));
                    const nextRoute:string = `/${RoutePathName.PRODUCT}/${RoutePathName.RETRIEVAL_ORDER_SUMMARY}`;
                    this.router.navigate([this.navigationUtils.addIdToRoute(nextRoute, this.route)]);
                },
                (error) => {
                    this.setInfoBanner(error);
                }),
            takeUntil(this._unsubscribe));
    }

    private createForm():void {
        if (this.comingFromRekla) {
            this.form = new FormGroup({
                serialNumbers: new FormControl(this.serialNumbers, {updateOn: 'change', validators: [Validators.required]}),
                customerNumber: new FormControl(this.customerNumber, {updateOn: 'change', validators: []}),
                orderNumber: new FormControl(this.orderNumber, {updateOn: 'change', validators: [Validators.required]})
            });
        } else {
            this.form = new FormGroup({
                serialNumbers: new FormControl(this.serialNumbers, {updateOn: 'change', validators: [Validators.required]}),
                customerNumber: new FormControl(this.customerNumber, {
                    updateOn: 'change',
                    validators: [Validators.required, Validators.minLength(7), Validators.maxLength(7)]
                }),
                orderNumber: new FormControl(this.orderNumber, {updateOn: 'change', validators: [Validators.required]})
            });
        }
        this.CD.markForCheck();
    }

    private collectFormData(products:Product[]):SerialMassClaim {
        return {
            products: products,
            orderNumber: this.orderNumberControl.value,
            customerNumber: this.customerNumberControl.value
        };
    }

    private createProductArray(serials:MassSerialCheck):Product[] {
        let fullProductList = [];
        Object.keys(serials).forEach(viabilityType => {
            fullProductList = serials[viabilityType].map((serial:MassSerialItem, index:number) => ({
                isValid: viabilityType === 'valid',
                productImg: serial.article?.hasImage ? this.formDataService.buildImageURL(serial.article?.id) : this.formDataService.PLACEHOLDER_IMG_URL,
                productIndex: index,
                productTitle: serial.article?.type,
                serialNumber: serial.serial,
                serialNumberIsChecked: true,
                valid: serial.valid,
                viability: viabilityType
            })).concat(fullProductList);
        });

        return fullProductList;
    }

    private setInfoBanner(error:Error) {
        switch (error.message) {
            case 'Duplicate':
                this.bannerTextKey = 'PRODUCTS.IDENTIFICATION.ERRORS.duplicates';
                this.bannerType = InfoBannerType.INFO;
                this.showBanner = true;
                break;
            case 'Validation':
                // Dont do anything
                break;
            default:
                this.bannerTextKey = 'PRODUCTS.IDENTIFICATION.ERRORS.general';
                this.bannerType = InfoBannerType.DANGER;
                this.showBanner = true;
        }
        setTimeout(() => {
            this.banner?.nativeElement.scrollIntoView({behavior: 'smooth'});
        }, 300);
    }

    private getProductFromStore() {
        this.products$
            .pipe(takeUntil(this._unsubscribe))
            .subscribe((products) => {
                products.products.forEach(product => {
                    this.serialNumbers += product.serialNumber + ' ';
                });
                this.orderNumber = products.orderNumber;
                this.customerNumber = products.customerNumber;
                this.createForm();
            });
    }

    private checkFieldValidations(form:FormGroup) {
        let valid = true;
        valid = this.formDataService.isValid(form, valid);
        if (!valid) {
            throw new Error('Validation');
        }
    }

    private checkForDuplicates() {
        const serials:string = this.form.value?.serialNumbers;
        let matches = serials?.match(/[\d|\w]+/ig);
        // If duplicates exist
        if (matches.filter((item, index) => matches.indexOf(item) !== index).length > 0) {
            throw new Error('Duplicate');
        }
    }

}
