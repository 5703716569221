import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormInputType } from '../../models/product.model';
import { FormGroup, FormControlName } from '@angular/forms';

/**
 * Component that has a text area that a user can input text into
 */
@Component({
    selector: 'sl-textarea',
    templateUrl: './textarea.component.html',
    styles: [`:host {
        width: 100%;
    }`],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class TextareaComponent implements OnInit {

    @Input() label:string;
    @Input() formGroup: FormGroup;
    @Input() helpText:string;
    @Input() placeholder:string;
    @Input() maxLength:number;
    @Input() tabindex:number;
    @Input() controlName: FormControlName;

    @Input() value:string;
    @Input() textAreaClass:string;
    @Output() onChange: EventEmitter<string> = new EventEmitter<string>();
    public FormInputType = FormInputType;

    /**
     * @ignore
     */
    constructor() {
    }

    /**
     * @ignore
     */
    ngOnInit() {
    }

    /**
     * @ignore
     */
    change(event) {
        event.preventDefault();
        event.stopPropagation();
        this.onChange.emit(event.target.value);
    }

}
