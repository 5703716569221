import { NgModule } from '@angular/core';
import { ExtraOptions, RouterModule, Routes } from '@angular/router';
import { LoginAuthGuard } from './services/login-auth.guard';
import { AuthGuard } from './services/auth.guard';
import { StartPageComponent } from './start-page/start-page.component';
import { LoginComponent, RoutePathName } from 'shared-lib';
import { PasswordResetComponent } from './registration/components/password-reset/password-reset.component';
import { EmptyLogoutComponent } from './empty-logout/empty-logout.component';

/**
 * List of the different possible routes that a user can navigate to in the site
 *
 * @const {Routes} routes
 */
const routes:Routes = [
    {
        path: RoutePathName.DEFAULT,
        component: StartPageComponent, //RegistrationFormComponent
        canLoad: [LoginAuthGuard],
        canActivate: [LoginAuthGuard]
    },
    {
        path: RoutePathName.PASSWORD_RESET,
        component: PasswordResetComponent
    },
    {
        path: RoutePathName.LOGIN,
        component: LoginComponent,
        canLoad: [LoginAuthGuard],
        canActivate: [LoginAuthGuard]
    },
    {
        path: RoutePathName.REGISTRATION,
        loadChildren: () => import('./registration/registration.module').then(m => m.RegistrationModule)
    },
    {
        path: RoutePathName.PRODUCT,
        loadChildren: () => import('./products/products.module').then(m => m.ProductsModule),
        canLoad: [AuthGuard],
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard]
    },
    {
        path: RoutePathName.SUBMISSION,
        loadChildren: () => import('./submission/submission.module').then(m => m.SubmissionWrapperModule),
        canLoad: [AuthGuard],
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard]
    },
    {
        path: RoutePathName.SERVICE_MESSAGE,
        loadChildren: () => import('./reclamation/reclamation.module').then(m => m.ReclamationModule),
        canLoad: [AuthGuard],
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard]
    },
    {
        path: RoutePathName.ACCOUNT_MANAGEMENT,
        loadChildren: () => import('./account/account.module').then(m => m.AccountModule),
        canLoad: [AuthGuard],
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard]
    },
    {
        path: RoutePathName.CONFIRMATION,
        loadChildren: () => import('./confirmation/confirmation.module').then(m => m.ConfirmationModule),
    },
     {
        path: RoutePathName.EMPTY_LOGOUT,
        component: EmptyLogoutComponent
    },
    {
        path: '**',
        redirectTo: RoutePathName.DEFAULT
    }
];

/**
 * Extra options that can be set that will do stuff such as save where the user scrolled to
 * @const {ExtraOptions} routerOptions
 */
const routerOptions:ExtraOptions = {
    scrollPositionRestoration: 'enabled',
    anchorScrolling: 'enabled',
    useHash: true
    // ...any other options you'd like to use
    ,
    relativeLinkResolution: 'corrected'
};

/**
 * Set up the routes for the site
 * @alias AppRoutingModule
 */
@NgModule({
    imports: [RouterModule.forRoot(routes, routerOptions)],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
