import { Component, OnInit, Input } from '@angular/core';
import { FormInputType, Product } from '../../../models';
import { FormGroup, FormControl, Validators } from '@angular/forms';

@Component({
    selector: 'sl-no-serials-main-form',
    templateUrl: './no-serials-main-form.component.html',
    styleUrls: []
})
export class NoSerialsMainFormComponent implements OnInit {
    @Input() detectorOptions;
    @Input() product: Product;
    @Input() formSelectionValues;
    public FormInputType = FormInputType;
    public productForm: FormGroup;

    constructor() { }

    private createForm() {
        this.productForm = new FormGroup({
            groupType: new FormControl(this.product?.groupType, {
                updateOn: 'blur',
                validators: [Validators.required],
            }),
            installationYear: new FormControl(this.product?.installationYear, {
                updateOn: 'blur',
                validators: [Validators.required],
            }),
            count: new FormControl(this.product?.count, {
                updateOn: 'blur',
                validators: [Validators.required],
            }),
            supplySource: new FormControl(this.product?.supplySource, {
                updateOn: 'blur',
                validators: [Validators.required],
            })
        })
    }


    ngOnInit(): void {
        this.createForm()
    }

}
