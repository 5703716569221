import { Component, ChangeDetectionStrategy } from '@angular/core';
import { FooterLang, imageSrc, PaymentIcons} from 'shared-lib';



@Component({
  selector: 'rp-payment-logos',
  templateUrl: './payment-logos.component.html',
  styleUrls: ['./payment-logos.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PaymentLogosComponent extends FooterLang implements imageSrc {
  public paymentIcons = PaymentIcons;
  constructor() {
    super();
  }

  imageSrc(logo: PaymentIcons): string {
    return `${PaymentIcons.LOCATION}${logo}${PaymentIcons.FILE_TYPE}`;
   }

}
