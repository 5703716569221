<div class="row mb-2 mt-3" *ngIf="!isEditMode">
    <h3>{{'PRODUCTS.IDENTIFICATION.SERIALS.title' | translate}}</h3>
    <div [innerHtml]="'PRODUCTS.IDENTIFICATION.SERIALS.subtext' | translate"></div>
</div>

<div class="row flex-nowrap">
    <div class="col px-0" [ngClass]="{'col-md-12': !isEditMode}">
        <sl-form-item *ngIf="!isSingleMode" [inputType]="FormInputType.TEXTAREA" [formGroup]="serialEntryForm" [controlName]="'serials'"
            [labelText]="'PRODUCTS.IDENTIFICATION.LABELS.serials'"
            [isInnerHtmlHelper]="true"
            [placeholderText]="'PRODUCTS.IDENTIFICATION.PLACEHOLDER.serial'"
            [helperText]="'PRODUCTS.IDENTIFICATION.HELPERTEXT.serial' | translate">
        </sl-form-item>
        <sl-form-item *ngIf="isSingleMode" [inputType]="FormInputType.TEXT" [formGroup]="serialEntryForm"
            [controlName]="'serial'" [labelText]="'PRODUCTS.IDENTIFICATION.LABELS.serials'"
            [placeholderText]="'PRODUCTS.IDENTIFICATION.PLACEHOLDER.serial'">
        </sl-form-item>
    </div>
    <div class="qr-container col-5 col-sm-3 col-md-offset-12 pt-2 pl-1 pr-0 px-md-1" [ngClass]="{'col-sm-2': !isEditMode, 'editable': isSingleMode}">
        <sl-qr-code-button [serialEntryControl]="serialEntryControl"></sl-qr-code-button>
    </div>
</div>
<div class="row" *ngIf="showSource">
    <div class="col-sm px-0">
        <sl-form-item [inputType]="FormInputType.DROPDOWN"
            [formGroup]="serialEntryForm"
            [controlName]="'supplySource'"
            [labelText]="'PRODUCTS.IDENTIFICATION.LABELS.supplySource'"
            [placeholderText]="'PRODUCTS.IDENTIFICATION.LABELS.supplySource'"
            [dropdownValues]="dropDownValues?.supplySource">
        </sl-form-item>
    </div>
</div>

<div class="row" *ngIf="showReturnReason">
    <div class="col-sm px-0">
        <sl-form-item [inputType]="FormInputType.DROPDOWN"
            [formGroup]="serialEntryForm"
            [controlName]="'returnReason'"
            [labelText]="'PRODUCTS.IDENTIFICATION.LABELS.returnReason'"
            [placeholderText]="'PRODUCTS.IDENTIFICATION.LABELS.returnReason'"
            [dropdownValues]="dropDownValues?.reasonOfReturn">
        </sl-form-item>
    </div>
</div>
