<div class="container" *ngIf="loadingData">
    <div class="col-12 col-md-10 mx-auto">
        <div class="row flex-column my-5">
            <h1>{{'PRODUCTS.LATE_RECLAMATION_CONFIRMATION.title' | translate}}</h1>
            <p class="lead">
                {{'PRODUCTS.LATE_RECLAMATION_CONFIRMATION.subTitle' | translate}}
            </p>
        </div>
    </div>
    <div class="col-12 col-md-10 mx-auto">
        <div class="row flex-column">
            <h4>{{'PRODUCTS.LATE_RECLAMATION_CONFIRMATION.reclamationNumber' | translate:
                {reclamationNumber: reclamationNumber} }}
            </h4>
        </div>
        <div class="row flex-column mt-2 mb-5">
            <p>
                 <span class="pr-2" *ngFor="let product of products; let i=index">
                    <ng-container *ngIf="products.length === 1; else more">
                        {{product.serialNumber}}
                    </ng-container>

                    <ng-template #more>
                        <ng-container *ngIf="(i+1) < products.length; else last">
                            {{product.serialNumber}},
                        </ng-container>
                        <ng-template #last>
                            {{product.serialNumber}}
                        </ng-template>
                    </ng-template>
                    
                </span>
            </p>
        </div>
    </div>
    <div class="col-12 col-md-10 mx-auto">
        <div class="row pb-4">
            <button class="btn btn-secondary btn-mobile-block mr-2 mb-2 mb-md-0" (click)="userReclamations()">
                {{'PRODUCTS.LATE_RECLAMATION_CONFIRMATION.BUTTONS.myReclamations'| translate}}
            </button>
            <button class="btn btn-tertiary btn-mobile-block" (click)="newReclamation()">
                {{'PRODUCTS.LATE_RECLAMATION_CONFIRMATION.BUTTONS.newReclamtion' | translate}}
            </button>
        </div>
    </div>
</div>
