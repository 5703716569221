import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { Store } from '@ngrx/store';
import {
    CustomerType,
    registerActions,
    RegisterAddress,
    RegistrationComponents
} from '../../../../shared';
import { State } from '../../../../../reducers/root/root.reducer';
import { SummaryPageLang } from '../../../../shared/models/util.model';

@Component({
    selector: 'sl-registration-summary-address',
    templateUrl: './registration-summary-address.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class RegistrationSummaryAddressComponent extends SummaryPageLang {
    @Input() address:RegisterAddress;
    @Input() customerType:CustomerType;
    @Input() registrationIncomplete:string;

    public isEditMode:boolean = false;
    public RegistrationComponents = RegistrationComponents;

    constructor(private store:Store<State>) {
        super();
    }

    ngOnInit():void {
    }

    public toggleEdit() {
        this.isEditMode = !this.isEditMode;
    }

    public save(registerAddress:RegisterAddress) {
        this.toggleEdit();
        this.store.dispatch(new registerActions.AddAddressFormData(registerAddress));
    }
}
