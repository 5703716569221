import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { TitleIcon, SVGIconNames } from '../../models/util.model';
@Component({
    selector: 'sl-steps-info',
    templateUrl: './steps-info.component.html',
    styleUrls: ['./steps-info.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class StepsInfoComponent {
    public title: string;
    public image: SVGIconNames;

    @Input() titleIcons: TitleIcon[];

    constructor() { }

    imageSrc(icon: SVGIconNames): string {
        return `${SVGIconNames.LOCATION}${icon}${SVGIconNames.FILE_TYPE}`;
    }
}
