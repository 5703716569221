<div class="col-12 col-md-10 mx-auto">
    <div class="my-3">
        <sl-back-navigation
        [buttonText]="'SERIAL_NUMBER_INPUT.backButton' | translate">
        </sl-back-navigation>
    </div>
    <div class="my-3 flex-column">
        <div *ngIf="showBanner" class="py-3">
            <sl-info-banner class="w-100" 
            [bannerType]="bannerType"
            [textKey]="bannerTextKey"
            ></sl-info-banner>
        </div>

        <h1>{{'SERIAL_NUMBER_INPUT.title' | translate }}</h1>

        <p *ngIf="!loadingData"
           class="lead">{{'SERIAL_NUMBER_INPUT.COMPLETE.subTitle' | translate: {number: reclamationNumber} }}
            {{'SERIAL_NUMBER_INPUT.info' | translate }}   
        </p>
    </div>

    <h4>{{'SERIAL_NUMBER_INPUT.youCanAddAMaxOf' | translate:
        {numberNeeded: serialsMissing} }}
    </h4>
            <div *ngIf="customerType && dropDownValues" class="col-md-12 mx-auto">
                <sl-serial-entry-form class="w-100" [dropDownValues]="dropDownValues | async"
                [products]="products" [showSource]="false" [isEditMode]="true"></sl-serial-entry-form>
            </div>
    

    <!--BUTTONS-->
    <div class="col-md-12 mx-auto">
        <div class="row my-3">
            <sl-busy-btn [action]="checkSerialsAndProceed" [tabindex]="0" [type]="'submit'">
                {{'PRODUCTS.IDENTIFICATION.BUTTONS.checkAndProceed' | translate}}
            </sl-busy-btn>
        </div>
    </div>
</div>
