import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import {
    DistinguishSupportService,
    FormInputType,
    PrivateAddressLang,
    RegisterAccount,
    RegistrationComponents,
    UserService,
    ValidationService
} from '../../../../shared';
import { take } from 'rxjs/operators';

@Component({
    selector: 'sl-account-form',
    templateUrl: './account-form.component.html',
    styleUrls: []
})
export class AccountFormComponent extends PrivateAddressLang implements OnInit {
    @Input() storedAccountData:RegisterAccount;
    @Output() next:EventEmitter<RegisterAccount> = new EventEmitter();
    @Output() save:EventEmitter<RegisterAccount> = new EventEmitter();
    @Output() cancel:EventEmitter<RegisterAccount> = new EventEmitter();
    @Input() calledFrom:RegistrationComponents = null;
    public accountForm:FormGroup;
    public RegistrationComponents = RegistrationComponents;
    public FormInputType = FormInputType;
    public salutationOptions;
    private pwdRegExp:RegExp;

    public constructor(public distinguishService:DistinguishSupportService,
                       private userService:UserService,
                       private CD:ChangeDetectorRef,
                       private validationService:ValidationService) {
        super();
    }

    ngOnInit():void {
        if (this.distinguishService.support) {
            this.setupSalutationValues();
            this.setupForm();
        } else {
            this.getPasswordRegEx().subscribe(
                (regExp:RegExp) => {
                    this.pwdRegExp = new RegExp(regExp);
                    this.setupSalutationValues();
                    this.setupForm();
                }
            );
        }
    }

    private setupForm() {
        this.createForm();
        if (this.storedAccountData) {
            this.setFormValues();
        }
        this.CD.markForCheck();
    }

    private setupSalutationValues() {
        this.salutationOptions = {
            0: 'REGISTRATION.FORM.SALUTATION.mr',
            1: 'REGISTRATION.FORM.SALUTATION.mrs'
        };
    }

    private getPasswordRegEx() {
        return this.userService.passwordRegExp().pipe(take(1));
    }

    private setFormValues() {
        Object.keys(this.accountForm.controls).forEach(field => {
            if (this.storedAccountData[field]) { // && field !== 'password' (usually pass not shown)
                const control = this.accountForm.get(field);
                control.setValue(this.storedAccountData[field]);
            }
        });
    }

    private createForm() {
        this.accountForm = new FormGroup(this.addAccountFormControl());
        if (!this.isSupportUser()) {
            this.addPasswordFormControl();
        }
    }

    private addPasswordFormControl() {
        this.accountForm.addControl('password', new FormControl(null, {
            updateOn: 'blur',
            validators: [Validators.required, Validators.pattern(this.pwdRegExp)]
        }));
    }

    private isSupportUser() {
        return this.distinguishService.support;
    }

    private addAccountFormControl() {
        return {
            salutation: new FormControl(null, {
                updateOn: 'blur',
                validators: [Validators.required]
            }),
            firstName: new FormControl(null, {
                updateOn: 'blur',
                validators: [Validators.required, this.validationService.customMinLength.bind(this), Validators.maxLength(40)]
            }),
            lastName: new FormControl(null, {
                updateOn: 'blur',
                validators: [Validators.required, this.validationService.customMinLength.bind(this), Validators.maxLength(40)]
            }),
            email: new FormControl(null, {
                updateOn: 'blur',
                validators: [Validators.required, Validators.email]
            })
        };
    }

    public submit() {
        if (!this.accountForm.valid) {
            Object.keys(this.accountForm.controls).forEach(field => {
                const control = this.accountForm.get(field);
                if (control.errors?.required || (control.errors && !control.errors.required && control.value !== '' && control.value !== null)) {
                    control.markAsTouched({onlySelf: true});
                }
            });
            return;
        }

        if (this.calledFrom === RegistrationComponents.SUMMARY) {
            this.save.emit(this.accountForm.value);
        } else {
            this.next.emit(this.accountForm.value);
        }
    }

    public onCancel() {
        this.cancel.emit(this.accountForm.value);
    }

}
