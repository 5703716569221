import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { SVGIconNames, TitleDescIcon, IconTitleDescriptionClass } from '../../models/util.model';


@Component({
  selector: 'sl-icon-text-bullet',
  templateUrl: './icon-text-bullet.component.html',
  styleUrls: ['./icon-text-bullet.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class IconTextBulletComponent implements IconTitleDescriptionClass {
  @Input() titleDescIcon: TitleDescIcon;
  constructor() {
  }

   imageSrc(icon: SVGIconNames | string): string {
    return `${SVGIconNames.LOCATION}${icon}${SVGIconNames.FILE_TYPE}`;
   }
}
