
<div class="row" *ngIf="contactTitle"><b>
        {{transBase + "contactTitle" | translate}}
    </b>
</div>

<div class="row">
    <div class="col-sm-6  p-sm-0">
        <p>{{transBase + 'ADDRESS.name' | translate}}</p>
        <p>{{transBase + 'ADDRESS.street' | translate}}</p>
        <p>{{transBase + 'ADDRESS.place' | translate}}</p>
        <p  class="mt-2">
            <i class="fa fa-phone px-1"></i>
            {{transBase + "tell" | translate}}
        </p>
    </div>

    <div class="col-sm-6">
        <p class="float-sm-right">{{transBase + 'fax' | translate}}</p>
        <p class="float-sm-right" > <u>{{transBase + 'email' | translate}}</u></p>
    </div>
</div>
