import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { UserService } from 'shared-lib';
import { Router } from '@angular/router';
import { take } from 'rxjs/operators';

@Component({
  selector: 'rp-empty-logout',
  templateUrl: './empty-logout.component.html',
  styleUrls: [],
})
export class EmptyLogoutComponent {
  constructor(private userService: UserService, private router: Router) {
    this.userService.logout().pipe(take(1)).subscribe(() => {
      this.router.navigate(['/']);
    });
  }
}
