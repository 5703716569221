import * as addressActions from './address.actions';

/**
 * The state of this reducer will contain the address as a string
 */
export interface AddressState {
    address:string;
}

/**
 * Set the initial State
 */
export const initialState:AddressState = {
    address: ''
};

/**
 * Reducer functions to save and delete an address
 * @param state
 * @param action
 */
export function reducer(state = initialState, action:addressActions.AddressActions):AddressState {
    let newState:AddressState;
    switch (action.type) {
        case addressActions.SAVE_ADDRESS:
            newState = Object.assign({}, state, {
                address: action.payload
            });
            break;
        case addressActions.DELETE_ADDRESS:
            newState = Object.assign({}, state, {
                address: initialState.address
            });
            break;
        default:
            return state;
    }

    return JSON.parse(JSON.stringify(newState));
}
