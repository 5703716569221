<ng-container *ngIf="serials && serials.length > 0">
    <div class="row my-3">
        <h3>{{"PRODUCTS.CONFIRMATION.PRODUCTLISTS.GENERAL.title" | translate}}</h3>
    </div>
    <div class="row">
        <div class="col-5 px-1 d-none d-sm-block"></div>
        <div class="col-7 col-sm-2 text-right px-1 font-weight-bold">
            {{"PRODUCTS.CONFIRMATION.PRODUCTLISTS.GENERAL.TABLEHEADERS.serialnr" | translate}}
        </div>
        <div class="col-5 text-right px-1 font-weight-bold">
            {{"PRODUCTS.CONFIRMATION.PRODUCTLISTS.GENERAL.TABLEHEADERS.reasonOfReturn" | translate}}
        </div>
    </div>
    <div *ngFor="let serial of serials" class="row py-2 border-top">
        <div class="col-5 px-1 font-weight-bold">
            {{serial.articleType}}
        </div>
        <div class="col-2 text-right px-1">
            {{serial.serialNumber}}
        </div>
        <div class="col-5 text-right px-1">
            {{"PRODUCTS.IDENTIFICATION.SELECTION_VALUES.REASON_OF_RETURN." + serial.returnReason | translate}}
        </div>
    </div>
</ng-container>