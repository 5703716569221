import { Component, ChangeDetectionStrategy } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { RecaptchaService, UtilsService, LoginPageLang } from '../../../shared';

@Component({
    selector: 'sl-anonymous-login',
    templateUrl: './anonymous-login.component.html',
    styleUrls: [],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AnonymousLoginComponent extends LoginPageLang {
    public readonly redirectRoute:string = '/produkte/identifikation';
    private readonly privacyPolicyUrl:string = 'https://policies.google.com/privacy?hl=de-DE&fg=1';
    private readonly termsOfUse:string = 'https://policies.google.com/terms?hl=de-DE&fg=1';
    private anchorMiddleSpacer:string = 'class=\"spacer-right-left\"';

    constructor(
        private translateService:TranslateService,
        public Recaptcha:RecaptchaService,
        private utilsService:UtilsService
    ) {
        super();
    }

    getSmallText() {
        if(this.Recaptcha.isRecaptchaEnabled(false)) {
            const toReplace = this.translateService.instant(this.LoginPage.small_text);
            const replace0 = `<a ${this.anchorMiddleSpacer} href=${this.privacyPolicyUrl}>${this.translateService.instant(this.LoginPage.privacy_policy)}</a>`;
            const replace1 = `<a ${this.anchorMiddleSpacer} href=${this.termsOfUse}>${this.translateService.instant(this.LoginPage.terms_of_use)}</a>`;
            const replaced = this.utilsService.replaceText(toReplace, [replace0, replace1]);
            return `<span class = "small"> ${replaced} </span>`;
        }else {
            return '';
        }
    }


}
