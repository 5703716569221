<div class="col-12 col-md-10 pl-0 d-flex flex-wrap align-items-center">
  <span class="pr-3 pl-0" [innerHTML]=" footer.title | translate"></span>
  <div>
      <img class="pr-0 logo" [src]="imageSrc(paymentIcons.PAYPAL)" [alt]="footer.paypal| translate"
      [ngbPopover]="footer.paypal| translate" triggers="click">
      <img class="pr-0 logo" [src]="imageSrc(paymentIcons.MASTER_CARD)" [alt]="footer.master| translate"
      [ngbPopover]="footer.master| translate" triggers="click">
      <img class="pr-0 logo " [src]="imageSrc(paymentIcons.KLARNA)" [alt]="footer.sofort| translate"
      [ngbPopover]="footer.sofort| translate" triggers="click">
      <img class="pr-0 invoice" [src]="imageSrc(paymentIcons.INVOICE)" [alt]="footer.invoice| translate"
        [ngbPopover]="footer.invoice | translate" triggers="click">
  </div>
</div>