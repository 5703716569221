import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { UtilsService } from './utils.service';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { SerialNumberCheckWithProdDate, StrictHttpParams } from '../models';
import { FormGroup } from '@angular/forms';

@Injectable({
    providedIn: 'root'
})
export class ProductService {

    constructor(private Http:HttpClient,
                private Utils:UtilsService) {
    }

    public checkSerialNumber(serialNumber:string):Observable<SerialNumberCheckWithProdDate> {
        let params = new StrictHttpParams();
        params = params.set('extendedInfo', 'true');
        return this.Http.get<SerialNumberCheckWithProdDate>(this.Utils.replace(environment.api.product.serialCheck, serialNumber), {params: params});
    }

    public buildImageURL(articleId:string):string {
        return this.Utils.replace(environment.api.image.imageURL, articleId);
    }

    public getFormInputSerials(form: FormGroup) {
        let serials: string = form.value?.serials || form.value?.serial;
        return serials?.match(/[\d|\w]+/ig);
    }
    public getFormInputReturnReason(form: FormGroup) {
        let reason: string = form.value?.returnReason;
        return reason;
    }
    public getFormInputSupplySource(form: FormGroup) {
        let source: string = form.value?.supplySource;
        return source;
    }

}
