<div *ngIf="showBanner" class="py-3">
    <sl-info-banner class="w-100" [bannerType]="bannerType" [textKey]="bannerTextKey"></sl-info-banner>
</div>

<form *ngIf="loginForm">
    <div class="row">
        <div class="col-sm">
            <sl-form-item [inputType]="InputType.TEXT" [formGroup]="loginForm" [controlName]="'email'"
                          [labelText]="'REGISTRATION.LABELS.email'"
                          [placeholderText]="'REGISTRATION.PLACEHOLDERS.email'">
            </sl-form-item>
        </div>
    </div>

    <div class="row" (keyup.enter)="onEnterPressed()" >
        <div class="col-sm">
            <sl-form-item [inputType]="InputType.PASSWORD" [formGroup]="loginForm" [controlName]="InputType.PASSWORD"
                          [labelText]="'REGISTRATION.LABELS.password'">
            </sl-form-item>
        </div>
    </div>
</form>
<div class="row mb-2" *ngIf="showAlternativeOptions">
    <div class="col-sm">
        <u class="btn-link px-0" role="button" (click)="openEmailForgotModal()">{{LoginPage.forgotPass | translate}}</u>
    </div>
</div>

<sl-busy-btn [clickedByEnter]="clickedByEnter" [action]="requestLoginAction" [tabindex]="0" [type]="'submit'">
  <span>
    {{LoginPage.loginButton| translate}}
  </span>
</sl-busy-btn>
