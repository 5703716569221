import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    OnInit,
    ViewChild,
    ViewChildren
} from '@angular/core';
import {
    AddressState,
    CustomerType,
    DistinguishSupportService,
    FormDataService,
    FormSelectionObjects,
    NoSerialsFormComponent,
    Product,
    ProductsState,
    User,
    UserDetailsService,
    UserService
} from '../../../../../shared';
import { interval, Observable, of, Subject } from 'rxjs';
import { mergeMap, take, takeUntil, takeWhile } from 'rxjs/operators';
import * as ProductsAction from '../../../../../shared/reducers/products/products.actions';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { State } from '../../../../../../reducers/root/root.reducer';
import { TranslateService } from '@ngx-translate/core';
import { NavigationUtilsService } from '../../../../../shared/services/navigation-utils.service';
import { FormGroup } from '@angular/forms';

@Component({
    selector: 'sl-no-serials-container',
    templateUrl: './no-serials-container.component.html',
    styles: []
})
export class NoSerialsContainerComponent implements OnInit {
    @ViewChildren(NoSerialsFormComponent) noSerialsFormChildren;

    public products:Product[] = [];
    public userId:string;
    public customerType:CustomerType;
    public CustomerType = CustomerType;
    public globalReturnReason:string;
    public address:string;
    public dropDownValues:Observable<FormSelectionObjects>;

    private _unsubscribe = new Subject();
    private products$:Observable<ProductsState>;
    private address$:Observable<AddressState>;

    constructor(private router:Router,
                private store:Store<State>,
                public route:ActivatedRoute,
                private changeDetectorRef:ChangeDetectorRef,
                private elementRef:ElementRef,
                private userService:UserService,
                private translateService:TranslateService,
                public navigationUtils:NavigationUtilsService,
                public distinguishService:DistinguishSupportService,
                private userDetails:UserDetailsService,
                public formDataService:FormDataService) {
    }

    ngOnInit():void {
        this.getUserDetails();
        this.products$ = this.store.select('products');
        this.products$.pipe(take(1)).subscribe((products) => {
            if (products.products.length > 0 && (!products.enterSerialsLater)) {
                this.store.dispatch(new ProductsAction.DeleteAllProductAction());
                this.products = [];
            } else {
                this.products = products.products;
            }
            this.store.dispatch(new ProductsAction.EnterSerialsLaterAction(true));

            if (this.products.length <= 0 && this.customerType) {
                this.newProduct();
            }
        });
        this.address$ = this.store.select('address');
        this.address$.pipe(mergeMap((state:AddressState) => {
            return this.customerType === CustomerType.Commercial
                ? of('')
                : state.address === ''
                    ? this.userService.getUserAddressDetails(this.address)
                    : of(state.address);
        }), takeUntil(this._unsubscribe)).subscribe((address:string) => {
            this.address = address;
        });
    }

    /**
     * Create a new product from the values that were inputted into one of the forms
     *
     */
    public newProduct() {
        const totalProducts:number = this.products.length;
        if (totalProducts === 0 || this.checkFieldValidations()) {
            this.products.push({
                productIndex: totalProducts
            });
            this.changeDetectorRef.detectChanges();

            const lastIndex:number = this.products.length - 1;
            if (lastIndex > 0) {
                this.navigationUtils.scrollIntoView(
                    false, this.elementRef.nativeElement.querySelector(`#no-serials-form-${lastIndex}`)
                );
            }
        }
    }

    /**
     * If a product needs to be updated it is done here and then
     * the whole list is updated to reflect any changes that might affect them all
     *
     * @param {Product} product
     */
    public updateProduct(product:Product) {
        this.products[product.productIndex] = product;
        NoSerialsContainerComponent.recheckValidityInAllChildForms(this.noSerialsFormChildren);
    }

    public deleteProduct(productNum:number) {
        this.products.splice(productNum, 1);
        this.products.map(product => {
            if (product.productIndex > productNum) {
                product.productIndex--;
            }
        });
    }

    private checkFieldValidations() {
        let valid = true;
        this.noSerialsFormChildren?._results?.forEach(child => {
            const productForm:FormGroup = child?.productForm;
            valid = this.formDataService.isValid(productForm, valid);
            productForm.updateValueAndValidity();
        });
        const objectDataForm = this.noSerialsFormChildren.first.objectData.productForm;
        valid = this.formDataService.isValid(objectDataForm, valid);
        objectDataForm.updateValueAndValidity();
        this.changeDetectorRef.markForCheck();
        return valid;
    }

    /**
     * Check to see if all the forms are valid and if so navigate the user to the next step in the process and save data in store.
     * Otherwise highlight where and which form the user needs to validate
     */
    public proceedToNext() {
        let stopCondition = false;
        interval(0).pipe(takeWhile(() => !stopCondition)).subscribe(() => {
            stopCondition = true;
            if (this.checkFieldValidations()) {
                this.createProductArray(this.noSerialsFormChildren.first.objectData.productForm.value);
                this.store.dispatch(new ProductsAction.SaveProductsAction(this.products));
                this.router.navigate([this.navigationUtils.addIdToRoute('/produkte/zusammenfassung/', this.route)]);
            }
        });
    }

    /**
     * Function to stop the *ngFor updating the DOM if a product updates
     *
     * @param {number} index
     * @param {Product} item
     */
    trackByFn(index:number, item:Product) {
        return item.serialNumber; // or item.id
    }

    private getUserDetails() {
        if (this.distinguishService.support) {
            this.userDetails.getUserFromStoreOrLoadUserUseRoute(this.route, 'id')
                .pipe(takeUntil(this._unsubscribe))
                .subscribe((user:User) => {
                    this.customerType = user.customerType;
                    this.userId = user.id;
                    this.dropDownValues = this.formDataService.getDropdowns(this.customerType);
                    if (this.products.length === 0) {
                        this.newProduct();
                    }
                    this.changeDetectorRef.markForCheck();
                });
        } else {
            const currentUser = JSON.parse(sessionStorage.getItem('currentUser'));
            this.customerType = currentUser?.customerType;
            this.dropDownValues = this.formDataService.getDropdowns(this.customerType);
            this.changeDetectorRef.markForCheck();
        }
    }

    /**
     * Function to recheck Validity in all child forms
     * @param {NoSerialsFormComponent[]} formChildren
     * */
    private static recheckValidityInAllChildForms(formChildren:NoSerialsFormComponent[]):void {
        formChildren.forEach(form => form.recheckForm());
    }

    private createProductArray(objectForm) {
        const products = this.products
        products.map(product => {
            this.customerType === CustomerType.Commercial
                ? product.numberRange = objectForm?.numberRange
                : product.number = objectForm?.number;
            product.objectName = objectForm?.objectName;
            product.objectType = objectForm?.objectType;
            product.supplySource = this.products[0]?.supplySource;
        });
    }

}
