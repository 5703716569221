  <div *ngIf="showBanner" class=" py-3">
    <sl-info-banner class="w-100" [bannerType]="bannerType" [textKey]="bannerTextKey"></sl-info-banner>
  </div>

<form *ngIf="emailForm">
  
  <div class="row">
      <div class="col-sm">
          <sl-form-item [inputType]="InputType.TEXT" [formGroup]="emailForm" [controlName]="'email'"
              [labelText]="'E-Mail'" [placeholderText]="'max.musterman@info.de'"
              [helperText]="'REGISTRATION.LOGIN.insert_email'">
          </sl-form-item>
      </div>
  </div>

</form>
 
  <sl-busy-btn (click)="submit()" [action]="requestGuestLoginAction" [tabindex]="0" [type]="'submit'">
    <span>
      {{'RECLAMATION_START.RECLAMATION_WITHOUT_ACCOUNT.nextButton'| translate}}
    </span>
</sl-busy-btn>

