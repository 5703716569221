import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { take } from 'rxjs/operators';
import { CustomerType, RegisterAddress, RegistrationComponents, DistinguishSupportService } from '../../../shared';
import { State } from '../../../../reducers/root/root.reducer';
import * as registerActions from '../../../shared/reducers/registration/register.actions';

@Component({
    selector: 'sl-address-registration',
    templateUrl: './address-registration.component.html'
})
export class AddressRegistration implements OnInit {
    public storedAddressData: RegisterAddress;
    public customerType: CustomerType;
    public RegistrationComponents = RegistrationComponents;

    public constructor(
        private distinguishService: DistinguishSupportService,
        private store: Store<State>,
        private router: Router
    ) {
        this.store.select('register').pipe(take(1)).subscribe(registerStore => {
            this.storedAddressData = registerStore?.registrationData?.address;
            this.customerType = registerStore?.registrationData?.customerType;
        });
    }

    ngOnInit(): void {
    }

    public next(addressForm: RegisterAddress) {
        this.store.dispatch(new registerActions.AddAddressFormData(addressForm));

        if (this.distinguishService.support) {
            this.router.navigate(
                this.customerType === CustomerType.Commercial ?
                    ['/user/registration/geschaeftskunden-typ'] : ['/user/registration/summary']
            );
        } else {
            this.router.navigate(
                this.customerType === CustomerType.Commercial ?
                    ['/registration/geschaeftskunden-typ'] : ['/registration/summary']
            );
        }
    }
}
