<div class="col-12 col-md-10 mx-auto">
    <div class="row">
        <p class="lead">
            {{startPage.chooseText| translate}}
        </p>
    </div>
    <sl-tile [titleText]="startPage.tileBusiness.heading" [mainText]="startPage.tileBusiness.description"
             (click)="setCustomerType(CustomerType.Commercial)">
    </sl-tile>
    <sl-tile [titleText]="startPage.tilePrivate.heading" [mainText]="startPage.tilePrivate.description"
        (click)="setCustomerType(CustomerType.Private)">
    </sl-tile>
    <div class="row">
        <p>
            <strong>{{startPage.note| translate}}</strong>
            {{startPage.noteText| translate}}
        </p>
    </div>
</div>