import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import {
    BusinessCustomerType,
    CheckboxOption,
    DistinguishSupportService,
    FormDataService,
    FormInputType
} from '../../../shared';
import { State } from '../../../../reducers/root/root.reducer';
import { take } from 'rxjs/operators';
import { Registration } from '../../../shared/reducers/registration/register.reducer';
import * as registerActions from '../../../shared/reducers/registration/register.actions';

@Component({
    selector: 'sl-business-license',
    templateUrl: './business-license.component.html',
    styles: [],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class BusinessLicenseComponent implements OnInit {

    public readonly options:CheckboxOption[] = [
        {
            id: BusinessCustomerType.NoLicense,
            value: BusinessCustomerType.NoLicense,
            labelText: 'REGISTRATION.LICENSE.no'
        },
        {
            id: BusinessCustomerType.License,
            value: BusinessCustomerType.License,
            labelText: 'REGISTRATION.LICENSE.yes'
        }
    ];
    public licenseForm:FormGroup;
    public FormInputType = FormInputType;
    private registrationData:Registration;

    constructor(
        private distinguishService:DistinguishSupportService,
        private formBuilder:FormBuilder,
        private formDataService:FormDataService,
        private store:Store<State>,
        private router:Router
    ) {
        this.store.select('register').pipe(take(1)).subscribe(registerStore => {
            this.registrationData = registerStore.registrationData;
        });
    }

    ngOnInit():void {
        this.createForm();
    }

    public submit() {
        if (!this.licenseForm.valid) {
            this.formDataService.markControlsTouched(this.licenseForm);
            return;
        }
        this.store.dispatch(new registerActions.AddBusinessCustomerType(this.licenseForm.get('businessCustomerType').value));
        const navigateSupport = this.distinguishService.support ? '/user' : '';
        const registrationIncomplete = sessionStorage.getItem('registrationIncomplete');
        const url = registrationIncomplete ? '/registration/kundengruppe' : (navigateSupport + '/registration/summary');
        this.router.navigate([url]);
    }

    private createForm() {
        if (this.registrationData?.businessCustomerType === BusinessCustomerType.Next) {
            this.registrationData.businessCustomerType = null;
        }
        this.licenseForm = this.formBuilder.group({
            businessCustomerType: [null, Validators.required]
        });
        if (this.registrationData) {
            this.formDataService.setFormValuesFromStore(this.licenseForm, this.registrationData);
        }
    }
}
