import { Action } from '@ngrx/store';
import { Product, SerialMassClaim } from '../../models';

export const SAVE_PRODUCT_LIST = '[Products] Save Product List';
export const SAVE_PRODUCT_DROPDOWNS = '[Products] Save Product Dropdowns';
export const UPDATE_PRODUCT = '[Products] Update Product';
export const DELETE_PRODUCT = '[Products] Delete Product';
export const DELETE_ALL_PRODUCTS = '[Products] Delete All Products';
export const ENTER_SERIALS_LATER = '[Products] Enter Serials Later';
export const SET_GLOBAL_RETURN_REASON = '[Product] Set Global Retrun Reason';
export const SAVE_MASS_SERIALS_CLAIM = '[Product] Save Mass Serials Claim'

/**
 * Call this action when you want to Save Products
 */
export class SaveProductsAction implements Action {
    readonly type = SAVE_PRODUCT_LIST;

    constructor(public payload:Product[]) {
    }
}

/**
 * Call this action when you want to Save Product Dropdowns
 */
export class SaveProductDropdowns implements Action {
    readonly type = SAVE_PRODUCT_DROPDOWNS;

    constructor(public payload:any) {

    }
}

/**
 * Call this action when you want to Update a Product
 */
export class UpdateProductAction implements Action {
    readonly type = UPDATE_PRODUCT;

    constructor(public payload:Product) {
    }
}

/**
 * Call this action when you want to Delete a Product
 */
export class DeleteProductAction implements Action {
    readonly type = DELETE_PRODUCT;

    constructor(public payload:Product) {
    }
}

/**
 * Call this action when you want to Delete All Products
 */
export class DeleteAllProductAction implements Action {
    readonly type = DELETE_ALL_PRODUCTS;

    constructor() {
    }
}

export class EnterSerialsLaterAction implements Action {
    readonly type = ENTER_SERIALS_LATER;

    constructor(public payload:boolean) {
    }
}

export class SetGlobalReturnReasonAction implements Action {
    readonly type = SET_GLOBAL_RETURN_REASON;

    constructor(public payload:boolean) {
    }
}

export class SaveMassSerialsClaimAction implements Action {
    readonly type = SAVE_MASS_SERIALS_CLAIM;
    constructor(public payload:SerialMassClaim) {
    }
}

export type ProductsActions = SaveProductsAction
    | SaveProductDropdowns
    | UpdateProductAction
    | DeleteProductAction
    | DeleteAllProductAction
    | EnterSerialsLaterAction
    | SetGlobalReturnReasonAction
    | SaveMassSerialsClaimAction;
