import { Action } from '@ngrx/store';
import { ReclamationDetailed, ReclamationType, ReclamationEvaluation } from '../../models/evaluation.model';

export const SAVE_CUSTOMER_NUMBERS = '[Evaluation] Save Customer Numbers';
export const SAVE_RECLAMATION_TYPE = '[Evaluation] Save Reclamation Type';
export const SAVE_RECLAMATION_EVALUATION = '[Evaluation] Save Evaluation';
export const SAVE_RECLAMATION_RESPONSE = '[Evaluation] Save Reclamation Response';
export const DELETE_RECLAMATION = '[Evaluation] Delete Reclamation';

/**
 * Call this action when you want to Save a Customer Number
 */
export class SaveCustomerNumbersAction implements Action {
    readonly type = SAVE_CUSTOMER_NUMBERS;

    constructor(public payload: string) {
    }
}

/**
 * Call this action when you want to Save a Reclamation Type
 */
export class SaveReclamationTypeAction implements Action {
    readonly type = SAVE_RECLAMATION_TYPE;

    constructor(public payload:ReclamationType) {
    }
}

export class SaveReclamationEvaluationAction implements  Action {
    readonly type = SAVE_RECLAMATION_EVALUATION;

    constructor(public payload:ReclamationEvaluation) {
    }
}

/**
 * Call this action when you want to Save a Reclamation Response
 */
export class SaveReclamationResponseAction implements Action {
    readonly type = SAVE_RECLAMATION_RESPONSE;

    constructor(public payload:ReclamationDetailed) {
    }

}

/**
 * Call this action when you want to Delete a Reclamation
 */
export class DeleteReclamationAction implements Action {
    readonly type = DELETE_RECLAMATION;

    constructor() {}
}

export type EvaluationActions =
    SaveCustomerNumbersAction
    | SaveReclamationTypeAction
    | SaveReclamationEvaluationAction
    | SaveReclamationResponseAction
    | DeleteReclamationAction;
