import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule, HttpHandler } from '@angular/common/http';
import { SharedLibComponent } from './shared-lib.component';
import { RegistrationModule } from './modules/registration/registration.module';
import { CustomTranslateLoader, DeviceChosenService, SharedModule } from './modules/shared';
import {
    AuthenticationInterceptor,
    DistinguishSupportService,
    ErrorHandlerUtilsService,
    FormDataService,
    LangUtilsService,
    ProductService,
    RecaptchaService,
    ReclamationService,
    ToastsService,
    UserService,
    UserDetailsService,
    UtilsService,
    ValidationService,
    XHttpClient
} from './modules/shared/services';
import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { UserModule } from './modules/user/user.module';
import { environment } from '../environments/environment';
import { processObjRec } from '../environments/common';
import { ProductModule } from './modules/product';
import { ConfirmationModule } from './modules/confirmation';
import { TranslateLoader } from '@ngx-translate/core';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
import { ReclamationModule } from './modules/reclamation/reclamation.module';
import Swal from 'sweetalert2';


/**
 * Function that provides the sweet alert that will display a choice for the user
 */
export async function provideSwal() {
    return Swal.mixin({
        confirmButtonText: 'Einreichen',
        cancelButtonText: 'Abbrechen',
        buttonsStyling: false,
        customClass: {
            confirmButton: 'btn btn-tertiary mr-2 px-4',
            cancelButton: 'btn btn-primary ml-2 px-4'
        }
    });
}

@NgModule({
    declarations: [SharedLibComponent],
    imports: [
        CommonModule,
        HttpClientModule,
        RegistrationModule,
        SharedModule,
        UserModule,
        NgbDropdownModule,
        ProductModule,
        ConfirmationModule,
        SweetAlert2Module.forRoot({
            provideSwal
        })
    ],
    exports: [
        SharedLibComponent,
        RegistrationModule,
        ReclamationModule,
        SharedModule,
        UserModule,
        ProductModule,
        ConfirmationModule
    ]
})
export class SharedLibModule {

    static forRoot(host:string):ModuleWithProviders<SharedLibModule> {

        processObjRec(environment.api, host);

        return {
            ngModule: SharedLibModule,
            providers: [
                DeviceChosenService,
                ErrorHandlerUtilsService,
                LangUtilsService,
                UtilsService,
                UserService,
                UserDetailsService,
                ToastsService,
                {provide: XHttpClient, useClass: XHttpClient, deps: [HttpHandler]},
                {provide: HttpClient, useExisting: XHttpClient},
                {provide: HTTP_INTERCEPTORS, useClass: AuthenticationInterceptor, multi: true},
                {provide: CustomTranslateLoader, useExisting: TranslateLoader},
                RecaptchaService,
                ReclamationService,
                ValidationService,
                ProductService,
                FormDataService,
                DistinguishSupportService
            ]
        };
    }
}
