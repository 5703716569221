import { Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {
  DropdownValue,
  ExternalData,
  FormInputType,
  registerActions, RoutePathName,
  SalesChannel,
  ValidationService
} from '../../../shared';
import { distinctUntilChanged, take, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { State } from '../../../../reducers/root/root.reducer';

@Component({
  selector: 'sl-sales-channel',
  templateUrl: './sales-channel.component.html',
  styles: [
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SalesChannelComponent implements OnInit {
  public salesChannelFrom:FormGroup;
  public formCreated:boolean;
  public saleChannels:SalesChannel[];
  public salesChannelOptions:DropdownValue;
  public subSegments:DropdownValue;
  public FormInputType = FormInputType;

  private unsubscribe$ = new Subject();
  private externalData:ExternalData;

  /**
   * Getter for the form
   */
  get f() {
    return this.salesChannelFrom.controls;
  }

  constructor(private formBuilder:FormBuilder,
              private validationService:ValidationService,
              private router:Router,
              private store:Store<State>) {
    this.store.select('register').pipe(take(1)).subscribe(registerStore => {
      this.externalData = registerStore?.registrationData?.externalData;
    });
  }

  ngOnInit(): void {
    this.createForm();
    this.getSalesChannelDropdown();
    this.getSubSegmentDropdowns();
  }

  /**
   * Create the form that the user can edit their details with
   */
  private createForm() {
    this.salesChannelFrom = this.formBuilder.group({
      salesChannel: [this.externalData?.salesChannel, [Validators.required]],
      subSegment: [this.externalData?.subSegment, [Validators.required]]
    });

    /*This validator has to be set on form instead of control, in order to work properly
      (due to conditional input):*/
    this.salesChannelFrom.setValidators(this.validationService.validatorSalesChannelRequired.bind(this));
    this.formCreated = true;

    if(this.salesChannelFrom.get('salesChannel')) {
      this.salesChannelFrom.get('salesChannel').valueChanges
          .pipe(distinctUntilChanged(), takeUntil(this.unsubscribe$))
          .subscribe(() => {
            this.onSalesChannelChange();
            this.getSubSegmentDropdowns();
          });
    }
  }

  public submit() {
    if(this.salesChannelFrom.invalid) {
      this.salesChannelFrom.markAllAsTouched();
      return;
    }
    this.externalData = {
      salesChannel: this.salesChannelFrom.get('salesChannel').value,
      subSegment: this.salesChannelFrom.get('subSegment').value
    }
    this.store.dispatch(new registerActions.AddSalesChannel(this.externalData));
    this.router.navigate([`/${RoutePathName.REGISTRATION}/${RoutePathName.REGISTRATION_SUMMARY}`]);
  }

  private getSalesChannelDropdown():void {
    this.saleChannels = JSON.parse(sessionStorage.getItem('salesChannels')) || [];
    this.salesChannelOptions = {};
    this.saleChannels.forEach(salesChannel => this.salesChannelOptions[salesChannel.id] = 'REGISTRATION.FORM.SALES_CHANNEL.' + salesChannel.id);
  }

  private getSubSegmentDropdowns():void {
    this.subSegments = {};
    this.saleChannels.find(saleChannel => saleChannel.id === Number(this.f.salesChannel.value))?.subSegments.forEach(value => this.subSegments[value] = 'REGISTRATION.FORM.SUB_SEGMENT.' + value);
  }

  /**
   * Reset the subSegment field
   */
  private onSalesChannelChange():void {
    this.f.subSegment.reset(null);
  }

}
