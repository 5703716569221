<div class="container">
    <div class="col-12 col-md-10 mx-auto mt-4">
        <div class="row flex-column mt-3">
            <sl-back-navigation [buttonText]="'RECLAMATION_START.DETECTOR_RECLAMATION.backLinkText' | translate">
            </sl-back-navigation>
        </div>

        <div *ngIf="reclamationDetails?.transactionNumber" class="row my-3">
            <h1>{{transBase + "title" | translate}}</h1>
            <p>{{transBase + "description" | translate}}</p>
        </div>

        <div *ngIf="reclamationDetails?.transactionNumber" class="row py-3">
            <sl-info-banner class="w-100" [bannerType]="bannerType.INFO"
                [boldTextKey]="'PRODUCTS.CONFIRMATION.bannerTransaction'" [text]="reclamationDetails.transactionNumber">
            </sl-info-banner>
        </div>

        <div *ngIf="!reclamationDetails?.transactionNumber" class="row py-3">
            <sl-info-banner class="w-100" [bannerType]="bannerType.DANGER"
                [textKey]="'PRODUCTS.CONFIRMATION.noTransaction'">
            </sl-info-banner>
        </div>
        
        <sl-contact></sl-contact>

        <div class="row mt-3 mb-5">
            <button *ngIf="currentUser" class="btn btn-secondary btn-mobile-block mr-sm-2 mb-2"
                (click)="onMyReclamation()">
                <span>{{"PRODUCTS.CONFIRMATION.BUTTONS.myReclamations" | translate}}</span>
            </button>
            <button class="btn btn-mobile-block mr-sm-2 mb-2"
                [ngClass]="{'btn-tertiary': !!currentUser, 'btn-secondary': !currentUser}" (click)="startNewReclamation()">
                <span>{{"PRODUCTS.CONFIRMATION.BUTTONS.addReclamation" | translate}}</span>
            </button>
        </div>

        <sl-reported-serials-list *ngIf="reclamationDetails?.serials" [serials]="reclamationDetails?.serials">
        </sl-reported-serials-list>
        <sl-reported-detectors-list *ngIf="reclamationDetails?.detectors" [detectors]="reclamationDetails?.detectors">
        </sl-reported-detectors-list>
    </div>
</div>
