import { Component, Input, OnInit } from '@angular/core';
import { InfoBannerType } from '../../models/info-banner.model';

@Component({
    selector: 'sl-info-banner',
    templateUrl: './info-banner.component.html',
    styleUrls: []
})
export class InfoBanner implements OnInit {
    @Input() bannerType: InfoBannerType;
    @Input() textKey?: string;
    @Input() boldTextKey?: string;
    @Input() text?: string;

    public InfoBannerType = InfoBannerType;

    public constructor() {}

    ngOnInit(): void {
    }
}
