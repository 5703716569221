import * as fromRouter from '@ngrx/router-store';
import * as addressReducer from '../../modules/shared/reducers/address/address.reducer';
import * as productsReducer from '../../modules/shared/reducers/products/products.reducer';
import * as evaluationReducer from '../../modules/shared/reducers/evaluation/evaluation.reducer';
import * as userReducer from '../../modules/shared/reducers/user/user.reducer';
import * as fromRegisterReducer from '../../modules/shared/reducers/registration/register.reducer';

import { RouterStateUrl } from '../router.utils';
import { ActionReducer, ActionReducerMap, MetaReducer } from '@ngrx/store';
import { environment } from '../../../environments/environment';

/**
 * This is the name that tells the Store that it is for Products
 */
export const productsStateName= 'products';

/**
 * As mentioned, we treat each reducer like account table in account database. This means
 * our top level state interface is just account map of keys to inner state types.
 * @alias State
 * @property address
 * @property products
 * @property evaluation
 * @property routerReducer
 */
export interface State {
    address: addressReducer.AddressState;
    products: productsReducer.State;
    evaluation: evaluationReducer.State;
    user: userReducer.State;
    routerReducer: fromRouter.RouterReducerState<RouterStateUrl>;
    register: fromRegisterReducer.State;
}

/**
 * Our state is composed of account map of action reducer functions.
 * These reducer functions are called with each dispatched action
 * and the current or initial state and return account new immutable state.
 * @property address
 * @property products
 * @property evaluation
 * @property routerReducer
 */
export const reducers: ActionReducerMap<State> = {
    address: addressReducer.reducer,
    products: productsReducer.reducer,
    evaluation: evaluationReducer.reducer,
    user: userReducer.reducer,
    routerReducer: fromRouter.routerReducer,
    register: fromRegisterReducer.RegistrationReducer
};

/**
 * all actions
 * @param reducer
 * @returns ActionReducer<State>
 */
export function logger(reducer: ActionReducer<State>): ActionReducer<State> {
    return function (state: State, action: any): State {
        return reducer(state, action);
    };
}

/**
 * By default, @ngrx/store uses combineReducers with the reducer map to compose
 * the root meta-reducer. To add more meta-reducers, provide an array of meta-reducers
 * that will be composed to form the root meta-reducer.
 */
export const metaReducers: MetaReducer<State>[] = !environment.production
    ? [logger]
    : [];

export enum StoreNames {
  PRODUCTS = 'products',
  ADDRESS = 'address',
  EVALUATION = 'evaluation',
  USER = 'user',
  ROUTER_REDUCER = 'routerReducer',
  REGISTER = 'register'
  }
