import { ChangeDetectionStrategy, Component } from '@angular/core';

/**
 * Footer Component
 */
@Component({
    selector: 'rp-footer',
    templateUrl: './footer.component.html',
    styles: [],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class FooterComponent {

    /**
     * @ignore
     */
    constructor() {
    }




}
