import { Injectable } from '@angular/core';
import { Observable, of, BehaviorSubject } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';
import { UtilsService } from './utils.service';
import { ReclamationBase, ReclamationDetailed, ReclamationMeta } from '../models/evaluation.model';
import { StrictHttpParams } from '../models/strict-http-params';
import { XHttpClient } from './extended-http-client.service';
import { ApiResponse } from '../models/api.model';

/**
 * This Service gets relevant Reclamation data from the Backend
 */
@Injectable({
    providedIn: 'root'
})
export class ReclamationService {


    /**
     *
     * @param {HttpClient} Http
     * @param {XHttpClient} XHttp
     * @param {UtilsService} Utils
     */
    constructor(private Http:HttpClient,
                private XHttp:XHttpClient,
                private Utils:UtilsService) {
    }

    /**
     * Gets details of reclamation
     * (using XhttpClient instead of httpClient to be able to
     *  pass an errorCodeRemapping for more precise error handling)
     *
     * @param {string} id
     */
    public getReclamation(id:string):Observable<ReclamationDetailed> {
        return this.Utils.stepIntoData(
            this.XHttp.get<ApiResponse<ReclamationDetailed>>(this.Utils.replace(environment.api.reclamation.details, id),
                {errorCodeRemapping: {'ERROR_NOT_FOUND': 'ERROR_RECLAMATION_NOT_FOUND'}})
        );
    }

    /**
     * Get the Reclamation List from the Backend
     *
     * @param {number} pageLength
     * @param {number} page
     */
    public getReclamationList(pageLength:number, page:number):Observable<ReclamationBase[] & ReclamationMeta> {
        const params:HttpParams = new StrictHttpParams()
            .set('pageLength', pageLength + '')
            .set('page', page + '');

        return this.Utils.stepIntoData(
            this.Http.get<ApiResponse<ReclamationBase[] & ReclamationMeta>>(
                this.Utils.replace(environment.api.reclamation.list), {params}));
    }

    public checkSerialNumber(dataToSend:{ serialNumber:string, returnReason:string }, extendedInfo:boolean):Observable<any> {
        return of({groupType: 'fake'});
    }

    /**
     * decides whether to user serviceReport number or transaction number as identifier for a reclamation
     * @param reclamation
     */
    public calculateReclamationNumber(reclamation:ReclamationDetailed):string {
        return reclamation.serviceReportNr && reclamation.serviceReportNr !== ''
            ? reclamation.serviceReportNr
            : reclamation.transactionNumber;
    }

    public calculateIfSerialNumbersMissing(reclamation:ReclamationDetailed):boolean {
         return this.calculateSerialNumbersMissing(reclamation) > 0;
    }


    public calculateSerialNumbersMissing(reclamation:ReclamationDetailed):number {
        if (!reclamation.detectors || reclamation.detectors.length < 1) {
            return 0;
        }
        let detectorCount: number = 0;
        reclamation.detectors.forEach(detector => {
            detectorCount = detectorCount + detector.count;
        });
        return detectorCount - reclamation.serials.length;
    }
}
