import { Component, OnInit, ChangeDetectionStrategy, Input, SimpleChanges, Output, EventEmitter } from '@angular/core';
import { ReclamationBase } from '../../models';

@Component({
  selector: 'sl-status-column',
  templateUrl: './status-column.component.html',
  styleUrls: [],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class StatusColumnComponent implements OnInit {
  @Input() public item: ReclamationBase;
  @Input() isSupportApp: boolean;
  @Input() fromComplaint: boolean = false;
  @Output() public linkClicked: EventEmitter<any> = new EventEmitter<any>();

  constructor() { }

  ngOnInit(): void {
  }

  public onLinkClick(item: any): void {
    this.linkClicked.emit(item);
  }
}
