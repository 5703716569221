import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';
import { BusinessCustomerType, ExternalData, RoutePathName } from '../../../../shared';
import { Router } from '@angular/router';

@Component({
    selector: 'sl-registration-summary-business-account',
    templateUrl: './registration-summary-business-account.component.html',
    styles: [],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class RegistrationSummaryBusinessAccountComponent implements OnInit {
    @Input() externalData:ExternalData;
    @Input() businessCustomerType:BusinessCustomerType;

    constructor(private router:Router) {
    }

    ngOnInit():void {
    }

    public goToEdit() {
        const navUrl = sessionStorage.getItem('registrationIncomplete') === 'true' && sessionStorage.getItem('redirectUrl')
            ? sessionStorage.getItem('redirectUrl')
            : `/${RoutePathName.REGISTRATION}/${RoutePathName.BUSINESS_USER_TYPE}`;
        this.router.navigate([navUrl]);
    }

}
