import { Injectable } from '@angular/core';
import { interval } from 'rxjs';
import { takeWhile } from 'rxjs/operators';
import { Product } from '../models/product.model';
import { FormDataService } from './form-data.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { FormGroup } from '@angular/forms';
import * as productReducer from '../reducers/products/products.reducer';
import * as ProductsAction from '../reducers/products/products.actions';

@Injectable({providedIn: 'root'})
export class NavigationUtilsService {

  constructor(private formDataService:FormDataService,
              private router:Router,
              private store:Store<productReducer.State>,
              private route:ActivatedRoute) { }
  /**
   * adds the id in an activated route to a new route if applicable
   * @param route
   * @param activatedRoute
   */
  public addIdToRoute(route:string, activatedRoute):string {
    const supportId:string = activatedRoute.snapshot.paramMap.get('id');
    if (supportId) {
      if(!route.endsWith('/')) {
        route = route + '/';
      }
      return route + supportId;
    } else {
      return route;
    }
  }

  /**
   * If the user wants to edit a product from the next step it will scroll that Product Form into view when navigating to this page
   *
   * @param {number} index
   * @param accountFixedHeader
   */
  public scrollIntoView(accountFixedHeader, el):void {
    // const el = elementRef.nativeElement.querySelector(`#product-${index}`);
    let stopCondition = false;
    interval(0).pipe(takeWhile(() => !stopCondition)).subscribe(() => {
      let element = el;
      if (el.nativeElement) {
        element = el.nativeElement;
      }
      element.scrollIntoView({behavior: 'smooth', block: 'start'});

      if (accountFixedHeader) {
        // accounting of fixed header only works if scrollbehavior is NOT smooth,
        // because function would be called before scroll end.
        element.scrollIntoView({ block: 'start' });
        this.accountFixedHeaderIntoScrolling();
      }
      stopCondition = true;

    });
  }

  /**
   * Scrolls to the Product Form if it exists as it may have been deleted
   *
   * @param paramSerialNumber
   */
  public scrollToProductIfExists(param: string, products: Product[], enterSerialsLater: boolean, el): void {
    const index:number = products.findIndex(product => (enterSerialsLater && product.productIndex.toString() === param)
        || (!enterSerialsLater && product.serialNumber === param
        || product.secondSerialNumber === param));
    if (index > -1) {
      this.scrollIntoView(true, el.nativeElement.querySelector(`#product-form-${index}`));
    }
  }

  /**
   * Account for the Header when Scrolling
   */
  public accountFixedHeaderIntoScrolling() {
    const PIXELS_ACCOUNTING_HEADER_HEIGHT:number = 120;
    const scrolledY:number = window.pageYOffset;
    if (scrolledY) {
      window.scroll(0, scrolledY - PIXELS_ACCOUNTING_HEADER_HEIGHT);
    }
  }

  /**
   * Check to see if all the forms are valid and if so navigate the user to the next step in the process and save data in store.
   * Otherwise highlight where and which form the user needs to validate
   */
  public proceedToNext(products:Product[], productForm:FormGroup, route:ActivatedRoute) {
    let stopCondition = false;
    interval(0).pipe(takeWhile(() => !stopCondition)).subscribe(() => {
      stopCondition = true;
      if (!this.formDataService.invalidProductForms(products)) {
        this.store.dispatch(new ProductsAction.SaveProductsAction(products));
        this.router.navigate([this.addIdToRoute('/produkte/zusammenfassung/', route)]);
      } else {
        this.formDataService.validateAllFormFields(productForm);
      }
    });
  }

}
