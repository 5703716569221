import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DistinguishSupportService, CustomerType, User } from '../../../shared';

/**
 * This Component shows the User's Account Details in Readme mode
 */
@Component({
    selector: 'sl-account-details',
    templateUrl: './account-details.component.html',
    styles: []
    
})
export class AccountDetailsComponent implements OnInit {

    @Input() user:User;
    @Input() canEdit:boolean;
    @Output() editMode:EventEmitter<boolean> = new EventEmitter<boolean>();

    public CustomerType = CustomerType;
    public customerNumberToShow:string;

    /**
     * @ignore
     */
    constructor(private distinguishSupportService: DistinguishSupportService) {
    }

    /**
     * @ignore
     */
    ngOnInit() {
        this.setCustomerNumber();
    }

    get isSupport() {
        return this.distinguishSupportService.support;
    }

    get hasLicense() {
        return this.user?.hasLicense ? 'Ja' : 'Nein';
    }
    get isInstaller() {
        return this.user?.installer ? 'Ja' : 'Nein';
    }
    /**
     * Emit to the parent container component to enter into Edit mode
     */
    public editForm() {
        this.editMode.emit(true);
    }

    private setCustomerNumber() {
        if(this.user) {
            if(!this.distinguishSupportService.support) {
                this.customerNumberToShow = this.user.customerNumber
                    ? this.user.customerNumber
                    : this.user.businessPartnerNumber
                        ? this.user.businessPartnerNumber
                        : this.user.extSystemId;
            }else {
                this.customerNumberToShow = this.user.customerNumber;
            }
        }
    }
}
