import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { Observable, Subject } from 'rxjs';
import { DistinguishSupportService, ErrorHandlerUtilsService, ReclamationService, UserService } from '../../services';
import { ActivatedRoute, Params } from '@angular/router';
import { takeUntil } from 'rxjs/operators';
import { CustomerType } from '../../models/user.model';
import { ReclamationDetailed, ReclamationTypeCases } from '../../models/evaluation.model';
import { flatMap } from 'rxjs/internal/operators';
import { PaymentInformation } from '../../models/shop.model';

@Component({
    // tslint:disable-next-line:component-selector
    selector: 'sl-reclamation-detail',
    templateUrl: './reclamation-detail.component.html'
})
export class ReclamationDetailComponent implements OnInit, OnDestroy {

    public loading:boolean;

    public reclamation:ReclamationDetailed;
    public CustomerType = CustomerType;
    public userId:string;
    public paymentInformation:PaymentInformation;
    public isSupportApp: boolean;

    private ReclamationTypeCases = ReclamationTypeCases;
    private endSubscriptions:Subject<void> = new Subject();

    /**
     * @param distinguishSupportService
     * @param location
     * @param {ReclamationService} ReclamationServ
     * @param {ErrorHandlerUtilsService} ErrorHandlerUtils
     * @param {ChangeDetectorRef} CD
     * @param {ActivatedRoute} Route
     * @param {UserService} userService
     */
    constructor(private ReclamationServ:ReclamationService,
                private ErrorHandlerUtils:ErrorHandlerUtilsService,
                private CD:ChangeDetectorRef,
                private distinguishSupportService:DistinguishSupportService,
                private Route:ActivatedRoute,
                private userService:UserService,
                private location:Location) {
        this.loading = true;
    }

    get reclamationNumber():string {
        return this.ReclamationServ.calculateReclamationNumber(this.reclamation);
    }


    // distinguishes ReklaApp from Support App
    get isReklaApp():boolean {
        return !this.distinguishSupportService.support;
    }

    /**
     * Getter that finds out whether reclmationType is Replacement
     */
    get isReplacement():boolean {
        return this.reclamation.reclamationType === ReclamationTypeCases.PartialExchange ||
            this.reclamation.reclamationType === ReclamationTypeCases.CompleteExchange;
    }

    get showReplacement():boolean {
        return this.reclamation.reclamationType === this.ReclamationTypeCases.NoExchange ||
            this.reclamation.reclamationType === this.ReclamationTypeCases.PartialExchange ||
            this.reclamation.reclamationType === this.ReclamationTypeCases.CompleteExchange;
    }

    /**
     * Request the Details of the Reclamation from the Backend
     */
    ngOnInit() {
        this.getIdFromPath().pipe(
            flatMap(params => this.ReclamationServ.getReclamation(params.id)),
            takeUntil(this.endSubscriptions))
            .subscribe((reclamation:ReclamationDetailed) => {
                    this.reclamation = reclamation;
                    this.paymentInformation = reclamation.paymentInformation;
                    this.loading = false;
                    this.CD.markForCheck();
                },
                (err) => {
                    this.loading = false;
                    this.ErrorHandlerUtils.handleNotFoundError(err, this.Route, ['../']);
                });

        this.currentUser();
        this.isSupportApp = !!this.userId;
    }

    ngOnDestroy():void {
        this.endSubscriptions.next();
        this.endSubscriptions.complete();
        this.endSubscriptions.unsubscribe();
    }

    /**
     * Get what the id of the Reclamation is from the URL
     *
     * @returns Observable<Params>
     */
    private getIdFromPath():Observable<Params> {
        return this.Route.params;
    }

    /**
     * check currentUser Id
     */
    private currentUser() {
        if ('user' in sessionStorage) {
            const user = JSON.parse(sessionStorage.getItem('user'));
            // user is null if not support app
            this.userId = user ? user.id : null;
        }
    }
}

