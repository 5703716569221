<div class="container">
    <div class="col-12 col-md-10 mx-auto">
        <div class="row py-3">
            <h1>{{startPage.heading| translate}}</h1>
        </div>
        <div class="row">
            <p class="lead">
                {{startPage.description| translate}}
            </p>
        </div>
    </div>

    <div class="col-12">
        <sl-steps-info [titleIcons]="titleIcons"></sl-steps-info>
    </div>

    <sl-customer-type-selection></sl-customer-type-selection>
</div>