import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../shared';
import { ProductDisplayComponent } from './components/product-display';
import { ReactiveFormsModule } from '@angular/forms';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
import { ProductListComponent } from './components/product-list';
import { ProductFormUtilsService } from './product-form-utils.service';
import { ReclamationStartComponent } from './components/reclamation-start';
import { SerialMassClaimComponent } from './components/serial-mass-claim';
import { NgbAccordionModule, NgbPaginationModule } from '@ng-bootstrap/ng-bootstrap';
import {
    ProductFormComponent,
    NoSerialsContainerComponent,
    SerialIdentification,
} from './components/product-forms';
import { WarrantySelectionComponent } from './components/warranty-selection';
import { AnonymousLoginComponent } from './components/anonymous-login';
import { AnonymousLoginFormComponent } from './components/anonymous-login';
import { RetrievalOrderConfirmationComponent } from './components/retrieval-order-confirmation/retrieval-order-confirmation.component';
import { RetrievalOrderListComponent } from './components/retrieval-order-list/retrieval-order-list.component';

// import { provideSwal } from './provideSwal';


@NgModule({
    declarations: [
        ProductDisplayComponent,
        ProductListComponent,
        SerialIdentification,
        ProductFormComponent,
        ReclamationStartComponent,
        SerialMassClaimComponent,
        WarrantySelectionComponent,
        AnonymousLoginComponent,
        AnonymousLoginFormComponent,
        NoSerialsContainerComponent,
        RetrievalOrderListComponent,
        RetrievalOrderConfirmationComponent
    ],
    imports: [
        CommonModule,
        SharedModule,
        ReactiveFormsModule,
        SweetAlert2Module,
        NgbAccordionModule,
        NgbPaginationModule,
    ],
    exports: [
        ProductDisplayComponent,
        ProductListComponent,
        SerialIdentification,
        ProductFormComponent,
        ReclamationStartComponent,
        SerialMassClaimComponent,
        WarrantySelectionComponent,
        NoSerialsContainerComponent,
        AnonymousLoginComponent,
        AnonymousLoginFormComponent,
        RetrievalOrderListComponent,
        RetrievalOrderConfirmationComponent
    ],
    providers: [ProductFormUtilsService]
})
export class ProductModule {
}
