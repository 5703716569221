import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

/**
 * Service to choose the camera for scanning QRCodes
 */
@Injectable()
export class DeviceChosenService {

    // Observable string sources
    private deviceChosenSource = new BehaviorSubject<string>(null);

    /**
     * return the currently chosen device/camera
     *
     * @returns string
     */
    public getCurrentlyChosenDevice():string {
        return this.deviceChosenSource.getValue();
    }

    /**
     * Choose a new device/camera
     *
     * @param deviceId
     */
    public newDeviceChosen(deviceId:string):void {
        this.deviceChosenSource.next(deviceId);
    }
}
