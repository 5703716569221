import { Action } from '@ngrx/store';
import { User } from '../../models/user.model';

export enum UserActionTypes {
    SAVE_USER_DETAILS = '[User] Save User Details',
    DELETE_USER_DETAILS = '[User] Delete User Details'
}

export class SaveUserDetailsAction implements Action {
    readonly type = UserActionTypes.SAVE_USER_DETAILS;

    constructor(public payload:User) {
    }
}

export class DeleteUserDetailsAction implements Action {
    readonly type = UserActionTypes.DELETE_USER_DETAILS;

    constructor() {
    }
}

export type UserActions = SaveUserDetailsAction | DeleteUserDetailsAction;
