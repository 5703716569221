<sl-header [loggedIn]="loggedIn"
           [guest]="guest"
           [username]="username"
           [isShown]="isShown"
           [menuItems]="menuItems"
           [fromSupport]="false"
           [notice]="notice"
           [logo]="{src: 'assets/logo/logo-hvg.svg', alt: ('GENERAL.HEADER.logo' | translate),
                    link:(loggedIn ? 'produkte/start' : 'https://www.hekatron-brandschutz.de/')}"
           (logout)="onLogout()"
           (routeTo)="onRouteTo($event)"></sl-header>
