import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'sl-product-type-label',
    templateUrl: './product-type-label.component.html',
    styles: []
})
export class ProductTypeLabelComponent implements OnInit {
    @Input() text: string;

    constructor() { }

    ngOnInit(): void {
    }
}