import {
    AfterViewInit,
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    Output,
    QueryList,
    ViewChildren
} from '@angular/core';
import { CustomerType, ReclamationBase, ReclamationDetailed, SVGIconNames } from '../../models';
import { ReclamationService } from '../../services';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import { ViewChild, ElementRef, ChangeDetectorRef } from '@angular/core';

@Component({
    selector: 'sl-reclamations-table',
    templateUrl: './reclamations-table.component.html',
    styles: [],
    changeDetection: ChangeDetectionStrategy.Default
})
export class ReclamationsTableComponent implements AfterViewInit {
    @Input() public list:ReclamationBase[];
    @Input() public linkToDetail:boolean;
    @Input() isSupportApp:boolean;
    @Output() public linkClicked:EventEmitter<ReclamationBase> = new EventEmitter<ReclamationBase>();
    @Output() public pdfClicked:EventEmitter<ReclamationBase> = new EventEmitter<ReclamationBase>();
    @ViewChildren('popover') popovers:QueryList<any>;
    @ViewChild('myDiv') myDiv:ElementRef;
    @ViewChild('canvas') canvas:ElementRef;

    public CustomerType = CustomerType;
    public clicked = false;
    public reclamation:ReclamationDetailed;


    get reclamationNumber():string {
        return this.ReclamationService.calculateReclamationNumber(this.reclamation);
    }

    constructor(private ReclamationService:ReclamationService,
                private cd:ChangeDetectorRef) {
    }

    ngAfterViewInit() {
        this.popovers?.first?.close();
    }

    /**
     * Shows the serviceReport number
     * @param item
     */
    public getserviceReportNr(item:ReclamationBase):string {
        return item.serviceReportNr && item.serviceReportNr.length > 0
            ? item.serviceReportNr : '-';
    }
    /**
     * Shows the transaction number
     * @param item
     */
    public getTransactionNumber(item:ReclamationBase):string {
        return item.transactionNumber && item.transactionNumber !== ''
            ? item.transactionNumber : '-';
    }

    /**
     * on link click
     * @param item
     */
    public onLinkClick(item:ReclamationBase):void {
        this.linkClicked.emit(item);
    }

    public onPdfClick(item:ReclamationBase):void {
        this.getReclamationFromBackend(item.id);
    }

    public imageSrc(icon:SVGIconNames | string):string {
        return `${SVGIconNames.LOCATION}${icon}${SVGIconNames.FILE_TYPE}`;
    }

    private savePdf() {

        html2canvas(this.myDiv.nativeElement).then(canvas => {
            const contentDataURL = canvas.toDataURL('image/png');
            let pdf = new jsPDF('p', 'mm', 'a4');

            const imgProps = pdf.getImageProperties(contentDataURL);
            const width = pdf.internal.pageSize.getWidth();
            const height = (imgProps.height * width) / imgProps.width;
            const pdfWidth = width * 0.8;
            const pdfHeight = height * 0.8;

            pdf.addImage(contentDataURL, 'PNG', width * 0.1, height * 0.1, pdfWidth, pdfHeight);
            pdf.save('rekla-' + this.reclamation.transactionNumber + '.pdf');
        });

    }


    private getReclamationFromBackend(id) {

        this.ReclamationService.getReclamation(id).subscribe({
            next: reklamation => {
                this.cd.markForCheck();
                this.reclamation = reklamation;
                this.cd.detectChanges();
            },
            error: error => {
                console.log('error: ' + error);
            },
            complete: () => {
                this.savePdf();
            }
        });
    }

}
