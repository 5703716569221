import * as evaluationActions from './evaluation.actions';
import { EvaluationState } from '../../models/evaluation.model';

export type State = EvaluationState;

/**
 * Set the initial State of the Evaluation Store
 */
export const initialState:State = {
    reclamationType: null,
    customerTransactionNumber: '',
    detectorsToExchange: [],
    detectorsToBuy: [],
    promptForSystemChange: false,
    reclamationResponse: {
        detectorsToExchange: [],
        id: '',
        transactionNumber: '',
        customerTransactionNumber: '',
        reclamationType: '',
        addressInformation: {
            company: '',
            firstname: '',
            lastname: '',
            address: '',
            zip: '',
            country: '',
            city: ''
        },
        serials: [],
        detectors: []
    },
    temporaryId: ''
};

/**
 * Reducer function that saves customer's numbers, save reclamation type and delete reclamations
 *
 * @param state
 * @param action
 */
export function reducer(state = initialState, action:evaluationActions.EvaluationActions):State {
    let newState:State;
    switch (action.type) {
        case evaluationActions.SAVE_CUSTOMER_NUMBERS:
            newState = Object.assign({}, state, {
                customerTransactionNumber: action.payload
            });
            break;
        case evaluationActions.SAVE_RECLAMATION_TYPE:
            newState = Object.assign({}, state, {
                reclamationType: action.payload
            });
            break;
        case evaluationActions.SAVE_RECLAMATION_EVALUATION:
            newState = Object.assign({}, state, {
                reclamationType: action.payload.reclamationType,
                temporaryId: action.payload.temporaryId,
                detectorsToExchange: action.payload.detectorsToExchange,
                detectorsToBuy: action.payload.detectorsToBuy,
                promptForSystemChange: action.payload.promptForSystemChange
            });
            break;
        case evaluationActions.SAVE_RECLAMATION_RESPONSE:
            newState = Object.assign({}, state, {
                reclamationResponse: action.payload
            });
            break;
        case evaluationActions.DELETE_RECLAMATION:
            newState = Object.assign({}, state, {
                reclamationType: initialState.reclamationType,
                customerTransactionNumber: initialState.customerTransactionNumber,
                detectorsToExchange: [],
                detectorsToBuy: [],
                reclamationResponse: initialState.reclamationResponse,
                temporaryId: initialState.temporaryId,
                promptForSystemChange: initialState.promptForSystemChange
            });
            break;
        default:
            return state;
    }

    return JSON.parse(JSON.stringify(newState));
}


