<div class="container">
    <div class="col-12 col-md-10 mx-auto">
        <div class="row">
            <sl-back-navigation [buttonText]="'RECLAMATION_START.DETECTOR_RECLAMATION.backLinkText' | translate">
            </sl-back-navigation>
        </div>
        <div *ngIf="!distinguishService.support && storedUserType !== CustomerType.Commercial  && guestEnabled" class="row py-3 mt-3 ">
          <button (click)="onComplainWithOutSerial()" class="btn btn-secondary btn-mobile-block" type="submit">
            {{privateCustAccCreation.info | translate}}
        </button>
        </div>
   
        <div class="row py-3">
            <h1>{{privateCustAccCreation.header | translate}}</h1>
        </div>
        <div class="row">
            <p class="lead">
                {{privateCustAccCreation.description| translate}}
            </p>
        </div>

        <div class="row pt-3">
            <ul>
                <li>
                    {{privateCustAccCreation.detail1| translate}}
                </li>
                <li>
                    {{privateCustAccCreation.detail2| translate}}
                </li>
                <li>
                    {{privateCustAccCreation.detail3| translate}}
                </li>
            </ul>
        </div>

        <div class="row">
            <!-- form -->
            <sl-account-form class="w-100" (next)="next($event)" [storedAccountData]="storedAccountData"></sl-account-form>
            <!-- end form -->
        </div>

    </div>
</div>