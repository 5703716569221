import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    Input,
    OnInit,
    DoCheck,
    Output,
    EventEmitter
} from '@angular/core';
import { FormGroup, Validators, FormControl } from '@angular/forms';
import { take } from 'rxjs/operators';
import { FormInputType, UserService, ValidationService } from 'shared-lib';

/**
 * Component that contains the Password input fields
 */
@Component({
    selector: 'rp-password',
    templateUrl: './password.component.html',
    styles: [],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class PasswordComponent implements OnInit {

    @Input() formGroup:FormGroup;
    @Input() submitted:boolean;
    public formCreated:boolean = false;
    private pwdRegExp:RegExp;
    public convertedFormGroup:FormGroup;


    get inputType() {
        return FormInputType;
    }

    /**
     * Getter for the Password of the Password Control
     */
    get password() {
        return this.formGroup.get('password');
    }

    /**
     * Getter for the Password Confirmation of the Password Control
     */
    get passwordConfirmation() {
        return this.formGroup.get('passwordConfirmation');
    }

    /**
     * Component requires UserService
     *
     * @param {UserService} userService
     * @param {ValidationService} validationService
     * @param {ChangeDetectorRef} CD
     */
    constructor(private userService:UserService,
                private validationService:ValidationService,
                private CD:ChangeDetectorRef) {
    }

    /**
     * Subscribe to the Password RexEx rules
     */
    ngOnInit() {
        this.userService.passwordRegExp().pipe(take(1)).subscribe(
            (regExp:RegExp) => {
                this.pwdRegExp = new RegExp(regExp);
                if (this.formGroup) {
                    this.createForm();
                    this.formCreated = true;
                }
                this.CD.markForCheck();
            }
        );
    }

    /**
     * Create the Password Form
     */
    private createForm() {
        this.password.setValidators(this.validationService.customPasswordValidator(this.pwdRegExp));
        this.passwordConfirmation.setValidators(this.validationService.customPasswordValidator(this.pwdRegExp));
        this.formGroup.setValidators(this.validationService.checkPasswords());
        this.formGroup.updateValueAndValidity();
    }
}
