import {ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {
    CheckboxOption,
    CustomerType,
    DistinguishSupportService,
    FormDataService,
    FormSelectionObjects,
    InfoBannerType,
    MassSerialCheck,
    MassSerialItem,
    ModalData,
    Product,
    ProductService,
    ProductsState,
    ProductValidityEnum,
    ProductViabilityName,
    RecaptchaService,
    ReclamationCompleteData,
    ReclamationDetailed,
    ReclamationService,
    SerialEntryForm,
    User,
    UserService,
    UtilsService,
    ValidationService
} from '../../../shared';
import {Observable, Subject} from 'rxjs';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {Store} from '@ngrx/store';
import {State} from '../../../../reducers/root/root.reducer';
import {concatMap, mergeMap, take, takeUntil, tap, switchMap} from 'rxjs/operators';
import {FormGroup} from '@angular/forms';
import * as ProductsAction from '../../../shared/reducers/products/products.actions';
import {NavigationUtilsService} from '../../../shared/services/navigation-utils.service';
import {NgbModalRef, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {AlertModalComponent} from '../../../shared/components/alert-modal/alert-modal.component';

@Component({
    selector: 'sl-recorded-serials',
    templateUrl: './recorded-serials.component.html',
    styles: []
})
export class RecordedSerialsComponent implements OnInit, OnDestroy {
    @ViewChild('infoBanner', {static: false, read: ElementRef}) banner: ElementRef;
    @ViewChild('addMoreSerialsForm') addMoreSerialEntryForm: SerialEntryForm;

    public readonly modalData: ModalData = {
        title: 'PRODUCTS.RECORDED_SERIALS.MODAL.title',
        dismissButtonText: 'PRODUCTS.RECORDED_SERIALS.MODAL.dismissButtonText',
        acceptButtonText: 'PRODUCTS.RECORDED_SERIALS.MODAL.acceptButtonText',
        message: 'PRODUCTS.RECORDED_SERIALS.MODAL.message'
    };

    private readonly NextNavReclamationSerials = {
        REC_SERIAL_SUPPORT: 'user/rueckmeldung-seriennummern-bestaetigung',
        REC_SERIAL_REKLA: 'servicemeldungen/rueckmeldung-seriennummern-bestaetigung'
    };

    public products: Product[] = [];
    public invalidProducts: Product[];
    public unfilteredProducts: Product[] = [];
    public actionProceed: Observable<any>;
    public addSerialsAction: Observable<any>;
    public user: User;
    public dropDownValues: FormSelectionObjects;
    public detectorOptions: CheckboxOption[];
    public showBanner: boolean = false;
    public bannerTextKey: string;
    public bannerType: InfoBannerType;
    public serialsMissing: number;
    public loadingData: boolean;
    public reclamationNumber: string;
    public addMore: boolean = false;

    private products$: Observable<ProductsState | any>;
    private _unsubscribe = new Subject();
    private year: number;
    private tooYoung: number = 2;
    private tooOld: number = 10;
    private reclamation: ReclamationDetailed;

    public get isSupportUser() {
        return this.distinguishSupportService.support;
    }

    get viableValidProducts(): Product[] {
        return this.unfilteredProducts.filter(product => product.viability === ProductViabilityName.VALID);
    }

    get invalidProductsExist(): boolean {
        return this.invalidProducts.length > 0;
    }

    get validProductsExist(): boolean {
        return this.products.length > 0;
    }

    get numberOfSerialsInList(): number {
        return this.products.length ?? 0;
    }

    constructor(private router: Router,
                private modalService: NgbModal,
                private route: ActivatedRoute,
                private formDataService: FormDataService,
                private store: Store<State>,
                private utils: UtilsService,
                private recaptchaService: RecaptchaService,
                public distinguishSupportService: DistinguishSupportService,
                private reclamationService: ReclamationService,
                private userService: UserService,
                private CD: ChangeDetectorRef,
                private productService: ProductService,
                private navigationUtils: NavigationUtilsService,
                private validationService: ValidationService) {
        this.initialize();
    }

    private initialize() {
        this.products$ = this.store.select('products');
        this.actionProceed = this.utils.createActionObs(() => this.proceedToNext());
        this.addSerialsAction = new Observable(sub => this.addSerials())
    }

    ngOnInit(): void {
        const currUser = JSON.parse(sessionStorage.getItem('currentUser'));
        this.tooYoung = currUser.tooYoung;
        this.tooOld = currUser.tooOld;

        if (this.distinguishSupportService.support) {
            this.user = JSON.parse(sessionStorage.getItem('user'));
        } else {
            this.user = currUser;
        }

        this.year = new Date().getFullYear();
        this.getProductFromStore();
        this.getReclamationDetails();
    }

    ngOnDestroy(): void {
        this._unsubscribe.next();
        this._unsubscribe.complete();
    }

    public updateProduct(serialEntryForm: FormGroup, originalProduct: Product, index) {
        this.recaptchaService.getToken('serials/check').pipe(
            tap(() => {
                this.showBanner = false;
                this.checkFieldValidations(serialEntryForm);
                this.validationService.checkForDuplicates(serialEntryForm, this.unfilteredProducts, originalProduct, originalProduct.productIndex, index);
            }),
            mergeMap((recaptcha) => {
                const serials = serialEntryForm.value?.serial;
                return this.formDataService.checkMassSerials(serials, recaptcha, true);
            }),
            mergeMap((res) => {
                const serialItem = res.valid[0] || res.corrupted[0] || res.invalid[0];
                const viabilityType = res.valid[0] ? ProductViabilityName.VALID : (res.corrupted[0] ? ProductViabilityName.CORRUPTED : ProductViabilityName.INVALID);
                this.unfilteredProducts = this.unfilteredProducts
                    .map(product => product.serialNumber === originalProduct.serialNumber ?
                        this.createUpdatedProduct(serialItem, viabilityType, originalProduct, serialEntryForm) : product);

                this.store.dispatch(new ProductsAction.SaveProductsAction(this.unfilteredProducts));

                return this.onCompleteOnlyValidate();
            }),
            take(1))
            .subscribe(() => {
                },
                (error) => {
                    this.setInfoBanner(error, true);
                });
    }

    /**
     * Delete product from the list and then update the Store
     *
     * @param {Product} productToDelete
     */
    public deleteProduct(productToDelete: Product) {
        const index = this.unfilteredProducts.findIndex(p => p.serialNumber === productToDelete.serialNumber)
        this.unfilteredProducts.splice(index, 1);
        this.unfilteredProducts.forEach((p, i) => {
            p.productIndex = i;
        });

        this.calculateProductLists(this.unfilteredProducts);
        this.store.dispatch(new ProductsAction.SaveProductsAction(this.unfilteredProducts));
    }

    private getProductFromStore() {
        this.products$
            .pipe(takeUntil(this._unsubscribe))
            .subscribe((products) => {
                this.unfilteredProducts = products.products;

                this.calculateProductLists(this.unfilteredProducts);
                this.dropDownValues = {
                    reasonOfReturn: products.reasonOfReturn,
                    groupType: products.groupType
                };
                this.setDetectorOptions();
                if (!products.products || products.products.length <= 0) {

                    const reroute: string = this.distinguishSupportService.support
                        ? this.user
                            ? '/user/seriennummern/' + this.reclamation?.id
                            : '/user'
                        : this.user?.customerType === CustomerType.Commercial
                            ? '/servicemeldungen/seriennummern/' + this.reclamation?.id
                            : '/produkte/auswahl-reklamation';
                    this.router.navigate([reroute]);
                }
            });
    }

    private calculateProductLists(unfilteredProducts: Product[]): void {
        const productsInDisplayFormat: Product[] = this.formDataService.getProductListToDisplay(
            unfilteredProducts
        );
        const validProducts: Product[] = [];
        const inValidProducts: Product[] = [];
        productsInDisplayFormat.forEach((product: Product) => {
            if (this.productIsValid(product)) {
                validProducts.push(product);
            } else {
                inValidProducts.push(product);
            }
        });
        this.products = validProducts;
        this.invalidProducts = inValidProducts;
    }

    private setDetectorOptions() {
        this.detectorOptions = [];
        if (this.dropDownValues) {
            Object.keys(this.dropDownValues.groupType).forEach(key => {
                this.detectorOptions.push({
                    id: key + '-' + 0,
                    value: key,
                    labelText: this.dropDownValues.groupType[key]
                });
            });
        }
    }

    /**
     * returns whether a product is valid without error code
     * @param product
     */
    private productIsValid(product: Product): boolean {
        return (product.installationYear >= this.year - this.tooOld && product.installationYear <= this.year - this.tooYoung)
            || product.valid === ProductValidityEnum.valid;
    }

    /**
     * Navigates the user to the next step in the Reclamation process
     * Regular Rekla user can not complete unless all serials complete
     * the required criteria
     * @returns
     */
    private proceedToNext() {
        this.checkPresenceOfInvalidProduct(false);
        return this.sendSerials(false);
    }

    /**
     * Support user can complete even if all serials do not match the
     * required criteria
     * @returns
     */
    private submitFromSupport() {
        this.checkPresenceOfInvalidProduct(true);
        this.sendSerials(true).subscribe();
    }

    private sendSerials(noWarn: boolean, onlyValidate: boolean = false): Observable<any> {
        return this.utils.createActionObs(() => {
            const dataToSend: ReclamationCompleteData[] = this.prepareDataToSend();
            return this.formDataService.sendSerials(dataToSend, this.reclamation?.id, noWarn, onlyValidate).pipe(
                tap(() => {
                    this.reclamation.finished = true;
                    this.onNavigateAfterCompleteCall(onlyValidate);
                }, (err) => {
                    this.onErrorAfterCompleteCall(err);
                }));
        });
    }

    private prepareDataToSend() {
        let dataToSend: ReclamationCompleteData[] = [];
        dataToSend = [...dataToSend, ...this.getDataToSendFromViableProductsList()];
        return dataToSend;
    }

    private onErrorAfterCompleteCall(err: any) {
        if (err.error.errors[0].code === 'ERROR_INVALID_INPUT') {
            this.setErrorItems(err.error.errors[0].detail.split(','));
        } else if (err.error.errors[0].code === 'ERROR_INVALID_SERIALS' && this.distinguishSupportService.support) {
            this.setErrorItems(err.error.errors[0].detail.split(','));
        } else {
            this.bannerTextKey = 'SERIAL_NUMBER_INPUT.TOAST.ERROR.default';
            this.bannerType = InfoBannerType.DANGER;
            this.showBanner = true;
        }
    }

    private onNavigateAfterCompleteCall(onlyValidate: boolean) {
        if (!onlyValidate) {
            const nextRoute: string = this.prepareRoutePath();
            this.router.navigate([this.navigationUtils.addIdToRoute(nextRoute, this.route)]);
        }
    }

    private prepareRoutePath(): string {
        return this.isSupportUser
            ? this.NextNavReclamationSerials.REC_SERIAL_SUPPORT
            : this.NextNavReclamationSerials.REC_SERIAL_REKLA;
    }

    private getDataToSendFromViableProductsList() {
        let dataToSend: ReclamationCompleteData[] = [];
        if (this.viableValidProducts.length > 0) {
            this.viableValidProducts?.forEach((item) => {
                dataToSend.push(
                    {
                        serialNumber: item?.serialNumber,
                        returnReason: item?.returnReason,
                        valid: item.valid
                    });
            });
        }
        return dataToSend;
    }

    private redirectOnFeatureDisabled() {
        this.userService
            .currentLoggedInStatus()
            .pipe(take(1))
            .subscribe((res) => {
                this.user.customerType === CustomerType.Commercial
                    ? this.router.navigate(['/produkte', 'start'])
                    : this.router.navigate(['/produkte', 'auswahl-reklamation']);
            });
    }

    public addSerials() {
        return this.recaptchaService.getToken('serials/check').pipe(
            tap(() => {
                this.showBanner = false;
                this.checkFieldValidations(this.addMoreSerialEntryForm.serialEntryForm);
                this.validationService.checkForDuplicates(this.addMoreSerialEntryForm.serialEntryForm, this.unfilteredProducts);
                this.checkIfMaxNumberOfSerialsEntered(this.addMoreSerialEntryForm.serialEntryForm);
            }),
            mergeMap((recaptcha) => {
                const serials = this.addMoreSerialEntryForm.serialEntryForm.value?.serials;
                return this.formDataService.checkMassSerials(serials, recaptcha, true);
            }),
            tap(res => {
                    const products = this.createProductArray(res, this.unfilteredProducts, this.addMoreSerialEntryForm.serialEntryForm);
                    this.unfilteredProducts = products;
                    this.store.dispatch(new ProductsAction.SaveProductsAction(products));
                    this.addMoreSerialEntryForm.serialEntryForm.reset();
                    this.toggleAddMore();
                },
                (error) => {
                    this.setInfoBanner(error.message, true);
                }),
            mergeMap(() => {
                return this.onCompleteOnlyValidate();
            }),
            takeUntil(this._unsubscribe)).subscribe();
    }

    private checkFieldValidations(form: FormGroup) {
        let valid = true;
        valid = this.formDataService.isValid(form, valid);
        if (!valid) {
            throw new Error('Validation');
        }
    }

    private checkIfMaxNumberOfSerialsEntered(form: FormGroup) {
        let matches = this.productService.getFormInputSerials(form);
        let validSerials = 0;
        this.viableValidProducts.map(product => {
            if (product.isValid === true) {
                validSerials++;
            }
        });
        let allowed = this.serialsMissing - validSerials;
        if (matches.length > allowed) {
            throw new Error('More than Max serials');
        }
    }

    public confirmationModal(type: 'support' | 'user', actionButton?: Observable<any>) {
        const modalRef: NgbModalRef = this.modalService.open(AlertModalComponent,
            {centered: true, ariaLabelledBy: 'modal-basic-title'});
        modalRef.componentInstance.data = this.modalData;
        modalRef.result.then(() => {
            switch (type) {
                case 'user':
                    this.subscribeToActionObservable(actionButton);
                    break;
                case 'support':
                    this.submitFromSupport();
                    break;
            }
        }, () => {
        });
    }

    private createProductArray(serials: MassSerialCheck, products: Product[], serialEntryForm): Product[] {
        Object.keys(serials).forEach(viabilityType => {
            products = serials[viabilityType].map((serial: MassSerialItem, index: number) => ({
                isValid: viabilityType === 'valid',
                number: products[0]?.number,
                numberRange: products[0]?.numberRange,
                productImg: serial?.article?.hasImage ? this.formDataService.buildImageURL(serial.article?.id) : this.formDataService?.PLACEHOLDER_IMG_URL,
                productIndex: index,
                supplySource: serialEntryForm?.value?.supplySource,
                productTitle: serial?.article?.type,
                returnReason: serialEntryForm?.value?.returnReason,
                serialNumber: serial?.serial,
                serialNumberIsChecked: true,
                valid: serial?.valid,
                viability: viabilityType
            })).concat(products);
        });
        return products;
    }

    private createUpdatedProduct(serial: MassSerialItem, viabilityType: ProductViabilityName, originalProduct: Product, serialEntryForm: FormGroup): Product {
        return {
            isValid: viabilityType === 'valid',
            productImg: serial.article?.hasImage ? this.formDataService.buildImageURL(serial.article?.id) : this.formDataService.PLACEHOLDER_IMG_URL,
            productIndex: originalProduct.productIndex,
            productTitle: serial.article?.type,
            returnReason: serialEntryForm.value?.returnReason,
            serialNumber: serial.serial,
            serialNumberIsChecked: true,
            valid: serial.valid,
            viability: viabilityType
        };
    }

    private setInfoBanner(error: string, onlyValidate: boolean = false) {
        if (onlyValidate) return;
        this.showBanner = true;
        switch (error) {
            case 'Duplicate':
                this.bannerTextKey = 'PRODUCTS.IDENTIFICATION.ERRORS.duplicates';
                this.bannerType = InfoBannerType.INFO;
                break;
            case 'ERROR_INVALID_CAPTCHA':
                this.bannerTextKey = 'PRODUCTS.SUMMARY.reCaptchaError';
                this.bannerType = InfoBannerType.DANGER;
                break;
            case 'ERROR_INVALID_INPUT':
                this.bannerTextKey = 'PRODUCTS.SUMMARY.invalidInput';
                this.bannerType = InfoBannerType.DANGER;
                break;
            case 'ERROR_FEATURE_DISABLED':
                this.bannerTextKey = 'PRODUCTS.SUMMARY.featureDisabled';
                this.bannerType = InfoBannerType.DANGER;
                this.redirectOnFeatureDisabled();
                break;
            case 'More than Max serials':
                this.bannerTextKey = 'PRODUCTS.IDENTIFICATION.ERRORS.max';
                this.bannerType = InfoBannerType.DANGER;
                break;
            case 'Validation':
                // Dont do anything
                break;
            default:

                this.bannerTextKey = 'PRODUCTS.IDENTIFICATION.ERRORS.general';
                this.bannerType = InfoBannerType.DANGER;

        }
        setTimeout(() => {
            this.banner?.nativeElement.scrollIntoView({behavior: 'smooth'});
        }, 300);
    }

    private getReclamationDetails() {
        this.route.params.pipe(
            concatMap((params: Params) => this.reclamationService.getReclamation(params.id)),
            switchMap((reclamation) => {
                this.loadingData = false;
                this.reclamation = reclamation;
                this.reclamationNumber = this.reclamationService.calculateReclamationNumber(reclamation);
                this.serialsMissing = this.reclamationService.calculateSerialNumbersMissing(reclamation);


                this.CD.markForCheck();
                return this.onCompleteOnlyValidate();
            }),
            takeUntil(this._unsubscribe))
            .subscribe(() => {
            });
    }

    private onCompleteOnlyValidate() {
        this.checkPresenceOfInvalidProduct(false);
        return this.sendSerials(this.isSupportUser, true);
    }

    private setErrorItems(details) {
        let tempProducts: Product[] = [];
        details.forEach(detail => {
            const position = detail
                .split('/')[0]
                .replace('[', '')
                .replace(']', '');
            const index = Number(position) - 1;

            const product = this.unfilteredProducts.find(product => product?.serialNumber === this.viableValidProducts[index]?.serialNumber);
            tempProducts.push(product);
        });
        tempProducts.forEach((prod) => {
            this.unfilteredProducts.map(product => {
                if (product?.serialNumber === prod?.serialNumber) {
                    product.valid = ProductValidityEnum.invalid_001;
                    product.viability = ProductViabilityName.INVALID;
                }
                return product;
            });
        });
        this.calculateProductLists(this.unfilteredProducts);
    }

    public toggleAddMore() {
        this.addMore = !this.addMore;
    }

    private decideIfProductIsTooOldOrOnlyInvalid() {
        let valid005 = 0;
        this.unfilteredProducts.forEach(element => {
            if (element.valid === '005') {
                valid005++;
            }
        });
        if (valid005 > 0) {
            this.router.navigate(['/produkte', 'kontakt-support']); //works for both rekla and support
        } else {
            this.setInfoBanner('ERROR_INVALID_INPUT', true);
        }
    }

    private checkPresenceOfInvalidProduct(isLast: boolean = true) {
        if (!isLast) return
        if (this.validProductsExist) {
            return;
        } else {
            this.decideIfProductIsTooOldOrOnlyInvalid();
        }
    }

    public subscribeToActionObservable(actionButton: Observable<any>) {
        actionButton?.subscribe();
    }
}
