import { Injectable, OnDestroy } from '@angular/core';
import { Observable, of, Subject } from 'rxjs';
import { CustomerType, LoginStatus } from '../models';
import { TranslateService } from '@ngx-translate/core';
import { XHttpClient } from './extended-http-client.service';
import { environment } from '../../../../environments/environment';
import { mergeMap, takeUntil } from 'rxjs/operators';

/**
 * Service to aid in Translations and Languages
 */
@Injectable({
    providedIn: 'root'
})
export class LangUtilsService implements OnDestroy {

    private _unsubscribe:Subject<any> = new Subject<any>();
    private translationsLoadedForCustomerType:CustomerType;

    constructor(private Http:XHttpClient,
                private Translate:TranslateService) {
    }

    /**
     * Requests the language file for a specific customer type via HTTP call.
     *
     * @param {LoginStatus} currentUser
     * @param {boolean} shouldMerge
     */
    public doGetTranslations(currentUser?:LoginStatus, shouldMerge?:boolean):void {
        const translationTypeNeeded:CustomerType = currentUser && currentUser.customerType ? (
                currentUser.customerType === CustomerType.Commercial ?
                    CustomerType.Commercial : CustomerType.Private)
            : null;

        // translations are loaded and the specific translations needed already are loaded:
        if (this.translationsLoadedForCustomerType && this.translationsLoadedForCustomerType === translationTypeNeeded) {
            return;
        }

        const queryParam:string = translationTypeNeeded ?
            '?service=' + translationTypeNeeded.toLowerCase() : '';
        let request:Observable<boolean>;

        // translations are loaded but the specific translations needed are not loaded:
        // (general translations need to be reloaded first because otherwise the merging of translation files will not work correctly)
        if (this.translationsLoadedForCustomerType) {
            request = this.requestTranslations('').pipe(
                mergeMap((translations) => {
                    this.doSetTranslations(translations, false);
                    return this.requestTranslations(queryParam);
                }),
                mergeMap((translations) => {
                    this.doSetTranslations(translations, true);
                    return of(true);
                }));
        } else {
            // translations are not loaded yet
            request = this.requestTranslations(queryParam).pipe(
                mergeMap((translations) => {
                    this.doSetTranslations(translations, shouldMerge || false);
                    return of(true);
                }));
        }

        request.pipe(
            takeUntil(this._unsubscribe))
            .subscribe(() => {
                this.translationsLoadedForCustomerType = translationTypeNeeded;
            });
    }

    private requestTranslations(queryParam):Observable<any> {
        return this.Http.get(environment.api.langFile + queryParam);
    }

    /**
     * Deletes currently used translations and requests default lang.
     */
    public clearAndReloadTranslations() {
        this.Translate.resetLang(this.Translate.defaultLang);
        this.doGetTranslations(null, false);
    }

    /**
     * Sets an object of translations for a given language.
     *
     * @param {Object} translations
     * @param {boolean} shouldMerge
     */
    private doSetTranslations(translations:Object, shouldMerge:boolean):void {
        this.Translate.setTranslation(this.Translate.defaultLang, translations, shouldMerge);
    }

    /**
     * @ignore
     */
    ngOnDestroy():void {
        this._unsubscribe.next();
        this._unsubscribe.complete();
        this._unsubscribe.unsubscribe();
    }
}
