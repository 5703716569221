import {
    Component,
    EventEmitter,
    Injectable,
    Input,
    Output,
    Pipe,
    PipeTransform
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { BehaviorSubject, defer, Observable, of } from 'rxjs';
import {
    ExternalData, LoginStatus, User,
    DetectorsToExchange, Feedback, ReclamationBase,
    Detector,
    FormSelectionObjects,
    Product,
    ProductValidityEnum,
    Serial, SerialNumberCheck,
    Logo, MenuItem, TitleDescIcon, RegisterAddress, RegisterAccount, RegistrationComponents, CustomerType
} from '../../shared';


/**
 * Create async observable that emits-once and completes
 *  after a JS engine turn
 *
 * @param data
 */
export function asyncData<T>(data:T) {
    return defer(() => Promise.resolve(data));
}

/**
 * Create async observable error that errors
 *  after a JS engine turn
 *
 *  @param errorObject
 */
export function asyncError<T>(errorObject:any) {
    return defer(() => Promise.reject(errorObject));
}


type SearchType = 'userObject' | '';


/**
 * Mock Component
 */
@Component({
    selector: '',
    template: ''
})
export class DummyComponent {
}

/**
 * Mock AddressComponent
 */
@Component({
    selector: 'sl-address-form',
    template: ''
})
export class FakeAddressComponent {
    @Input() addressForm:FormGroup;
    @Input() storedAddressData:RegisterAddress;
    @Input() calledFrom:RegistrationComponents = null;
    @Input() customerType:CustomerType;
    @Input() showCountry:boolean = true;
    @Output() next:EventEmitter<RegisterAddress> = new EventEmitter();
    @Output() cancel:EventEmitter<RegisterAddress> = new EventEmitter();
    @Output() save:EventEmitter<RegisterAccount> = new EventEmitter();
    @Output() reset:EventEmitter<void> = new EventEmitter();
}

/**
 * Mock EmailTelComponent
 */
@Component({
    selector: 'sl-email-tel',
    template: ''
})
export class FakeEmailTelComponent {
    @Input() followUpForm:boolean;
    @Input() formGroup:FormGroup;
    @Input() submitted:boolean;
    @Input() emailReadOnly:boolean;
    @Output() reset:EventEmitter<void> = new EventEmitter();

}

/**
 * Mock BusyBtnComponent
 */
@Component({
    selector: 'sl-busy-btn',
    template: ''
})
export class FakeBusyBtnComponent {
    @Input() action;
    @Input() classes;
    @Input() type;
    @Input() disabled;
    @Input() tabindex;
    @Input() cancelClickOnDisabled;
    @Input() finishActionOnComplete;

    @Output() actionPending:EventEmitter<boolean> = new EventEmitter();
}

/**
 * Mock PopoverComponent
 */
@Component({selector: 'sl-popover', template: ''})
export class FakePopoverComponent {
    @Input() popoverText:string;
    @Input() popoverTitle:string;
    @Input() placement:string;
    @Input() inLink:boolean;
    @Input() htmlText:boolean;
}

/**
 * Mock AccountDetailsComponent
 */
@Component({
    selector: 'sl-account-details',
    template: ''
})
export class FakeAccountDetailsComponent {
    @Input() user:User;
    @Input() canEdit:boolean;
    @Output() editMode:EventEmitter<boolean> = new EventEmitter<boolean>();
}

/**
 * Mock BackNavigationComponent
 */
@Component({
    selector: 'sl-back-navigation',
    template: ''
})
export class FakeBackNavigationComponent {
    @Input() buttonText:string;
    @Input() route:string;
    @Input() icon:string;
}

/**
 * Mock ProductFormComponent
 */
@Component({
    selector: 'sl-product-form',
    template: ''
})
export class FakeProductFormComponent {
    @Input() totalProducts:number;
    @Input() product:Product;
    @Input() productIndex:number;
    @Input() products:Product[];
    @Input() formSelectionValues:FormSelectionObjects;
    @Input() globalReturnReason:string;
    @Input() reasonSet:boolean;
    @Input() markAllFields:boolean;
    @Output() updatedProduct:EventEmitter<Product> = new EventEmitter<Product>();
    @Output() productToDelete:EventEmitter<number> = new EventEmitter<number>();
    @Output() newForm:EventEmitter<boolean> = new EventEmitter<boolean>();
}

/**
 * Mock PrivateProductFormComponent
 */
@Component({
    selector: 'sl-private-product-form',
    template: ''
})
export class FakePrivateProductFormComponent {
    @Input() totalProducts:number;
    @Input() product:Product;
    @Input() productIndex:number;
    @Input() products:Product[];
    @Input() formSelectionValues:FormSelectionObjects;
    @Input() address:string;
    @Input() currentUserStatus:LoginStatus;
    @Input() reasonSet:boolean;
    @Input() globalReturnReason:string;
    @Input() markAllFields:boolean = false;
    @Output() updatedProduct:EventEmitter<Product> = new EventEmitter<Product>();
    @Output() productToDelete:EventEmitter<number> = new EventEmitter<number>();
    @Output() newForm:EventEmitter<boolean> = new EventEmitter<boolean>();
    @Output() globalReasonSet:EventEmitter<boolean> = new EventEmitter<boolean>();
}

/**
 * Mock SearchServiceComponent
 */
@Component({
    selector: 'sl-search-slot',
    template: ''
})
export class FakeSearchSlotComponent {
    @Input() showAsValid:boolean;
    @Input() showAsInvalid:boolean;
    @Input() controlValue;
    @Input() searchType:SearchType;
    @Input() parentForm:FormGroup;
    @Input() placeholder:string;
    @Input() filterResult:(res:any[]) => any[];
    @Input() readonly:boolean;
    @Input() tabindex:number;
    @Input() searchOff:boolean;
    @Output() submitSelectedValue:EventEmitter<any> = new EventEmitter();
}

/**
 * Mock ProductsClaimedComponent
 */
@Component({
    selector: 'sl-products-claimed',
    template: ''
})
export class ProductsClaimedStubComponent {
    @Input() products:Serial[] | Detector[];
    @Input() showObjectName:boolean;
    @Input() showReclamationType:boolean;
}

/**
 * Mock RecaptchaComponent
 */
@Component({
    selector: 'sl-recaptcha',
    template: ''
})
export class FakeRecaptchaComponent {
}

/**
 * Mock FeedbackModalComponent
 */
@Component({selector: 'sl-feedback-modal', template: ''})
export class FeedbackModalStubComponent {

}

/*@Component({
    selector: 'rp-hvg-contact',
    template: ''
})
export class FakeHvgContactComponent {
}*/

@Component({
    selector: 'sl-info-outlet',
    template: ''
})
export class FakeInfoOutletComponent {
    @Input() title:string;
    @Input() type:'warning' | 'info' = 'info';
}

@Component({
    selector: 'sl-products-replaced',
    template: ''
})
export class FakeProductsReplacedComponent {
    @Input() detectorsToExchange:DetectorsToExchange[];
}

@Component({
    selector: 'sl-email-address',
    template: ''
})
export class FakeEmailAddressComponent {
    @Input() emailKey:string;
}

@Component({
    selector: 'sl-support-contact-details',
    template: ''
})
export class FakeSupportContactDetailsComponent {

    @Input() titleText;
    @Input() sublineText;
}

@Component({
    selector: 'sl-header',
    template: ''
})
export class FakeHeaderComponent {
    @Input() loggedIn:boolean;
    @Input() guest:boolean;
    @Input() username:string;
    @Input() isShown:boolean;
    @Input() menuItems:Observable<MenuItem[]>;
    @Input() logo:Logo;

    @Output() logout:EventEmitter<any> = new EventEmitter<any>();
    @Output() routeTo:EventEmitter<string> = new EventEmitter<string>();
}

@Component({
    selector: 'sl-reclamations-table',
    template: ''
})
export class FakeReclamationsTableComponent {
    @Input() public list:ReclamationBase[];
    @Input() public linkToDetail:boolean;
    @Output() public linkClicked:EventEmitter<ReclamationBase> = new EventEmitter<ReclamationBase>();
}

@Component({
    selector: 'support-extended-user-detail',
    template: ''
})
export class FakeExtendedUserDetailComponent {
    @Input() public externalData:ExternalData;
}

@Component({
    selector: 'sl-icon-text-bullet',
    template: ''
})
export class FakeIconTextBulletComponent {
    @Input() titleDescIcon:TitleDescIcon;
}

@Component({
    selector: 'sl-tile',
    template: ''
})
export class FakeTileComponent {
    @Input() titleText:string;
    @Input() mainText:string;
    @Input() routeUrl:string;
}

/**
 * Mock SerialNumberComponent
 */
@Component({
    selector: 'sl-serial-number',
    template: ''
})
export class FakeSerialNumberComponent {
    @Input() parentForm:FormGroup;
    @Input() action:Observable<SerialNumberCheck>;
    @Input() title:string;
    @Input() serialText:string;
    @Input() isTextArea:boolean;
    @Input() controlName:string;
    @Input() hasSerialText?:boolean = false;
    @Input() submitted?:boolean = false;
    @Input() serialNumberIsChecked?:boolean = false;
    @Input() hasCheckButton?:boolean = true;
    @Input() required?:boolean = true;
}

@Component({
    selector: 'sl-address-form',
    template: ''
})
export class FakeAddressFormComponent {
    @Input() addressForm:FormGroup;
    @Input() storedAddressData:RegisterAddress;
    @Input() calledFrom:RegistrationComponents = null;
    @Input() customerType:CustomerType;
    @Input() showCountry:boolean = true;
    @Output() next:EventEmitter<RegisterAddress> = new EventEmitter();
    @Output() cancel:EventEmitter<RegisterAddress> = new EventEmitter();
    @Output() save:EventEmitter<RegisterAddress> = new EventEmitter();
    @Output() reset:EventEmitter<void> = new EventEmitter();
}

@Component({
    selector: 'sl-serial-entry-form',
    template: ''
})
export class FakeSerialEntryFormComponent {
    @Input() isSingleMode:boolean = false;
    @Input() isEditMode:boolean = false;
    @Input() showSource:boolean = true;
    @Input() showReturnReason:boolean = true;
    @Input() products?:Product[];
    @Input() product?:Product;
    @Input() dropDownValues:FormSelectionObjects;
}


/**
 * Mock Translation Pipe
 */
@Pipe({name: 'translate'})
export class MockTranslatePipe implements PipeTransform {
    transform(value:string):string {
        return value;
    }
}

/**
 * Mock Date Pipe
 */
@Pipe({
    name: 'date',
    pure: false // required to update the value when the promise is resolved
})
export class MockedDatePipe implements PipeTransform {
    name:string = 'date';

    transform(query:string, ...args:any[]):any {
        return query;
    }
}

/**
 * Mock FormDataService
 */
@Injectable()
export class FakeFormDataService {
    public sendFeedbackShown():Observable<any> {
        return of({});
    }

    public sendFeedback(feedback:Feedback):Observable<any> {
        return of(feedback);
    }
}

/**
 * Mock ProductFormUtilsService
 */
@Injectable()
export class FakeProductFormUtilsService {
    public determineSerialNumberValidity() {
        return ProductValidityEnum.valid;
    }
}

/**
 * Mock ToastsService
 */
@Injectable()
export class FakeToastsService {
    showWarning():any {
    }

    showError() {
    }

    showSuccess() {

    }
}

/**
 * Mock UserService
 */
@Injectable()
export class FakeUserService {
    public currentUserStatus = {customerType: 'COMMERCIAL', feedbackShown: false};
    private currentStatusSubject = new BehaviorSubject<any>(this.currentUserStatusValue);

    private _storedUserDetails:BehaviorSubject<User> = new BehaviorSubject<User>(null);

    public currentUserStatusValue() {
        return this.currentUserStatus;
    }

    public currentLoggedInStatus() {
        return this.currentStatusSubject.asObservable();
    }

    public getUserList(pageLength:number, page:number, q?:string) {
        return of({});
    }

    public getUserDetails() {
        return of({});
    }

    public storeUserDetails(user:User) {
        user = {};
        this._storedUserDetails.next(user);
    }

    public getCurrentUserStatus() {
        return of(this.currentUserStatus);
    }

    public getReclamations() {
        return of({});
    }

    public getAdminUserList(pageLength:number, page:number, q?:string) {
        return of({});
    }

    public getAdminUserDetails(id:string) {
        return of({});
    }
}

/**
 * Mock TranslateService
 */
@Injectable()
export class FakeTranslateService {
    public setDefaultLang(language:string) {
    }

    public use(language:string) {
    }

    public get() {
    }

    public instant(key:string):string {
        return key;
    }

    public onTranslationChange() {
        return of({});
    }
}

/**
 * Mock Menu Items Service
 */
@Injectable()
export class FakeMenuItemsService {
}

/**
 * Mock LangUtilsService
 */
@Injectable()
export class FakeLangUtilsService {
}

@Injectable()
export class FakeRecaptchaService {
    public getCurrentRecaptchaStatus() {
        return of({});
    }

    public getToken() {
        return of({});
    }
}

@Injectable()
export class FakeNavigationUtilsService {
    public addIdToRoute(route:string, activatedRoute):string {
        return '/';
    }

    public scrollIntoView(accountFixedHeader, el) {
    }
}

@Injectable()
export class FakeDistinguishSupportService {

}

@Injectable()
export class FakeProductService {}

@Injectable()
export class FakeValidationService {
    public validatorSalesChannelRequired() {}

    public customMinLength() {}

    public checkCustomerNumber() {}

    public spacesAtStart() {}

    public checkNumberLength() { }

    public addressPattern() { }
}

@Injectable()
export class FakeUserDetailsService {}

@Injectable()
export class FakeSerialService {}
