/**
 *  The model of an Object
 */

export interface ObjectInformation {
    objectType?:number;
    objectName?:string;
    numberRange?:number;
    number?:number;
}

/**
 * Model of a Product
 */
export interface Product extends ObjectInformation {
    productIndex:number;
    serialNumber?:string;
    serialNumberIsChecked?:boolean;
    valid?:ProductValidity;
    productTitle?:string;
    productType?:string;
    productImg?:string;
    secondSerialNumber?:string;
    secondSerialChecked?:boolean;
    secondSerialNumberValid?:ProductValidity;
    secondProductTitle?:string;
    secondProductType?:string;
    secondProductImg?:string;
    supplySource?:string;
    returnReason?:number;
    isValid?:boolean;
    type?:string;
    isSecondaryProduct?:boolean;
    articleType?:string;
    reclamationType?:string;
    groupType?:string;
    installationYear?:number;
    count?: number;
    viability?: ProductViabilityName
}


export enum ProductViabilityName {
  CORRUPTED = 'corrupted',
  INVALID = 'invalid',
  VALID = 'valid'
}

/**
 * Detailed Information of an Object
 */
export interface ObjectInformationDetailed extends ObjectInformation {
    createdAt:string;
    updatedAt:string;
    id:string;
}

/**
 * Model of a key/value pair
 */
export interface KeyValuePair {
    key:number;
    value:string;
}

/**
 * Model of the Base Examination Data
 */
export interface BaseExaminationData {
    valid?:ProductValidity;
    objectName?:string;
    objectType?:number;
    returnReason?:number;
}

export type CheckType = CheckTypes.detectors | CheckTypes.serials;

export enum CheckTypes {
    serials = 'SERIALS',
    detectors = 'DETECTORS'
}

/**
 * Model of a Examination of a Product
 */
export interface ProductExaminationData extends BaseExaminationData {
    serialNumber:string;
}

/**
 * Model of a Examination of a Detector (Product entered without serialNumber)
 */
export interface Detector extends BaseExaminationData {
    groupType:string;
    installationYear:number;
    count:number;
}

export interface Serial extends BaseExaminationData {
    serialNumber:string;
    articleNumber:string;
    prodDate:string;
    articleType:string;
}

export interface ReclamationTypeRequest {
    checkType:CheckType;
    customerTransactionNumber?:string;
    objectName:string;
    supplySource?:string;
    numberRange?:number;
    number?: number;
    reclamationType?: string;
}

export interface SerialReclamationTypeRequest extends ReclamationTypeRequest {
    checkType:CheckTypes.serials;
    serials:ProductExaminationData[];
}

export interface DetectorReclamationTypeRequest extends ReclamationTypeRequest {
    checkType:CheckTypes.detectors;
    detectors:Detector[];
}

/**
 * Types of product validity
 */
export type ProductValidity =
    ProductValidityEnum.valid |
    ProductValidityEnum.invalid_001 |
    ProductValidityEnum.invalid_002 |
    ProductValidityEnum.invalid_003 |
    ProductValidityEnum.invalid_004 |
    ProductValidityEnum.invalid_005 |
    ProductValidityEnum.invalid_006 |
    ProductValidityEnum.default;

/**
 * Enum of possible product validitys
 */

export enum ProductValidityEnum {
    valid = 'valid',
    invalid_001 = '001',
    invalid_002 = '002',
    invalid_003 = '003',
    invalid_004 = '004',
    invalid_005 = '005',
    invalid_006 = '006',
    default = '0'
}

/**
 * Component Type of a Product
 */
export interface ComponentType {
    componentType?:'wifiCapableDetector' | 'wifiModule' | 'standalone' | 'other';
}

/**
 * Model of a Products Article Details
 */
export interface Article extends ComponentType {
    id:string;
    articleNumber:string;
    type:string;
    description:string;
    hasImage:boolean;
    price?:number;
}

/**
 * Check to see if a Serial Number is Valid
 */
export interface SerialNumberCheck {
    data:{
        valid:ProductValidity;
        article?:Article;
    };
}

/**
 * Data that needs to be sent in to complete a detector reclamation
 */
export interface ReclamationCompleteData {
    valid:ProductValidity;
    returnReason:number;
    serialNumber:string;
}

export type SerialNumberCheckWithProdDate = SerialNumberCheck & {
    data:{
        prodDate:string;
    }
};

export type SerialNumberCheckWithProdYear = SerialNumberCheck & {
    data:{
        prodYear:string;
    }
};


/**
 * Model for the Dropdowns on the Product Form
 */
export interface FormSelectionObjects {
    supplySource?:DropdownValue;
    reasonOfReturn?:DropdownValue;
    objectType?:DropdownValue;
    totalDetectors?:DropdownValue;
    groupType?:DropdownValue;
}

/**
 * The Selection Values of the Product Form
 */
export interface FormSelectionValues {
    selectionValues:FormSelectionObjects;
}

/**
 * The Object of the Second Serial Number
 */
export interface SecondSerialTextObject {
    title:string;
    text:string;
    popover:string;
}

/**
 * The Text value for the Second Serial Number
 */
export interface SecondSerialTextValue {
    secondSerialTextObject:SecondSerialTextObject;
}

/**
 * Model for the Reason of the Reclamation
 */
export interface DropdownValue {
    [key:string]:string;
}

export interface MassSerialItem {
    serial:string;
    prodDate:string;
    article:Article;
    valid:ProductValidityEnum;
    prodYear?:number;
}

export interface MassSerialCheck {
    valid: MassSerialItem[];
    invalid: MassSerialItem[];
    corrupted: MassSerialItem[];
}

/**
 * Enum for the different form items
 */
export enum FormInputType {
    TEXT = 'text',
    NUMBER = 'number',
    PASSWORD = 'password',
    DROPDOWN = 'dropdown',
    TEXTAREA = 'textarea',
    CHECKBOX = 'checkbox',
    RADIO = 'radio'
}

/**
 * The state that will be used for the Product Reducer
 */
export interface ProductsState {
    products: Product[];
    reasonOfReturn: DropdownValue;
    objectType: KeyValuePair[];
    totalDetectors: DropdownValue;
    groupType: DropdownValue;
    enterSerialsLater: boolean;
    globalReasonSet: boolean;
    supplySource: DropdownValue;
    orderNumber?:string;
    customerNumber?:string;
}

export interface SerialMassClaim {
    products?:Product[];
    serialsString?:string;
    orderNumber:string;
    customerNumber:string;
}

export interface SerialMassClaimResponse {
    id: string;
    customerNumber:string;
    orderNumber:string;
    serials:Serial[];
}