<div class="col-12 my-3">
    <div class="row bg-light px-3 px-md-0">
        <div class="col-12 col-md-10 mx-auto">
            <div *ngIf="title" class="row my-3">
                <h3>{{title | translate}}</h3>
            </div>
            <div *ngIf="lead">
                <p class="lead">{{lead | translate}}</p>
            </div>
            <ng-content></ng-content>
            <div class="row my-3">
                <ng-container *ngIf="hasAction">
                    <sl-busy-btn [classes]="'btn btn-secondary btn-mobile-block mb-2 mb-sm-0 mr-sm-2'" [action]="action" [tabindex]="0" [type]="'submit'">
                        {{'PRODUCTS.SUMMARY.BUTTONS.accept' | translate}}
                    </sl-busy-btn>
                </ng-container>
                <ng-container *ngIf="hasAccept">
                    <button class="btn btn-secondary btn-mobile-block mb-2 mb-sm-0 mr-sm-2" (click)="accept.emit()">
                        {{'PRODUCTS.SUMMARY.BUTTONS.accept' | translate}}
                    </button>
                </ng-container>
                <ng-container *ngIf="hasCancel">
                    <button class="btn btn-light btn-mobile-block" (click)="cancel.emit()">
                        {{'PRODUCTS.SUMMARY.BUTTONS.cancel' | translate}}
                    </button>
                </ng-container>
            </div>
        </div>
    </div>
</div>