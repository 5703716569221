import {
    RegisterAccount,
    RegisterAddress,
    BusinessCustomerType
} from '../../models';
import { State, Registration } from './register.reducer';
import * as RegistrationAction from './register.actions';

export function getDeletedAddress(state:State):Registration {
    return {
        ...state.registrationData, address: null
    };
}

export function getDeletedAccount(state:State):Registration {
    return {
        ...state.registrationData, account: null
    };
}

export function getDeletedUserType(state:State):Registration {
    return {
        ...state.registrationData, customerType: null
    };
}

export function getDeletedRegistrationForm(state:State):Registration {
    return {
        ...state.registrationData,
        customerType: null,
        account: null,
        address: null,
        businessCustomerType: null,
    };
}

export function getNewAccount(state:State, action:RegistrationAction.AddAccountFormData):Registration {
    return {
        ...state.registrationData,
        account: new RegisterAccount(action.payload.firstName, action.payload.lastName, action.payload.email, action.payload.salutation, action.payload.password)
    };
}

export function getNewAddress(state:State, action:RegistrationAction.AddAddressFormData):Registration {
    return {
        ...state.registrationData,
        address: new RegisterAddress(
            action?.payload?.street, action.payload.addressExtension,
            action.payload.zip, action.payload.city, action.payload.country, action.payload.phone,
            action.payload.customerNumber, action.payload.company
        )
    };
}

export function getNewBusinessCustomerTypeOption(state:State, action:RegistrationAction.AddBusinessCustomerType):Registration {
    return {...state.registrationData, businessCustomerType: action.payload};
}

export function getNewCustomerType(state:State, action:RegistrationAction.AddUserTypeFormData):Registration {
    return {...state.registrationData, customerType: action.payload};
}

export function getSalesChannel(state:State, action:RegistrationAction.AddSalesChannel):Registration {
    return {...state.registrationData, externalData: action.payload};
}
