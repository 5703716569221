import { NgModule } from '@angular/core';
import { ActionReducer, MetaReducer, StoreModule } from '@ngrx/store';
import { RouterStateSerializer, StoreRouterConnectingModule } from '@ngrx/router-store';
import { CustomRouterStateSerializer } from './router.utils';
import { environment } from '../../environments/environment';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { localStorageSync } from 'ngrx-store-localstorage';
import * as fromRoot from './root/root.reducer';


/**
 * Saves the 'products' and 'evaluation' parts of the store to localStorage so that they can persist on page reload
 *
 * @alias localStorageSyncReducer
 * @param {ActionReducer<any>} reducer
 * @returns {ActionReducer<any>}
 *
 */
export function localStorageSyncReducer(reducer:ActionReducer<any>):ActionReducer<any> {
    return localStorageSync({
      keys: [fromRoot.StoreNames.ADDRESS, fromRoot.StoreNames.PRODUCTS, fromRoot.StoreNames.EVALUATION, fromRoot.StoreNames.USER, fromRoot.StoreNames.REGISTER],
        rehydrate: true,
        storage: sessionStorage
    })(reducer);
}

const metaReducers:Array<MetaReducer<any, any>> = [localStorageSyncReducer];


const runtimeChecks = {
  strictActionImmutability: false,
  strictActionSerializability: false,
  strictStateImmutability: false,
  strictStateSerializability: false,
  strictActionWithinNgZone: true,
  strictActionTypeUniqueness: true,
};
@NgModule({
    imports: [
    StoreModule.forRoot(
      fromRoot.reducers,
        {
          metaReducers,
          runtimeChecks
        }
      ),
        /**
         * @ngrx/router-store keeps router state up-to-date in the store.
         */
        StoreRouterConnectingModule.forRoot(),
        /**
         * Store devtools instrument the store retaining past versions of state
         * and recalculating new states. This enables powerful time-travel
         * debugging.
         *
         * To use the debugger, install the Redux Devtools extension for either
         * Chrome or Firefox
         *
         * See: https://github.com/zalmoxisus/redux-devtools-extension
         */
        !environment.production ? StoreDevtoolsModule.instrument() : []
    ],
    exports: [
        StoreModule
    ],
    providers: [
        /**
         * The `RouterStateSnapshot` provided by the `Router` is a large complex structure.
         * A custom RouterStateSerializer is used to parse the `RouterStateSnapshot` provided
         * by `@ngrx/router-store` to include only the desired pieces of the snapshot.
         */
        {provide: RouterStateSerializer, useClass: CustomRouterStateSerializer}
    ]
})
export class NgrxConfigModule {
}
