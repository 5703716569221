<div *ngIf="!isEditMode" class="address-profile row">
    <div class="col-12 col-md-10 mx-auto d-flex justify-content-between">
        <div>
            <div>
                <h3>{{summaryPage.addressData| translate}}</h3>
            </div>
            <div *ngIf="address.customerNumber" class="pb-3">
                {{('REGISTRATION.LABELS.customerNumber' | translate) + ': ' + address.customerNumber }}
            </div>
            <div *ngIf="address.company">
                {{address.company}}
            </div>
            <div>
                {{address.street}}
            </div>
            <div>
                {{address.addressExtension}}
            </div>
            <div>
                {{address.zip}} {{address.city}}
            </div>
            <div *ngIf="address.phone" class="pt-3">
                <p>{{'REGISTRATION.SUMMARY_PAGE.phone' | translate}}</p>
                <p>{{address.phone}}</p>
            </div>
        </div>
        <div *ngIf="registrationIncomplete === null || registrationIncomplete === 'false'">
            <button (click)="toggleEdit()" class="btn p-2">
                <i class="fa fa-pencil text-blue"></i>
            </button>
        </div>
    </div>
</div>

<div *ngIf="isEditMode" class="edit-profile row pt-1 bg-light">
    <div class="col-12 col-md-10 mx-auto">
        <sl-address-form class="summary-edit-form" (cancel)="toggleEdit()" (save)="save($event)"
                         [customerType]="customerType"
                         [calledFrom]="RegistrationComponents.SUMMARY"
                         [storedAddressData]="address"></sl-address-form>
    </div>
</div>