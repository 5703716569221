import {
    HttpClient,
    HttpEvent,
    HttpHandler,
    HttpHeaders,
    HttpParameterCodec,
    HttpParams,
    HttpRequest,
    HttpResponse
} from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { concatMap, filter, map } from 'rxjs/operators';
import { ApiResponse } from '../models/api.model';
import { Injectable } from '@angular/core';

/**
 * A Service that extends the HttpRequest class to fit with requests sent to the BE
 */
export class ExtendedHttpRequest<T> extends HttpRequest<T> {
    readonly ignoreUnauthorized:boolean;
    readonly ignoreGlobalErrorHandler:boolean | ((request:ApiResponse<any>) => boolean);
    readonly errorCodeRemapping:{
        [code:string]:string
    };

    /**
     *
     * @param {string} method
     * @param {string} url
     * @param {T | RequestOptionsWBody | null} third
     * @param fourth
     */
    constructor(method:string, readonly url:string, third?:T | RequestOptionsWBody | null, fourth?:{
        headers?:HttpHeaders;
        reportProgress?:boolean;
        params?:HttpParams;
        responseType?:'arraybuffer' | 'blob' | 'json' | 'text';
        withCredentials?:boolean;
        ignoreUnauthorized?:boolean;
        ignoreGlobalErrorHandler?:boolean | ((request:ApiResponse<any>) => boolean);
        errorCodeRemapping?:{
            [code:string]:string
        };
    }) {
        super(method, url, third as any, fourth);

        if (!!fourth) {
            this.ignoreUnauthorized = fourth.ignoreUnauthorized;
            this.ignoreGlobalErrorHandler = fourth.ignoreGlobalErrorHandler;
            this.errorCodeRemapping = fourth.errorCodeRemapping;
        } else if (!!third) {
            this.ignoreUnauthorized = (third as any).ignoreUnauthorized;
            this.ignoreGlobalErrorHandler = (third as any).ignoreGlobalErrorHandler;
            this.errorCodeRemapping = (third as any).errorCodeRemapping;
        }
    }

    clone():ExtendedHttpRequest<T>;

    clone(update:{
        headers?:HttpHeaders;
        reportProgress?:boolean;
        params?:HttpParams;
        responseType?:'arraybuffer' | 'blob' | 'json' | 'text';
        withCredentials?:boolean;
        body?:T | null;
        method?:string;
        url?:string;
        setHeaders?:{
            [name:string]:string | string[];
        };
        setParams?:{
            [param:string]:string;
        };
    }):ExtendedHttpRequest<T>;

    clone<V>(update:{
        headers?:HttpHeaders;
        reportProgress?:boolean;
        params?:HttpParams;
        responseType?:'arraybuffer' | 'blob' | 'json' | 'text';
        withCredentials?:boolean;
        body?:V | null;
        method?:string;
        url?:string;
        setHeaders?:{
            [name:string]:string | string[];
        };
        setParams?:{
            [param:string]:string;
        };
    }):ExtendedHttpRequest<V>;

    clone<V>(update?:{
        headers?:HttpHeaders;
        reportProgress?:boolean;
        params?:HttpParams;
        responseType?:'arraybuffer' | 'blob' | 'json' | 'text';
        withCredentials?:boolean;
        body?:V | null;
        method?:string;
        url?:string;
        setHeaders?:{
            [name:string]:string | string[];
        };
        setParams?:{
            [param:string]:string;
        };
        ignoreUnauthorized?:boolean;
        ignoreGlobalErrorHandler?:boolean | ((request:ApiResponse<any>) => boolean);
        errorCodeRemapping?:{
            [code:string]:string
        };
    }):ExtendedHttpRequest<V>;

    clone(update?:{
        headers?:HttpHeaders;
        reportProgress?:boolean;
        params?:HttpParams;
        responseType?:'arraybuffer' | 'blob' | 'json' | 'text';
        withCredentials?:boolean;
        body?:T | null;
        method?:string;
        url?:string;
        setHeaders?:{
            [name:string]:string | string[];
        };
        setParams?:{
            [param:string]:string;
        };
        ignoreUnauthorized?:boolean;
        ignoreGlobalErrorHandler?:boolean | ((request:ApiResponse<any>) => boolean);
        errorCodeRemapping?:{
            [code:string]:string
        };
    }):ExtendedHttpRequest<T> {
        if (!update) {
            update = {};
        }
        // For method, url, and responseType, take the current value unless
        // it is overridden in the update hash.
        const method = update.method || this.method;
        const url = update.url || this.url;
        const responseType = update.responseType || this.responseType;
        // The body is somewhat special - a `null` value in update.body means
        // whatever current body is present is being overridden with an empty
        // body, whereas an `undefined` value in update.body implies no
        // override.
        const body = (update.body !== undefined) ? update.body : this.body;
        // Carefully handle the boolean options to differentiate between
        // `false` and `undefined` in the update args.
        const withCredentials = (update.withCredentials !== undefined) ? update.withCredentials : this.withCredentials;
        const reportProgress = (update.reportProgress !== undefined) ? update.reportProgress : this.reportProgress;
        // Headers and params may be appended to if `setHeaders` or
        // `setParams` are used.
        let headers = update.headers || this.headers;
        let params = update.params || this.params;
        // Check whether the caller has asked to add headers.
        if (update.setHeaders !== undefined) {
            // Set every requested header.
            headers =
                Object.keys(update.setHeaders)
                    .reduce((headers, name) => headers.set(name, update.setHeaders[name]), headers);
        }
        // Check whether the caller has asked to set params.
        if (update.setParams) {
            // Set every requested param.
            params = Object.keys(update.setParams)
                .reduce((params, param) => params.set(param, update.setParams[param]), params);
        }

        const ignoreUnauthorized = update.ignoreUnauthorized !== undefined ? update.ignoreUnauthorized : this.ignoreUnauthorized;
        const ignoreGlobalErrorHandler = update.ignoreGlobalErrorHandler !== undefined ? update.ignoreGlobalErrorHandler : this.ignoreGlobalErrorHandler;
        const errorCodeRemapping = update.errorCodeRemapping !== undefined ? update.errorCodeRemapping : this.errorCodeRemapping;
        // Finally, construct the new HttpRequest using the pieces from above.
        return new ExtendedHttpRequest(method, url, body, {
            params,
            headers,
            reportProgress,
            responseType,
            withCredentials,
            ignoreUnauthorized,
            ignoreGlobalErrorHandler,
            errorCodeRemapping
        });
    }
}

/**
 * Construct an instance of `HttpRequestOptions<T>` from a source `HttpMethodOptions` and
 * the given `body`. Basically, this clones the object and adds the body.
 */
function addBody<T>(options:RequestOptions, body:T | null):any {
    return {
        body,
        headers: options.headers,
        observe: options.observe,
        params: options.params,
        reportProgress: options.reportProgress,
        responseType: options.responseType,
        withCredentials: options.withCredentials,
        ignoreUnauthorized: options.ignoreUnauthorized,
        ignoreGlobalErrorHandler: options.ignoreGlobalErrorHandler,
        errorCodeRemapping: options.errorCodeRemapping
    };
}

/**
 * RequestOptions interface
 */
export interface RequestOptions {
    headers?:HttpHeaders | {
        [header:string]:string | string[];
    };
    observe?:'body' | 'events' | 'response';
    params?:HttpParams | {
        [param:string]:string | string[];
    };
    reportProgress?:boolean;
    responseType?:'arraybuffer' | 'blob' | 'json' | 'text';
    withCredentials?:boolean;
    ignoreUnauthorized?:boolean;
    ignoreGlobalErrorHandler?:boolean | ((request:ApiResponse<any>) => boolean);
    errorCodeRemapping?:{
        [code:string]:string
    };
}

export declare interface HttpParamsOptions {
    /**
     * String representation of the HTTP parameters in URL-query-string format.
     * Mutually exclusive with `fromObject`.
     */
    fromString?:string;
    /** Object map of the HTTP parameters. Mutually exclusive with `fromString`. */
    fromObject?:{
        [param:string]:string | string[];
    };
    /** Encoding codec used to parse and serialize the parameters. */
    encoder?:HttpParameterCodec;
}

/**
 * Interface for Request Options with Body
 */
export interface RequestOptionsWBody extends RequestOptions {
    body?:any;
}

// TODO: Once angular has support for providing custom parameters, REMOVE THIS.
//  THIS IS A DIRTY HACK TO MAKE IT WORK(by simply copying the whole request method from HttpClient)
@Injectable({
    providedIn: 'root'
})
export class XHttpClient extends HttpClient {
    constructor(private httpHandler:HttpHandler) {
        super(httpHandler);
    }

    // @ts-ignore
    request(first:string | HttpRequest<any>, url?:string, options:RequestOptionsWBody = {}):Observable<any> {
        let req:any;
        // Firstly, check whether the primary argument is an instance of `HttpRequest`.
        if (first instanceof ExtendedHttpRequest) {
            // It is. The other arguments must be undefined (per the signatures) and can be
            // ignored.
            req = first as ExtendedHttpRequest<any>;
        } else if (first instanceof HttpRequest) {
            req = new ExtendedHttpRequest(first.method, first.url, first.body, {
                headers: first.headers,
                params: first.params,
                reportProgress: first.reportProgress,
                // By default, JSON is assumed to be returned for all calls.
                responseType: first.responseType || 'json',
                withCredentials: first.withCredentials
            });
        } else {
            // It's a string, so it represents a URL. Construct a request based on it,
            // and incorporate the remaining arguments (assuming GET unless a method is
            // provided.

            // Figure out the headers.
            let headers:HttpHeaders | undefined;
            if (options.headers instanceof HttpHeaders) {
                headers = options.headers;
            } else {
                headers = new HttpHeaders(options.headers);
            }

            // Sort out parameters.
            let params:HttpParams | undefined;
            if (!!options.params) {
                if (options.params instanceof HttpParams) {
                    params = options.params;
                } else {
                    params = new HttpParams({fromObject: options.params} as HttpParamsOptions);
                }
            }
            req = new ExtendedHttpRequest(first as any, url || null, options.body || null, {
                headers: headers,
                params: params,
                reportProgress: options.reportProgress,
                // By default, JSON is assumed to be returned for all calls.
                responseType: options.responseType || 'json',
                withCredentials: options.withCredentials,
                ignoreUnauthorized: options.ignoreUnauthorized,
                ignoreGlobalErrorHandler: options.ignoreGlobalErrorHandler,
                errorCodeRemapping: options.errorCodeRemapping
            });
        }

        // Start with an Observable.of() the initial request, and run the handler (which
        // includes all interceptors) inside a concatMap(). This way, the handler runs
        // inside an Observable chain, which causes interceptors to be re-run on every
        // subscription (this also makes retries re-run the handler, including interceptors).
        const events$:Observable<HttpEvent<any>> =
            of(req).pipe(concatMap((request:HttpRequest<any>) => this.httpHandler.handle(request)));

        // If coming via the API signature which accepts a previously constructed HttpRequest,
        // the only option is to get the event stream. Otherwise, return the event stream if
        // that is what was requested.
        // TODO: Just to highlight. This instanceof prevents us from calling super.request here.........
        if (first instanceof HttpRequest || options.observe === 'events') {
            return events$;
        }

        // The requested stream contains either the full response or the body. In either
        // case, the first step is to filter the event stream to extract a stream of
        // responses(s).
        const res$:Observable<HttpResponse<any>> = <Observable<HttpResponse<any>>>events$.pipe(
            filter((event:HttpEvent<any>) => event instanceof HttpResponse));

        // Decide which stream to return.
        switch (options.observe || 'body') {
            case 'body':
                // The requested stream is the body. Map the response stream to the response
                // body. This could be done more simply, but a misbehaving interceptor might
                // transform the response body into a different format and ignore the requested
                // responseType. Guard against this by validating that the response is of the
                // requested type.
                switch (req.responseType) {
                    case 'arraybuffer':
                        return res$.pipe(map((res:HttpResponse<any>) => {
                            // Validate that the body is an ArrayBuffer.
                            if (res.body !== null && !(res.body instanceof ArrayBuffer)) {
                                throw new Error('Response is not an ArrayBuffer.');
                            }
                            return res.body;
                        }));
                    case 'blob':
                        return res$.pipe(map((res:HttpResponse<any>) => {
                            // Validate that the body is a Blob.
                            if (res.body !== null && !(res.body instanceof Blob)) {
                                throw new Error('Response is not a Blob.');
                            }
                            return res.body;
                        }));
                    case 'text':
                        return res$.pipe(map((res:HttpResponse<any>) => {
                            // Validate that the body is a string.
                            if (res.body !== null && typeof res.body !== 'string') {
                                throw new Error('Response is not a string.');
                            }
                            return res.body;
                        }));
                    case 'json':
                    default:
                        // No validation needed for JSON responses, as they can be of any type.
                        return res$.pipe(map((res:HttpResponse<any>) => res.body));
                }
            case 'response':
                // The response stream was requested directly, so return it.
                return res$;
            default:
                // Guard against new future observe types being added.
                throw new Error(`Unreachable: unhandled observe type ${options.observe}}`);
        }
    }

    /**
     * Constructs an `Observable` which, when subscribed, will cause the configured
     * DELETE request to be executed on the server. See the individual overloads for
     * details of `delete()`'s return type based on the provided options.
     */
    // @ts-ignore
    delete(url:string, options:RequestOptions = {}):Observable<any> {
        return this.request('DELETE', url, options as any);
    }

    /**
     * Construct a GET request which interprets the body as an `ArrayBuffer` and returns it.
     *
     * @return an `Observable` of the body as an `ArrayBuffer`.
     */
    get(url:string, options:{
        headers?:HttpHeaders;
        observe?:'body';
        params?:HttpParams;
        reportProgress?:boolean;
        responseType:'arraybuffer';
        withCredentials?:boolean;
        ignoreUnauthorized?:boolean;
        ignoreGlobalErrorHandler?:boolean | ((request:ApiResponse<any>) => boolean);
        errorCodeRemapping?:{
            [code:string]:string
        };
    }):Observable<ArrayBuffer>;

    /**
     * Construct a GET request which interprets the body as a `Blob` and returns it.
     *
     * @return an `Observable` of the body as a `Blob`.
     */
    get(url:string, options:{
        headers?:HttpHeaders;
        observe?:'body';
        params?:HttpParams;
        reportProgress?:boolean;
        responseType:'blob';
        withCredentials?:boolean;
        ignoreUnauthorized?:boolean;
        ignoreGlobalErrorHandler?:boolean | ((request:ApiResponse<any>) => boolean);
        errorCodeRemapping?:{
            [code:string]:string
        };
    }):Observable<Blob>;

    /**
     * Construct a GET request which interprets the body as text and returns it.
     *
     * @return an `Observable` of the body as a `string`.
     */
    get(url:string, options:{
        headers?:HttpHeaders;
        observe?:'body';
        params?:HttpParams;
        reportProgress?:boolean;
        responseType:'text';
        withCredentials?:boolean;
        ignoreUnauthorized?:boolean;
        ignoreGlobalErrorHandler?:boolean | ((request:ApiResponse<any>) => boolean);
        errorCodeRemapping?:{
            [code:string]:string
        };
    }):Observable<string>;

    /**
     * Construct a GET request which interprets the body as an `ArrayBuffer` and returns the full event stream.
     *
     * @return an `Observable` of all `HttpEvent`s for the request, with a body type of `ArrayBuffer`.
     */
    get(url:string, options:{
        headers?:HttpHeaders;
        observe:'events';
        params?:HttpParams;
        reportProgress?:boolean;
        responseType:'arraybuffer';
        withCredentials?:boolean;
        ignoreUnauthorized?:boolean;
        ignoreGlobalErrorHandler?:boolean | ((request:ApiResponse<any>) => boolean);
        errorCodeRemapping?:{
            [code:string]:string
        };
    }):Observable<HttpEvent<ArrayBuffer>>;

    /**
     * Construct a GET request which interprets the body as a `Blob` and returns the full event stream.
     *
     * @return an `Observable` of all `HttpEvent`s for the request, with a body type of `Blob`.
     */
    get(url:string, options:{
        headers?:HttpHeaders;
        observe:'events';
        params?:HttpParams;
        reportProgress?:boolean;
        responseType:'blob';
        withCredentials?:boolean;
        ignoreUnauthorized?:boolean;
        ignoreGlobalErrorHandler?:boolean | ((request:ApiResponse<any>) => boolean);
        errorCodeRemapping?:{
            [code:string]:string
        };
    }):Observable<HttpEvent<Blob>>;

    /**
     * Construct a GET request which interprets the body as text and returns the full event stream.
     *
     * @return an `Observable` of all `HttpEvent`s for the request, with a body type of `string`.
     */
    get(url:string, options:{
        headers?:HttpHeaders;
        observe:'events';
        params?:HttpParams;
        reportProgress?:boolean;
        responseType:'text';
        withCredentials?:boolean;
        ignoreUnauthorized?:boolean;
        ignoreGlobalErrorHandler?:boolean | ((request:ApiResponse<any>) => boolean);
        errorCodeRemapping?:{
            [code:string]:string
        };
    }):Observable<HttpEvent<string>>;

    /**
     * Construct a GET request which interprets the body as JSON and returns the full event stream.
     *
     * @return an `Observable` of all `HttpEvent`s for the request, with a body type of `Object`.
     */
    get(url:string, options:{
        headers?:HttpHeaders;
        observe:'events';
        params?:HttpParams;
        reportProgress?:boolean;
        responseType?:'json';
        withCredentials?:boolean;
        ignoreUnauthorized?:boolean;
        ignoreGlobalErrorHandler?:boolean | ((request:ApiResponse<any>) => boolean);
        errorCodeRemapping?:{
            [code:string]:string
        };
    }):Observable<HttpEvent<Object>>;

    /**
     * Construct a GET request which interprets the body as JSON and returns the full event stream.
     *
     * @return an `Observable` of all `HttpEvent`s for the request, with a body type of `T`.
     */
    get<T>(url:string, options:{
        headers?:HttpHeaders;
        observe:'events';
        params?:HttpParams;
        reportProgress?:boolean;
        responseType?:'json';
        withCredentials?:boolean;
        ignoreUnauthorized?:boolean;
        ignoreGlobalErrorHandler?:boolean | ((request:ApiResponse<any>) => boolean);
        errorCodeRemapping?:{
            [code:string]:string
        };
    }):Observable<HttpEvent<T>>;

    /**
     * Construct a GET request which interprets the body as an `ArrayBuffer` and returns the full response.
     *
     * @return an `Observable` of the `HttpResponse` for the request, with a body type of `ArrayBuffer`.
     */
    get(url:string, options:{
        headers?:HttpHeaders;
        observe:'response';
        params?:HttpParams;
        reportProgress?:boolean;
        responseType:'arraybuffer';
        withCredentials?:boolean;
        ignoreUnauthorized?:boolean;
        ignoreGlobalErrorHandler?:boolean | ((request:ApiResponse<any>) => boolean);
        errorCodeRemapping?:{
            [code:string]:string
        };
    }):Observable<HttpResponse<ArrayBuffer>>;

    /**
     * Construct a GET request which interprets the body as a `Blob` and returns the full response.
     *
     * @return an `Observable` of the `HttpResponse` for the request, with a body type of `Blob`.
     */
    get(url:string, options:{
        headers?:HttpHeaders;
        observe:'response';
        params?:HttpParams;
        reportProgress?:boolean;
        responseType:'blob';
        withCredentials?:boolean;
        ignoreUnauthorized?:boolean;
        ignoreGlobalErrorHandler?:boolean | ((request:ApiResponse<any>) => boolean);
        errorCodeRemapping?:{
            [code:string]:string
        };
    }):Observable<HttpResponse<Blob>>;

    /**
     * Construct a GET request which interprets the body as text and returns the full response.
     *
     * @return an `Observable` of the `HttpResponse` for the request, with a body type of `string`.
     */
    get(url:string, options:{
        headers?:HttpHeaders;
        observe:'response';
        params?:HttpParams;
        reportProgress?:boolean;
        responseType:'text';
        withCredentials?:boolean;
        ignoreUnauthorized?:boolean;
        ignoreGlobalErrorHandler?:boolean | ((request:ApiResponse<any>) => boolean);
        errorCodeRemapping?:{
            [code:string]:string
        };
    }):Observable<HttpResponse<string>>;

    /**
     * Construct a GET request which interprets the body as JSON and returns the full response.
     *
     * @return an `Observable` of the `HttpResponse` for the request, with a body type of `Object`.
     */
    get(url:string, options:{
        headers?:HttpHeaders;
        observe:'response';
        params?:HttpParams;
        reportProgress?:boolean;
        responseType?:'json';
        withCredentials?:boolean;
        ignoreUnauthorized?:boolean;
        ignoreGlobalErrorHandler?:boolean | ((request:ApiResponse<any>) => boolean);
        errorCodeRemapping?:{
            [code:string]:string
        };
    }):Observable<HttpResponse<Object>>;

    /**
     * Construct a GET request which interprets the body as JSON and returns the full response.
     *
     * @return an `Observable` of the `HttpResponse` for the request, with a body type of `T`.
     */
    get<T>(url:string, options:{
        headers?:HttpHeaders;
        observe:'response';
        params?:HttpParams;
        reportProgress?:boolean;
        responseType?:'json';
        withCredentials?:boolean;
        ignoreUnauthorized?:boolean;
        ignoreGlobalErrorHandler?:boolean | ((request:ApiResponse<any>) => boolean);
        errorCodeRemapping?:{
            [code:string]:string
        };
    }):Observable<HttpResponse<T>>;

    /**
     * Construct a GET request which interprets the body as JSON and returns it.
     *
     * @return an `Observable` of the body as an `Object`.
     */
    get(url:string, options?:{
        headers?:HttpHeaders;
        observe?:'body';
        params?:HttpParams;
        reportProgress?:boolean;
        responseType?:'json';
        withCredentials?:boolean;
        ignoreUnauthorized?:boolean;
        ignoreGlobalErrorHandler?:boolean | ((request:ApiResponse<any>) => boolean);
        errorCodeRemapping?:{
            [code:string]:string
        };
    }):Observable<Object>;

    /**
     * Construct a GET request which interprets the body as JSON and returns it.
     *
     * @return an `Observable` of the body as type `T`.
     */
    get<T>(url:string, options?:{
        headers?:HttpHeaders;
        observe?:'body';
        params?:HttpParams;
        reportProgress?:boolean;
        responseType?:'json';
        withCredentials?:boolean;
        ignoreUnauthorized?:boolean;
        ignoreGlobalErrorHandler?:boolean | ((request:ApiResponse<any>) => boolean);
        errorCodeRemapping?:{
            [code:string]:string
        };
    }):Observable<T>;

    /**
     * Constructs an `Observable` which, when subscribed, will cause the configured
     * GET request to be executed on the server. See the individual overloads for
     * details of `get()`'s return type based on the provided options.
     */
    get(url:string, options:RequestOptions = {}):Observable<any> {
        return this.request('GET', url, options as any);
    }

    /**
     * Constructs an `Observable` which, when subscribed, will cause the configured
     * HEAD request to be executed on the server. See the individual overloads for
     * details of `head()`'s return type based on the provided options.
     */
    // @ts-ignore
    head(url:string, options:RequestOptions = {}):Observable<any> {
        return this.request('HEAD', url, options as any);
    }

    /**
     * Constructs an `Observable` which, when subscribed, will cause the configured
     * OPTIONS request to be executed on the server. See the individual overloads for
     * details of `options()`'s return type based on the provided options.
     */
    // @ts-ignore
    options(url:string, options:RequestOptions = {}):Observable<any> {
        return this.request('OPTIONS', url, options as any);
    }

    /**
     * Constructs an `Observable` which, when subscribed, will cause the configured
     * PATCH request to be executed on the server. See the individual overloads for
     * details of `patch()`'s return type based on the provided options.
     */
    // @ts-ignore
    patch(url:string, body:any | null, options:RequestOptions = {}):Observable<any> {
        return this.request('PATCH', url, addBody(options, body));
    }

    /**
     * Construct a POST request which interprets the body as an `ArrayBuffer` and returns it.
     *
     * @return an `Observable` of the body as an `ArrayBuffer`.
     */
    post(url:string, body:any | null, options:{
        headers?:HttpHeaders;
        observe?:'body';
        params?:HttpParams;
        reportProgress?:boolean;
        responseType:'arraybuffer';
        withCredentials?:boolean;
        ignoreUnauthorized?:boolean;
        ignoreGlobalErrorHandler?:boolean | ((request:ApiResponse<any>) => boolean);
        errorCodeRemapping?:{
            [code:string]:string
        };
    }):Observable<ArrayBuffer>;
    /**
     * Construct a POST request which interprets the body as a `Blob` and returns it.
     *
     * @return an `Observable` of the body as a `Blob`.
     */
    post(url:string, body:any | null, options:{
        headers?:HttpHeaders;
        observe?:'body';
        params?:HttpParams;
        reportProgress?:boolean;
        responseType:'blob';
        withCredentials?:boolean;
        ignoreUnauthorized?:boolean;
        ignoreGlobalErrorHandler?:boolean | ((request:ApiResponse<any>) => boolean);
        errorCodeRemapping?:{
            [code:string]:string
        };
    }):Observable<Blob>;
    /**
     * Construct a POST request which interprets the body as text and returns it.
     *
     * @return an `Observable` of the body as a `string`.
     */
    post(url:string, body:any | null, options:{
        headers?:HttpHeaders;
        observe?:'body';
        params?:HttpParams;
        reportProgress?:boolean;
        responseType:'text';
        withCredentials?:boolean;
        ignoreUnauthorized?:boolean;
        ignoreGlobalErrorHandler?:boolean | ((request:ApiResponse<any>) => boolean);
        errorCodeRemapping?:{
            [code:string]:string
        };
    }):Observable<string>;
    /**
     * Construct a PATCH request which interprets the body as an `ArrayBuffer` and returns the full event stream.
     *
     * @return an `Observable` of all `HttpEvent`s for the request, with a body type of `ArrayBuffer`.
     */
    post(url:string, body:any | null, options:{
        headers?:HttpHeaders;
        observe:'events';
        params?:HttpParams;
        reportProgress?:boolean;
        responseType:'arraybuffer';
        withCredentials?:boolean;
        ignoreUnauthorized?:boolean;
        ignoreGlobalErrorHandler?:boolean | ((request:ApiResponse<any>) => boolean);
        errorCodeRemapping?:{
            [code:string]:string
        };
    }):Observable<HttpEvent<ArrayBuffer>>;
    /**
     * Construct a POST request which interprets the body as a `Blob` and returns the full event stream.
     *
     * @return an `Observable` of all `HttpEvent`s for the request, with a body type of `Blob`.
     */
    post(url:string, body:any | null, options:{
        headers?:HttpHeaders;
        observe:'events';
        params?:HttpParams;
        reportProgress?:boolean;
        responseType:'blob';
        withCredentials?:boolean;
        ignoreUnauthorized?:boolean;
        ignoreGlobalErrorHandler?:boolean | ((request:ApiResponse<any>) => boolean);
        errorCodeRemapping?:{
            [code:string]:string
        };
    }):Observable<HttpEvent<Blob>>;
    /**
     * Construct a POST request which interprets the body as text and returns the full event stream.
     *
     * @return an `Observable` of all `HttpEvent`s for the request, with a body type of `string`.
     */
    post(url:string, body:any | null, options:{
        headers?:HttpHeaders;
        observe:'events';
        params?:HttpParams;
        reportProgress?:boolean;
        responseType:'text';
        withCredentials?:boolean;
        ignoreUnauthorized?:boolean;
        ignoreGlobalErrorHandler?:boolean | ((request:ApiResponse<any>) => boolean);
        errorCodeRemapping?:{
            [code:string]:string
        };
    }):Observable<HttpEvent<string>>;
    /**
     * Construct a POST request which interprets the body as JSON and returns the full event stream.
     *
     * @return an `Observable` of all `HttpEvent`s for the request, with a body type of `Object`.
     */
    post(url:string, body:any | null, options:{
        headers?:HttpHeaders;
        observe:'events';
        params?:HttpParams;
        reportProgress?:boolean;
        responseType?:'json';
        withCredentials?:boolean;
        ignoreUnauthorized?:boolean;
        ignoreGlobalErrorHandler?:boolean | ((request:ApiResponse<any>) => boolean);
        errorCodeRemapping?:{
            [code:string]:string
        };
    }):Observable<HttpEvent<Object>>;
    /**
     * Construct a POST request which interprets the body as JSON and returns the full event stream.
     *
     * @return an `Observable` of all `HttpEvent`s for the request, with a body type of `T`.
     */
    post<T>(url:string, body:any | null, options:{
        headers?:HttpHeaders;
        observe:'events';
        params?:HttpParams;
        reportProgress?:boolean;
        responseType?:'json';
        withCredentials?:boolean;
        ignoreUnauthorized?:boolean;
        ignoreGlobalErrorHandler?:boolean | ((request:ApiResponse<any>) => boolean);
        errorCodeRemapping?:{
            [code:string]:string
        };
    }):Observable<HttpEvent<T>>;
    /**
     * Construct a POST request which interprets the body as an `ArrayBuffer` and returns the full response.
     *
     * @return an `Observable` of the `HttpResponse` for the request, with a body type of `ArrayBuffer`.
     */
    post(url:string, body:any | null, options:{
        headers?:HttpHeaders;
        observe:'response';
        params?:HttpParams;
        reportProgress?:boolean;
        responseType:'arraybuffer';
        withCredentials?:boolean;
        ignoreUnauthorized?:boolean;
        ignoreGlobalErrorHandler?:boolean | ((request:ApiResponse<any>) => boolean);
        errorCodeRemapping?:{
            [code:string]:string
        };
    }):Observable<HttpResponse<ArrayBuffer>>;
    /**
     * Construct a POST request which interprets the body as a `Blob` and returns the full response.
     *
     * @return an `Observable` of the `HttpResponse` for the request, with a body type of `Blob`.
     */
    post(url:string, body:any | null, options:{
        headers?:HttpHeaders;
        observe:'response';
        params?:HttpParams;
        reportProgress?:boolean;
        responseType:'blob';
        withCredentials?:boolean;
        ignoreUnauthorized?:boolean;
        ignoreGlobalErrorHandler?:boolean | ((request:ApiResponse<any>) => boolean);
        errorCodeRemapping?:{
            [code:string]:string
        };
    }):Observable<HttpResponse<Blob>>;
    /**
     * Construct a POST request which interprets the body as text and returns the full response.
     *
     * @return an `Observable` of the `HttpResponse` for the request, with a body type of `string`.
     */
    post(url:string, body:any | null, options:{
        headers?:HttpHeaders;
        observe:'response';
        params?:HttpParams;
        reportProgress?:boolean;
        responseType:'text';
        withCredentials?:boolean;
        ignoreUnauthorized?:boolean;
        ignoreGlobalErrorHandler?:boolean | ((request:ApiResponse<any>) => boolean);
        errorCodeRemapping?:{
            [code:string]:string
        };
    }):Observable<HttpResponse<string>>;
    /**
     * Construct a POST request which interprets the body as JSON and returns the full response.
     *
     * @return an `Observable` of the `HttpResponse` for the request, with a body type of `Object`.
     */
    post(url:string, body:any | null, options:{
        headers?:HttpHeaders;
        observe:'response';
        params?:HttpParams;
        reportProgress?:boolean;
        responseType?:'json';
        withCredentials?:boolean;
        ignoreUnauthorized?:boolean;
        ignoreGlobalErrorHandler?:boolean | ((request:ApiResponse<any>) => boolean);
        errorCodeRemapping?:{
            [code:string]:string
        };
    }):Observable<HttpResponse<Object>>;
    /**
     * Construct a POST request which interprets the body as JSON and returns the full response.
     *
     * @return an `Observable` of the `HttpResponse` for the request, with a body type of `T`.
     */
    post<T>(url:string, body:any | null, options:{
        headers?:HttpHeaders;
        observe:'response';
        params?:HttpParams;
        reportProgress?:boolean;
        responseType?:'json';
        withCredentials?:boolean;
        ignoreUnauthorized?:boolean;
        ignoreGlobalErrorHandler?:boolean | ((request:ApiResponse<any>) => boolean);
        errorCodeRemapping?:{
            [code:string]:string
        };
    }):Observable<HttpResponse<T>>;
    /**
     * Construct a POST request which interprets the body as JSON and returns it.
     *
     * @return an `Observable` of the body as an `Object`.
     */
    post(url:string, body:any | null, options?:{
        headers?:HttpHeaders;
        observe?:'body';
        params?:HttpParams;
        reportProgress?:boolean;
        responseType?:'json';
        withCredentials?:boolean;
        ignoreUnauthorized?:boolean;
        ignoreGlobalErrorHandler?:boolean | ((request:ApiResponse<any>) => boolean);
        errorCodeRemapping?:{
            [code:string]:string
        };
    }):Observable<Object>;
    /**
     * Construct a POST request which interprets the body as JSON and returns it.
     *
     * @return an `Observable` of the body as type `T`.
     */
    post<T>(url:string, body:any | null, options?:{
        headers?:HttpHeaders;
        observe?:'body';
        params?:HttpParams;
        reportProgress?:boolean;
        responseType?:'json';
        withCredentials?:boolean;
        ignoreUnauthorized?:boolean;
        ignoreGlobalErrorHandler?:boolean | ((request:ApiResponse<any>) => boolean);
        errorCodeRemapping?:{
            [code:string]:string
        };
    }):Observable<T>;

    /**
     * Constructs an `Observable` which, when subscribed, will cause the configured
     * POST request to be executed on the server. See the individual overloads for
     * details of `post()`'s return type based on the provided options.
     */
    post(url:string, body:any | null, options:RequestOptions = {}):Observable<any> {
        return this.request('POST', url, addBody(options, body));
    }

    /**
     * Constructs an `Observable` which, when subscribed, will cause the configured
     * POST request to be executed on the server. See the individual overloads for
     * details of `post()`'s return type based on the provided options.
     */
    // @ts-ignore
    put(url:string, body:any | null, options:RequestOptions = {}):Observable<any> {
        return this.request('PUT', url, addBody(options, body));
    }
}
