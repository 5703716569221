<ng-template #surveyModal let-modal let-c="close" let-d="dismiss">
    <div class="modal-header">
        <h1 class="modal-title" id="modal-basic-title">{{'FEEDBACK.title' | translate}}</h1>
        <button type="button" class="close" aria-label="Close" (click)="d('close')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    
    <div class="modal-body">
        <ng-container *ngIf="feedbackSent; else feedbackForm">
            <div class="modal-body">
                <p class="subline">{{'FEEDBACK.thankYou' | translate}}</p>
                <div class="form-row my-4 pt-5">
                    <div class="col-12 d-flex justify-content-end">
                        <div>
                            <button class="btn btn-mobile-block btn-primary" (click)="c('end')">
                                {{'FEEDBACK.close' | translate}}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </ng-container>
        <ng-template #feedbackForm>
            <p class="subline">{{'FEEDBACK.text' | translate}}</p>
            <form [formGroup]="surveyForm">
                <!-- RATING -->
                <div class="form-row align-items-end mt-5 mb-2">
                    <div class="col-12">
                        <h4 class="font-weight-bold">{{'FEEDBACK.experience' | translate}}</h4>
                    </div>
                    <div class="col-12 col-md-10 mx-auto d-flex justify-content-between pt-2">
                        <div class="form-check form-check-inline">
                            <input type="radio" name="rating" id="rating8"
                                   formControlName="rating" value="1">
                        </div>
                        <div class="form-check form-check-inline">
                            <input type="radio" name="rating" id="rating7"
                                   formControlName="rating" value="2">
                        </div>
                        <div class="form-check form-check-inline">
                            <input type="radio" name="rating" id="rating6"
                                   formControlName="rating" value="3">
                        </div>
                        <div class="form-check form-check-inline">
                            <input type="radio" name="rating" id="rating5"
                                   formControlName="rating" value="4">
                        </div>
                        <div class="form-check form-check-inline">
                            <input type="radio" name="rating" id="rating4"
                                   formControlName="rating" value="5">
                        </div>
                        <div class="form-check form-check-inline">
                            <input type="radio" name="rating" id="rating3"
                                   formControlName="rating" value="6">
                        </div>
                        <div class="form-check form-check-inline">
                            <input type="radio" name="rating" id="rating2"
                                   formControlName="rating" value="7">
                        </div>
                        <div class="form-check form-check-inline">
                            <input type="radio" name="rating" id="rating1"
                                   formControlName="rating" value="8">
                        </div>
                    </div>
                    <div class="col-12 col-md-10 mx-auto d-flex justify-content-between pt-3">
                        <p>{{'FEEDBACK.veryDissatisfied' | translate}}</p>
                        <p class="pr-3 d-flex justify-content-end">{{'FEEDBACK.verySatisfied' | translate}}</p>
                    </div>
                </div>
                <!-- MESSAGE -->
                <div class="form-row align-items-end mt-5 mb-2">
                    <div class="col-12">
                        <h4 class="font-weight-bold">{{'FEEDBACK.improvements' | translate}}</h4>
                    </div>
                    <div class="col-12 col-md-10 mx-auto pt-3">
                            <textarea class="form-control form-control-lg"
                                      id="message"
                                      placeholder=""
                                      formControlName="message"
                                      [ngClass]="{'is-invalid' : surveyForm.controls['message'].errors}"></textarea>
          </div>
          <div *ngIf="surveyForm.controls['message'].errors"
               class="invalid-feedback pl-2 d-block">
            {{'FEEDBACK.VALIDATION_ERROR.maxLength' | translate}}
          </div>
        </div>
        <!-- BUTTONS -->
        <div class="form-row my-4 pt-5  d-md-flex justify-content-md-between">
          <div class="col-12 col-md-6 order-1 order-md-0 pt-3 pt-md-0">
            <div class="d-flex justify-content-end justify-content-md-start">
              <button class="btn rp-btn-link-black" (click)="c('cancel')"><i
                      class="fa fa-times mr-2"></i>{{'FEEDBACK.close' | translate}}
              </button>
            </div>
          </div>
          <div class="col-12 col-md-6 order-0 order-md-1">
            <div class="d-flex justify-content-end">
              <sl-busy-btn
                      [action]="feedbackAction"
                      [type]="'submit'">
                <span>{{'FEEDBACK.sendFeedback' | translate}}</span>
              </sl-busy-btn>
            </div>
          </div>
        </div>
      </form>
    </ng-template>
  </div>
</ng-template>
