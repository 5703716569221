<div class="container" *ngIf="!hideContent">
    <div class="col-12 col-md-10 mx-auto my-3">
        <div class="row">
            <button (click)="navigateBack()" class="mt-2 btn btn-light ml-0 ml-md-n3 p-0 font-weight-normal">
                <i class="fa mr-2 text-size-normal fa-arrow-left"></i>
                {{'RECLAMATION_START.DETECTOR_RECLAMATION.backLinkText' | translate}}
            </button>
        </div>
        <div *ngIf="isWarrantyCase" class="row py-3">
            <sl-info-banner class="w-100" [bannerType]="InfoBannerType.INFO"
                            [textKey]="'PRODUCTS.IDENTIFICATION.WARRANTY.infoText'"></sl-info-banner>
        </div>
        <div #infoBanner *ngIf="showBanner" class="row py-3">
            <sl-info-banner class="w-100" [bannerType]="bannerType" [textKey]="bannerTextKey"></sl-info-banner>
        </div>
        <div class="row my-3">
            <h1>{{'PRODUCTS.IDENTIFICATION.title' | translate}}</h1>
            <p class="lead">
                {{'PRODUCTS.IDENTIFICATION.lead' | translate}}
            </p>
        </div>

        <ng-container *ngIf="customerType && dropDownValues" class="col-12 col-md-10 mx-auto">
            <sl-serial-entry-form class="w-100" [dropDownValues]="dropDownValues | async"
                                  [products]="products"></sl-serial-entry-form>

            <!--OBJECT NAME TYPE NUMBER-->
            <sl-product-form-object-group
                    [searchOff]="false"
                    [customerType]="customerType"
                    [address]="address"
                    [product]="firstProduct"
                    [formSelectionValues]="dropDownValues | async">
            </sl-product-form-object-group>
        </ng-container>
        <div class="row my-3">
            <sl-busy-btn [action]="checkSerialsAndProceed" [tabindex]="0" [type]="'submit'">
                {{'PRODUCTS.IDENTIFICATION.BUTTONS.checkAndProceed' | translate}}
            </sl-busy-btn>
        </div>
    </div>
</div>
