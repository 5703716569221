<div class="row mb-4">
  <div class="col-3 col-sm-2 mb-3">
    <div class="icon">
      <img [src]="imageSrc(titleDescIcon[2])" [alt]="titleDescIcon[2]">
    </div>
  </div>
  <div class="col-9 col-sm-10 mb-2">
    <b class="title d-block">
        {{ titleDescIcon[0] | translate}}
    </b>
    <span>
      {{ titleDescIcon[1] | translate}}
    </span>
  </div>
</div>
