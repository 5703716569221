<button (click)="scannerService.startScanner(scannerModal)"
    class="btn btn-secondary btn-qr d-flex flex-column justify-content-center align-items-center px-1">
    <i class="fa fa-qrcode pr-1 text-white"></i>
    <span>{{'PRODUCTS.IDENTIFICATION.BUTTONS.qr' | translate}}</span>
</button>

<ng-template #scannerModal let-modal>
    <div class="modal-header mr-2 pt-2 px-0">
        <button type="button" class="close" aria-describedby="modal-title" (click)="modal.close()"
            style="outline:none;">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body mt-1 py-0 px-4">
        <sl-scanner (scannedSuccesful)="scannerService.onScannerSuccess($event, serialEntryControl)"
            (endScanner)="scannerService.onEndScanner()"></sl-scanner>
    </div>
</ng-template>
