import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {Observable} from 'rxjs';
import {CustomerType, productActions, State, UserService} from 'shared-lib';
import {Store} from '@ngrx/store';

export class MenuItem {
    translation: string;
    route: string;
}

/**
 * Header Component that has menu
 */
@Component({
    selector: 'rp-header',
    templateUrl: './header.component.html',
    styles: [],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class HeaderComponent implements OnInit {

    @Input() loggedIn: boolean;
    @Input() guest: boolean;
    @Input() username: string;
    @Input() menuItems: Observable<MenuItem[]>;
    @Input() notice: string;

    @Input() isShown: boolean;

    /**
     *
     * @param {Router} router
     * @param {UserService} userService
     * @param {NgbModal} modalService
     * @param {ChangeDetectorRef} CD
     * @param {Store<State>} store
     */
    constructor(private router: Router,
                private userService: UserService,
                private modalService: NgbModal,
                private CD: ChangeDetectorRef,
                private store: Store<State>) {
    }

    /**
     * @ignore
     */
    ngOnInit() {
    }

    /**
     * Navigation
     * @param destination
     */
    public onRouteTo(destination: string) {
        if (destination === '/produkte/start'
            || (this.userService.currentUserStatusValue.customerType === CustomerType.Private
                && destination === '/produkte/auswahl-reklamation')) {
            this.store.dispatch(new productActions.DeleteAllProductAction());
        }
        this.router.navigate([destination]);

    }

    /**
     * Log the user out
     */
    public onLogout() {
        this.userService.logout().subscribe(() => {
            this.router.navigate(['/']);
            this.loggedIn = false;
            this.isShown = false;
            this.CD.markForCheck();
        });
    }
}
