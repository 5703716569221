import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { EMPTY, Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, switchMap } from 'rxjs/operators';

import { SearchType } from '../components/search-slot/search-slot.component';
import { MetaObject, UtilsService } from './utils.service';
import { ApiResponse } from '../models/api.model';
import { ObjectInformationDetailed } from '../models/product.model';
import { environment } from '../../../../environments/environment';

/**
 * Service to help with the Search Slot Component
 */
@Injectable({
    providedIn: 'root'
})
export class SearchService {

    /**
     *
     * @param {UtilsService} Utils
     * @param {HttpClient} Http
     */
    constructor(private Utils:UtilsService,
                private Http:HttpClient) {
    }

    /**
     * Observable for when the user searches
     *
     * @param terms
     * @param category
     * @param userId
     * @param debounceDuration
     */
    public slotSearch(terms:Observable<string>, category:SearchType, userId?:string, debounceDuration = 400):Observable<any[]> {
        return terms.pipe(
            filter(term => term !== null),
            debounceTime(debounceDuration),
            distinctUntilChanged(),
            switchMap(term => this.rawSearch(term, category, userId))
        );
    }

    /**
     * Return either an empty list or the user object list return from BE
     *
     * @param term
     * @param category
     * @param userId
     */
    private rawSearch(term:string, category:SearchType, userId?:string):Observable<any[]> {
        if (term?.length <= 0) {
            return EMPTY;
        }
        if (category === 'userObject') {
            return this.getUserObjectList(term, userId);
        }
    }

    /**
     * Get the User Object List from BE
     *
     * @param searchTerm
     * @param userId
     */
    private getUserObjectList(searchTerm:string, userId?:string):Observable<ObjectInformationDetailed[] & MetaObject> {
        const params:HttpParams = new HttpParams().set('q', searchTerm);
        return this.Utils.stepIntoData(
            this.Http.get<ApiResponse<ObjectInformationDetailed[]>>(
                userId ? this.Utils.replace(environment.api.user.supportObject, userId) : this.Utils.replace(environment.api.user.object), {params})
        );
    }
}
