import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, CanLoad, Route, Router, UrlSegment } from '@angular/router';
import { Observable } from 'rxjs';
import { UserService } from 'shared-lib';

/**
 * Finds if the user is logged in or not and whether they can access certain parts of the site or not
 * @alias LoginAuthGuard
 */
@Injectable()
export class LoginAuthGuard implements CanActivate, CanActivateChild, CanLoad {

    public static readonly DEFAULT_LOGGED_IN_ROUTE:string = '/produkte';
    public static readonly DEFAULT_PRIVATE_LOGGED_IN_ROUTE:string = '/produkte/auswahl-reklamation';
    public static readonly DEFAULT_LOGGED_OUT_ROUTE:string = '';
    public static readonly PASSWORD_RESET_ROUTE:string = 'password-reset';
    public static readonly SHOP_CLOSED:string = 'reclamation';
    public static readonly COMPLETE_REGISTRATION:string = '/registration/geschaeftskunden-typ';

    /**
     * @param {Router} router
     * @param {UserService} userService
     */
    constructor(private router:Router, private userService:UserService) {
    }

    /**
     * Verifies that a user is logged in and returns true otherwise redirect to login/register page and return false.
     *
     * @alias canActivate
     * @param {ActivatedRouteSnapshot} route Current route
     * @return {boolean} See description
     */
    canActivate(route:ActivatedRouteSnapshot):Observable<boolean> | boolean {
        return this.userService.checkUserStatus(null, false);
    }

    /**
     * Verifies that a user is logged in and returns true otherwise redirect to login/register page and return false.
     *
     * @alias canActivateChild
     * @param {ActivatedRouteSnapshot} route
     * @return Observable<boolean> | boolean
     */
    canActivateChild(route:ActivatedRouteSnapshot):Observable<boolean> | boolean {
        return this.userService.checkUserStatus(null, false);
    }

    /**
     * Decides if the user is allowed to LOAD a specific route.
     * For some reason, if we decided to redirect to a different route(e.g. /produkte) THIS function is still called.
     * In such a case route.path is still EMPTY, but segments has the correct URL. This is why this function relies on that.
     *
     * @alias canLoad
     * @param {Route} route
     * @param {UrlSegment[]} segments
     */
    canLoad(route: Route, segments: UrlSegment[]): Observable<boolean> | boolean {
        const isRequestToLogin: boolean = !segments || !segments.length;
        if (['registration', 'start'].includes(segments[0]?.path)) {
            return this.userService.checkUserStatus(LoginAuthGuard.DEFAULT_LOGGED_IN_ROUTE, false);
        } else if (isRequestToLogin) {
            return this.userService.checkUserStatus(LoginAuthGuard.DEFAULT_LOGGED_IN_ROUTE, !isRequestToLogin);
        } else {
            if (segments[0].path === LoginAuthGuard.PASSWORD_RESET_ROUTE) {
                return this.userService.checkUserStatus(LoginAuthGuard.PASSWORD_RESET_ROUTE, isRequestToLogin);
            } else if(segments[0].path.includes(LoginAuthGuard.SHOP_CLOSED)) {
                return this.userService.checkUserStatus(LoginAuthGuard.SHOP_CLOSED, isRequestToLogin);
            }
            return this.userService.checkUserStatus(LoginAuthGuard.DEFAULT_LOGGED_OUT_ROUTE, !isRequestToLogin);
        }
    }
}
