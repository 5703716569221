export abstract class LangCallSimplifier {
  public readonly footerRootLang = 'GENERAL.FOOTER_PAYMENT_METHODS.';
  public readonly stepsInfoRootLang = 'REGISTRATION.STEPS_INFO.';
  public readonly startPageRootLang = 'REGISTRATION.START_PAGE.';
  public readonly summaryPageRootLang = 'REGISTRATION.SUMMARY_PAGE.';
  public readonly privateCustAccCreationRootLang = 'REGISTRATION.PRIVATE_CUST_ACC_CREATION.';
  public readonly privateCustAddressCreationRootLang = 'REGISTRATION.PRIV_CUS_ADDRESS_CREATION.';
  public readonly Login = 'REGISTRATION.LOGIN.';

}
export class FooterLang extends LangCallSimplifier {
  constructor() {
    super();
  }
  public readonly footer = {
    title: this.footerRootLang + 'title',
    invoice: this.footerRootLang + 'invoice',
    sofort: this.footerRootLang + 'sofort',
    paypal: this.footerRootLang + 'paypal',
    master: this.footerRootLang + 'master'
  };
}

export class SummaryPageLang extends LangCallSimplifier {
  constructor() {
    super();
  }
  public readonly summaryPage = {
    heading: this.summaryPageRootLang + 'title',
    description: this.summaryPageRootLang + 'description',
    userProfile: this.summaryPageRootLang + 'user_profile',
    userProfileEdit: this.summaryPageRootLang + 'user_profile_edit',
    addressData: this.summaryPageRootLang + 'address_data',
    smallText: this.summaryPageRootLang + 'small_text',
    dataProtection: this.summaryPageRootLang + 'data_protection',
    iHaveReadCheck: this.summaryPageRootLang + 'i_have_read_check',
    underlinedRead: this.summaryPageRootLang + 'underlined_read',
    button: this.summaryPageRootLang + 'button',
    button_NO_LICENSE: this.summaryPageRootLang + 'button_NO_LICENSE'
  };

}
export class PrivateAddressLang extends LangCallSimplifier {
  constructor() {
    super();
  }
  public readonly privateCustAddCreation = {
    userProfileEdit: this.summaryPageRootLang + 'user_profile_edit',
    nextButton: this.privateCustAccCreationRootLang + 'nextButton',
    saveButton: this.privateCustAccCreationRootLang + 'saveButton',
    cancelButton: this.privateCustAccCreationRootLang + 'cancelButton',
    emailValidation: this.privateCustAccCreationRootLang + 'emailValidation',
    passwordValidationMain: this.privateCustAccCreationRootLang + 'passwordValidationMain',
    passwordValidation8: this.privateCustAccCreationRootLang + 'passwordValidation8',
    passwordValidationUpper: this.privateCustAccCreationRootLang + 'passwordValidationUpper',
    passwordValidationLower: this.privateCustAccCreationRootLang + 'passwordValidationLower',
    passwordValidationSpecial: this.privateCustAccCreationRootLang + 'passwordValidationSpecial',
    passwordValidationNum: this.privateCustAccCreationRootLang + 'passwordValidationNum',
  };

}

export class PrivateAccountLang extends LangCallSimplifier {
  constructor() {
    super();
  }
  public readonly privateCustAccCreation = {
    info: this.privateCustAccCreationRootLang + 'info',
    header: this.privateCustAccCreationRootLang + 'header',
    description: this.privateCustAccCreationRootLang + 'description',
    detail1: this.privateCustAccCreationRootLang + 'detail1',
    detail2: this.privateCustAccCreationRootLang + 'detail2',
    detail3: this.privateCustAccCreationRootLang + 'detail3',
    emailValidation: this.privateCustAccCreationRootLang + 'emailValidation',
    passwordValidationMain: this.privateCustAccCreationRootLang + 'passwordValidationMain',
    passwordValidation8: this.privateCustAccCreationRootLang + 'passwordValidation8',
    passwordValidationUpper: this.privateCustAccCreationRootLang + 'passwordValidationUpper',
    passwordValidationLower: this.privateCustAccCreationRootLang + 'passwordValidationLower',
    passwordValidationSpecial: this.privateCustAccCreationRootLang + 'passwordValidationSpecial',
    passwordValidationNum: this.privateCustAccCreationRootLang + 'passwordValidationNum',
    nextButton: this.privateCustAccCreationRootLang + 'nextButton',
    saveButton: this.privateCustAccCreationRootLang + 'saveButton',
    cancelButton: this.privateCustAccCreationRootLang + 'cancelButton'
  };
}
export class StartPageLang extends LangCallSimplifier {
  constructor() {
    super();
  }
  public readonly startPage = {
    heading: this.startPageRootLang + 'heading',
    description: this.startPageRootLang + 'description',
    chooseText: this.startPageRootLang + 'choose_text',
    note: this.startPageRootLang + 'note',
    noteText: this.startPageRootLang + 'note_text',
    tileBusiness: {
      heading: this.startPageRootLang + 'tileBusiness.heading',
      description: this.startPageRootLang + 'tileBusiness.description'
    },
    tilePrivate: {
      heading: this.startPageRootLang + 'tilePrivate.heading',
      description: this.startPageRootLang + 'tilePrivate.description'
    }
  };
}

export class StepsInfoLang extends StartPageLang {
  constructor() {
    super();
  }
  public readonly stepsInfo = {
    createAccount: this.stepsInfoRootLang + 'create_account',
    recordDeftDetect: this.stepsInfoRootLang + 'record_defective_detectors',
    determCompens: this.stepsInfoRootLang + 'determine_compensation',
    exchangeReceived: this.stepsInfoRootLang + 'exchange_received'
  };
}
export class LoginPageLang extends LangCallSimplifier {
  constructor() {
    super();
  }
  public readonly LoginPage = {
    heading: this.Login + 'title',
    description: this.Login + 'description',
    noAccount: this.Login + 'noAccount',
    register: this.Login + 'register',
    forgotPass: this.Login + 'forgotPass',
    loginButton: this.Login + 'loginButton',
    small_text: this.Login + 'small_text',
    privacy_policy: this.Login + 'privacy_policy',
    terms_of_use: this.Login + 'terms_of_use',
    email_not_verified: this.Login + 'email_not_verified',
    email_error: this.Login + 'email_error',
    password_error: this.Login + 'password_error',
    not_authorized: this.Login + 'not_authorized',
    login_failed: this.Login + 'login_failed',
    insert_email: this.Login + 'insert_email',
    insert_password: this.Login + 'insert_password',
    title_after_register: this.Login + 'title_after_register'
  };
}

export enum SVGIconNames {
  DETECTORS_CLAIM = 'ic-detectors-claim',
  DETECTORS_SELECT = 'ic-detectors-select',
  DETECTORS = 'ic-detectors',
  MAIL_CONFIRM = 'ic-mail-confirm',
  OPTIONS = 'ic-options',
  USER_ACCOUNT = 'ic-user-account',
  USER_DELIVERY = 'ic-user-delivery',
  USER_SUPPORT = 'ic-user-support',
  LOCATION = 'assets/icons/',
  FILE_TYPE = '.svg'
}
export enum JPGIconNames {
  PLACE_HOLDER = 'placeholder-img',
  LOCATION = 'assets/',
  FILE_TYPE = '.jpg'
}

export enum PaymentIcons {
  KLARNA = 'klarna',
  MASTER_CARD = 'mastercard',
  PAYPAL = 'paypal',
  INVOICE = 'invoice',
  LOCATION = 'assets/logo/payment/',
  FILE_TYPE = '.svg'
}

export enum GeniusPlusOfferImages {
  LOCATION = 'assets/genius-plus/',
  BANNER = 'Banner_Genius_Port_Empfehlung.png',
  AMAZON = 'amazon-logo.jpeg',
  CONRAD = 'conrad-logo.png'
 }

export enum RegistrationComponents {
  SUMMARY = 'summary',
  ACCOUNT = 'account',
  ADDRESS = 'address',
  COMPLETION = 'completion',
  ACC_INFO = 'acc_info', 
  CREATE_SUPPORT_USER = 'create_support_user',
  EDIT_SUPPORT_USER = 'edit_support_user'
}

export enum ProductReclamationComponents {
  SUMMARY = 'summary',
  IDENTIFY = 'identify',
  COMPLAINT_SELECTION = 'complaint-selection',
}


export type TitleDescIcon = [string, string, SVGIconNames | string]; //Title, Description, Iconname
export type TitleIcon = [string, SVGIconNames];

export interface IconTitleDescriptionClass extends imageSrc {
  titleDescIcon: TitleDescIcon;
}

export interface IconTitleClass extends imageSrc {
  titleIcon: TitleIcon;
}

export interface imageSrc {
  imageSrc(icon: string): string;
}

export interface IconTextClass extends imageSrc {
  titleDescIcon: [string, string, string];
}

export interface CheckboxOption {
  id: string;
  value: string | boolean;
  labelText: string;
}

export interface SystemStatus {
  guestReclamationAvailable?:boolean;
}

export abstract class PaymentLogoLang {
  public readonly rootLang = 'GENERAL.FOOTER_PAYMENT_METHODS.';
  public readonly title = this.rootLang + 'title';
  public readonly invoice = this.rootLang + 'invoice';
  public readonly sofort = this.rootLang + 'sofort';
  public readonly paypal = this.rootLang + 'paypal';
  public readonly master = this.rootLang + 'master';
}

export enum InputType {
  TEXT = 'text',
  DROPDOWN = 'dropdown',
  TEXTAREA = 'textarea'
}

export interface ModalData {
  title: string;
  dismissButtonText?: string;
  acceptButtonText?: string;
  message?: string;
}


export enum RoutePathName {
  DEFAULT = '',
  RECLAMATION_DETAIL = ':id',
  RECLAMATION_SERIALS = 'seriennummern/:id',
  RECORDED_SERIALS = 'aufgezeichnete-seriennummern/:id',
  LATE_RECLAMATION_CONFIRMATION = 'rueckmeldung-seriennummern-bestaetigung/:id',
  USER_DETAIL = 'details/:id',
  RECLAMATIONS = 'reclamations',
  RECLAMATION_LIST = 'reclamations/:id',
  RECLAMATION_LIST_DETAIL = 'reclamationsdetail/:id',
  SERVICE_MESSAGE = 'servicemeldungen',
  ACCOUNT_MANAGEMENT = 'accountverwaltung',
  CREATE_USER = 'create-user',
  REGISTRATION_SUMMARY = 'summary',
  EMAIL_CONFIRMATION = 'email-confirmation',
  REGISTRATION = 'registration',
  PASSWORD_RESET = 'password-reset',
  USER = 'user',
  COMPLAINTS = 'complaints',
  ADMIN_LIST = 'admin-list',
  ADMIN_USER_DETAILS = 'admin-user-details/:id',
  SUPPORT_USER_CREATE = 'support-user-create',
  ADDRESS = 'anschrift',
  PRODUCT = 'produkte',
  COMPLAIN_WITH_NO_USER_ACCOUNT = 'no-user-acc-complain',
  SUBMISSION = 'einreichung',
  COMPLETE_RECLAIM = 'reclamation/complete',
  EMPTY_LOGOUT = "empty_logout",
  START = 'start',
  SELECTION_COMPLAINT = 'auswahl-reklamation',
  SERIAL_NUMBER_RETRIEVAL_ORDER = 'seriennummern-abrufauftrag',
  IDENTIFICATION = 'identifikation',
  SUMMARY = 'zusammenfassung',
  SIMPLIFIED_IDENTIFICATION = 'identifizierung-vereinfacht',
  CONFIRMATION = 'bestaetigung',
  WARRANTY_IDENTIFICATION = 'garantie-identifikation',
  WARRANTY_SUMMARY = 'garantie-zusammenfassung',
  WARRANTY_CONFIRMATION = 'garantie',
  CONTACT_CONFIRMATION = 'kontakt',
  BUSINESS_USER_TYPE = 'geschaeftskunden-typ',
  BUSINESS_LICENSE = 'lizenz',
  LOGIN = 'login',
  SALES_CHANNEL = 'kundengruppe',
  START_PRODUCT = 'produkte/start',
  CONTACT_SUPPORT = "kontakt-support",
  IDENTIFY_PRODUCT = "produkte/identifikation",
  RETRIEVAL_ORDER_SUMMARY = 'zusammenfassung-seriennummern-abrufauftrag',
  RETRIEVAL_ORDER_CONFIRMATION = 'bestaetigung-seriennummern-abrufauftrag',
  RETURNABLES = 'rücksendepflichtige',
  BANNER_NOTIFICATION = 'anzeige-störer'
}
