<div class="col-12 col-md-10 mx-auto" >
    <!-- BACK BUTTON -->
    <div class="row my-3">  
        <div class="col-6 pl-0">
            <sl-back-navigation
                [buttonText]="'GENERAL.BUTTONS.prevStep' | translate"></sl-back-navigation>
        </div>
    </div>
    <!-- END BACK BUTTON -->

        <!-- TITLE AND BANNERS -->
        <div class="row my-3">
            <h1>{{'PRODUCTS.EVALUATION.title' | translate}}</h1>
            <sl-info-banner  #infoBanner class="w-100 mt-2 mb-4" [boldTextKey]="infoBannerBoldTextKey"
                [bannerType]="infoBannerType" [textKey]="infoBannerTextKey">
            </sl-info-banner>
            <p class="lead" [innerHtml]="'PRODUCTS.EVALUATION.descriptionParagOne' | translate"></p>
            <p class="lead" [innerHtml]="'PRODUCTS.EVALUATION.weCant' | translate"></p>
            <p class="lead mt-3" [innerHtml]="'PRODUCTS.EVALUATION.ifAnyQuestion' | translate"></p>
        </div>
        <!-- END TITLE AND BANNERS -->

        <sl-contact [contactTitle]="false"></sl-contact>

        <!-- BUTTON-GROUP -->
            <div class="row pb-4 mt-3">
                <button class="btn btn-secondary btn-mobile-block mr-2 mb-2 mb-sm-0" (click)="userReclamations()">
                    {{'PRODUCTS.LATE_RECLAMATION_CONFIRMATION.BUTTONS.myReclamations'| translate}}
                </button>
                <button class="btn btn-tertiary btn-mobile-block mr-2" (click)="newReclamation()">
                    {{'PRODUCTS.LATE_RECLAMATION_CONFIRMATION.BUTTONS.newReclamtion' | translate}}
                </button>
            </div>
        <!-- END BUTTON-GROUP -->
</div>
