<div class="col-12 col-md-10 mx-auto">
    <div class="row mt-5 pl-1">
        <h1>{{'PASSWORD_RESET.title' | translate}}</h1>
    </div>
    <div class="row pl-1">
        <p class="subline">
            {{'PASSWORD_RESET.text' | translate}}
        </p>
    </div>
    <div *ngIf="showBanner" class="row py-3">
               <sl-info-banner class="w-100" [bannerType]="bannerType" [textKey]="bannerTextKey"></sl-info-banner>
           </div>
</div>
<form [formGroup]="passwordResetForm">
    <div class="row py-2">
        <div class="col-12 col-md-10 mx-auto">
            <rp-password (patchMainForm)="onPatchMainForm($event)" [formGroup]="passwordResetForm"
                         [submitted]="submitted"></rp-password>
        </div>
    </div>
    <div class="row mb-2">
        <div class="col-12 col-md-10 mx-auto">
            <sl-busy-btn [action]="passwordResetAction"
                         [type]="'submit'">
                <span>{{'PASSWORD_RESET.save' | translate}}</span>
            </sl-busy-btn>
        </div>
    </div>
</form>
