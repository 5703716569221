<ng-template #offerModal let-modal let-c="close" let-d="dismiss">
    <div class=" bg-secondary">
        <div class="modal-header p-0">
            <button type="button" class="banner-close close" aria-label="Close" (click)="d('close')">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body banner-body text-white p-0">
            <img [src]="GeniusPlusOfferImages.LOCATION + GeniusPlusOfferImages.BANNER" class="modal-banner" alt="banner-logo">
            <div class="row pt-3">
                <div class="col-10 col-lg-8 mx-auto">
                    <h3 class="text-white py-3">
                        {{ 'GENIUS_PLUS_OFFER.title'  | translate }}
                    </h3>
                    <span class="pb-3">{{ 'GENIUS_PLUS_OFFER.body'  | translate }}</span>
                    <h3 class="text-white pb-0 pt-3">{{ 'GENIUS_PLUS_OFFER.bottomText'  | translate }}</h3>
                </div>
            </div>
            <div class="row pt-3">
                <div class="col-10 col-lg-8 mx-auto pb-3">
                    <button class="btn banner-button mr-2 mr-lg-3 px-4 px-sm-5 py-1 py-sm-2" (click)="navigateToAmazon()">
                        <img [src]="GeniusPlusOfferImages.LOCATION + GeniusPlusOfferImages.AMAZON" alt="amazon-link" class="px-3">
                    </button>
                    <button class="btn banner-button px-4 px-sm-5 py-1 py-sm-2"(click)="navigateToConrad()">
                        <img [src]="GeniusPlusOfferImages.LOCATION + GeniusPlusOfferImages.CONRAD" alt="conrad-link" class="px-2 px-md-3">
                    </button>
                </div>
            </div>
        </div>
    </div>
</ng-template>