import { ChangeDetectionStrategy, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, of, Subject } from 'rxjs';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { Router } from '@angular/router';
import {
    DistinguishSupportService,
    CustomerType,
    FormDataService,
    FormInputType,
    InfoBannerType,
    RegisterAccount,
    RegisterAddress,
    registerReducer,
    RegistrationComponents,
    User,
    UserService,
    UtilsService,
    registerActions,
    ExternalData,
    BusinessCustomerType, SummaryPageLang
} from '../../../shared';
import { State } from '../../../../reducers/root/root.reducer';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'sl-registration-summary',
    templateUrl: './registration-summary.component.html',
    styleUrls: [],
    changeDetection: ChangeDetectionStrategy.OnPush
})

export class RegistrationSummaryComponent extends SummaryPageLang implements OnInit, OnDestroy {
    @ViewChild('customerNumberWarning', { static: true }) customerNumberWarning: ElementRef;

    public dataPrivacyForm:FormGroup;
    public registerAction:Observable<User>;
    public forceUserCreationAction:Observable<User>;

    public register$:Observable<registerReducer.State>;
    public storedAccountData:RegisterAccount;
    public storedAddressData:RegisterAddress;
    public customerType:CustomerType;
    public externalData:ExternalData;
    public businessCustomerType:BusinessCustomerType;

    public showEditAccount:boolean = false;
    public showEditAddress:boolean = false;

    public showBanner:boolean = false;
    public bannerType:InfoBannerType;
    public bannerTextKey:string;

    public InputType = FormInputType;
    public RegistrationComponents = RegistrationComponents;
    public CustomerType = CustomerType;

    public registrationIncomplete:string = sessionStorage.getItem('registrationIncomplete');

    private modalInstance:any;
    private endSubscriptions:Subject<void> = new Subject<void>();


    constructor(
        private UserService: UserService,
        public distinguishSupportService:DistinguishSupportService,
        private store:Store<State>,
        private translateService:TranslateService,
        private utilsService:UtilsService,
        private formDataService:FormDataService,
        private userService:UserService,
        private modalService:NgbModal,
        private router:Router
    ) {
        super();
        this.getStoreData();
        this.registerAction = this.utilsService.createActionObs(() => this.onSubmit(false));
        this.forceUserCreationAction = this.utilsService.createActionObs(() => this.onSubmit(true));
    }

    get regIncomplete() {
        const registrationIncomplete = sessionStorage.getItem('registrationIncomplete');
        return registrationIncomplete;
    }

    public buttonText() {
        if (this.regIncomplete) {
            return this.summaryPage.button_NO_LICENSE
        } else {
          return this.summaryPage.button 
        }
    }

    ngOnInit():void {
        this.register$ = this.store.select('register');
        if (!this.distinguishSupportService.support) {
            this.createForm();
        }
    }

    ngOnDestroy():void {
        this.endSubscriptions.next();
        this.endSubscriptions.complete();
    }

    private getStoreData() {
        this.store.select('register').pipe(takeUntil(this.endSubscriptions)).subscribe(registerStore => {
            this.storedAccountData = registerStore.registrationData.account;
            this.storedAddressData = registerStore.registrationData.address;
            this.customerType = registerStore.registrationData.customerType;
            this.externalData = registerStore.registrationData.externalData;
            this.businessCustomerType = registerStore.registrationData.businessCustomerType;
        });
    }

    public getSelectionValue() {
        return [{
            value: true,
            labelText: this.getReplacedTex()
        }];
    }

    public getReplacedTex():string {
        const toReplace = this.translateService.instant(this.summaryPage.iHaveReadCheck);
        const replace = `<a target="_blank" href="https://www.hekatron-brandschutz.de/kundenservice/agb-reklamationsservice">${this.translateService.instant(this.summaryPage.underlinedRead)}</a>`;
        return toReplace.replace('{replace}', replace);
    }

    public cancel() {
        this.modalService.dismissAll('done');
    }

    private createForm() {
        let val = [];
        if (!this.regIncomplete) {
            val.push(Validators.requiredTrue)
        }
        this.dataPrivacyForm = new FormGroup({
            checked: new FormControl(null, {
                updateOn: 'change',
                validators: val
            })
        });
    }

    private onSubmit(noWarn?: boolean): Observable<User> {        
        if (!this.distinguishSupportService.support && !this.dataPrivacyForm.valid) {
            this.dataPrivacyForm.get('checked').markAsTouched();
            return of(null);
        } else {
            return this.store.select('register').pipe(take(1),
                switchMap((register) => {

                    const user:User = this.prepareUserData(register);

                    return this.distinguishSupportService.support
                        ? this.userService.createUser(user, noWarn)
                        : this.registrationIncomplete === 'true'
                            ? this.userService.completeRegistration(user)
                            : this.formDataService.submitRegistration(user);
                }),
                tap((user) => {
                        sessionStorage.removeItem('registrationIncomplete');
                        sessionStorage.removeItem('redirectUrl');
                        this.triggerCurrUserObsChange();
                        if (this.modalService.hasOpenModals()) {
                            this.modalService.dismissAll('done');
                        }
                        if (user && this.distinguishSupportService.support) {
                            this.store.dispatch(new registerActions.DeleteAllFormData());
                            this.router.navigateByUrl('user/details/' + user.id);
                        }else if (this.registrationIncomplete === 'true') {
                            this.userService.restoreSavedRoute('produkte/start');
                        } else {
                            this.router.navigate(['/registration/email-confirmation']);
                        }
                    },
                    (error) => {
                        if (this.modalService.hasOpenModals()) {
                            this.modalService.dismissAll('done');
                        }
                        switch (error.error.errors[0].code) {
                            case 'ERROR_USERNAME_TAKEN':
                                this.bannerTextKey = 'ERRORS.ERROR_USERNAME_TAKEN.text';
                                break;
                            case 'ERROR_CUSTOMER_NUMBER_EXISTS':
                                this.bannerTextKey = 'USER.CREATION.CUSTOMER_NUMBER_WARNING.text';
                                if (this.distinguishSupportService.support) {
                                    this.showModal();
                                }
                                break;
                            case 'ERROR_ALREADY_CONFIRMED':
                                this.bannerTextKey = 'ERRORS.ERROR_ALREADY_CONFIRMED.text';
                                break;
                            default:
                                this.bannerTextKey = 'ERRORS.common';
                        }
                        this.turnOnBanner();
                    }
                ),
                take(1));
        }
    }

    private triggerCurrUserObsChange() {
        this.userService.getCurrentUserStatus().pipe(take(1), tap(r => {
            this.userService.setCurrUser(r);
        })).subscribe();
    }

    private turnOnBanner() {
        this.bannerType = InfoBannerType.DANGER;
        this.showBanner = true;
    }

    private prepareUserData(register):User {
        const user:User = {
            salutation: register.registrationData.account.salutation,
            firstname: register.registrationData.account.firstName,
            lastname: register.registrationData.account.lastName,
            addressInformation: {
                address: register.registrationData.address.street,
                address2: register.registrationData.address.addressExtension,
                city: register.registrationData.address.city,
                zip: register.registrationData.address.zip
            },
            email: register.registrationData.account.email,
            phone: register.registrationData.address.phone,
            password: register.registrationData.account.password,
            customerType: this.customerType
        };

        if(this.registrationIncomplete === 'true') {
            user.externalData = this.externalData;
        }

        if (this.customerType === CustomerType.Commercial) {
            user.customerNumber = register.registrationData.address.customerNumber;
            user.company = register.registrationData.address.company;
            user.businessCustomerType = register.registrationData.businessCustomerType;
        }
        return user;
    }

    private showModal() {
        this.modalInstance = this.modalService.open(this.customerNumberWarning, {
            centered: true,
            ariaLabelledBy: 'modal-basic-title'
        }).result.then((result) => {
        }, (reason) => {
        });
    }
}
