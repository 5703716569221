import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable, of, Subject } from 'rxjs';
import { mergeMap, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import {
    AddressInformation,
    CustomerType,
    Detector,
    DistinguishSupportService,
    EvaluationState,
    FormDataService,
    InfoBannerType,
    Product,
    ProductsState,
    RecaptchaService,
    ReclamationDetailed,
    ReclamationSubmissionBase,
    ReclamationSubmissionWithDetectors,
    ReclamationSubmissionWithSerials,
    ReclamationTypeCases,
    User,
    UserService,
    UtilsService
} from '../../../shared';
import { Location } from '@angular/common';
import * as fromRoot from '../../../../reducers/root/root.reducer';
import * as EvaluationAction from '../../../shared/reducers/evaluation/evaluation.actions';

@Component({
    // tslint:disable-next-line:component-selector
    selector: 'sl-evaluation',
    templateUrl: './evaluation.component.html',
    styleUrls: []
})
export class EvaluationComponent implements OnInit, OnDestroy {
    @ViewChild('errorBanner', {static: false, read: ElementRef}) errorBanner:ElementRef;
    public evaluation:EvaluationState;
    public ReclamationTypeCases = ReclamationTypeCases;
    public submitted:boolean;
    public products:Product[] = [];

    public infoBannerTextKey: string;
    public errorBannerTextKey: string;
    public infoBannerType = InfoBannerType.INFO;
    public errorBannerType: InfoBannerType;
    public showErrorBanner: boolean = false;
    public showBanner: boolean = true;
    public infoBannerBoldTextKey: string;

    public actionSend:Observable<ReclamationDetailed>;
    public user:User;
    private fromSupport: boolean = false;

    private user$:Observable<User>;
    private evaluation$:Observable<EvaluationState>;
    private products$: Observable<ProductsState>;

    private endSubscriptions:Subject<void> = new Subject<void>();
    private enterSerialsLater:boolean;

    /**
     *
     * @param {Router} router
     * @param {Store<State>} store
     * @param {UserService} userService
     * @param {FormDataService} formDataService
     * @param {UtilsService} utils
     * @param {DistinguishSupportService} distinguishSupportService
     * @param {RecaptchaService} recaptchaService
     * @param {Location} location
     */
    constructor(
        private router:Router,
        private store:Store<fromRoot.State>,
        private userService:UserService,
        private formDataService:FormDataService,
        private utils:UtilsService,
        public  distinguishSupportService:DistinguishSupportService,
        private recaptchaService:RecaptchaService,
        private location: Location,
    ) {
        this.initialize();
    }

    private initialize() {
        this.setInfoBanner();
        this.getState();
        this.prepareSendButton();
    }

    public setInfoBanner() {
        this.infoBannerBoldTextKey = 'PRODUCTS.EVALUATION.infoBannerBold';
        this.infoBannerTextKey = 'PRODUCTS.EVALUATION.infoBanner';
        }

    private prepareSendButton() {
        this.actionSend = this.utils.createActionObs(() => this.sendReclamation());
    }

    private getState() {
        this.products$ = this.store.select<ProductsState | any>('products');
        this.evaluation$ = this.store.select<EvaluationState | any>('evaluation');
        this.user$ = this.store.select<User | any>('user');
    }

   
        /**
         * Getter that finds out whether the user is a Commercial user or not
         */
        get isCustomerCommercial():boolean {
            return this.user.customerType === CustomerType.Commercial;
        }
    
        /**
         * Getter that finds out whether reclmationType is Replacement
         */
        get isReplacement():boolean {
            return this.evaluation.reclamationType ===
                ReclamationTypeCases.PartialExchange ||
                this.evaluation.reclamationType === ReclamationTypeCases.CompleteExchange;
    
        }
    
     
    
        /**
         * Get the list of products, what the reclamation type is going to be,
         * build the form and get the user data
         */
        ngOnInit() {
            this.products$
                .pipe(takeUntil(this.endSubscriptions))
                .subscribe((products) => {
                    this.enterSerialsLater = products.enterSerialsLater;
                    this.products = this.formDataService.getProductListToDisplay(
                        products.products
                    );
                    if (!this.products || this.products.length <= 0) {
                        this.user.customerType === CustomerType.Commercial
                            ? this.router.navigate(['/produkte', 'start'])
                            : this.router.navigate(['/produkte', 'auswahl-reklamation']);
                    }
                });
    
            this.evaluation$
                .pipe(takeUntil(this.endSubscriptions))
                .subscribe((evaluation) => {
                    this.evaluation = evaluation;
                    if (!evaluation.reclamationType) {
                        this.location.back();
                    }
                });
    
            this.user$
                .pipe(
                    switchMap((user) => {
                        if (user) {
                            this.fromSupport = true;
                            return of(user);
                        } else {
                            return this.userService.getCurrentUser();
                        }
                    }),
                    takeUntil(this.endSubscriptions)
                )
                .subscribe((userData:User) => {
                    this.user = userData;
                });
        }
    
        /**
         * Unsubscribe from Subscriptions
         */
        ngOnDestroy():void {
            this.endSubscriptions.next();
            this.endSubscriptions.complete();
            this.endSubscriptions.unsubscribe();
        }
    
        /**
         * Send the Reclamation to the back end denoting whether or not the user entered
         * an address or tel/email data and which they prefer if it is tel/email
         *
         * @return Observable<ReclamationDetailed>
         */
        private sendReclamation():Observable<ReclamationDetailed> {
            this.submitted = true;
            return this.createReclamation();
        }
    
        private createReclamation():Observable<ReclamationDetailed> {
            return this.recaptchaService.getToken('reclamation/create').pipe(
                mergeMap((response) => this.reclamationSubmitCall(response)),
                tap(
                    (data: ReclamationDetailed) => {
                        this.showErrorBanner = false;
                        this.reclamationResponse(data);
                    },
                    (err) => {
                        this.setErrorBanner(err.error.errors[0].code)
                    }
                ),
                takeUntil(this.endSubscriptions)
            );
        }
    
    private decideOnFeatureDisabled() {
        this.userService
            .currentLoggedInStatus()
            .pipe(take(1))
            .subscribe(() => {
                this.user.customerType === CustomerType.Commercial
                    ? this.router.navigate(['/produkte', 'start'])
                    : this.router.navigate(['/produkte', 'auswahl-reklamation']);
            });
    }

    private setErrorBanner(code: string) {
        this.errorBannerType = InfoBannerType.DANGER;
        this.showErrorBanner = true;
        switch (code) {
            case 'ERROR_INVALID_CAPTCHA':
                this.errorBannerTextKey = 'PRODUCTS.SUMMARY.reCaptchaError';
                break;
            case 'ERROR_MISSING_TEMPORARY_ID':
                this.errorBannerTextKey = 'PRODUCTS.SUMMARY.temporaryId';
                break;
            case 'ERROR_INVALID_INPUT':
                this.errorBannerTextKey = 'PRODUCTS.SUMMARY.invalidInput';
                break;
            case 'ERROR_FEATURE_DISABLED':
                this.errorBannerTextKey = 'PRODUCTS.SUMMARY.featureDisabled';
                this.decideOnFeatureDisabled();
                break;
            default:
                break;
        }
        setTimeout(() => {
            this.errorBanner?.nativeElement.scrollIntoView({behavior: 'smooth'});
        }, 300);
    }
        private reclamationResponse(reclamationDetailed:ReclamationDetailed):void {
            this.store.dispatch(new EvaluationAction.SaveReclamationResponseAction(reclamationDetailed));
            this.router.navigate(['/bestaetigung/kontakt']);
        }
    
        /**
         * Decide which call to the BE is needed for the submission of the reclamation
         *
         * @return Observable<ReclamationDetailed>
         */
        private reclamationSubmitCall(
            recaptchaResponse?:string
        ):Observable<ReclamationDetailed> {
            if (this.fromSupport) {
                return this.formDataService.submitUserReclamation(
                    this.buildReclamationSubmission(),
                    this.user.id
                );
            } else {
                return this.formDataService.submitReclamation(
                    this.buildReclamationSubmission(),
                    recaptchaResponse
                );
            }
        }
    
        /**
         * Build the Reclamation Data that will be sent to the Backend
         */
        private buildReclamationSubmission():
            ReclamationSubmissionWithDetectors
            | ReclamationSubmissionWithSerials {
            let addressInformation:AddressInformation;
            if (this.isReplacement) {
                addressInformation = {};
            }
    
            let submission:ReclamationSubmissionBase = {
                id: this.evaluation.temporaryId,
                customerType: this.user.customerType,
                transactionNumber: '',
                reclamationType: this.evaluation.reclamationType,
                addressInformation: addressInformation,
                supplySource: this.products[0].supplySource,
                customerTransactionNumber: this.evaluation.customerTransactionNumber
            };
            if (this.isCustomerCommercial) {
                submission = {...submission, numberRange: this.products[0].numberRange};
            } else {
                submission = {...submission, number: this.products[0].number};
            }
    
            if (this.enterSerialsLater) {
                return {
                    ...submission,
                    detectors: this.extractDetectorsFromProductData(this.products)
                };
            } else {
                return {...submission, serials: this.products};
            }
        }
    
        /**
         Method that returns detectors from stored product data
         */
        private extractDetectorsFromProductData(products:Product[]):Detector[] {
            const detectors:Detector[] = [];
            products.forEach((product:Product) =>
                detectors.push({
                    groupType: product.groupType,
                    installationYear: product.installationYear,
                    count: product.count,
                    valid: product.valid,
                    objectName: product.objectName,
                    objectType: product.objectType,
                    returnReason: product.returnReason
                })
            );
            return detectors;
        }
    }
    
    
    