<div class="col-12 col-md-10 mx-auto" >
    <!-- BACK BUTTON -->
    <div class="row my-3">
        <div class="col-6 pl-0">
            <sl-back-navigation *ngIf="!distinguishSupportService.support" [route]="'/produkte/zusammenfassung'"
                [buttonText]="'GENERAL.BUTTONS.prevStep' | translate"></sl-back-navigation>

            <sl-back-navigation *ngIf="distinguishSupportService.support"
                [route]="'/produkte/zusammenfassung' + (user?.id ? ('/' + user.id) : '' ) "
                [buttonText]="'GENERAL.BUTTONS.prevStep' | translate"></sl-back-navigation>
        </div>
    </div>
    <!-- END BACK BUTTON -->

        <!-- TITLE AND BANNERS -->
      
        <div class="row my-3">
            <h1>{{'PRODUCTS.EVALUATION.title' | translate}}</h1>
            <sl-info-banner *ngIf="showErrorBanner" #errorBanner class="w-100 mt-2 mb-4" 
                [bannerType]="errorBannerType" [textKey]="errorBannerTextKey"></sl-info-banner>
            <sl-info-banner #infoBanner class="w-100 mt-2 mb-4" [boldTextKey]="infoBannerBoldTextKey"
                [bannerType]="infoBannerType" [textKey]="infoBannerTextKey">
            </sl-info-banner>
            <p class="lead" [innerHtml]="'PRODUCTS.EVALUATION.descriptionParagOne' | translate"></p>
            <p class="lead" [innerHtml]="'PRODUCTS.EVALUATION.weCant' | translate"></p>
            <p class="lead mt-3" [innerHtml]="'PRODUCTS.EVALUATION.descriptionParagTwo' | translate"></p>
        </div>
        <!-- END TITLE AND BANNERS -->

        <!--NAVIGATION AND SUBMIT -->
        <div class="row mt-4">
            <sl-busy-btn [action]="actionSend" [type]="'submit'">
                <span>{{'PRODUCTS.EVALUATION.BUTTONS.sendReclamation' | translate}}</span>
            </sl-busy-btn>
        </div>
         <!--END NAVIGATION AND SUBMIT -->
</div>
