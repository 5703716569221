import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { Observable, EMPTY, Subject } from 'rxjs';
import { UtilsService } from '../../../../shared/services/utils.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { UserService } from '../../../../shared/services/user.service';
import { tap, takeUntil } from 'rxjs/operators';
import { InfoBannerType } from '../../../../shared/models/info-banner.model';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { LoginPageLang } from '../../../../shared/models/util.model';
import { FormInputType } from '../../../../shared/models/product.model';

@Component({
  selector: 'sl-forgot-pass-modal',
  templateUrl: './forgot-pass-modal.component.html',
  styleUrls: [],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ForgotPassModalComponent extends LoginPageLang implements  OnInit {
  public forgotPasswordAction: Observable<any>;
  public emailForm:FormGroup;
  public forgotPasswordSubmitted: boolean;
  public bannerType: InfoBannerType;
  public showBanner: boolean = false;
  public bannerTextKey: string;
  public requestConfirmationLinkAction: Observable<any>;
  public confirmationRequestSubmitted: boolean;
  private unsubscribe$ = new Subject<any>();

  constructor(
    public ngbModal: NgbModal,
    private utilsService: UtilsService,
    private userService: UserService
  ) { super(); }

  ngOnInit(): void {
    this.createForm();
    this.forgotPassword();
  }

  private createForm() {
    this.emailForm = new FormGroup(this.getLoginFormControl());
  }

  get inputType() {
    return FormInputType;
    }

  get email() {
    return this.emailForm.get('email');
}
  private getLoginFormControl() {
    return {
        email: new FormControl(null, {
            updateOn: 'change',
            validators: [Validators.required, Validators.email],
        })
    };
  }

   private forgotPassword() {
    this.forgotPasswordAction = this.utilsService.createActionObs(() => {
        if (this.emailForm.invalid) {
            return EMPTY;
        }
        return this.makeEmailResetCall();
    });
   }

  private makeEmailResetCall(): Observable<any> {
    return this.userService
      .requestPasswordResetEmail(this.email.value)
      .pipe(
        this.getEmailResetCallResult(),
        takeUntil(this.unsubscribe$)
      );
  }

  private getEmailResetCallResult() {
    return tap(
      (result: Response) => {
        if (result.status === 204) {
          this.forgotPasswordSubmitted = true;
        } else {
          this.isNot204();
        }
      },
      () => {
        this.isNot204();
      }
    );
  }

  private isNot204() {
    this.forgotPasswordSubmitted = false;
    this.setCheckEmailError();
    this.createForm();
    this.email.updateValueAndValidity();
  }

  private setCheckEmailError() {
    this.setBannerState(InfoBannerType.DANGER);
    if (this.isEmailNotVerified()) {
      this.bannerTextKey = 'ERRORS.common';
    }
  }
  
   private isEmailNotVerified(): boolean {
    return true;
  }

   private setBannerState(type: InfoBannerType): void {
    this.bannerType = type;
    this.showBanner = true;
  }

}
