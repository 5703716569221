import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReclamationSerialsComponent } from './components/reclamation-serials/reclamation-serials.component';
import { TranslateModule } from '@ngx-translate/core';
import { ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from '../shared';
import { ReclamationListComponent } from './components/reclamation-list/reclamation-list.component';
import { NgbPaginationModule, NgbAccordionModule } from '@ng-bootstrap/ng-bootstrap';
import { RouterModule } from '@angular/router';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
import { RecordedSerialsComponent } from './components/recorded-serials/recorded-serials.component';
import { LateReclamationConfirmationComponent } from './components/late-reclamation-confirmation/late-reclamation-confirmation.component';
import { ContactSupportComponent } from './components/contact-support/contact-support.component';

@NgModule({
    declarations: [
        ReclamationSerialsComponent,
        ReclamationListComponent,
        RecordedSerialsComponent,
        LateReclamationConfirmationComponent,
        ContactSupportComponent
    ],
    imports: [
        CommonModule,
        TranslateModule,
        ReactiveFormsModule,
        NgbPaginationModule,
        RouterModule,
        SharedModule,
        SweetAlert2Module,
        NgbAccordionModule
    ],
    exports: [
        ReclamationSerialsComponent,
        ReclamationListComponent,
        RecordedSerialsComponent,
        LateReclamationConfirmationComponent,
        ContactSupportComponent
    ]
})
export class ReclamationModule {
}
