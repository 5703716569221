<a *ngIf="item?.finished !== undefined && !item?.finished" [routerLink]="[
fromComplaint? '../user/seriennummern/' + item?.id:
isSupportApp
? '../../seriennummern/' + item?.id
: '/servicemeldungen/seriennummern/'+item?.id ]">
    <i class="fa fa-exclamation-triangle popover-z-0 mr-2 mb-2"
       placement="right-center"
       [ngbPopover]="popContent"
       #popover="ngbPopover"
       triggers="hover">
    </i>
    <ng-template #popContent>
        <span>{{ 'ACCOUNT.RECLAMATIONS.SERIAL_NUMBER_INPUT_TOOLTIP.text' | translate }}</span>
    </ng-template>
</a>

<a class="text-primary mt-2 mb-2" *ngIf="isSupportApp && item?.completedLate" (click)="onLinkClick(item)">
    <i class="fa fa-clock-o popover-z-0"
       placement="right-center"
       [ngbPopover]="completedLatePopContent"
       #popover="ngbPopover"
       triggers="hover">
    </i>
    <ng-template #completedLatePopContent>
        <span>{{ 'ACCOUNT.RECLAMATIONS.SERIAL_NUMBER_INPUT_TOOLTIP_TIMEOUT.text' | translate }}</span>
    </ng-template>
</a>

<a class="text-secondary" *ngIf="item?.reclamationType === 'warranty'">
    <i class="fa fa-shield popover-z-0 m-1"
       placement="right-center"
       [ngbPopover]="warrantyPopContent"
       #popover="ngbPopover"
       triggers="hover">
    </i>
    <ng-template #warrantyPopContent>
        <span>{{ 'ACCOUNT.RECLAMATIONS.SERIAL_NUMBER_INPUT_TOOLTIP_WARRANTY.text' | translate }}</span>
    </ng-template>
</a>
