import { Injectable } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, ValidatorFn } from '@angular/forms';
import { Product } from '../models/product.model';
import { UtilsService } from './utils.service';
import { RecaptchaService } from './recaptcha.service';
import { ProductService } from './product.service';

@Injectable({
    providedIn: 'root'
})
export class ValidationService {

    constructor(private utils:UtilsService,
                private productService:ProductService) {
    }

    /**
     * Regex check for the customer number
     * @param {AbstractControl} control
     * @returns { [key:string]:boolean } | null
     */
    public checkCustomerNumber(control:AbstractControl):{ [key:string]:boolean } | null {
        if (control.value === '' || control.value === null) {
            return null;
        }
        const NUMBER_REGEXP = /^[0-9]{7,8}$/;
        if (NUMBER_REGEXP.test(control.value)) {
            return null;
        }
        return {customerNumber: true};
    }

    /**
     *
     * @param control
     */
    public customMinLength(control:FormControl) {
        if ((control.value || '') === '') { // Indicates an empty field. This should only be checked by the Validators.required validator!
            return null;
        }
        let trimmed = (control.value || '').trim();
        trimmed = trimmed.replace(/[0-9]/g, '');
        const isWhitespace = trimmed.length === 0;
        return !isWhitespace && trimmed.length >= 2 ? null : {'min2': true};
    }

    public checkNumberLength(amountRequired:number, field:string, control:FormControl) {
        const numberValue = control && control.value ? control.value.replace(/\D/g, '') : '';
        return numberValue.length >= amountRequired || numberValue.length === 0 ? null : {[field + '.minLength']: true};
    }

    public spacesAtStart(control:FormControl) {
        let entry = control.value?.split('') || '';
        const isWhitespace = entry.length !== 0 && entry[0] === ' ';
        return !isWhitespace ? null : {'whitespace': true};
    }

    public addressPattern(control: FormControl) {
        if ((<string>control.value)?.match(/^(?=.*[\wß].*)(?=.* .*)(?=.*\d.*).*$/) === null) {
            return { 'addressPattern': true };
        }
        return null;
    }

    /**
     * This validator makes sure serial number and second serial number are not identical in ONE form
     * @returns ValidatorFn
     */
    public noIdenticalProductSerialNumbersValidator():ValidatorFn {
        return (control:FormGroup):{ [key:string]:any } | null => {
            if (control.value.secondSerialNumber === '') {
                return null;
            }

            if (control.value.secondSerialNumber !== control.value.serialNumber) {
                return null;
            }
            return {'duplicateSerial': true};
        };
    }

    /**
     * Check to make sure that the password is the correct RexEx
     *
     * @param {RegExp} pwdRegExp
     * @returns ValidatorFn
     */
    public customPasswordValidator(pwdRegExp:RegExp):ValidatorFn {
        return (control:AbstractControl):{ [key:string]:boolean } | null => {
            const validPassword = pwdRegExp.test(control.value);
            return validPassword ? null : {password: true};
        };
    }

    /**
     * Check to make sure that the old password and the new one are not the same
     *
     * @returns ValidatorFn
     */
    public checkPasswords():ValidatorFn {
        return (control:AbstractControl):{ [key:string]:boolean } | null => {
            if (control.get('password') && control.get('passwordConfirmation')) {
                if (control.get('password').value !== control.get('passwordConfirmation').value) {
                    return {notSame: true};
                }
            }
            return null;
        };
    }

    public checkYearValidation(control:FormControl) {
        const today = new Date();
        if (control.value && control.value.toString().length < 4) {
            return {'yearMinLength': true};
        } else if (control.value && control.value.toString().length > 4) {
            return {'yearMaxLength': true};
        }

        if (parseInt(control.value) > today.getFullYear()) {
            return {'futureYear': true};
        }

        return null;
    }

    public checkDuplicateCombo(productIndex:number, products:Product[], group:FormGroup) {
        const groupType = group.get('groupType');
        const installationYear = group.get('installationYear');
        let hasDuplicateCombo = false;
        products.forEach(product => {
            if (product.productIndex !== productIndex && product.groupType === groupType.value && product.installationYear === installationYear.value) {
                hasDuplicateCombo = true;
            }
        });
        if (hasDuplicateCombo) {
            groupType.setErrors({duplicateCombo: true});
            installationYear.setErrors({duplicateCombo: true});
        } else if (!hasDuplicateCombo && groupType.hasError('duplicateCombo')) {
            delete groupType.errors['duplicateCombo'];
            groupType.updateValueAndValidity();
        } else if (!hasDuplicateCombo && installationYear.hasError('duplicateCombo')) {
            delete installationYear.errors['duplicateCombo'];
            installationYear.updateValueAndValidity();
        }
    }

    /**
     * Validator for Sales Channel field
     */
    public validatorSalesChannelRequired():ValidatorFn {
        return (control:FormGroup):{ [key:string]:any } | null => {
            if (control.value.salesChannel !== null) {
                return null;
            }
            return {'salesChannelRequired': true};
        };
    }

    public checkForDuplicates(form:FormGroup, unfilteredProducts:Product[], originalProduct?:Product, productIndex?:number, index?:number) {
        let matches = this.productService.getFormInputSerials(form);
        let source = this.productService.getFormInputSupplySource(form);
        let reason = this.productService.getFormInputReturnReason(form);
        matches = this.findDuplicatesForAdd(index, matches, productIndex, unfilteredProducts);
        this.findDuplicatesWhenUpdate(index, originalProduct, matches, source, reason, unfilteredProducts);
    }

    private findDuplicatesWhenUpdate(index: number, originalProduct: Product, matches: RegExpMatchArray, source: string, reason: string, unfilteredProducts: Product[]): void {        
        if (index !== undefined && index !== null) {
            let matchesSerial = unfilteredProducts.map(product => product.serialNumber);
            matchesSerial = matches.concat(matchesSerial);
            let exitLoop = false;
            if (this.utils.arrayHasDuplicates(matches)) {
                exitLoop = this.decideUpdatePermission(exitLoop, source, reason, matchesSerial, originalProduct, unfilteredProducts);
            }
        }
    }

    private decideUpdatePermission(exitLoop:boolean, source:string, reason:string, matchesSerial:string[], originalProduct:Product, unfilteredProducts:Product[]) {
        unfilteredProducts.forEach((element, i) => {
            if (exitLoop) {
                return;
            }
            if (((+element.supplySource === +source) && (+element.returnReason === +reason)) && +matchesSerial[i] === +originalProduct.serialNumber) {
                throw new Error('Duplicate'); // if serial not changed and none of dropdown changed
            } else if (((+element.supplySource !== +source) || (+element.returnReason !== +reason)) && +matchesSerial[i] !== +originalProduct.serialNumber) {
                throw new Error('Duplicate'); // if serial changed and one of dropdown changed
            } else if (((+element.supplySource !== +source) || (+element.returnReason !== +reason)) && +matchesSerial[i] === +originalProduct.serialNumber) {
                return exitLoop = true; // if serial not changed but and one of dropdown changed
            } else if (((+element.supplySource === +source) && (+element.returnReason === +reason)) && +matchesSerial[i] !== +originalProduct.serialNumber) {
                throw new Error('Duplicate'); // if serial changed but none of dropdown changed
            }
        });
        return exitLoop;
    }

    private findDuplicatesForAdd(index:number, matches:RegExpMatchArray, productIndex:number, unfilteredProducts:Product[]): RegExpMatchArray {
        if (index == undefined) {
            matches = matches.concat(
                unfilteredProducts
                    .filter((product) => (!!productIndex && (productIndex !== product.productIndex)) || isNaN(productIndex))
                    .map(product => product.serialNumber)
            );
            if (this.utils.arrayHasDuplicates(matches)) {
                throw new Error('Duplicate');
            }
        }
        return matches;
    }
}
