import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { State } from '../../../../reducers/root/root.reducer';
import * as ProductsAction from '../../../shared/reducers/products/products.actions';

@Component({
  selector: 'sl-warranty-choice',
  templateUrl: './warranty-selection.component.html',
  styles: [
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class WarrantySelectionComponent implements OnInit {

  constructor(private store: Store<State>, private router:Router) { }

  ngOnInit(): void {
  }

  public proceedToProductIdentification(): void {
    this.store.dispatch(new ProductsAction.EnterSerialsLaterAction(false));
    this.store.dispatch(new ProductsAction.DeleteAllProductAction());
    this.router.navigate(['/produkte', 'garantie-identifikation']);
  }
}
