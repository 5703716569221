export class BaseUser {
    id?: string;
    username?: string;
    firstname?: string;
    lastname?: string;
    company?: string;
    email?: string;
    phone?: string;
    salutation?: (0 | 1);
    customerType?: CustomerType;
}

/**
 * The model of a User
 */
export class User extends BaseUser {
    extSystemId?: string;
    password?: string;
    customerNumber?: string;
    businessPartnerNumber?: string;
    salutation?: (0 | 1); // 0 Frau 1 Herr
    addressInformation?: AddressInformation;
    customerType?: CustomerType;
    externalData?: ExternalData;
    businessCustomerType?: BusinessCustomerType;
    installer?: boolean;
    hasLicense?: boolean;
}

export class SupportUser extends BaseUser {
    assignedOrgunit?: {
        id: string;
        name: string;
    };
    assignedRole?: AssignedRole[];
}


/**
 * Model of External Data that may be associated with a User
 */
export interface ExternalData {
    salesChannel: number;
    subSegment?: number;
    directCustomer?: boolean;
    regulatoryModel?: string;
}

/**
 * The User's Address Info
 */
export class AddressInformation {
    company?: string;
    salutation?: (0 | 1);
    firstname?: string;
    lastname?: string;
    address?: string;
    address2?: string;
    street?: string;
    addressExtension?: string;
    city?: string;
    country?: string;
    zip?: string;
}

/**
 * Login Data for the User
 */
export interface LoginData {
    username?: string;
    password?: string;
    reCaptchaResponse?: string;
}

/**
 * The User's Logged in Status
 */
export interface LoginStatus {
    massClaimAllowed: boolean;
    loggedIn?: boolean;
    username?: string;
    feedbackShown?: boolean;
    customerType?: CustomerType;
    reCaptcha: RecaptchaStatus;
    reclamationsWithoutSerialsEnabled?: boolean;
    isSuperUser?: boolean;
    notice?: string;
}

/**
 * User's Recaptcha Status
 */
export interface RecaptchaStatus {
    enabled: boolean;
    siteKey: string;
}

/**
 * Sales Channel Option
 */
export class SalesChannel {
    id: number;
    subSegments: number[];
}

/**
 * Session Storage User Item
 */
export interface SessionStorageUserItem {
    username: 'currentUser';
    status?: string;
}

/**
 * The Address State of the User
 */
export interface AddressState {
    address: string;
}

/**
 * Enum for the User's Customer Type
 */
export enum CustomerType {
    Commercial = 'COMMERCIAL',
    Private = 'PRIVATE',
    Guest = 'GUEST'
}

/**
 * Enum for the Form Type that the User needs to Fill out to complete Registration
 */
export enum FormType {
    Full = 'full',
    External = 'externalData',
    Customer = 'customerType',
    BusinessCustomer = 'businessCustomerType'
}

export enum BusinessCustomerType {
    License = 'LICENSE',
    NoLicense = 'NO_LICENSE',
    Operator = 'OPERATOR',
    Next = 'Next'
}

export type BusinessCustomerNavOption = 'lizenz' | 'summary'

export enum BusinessCustomerNavOptionName {
    LICENSE = 'lizenz',
    SUMMARY = 'summary'
}

export class RegisterAccount {
    constructor(
        public firstName: string,
        public lastName: string,
        public email: string,
        public salutation: (0 | 1),
        public password?: string) {
    }
}

export class BusinessUserType {
    constructor(public businessUserType: BusinessCustomerType) {
    }
}

export class LicenseOption {
    constructor(public hasHekatronProductLicense: boolean) {
    }
}

export class RegisterAddress {
    constructor(
        public street: string,
        public addressExtension: string,
        public zip: string,
        public city?: string,
        public country?: string,
        public phone?: string,
        public customerNumber?: string,
        public company?: string
    ) {
    }
}

export interface RouteCondition {
    path: string,
    routeBack?: string,
    storeValue?: string,
    customerType?: CustomerType
}

export enum AssignedRole {
    ROLE_SUPER_ADMIN = 'ROLE_SUPER_ADMIN',
    ROLE_ADMIN = 'ROLE_ADMIN'
}
