import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import {
    BusinessCustomerNavOption,
    BusinessCustomerNavOptionName,
    BusinessCustomerType, CheckboxOption,
    DistinguishSupportService,
    FormDataService,
    FormInputType,
    RoutePathName
} from '../../../shared';
import { State } from '../../../../reducers/root/root.reducer';
import { take } from 'rxjs/operators';
import { Registration } from '../../../shared/reducers/registration/register.reducer';
import * as registerActions from '../../../shared/reducers/registration/register.actions';


@Component({
    selector: 'sl-business-user-type',
    templateUrl: './business-user-type.component.html',
    styles: [],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class BusinessUserTypeComponent implements OnInit {

    public readonly options:CheckboxOption[] = [
        {
            id: BusinessCustomerType.Operator,
            value: BusinessCustomerType.Operator,
            labelText: 'REGISTRATION.BUSINESS_CUSTOMER_TYPE.OPERATOR.text'
        },
        {
            id: BusinessCustomerType.Next,
            value: BusinessCustomerType.Next,
            labelText: 'REGISTRATION.BUSINESS_CUSTOMER_TYPE.LICENSE.text'
        }
    ];
    @Input() formGroup:FormGroup;
    @Input() showCopy:boolean = true;
    public FormInputType = FormInputType;
    public BusinessCustomerType = BusinessCustomerType;

    private registrationData:Registration;

    constructor(
        private distinguishService:DistinguishSupportService,
        private formBuilder:FormBuilder,
        private formDataService:FormDataService,
        private store:Store<State>,
        private router:Router
    ) {
        this.store.select('register').pipe(take(1)).subscribe(registerStore => {
            this.registrationData = registerStore.registrationData;
        });
    }

    ngOnInit():void {
        if (!this.formGroup) {
            if (this.registrationData?.businessCustomerType === BusinessCustomerType.License || this.registrationData?.businessCustomerType === BusinessCustomerType.NoLicense) {
                this.registrationData.businessCustomerType = null;
            }
            this.createForm();
            if (this.registrationData) {
                this.formDataService.setFormValuesFromStore(this.formGroup, this.registrationData);
            }
        }
    }

    public submit() {
        if (!this.formGroup.valid) {
            this.formDataService.markControlsTouched(this.formGroup);
            return;
        }
        this.store.dispatch(new registerActions.AddBusinessCustomerType(this.formGroup.get('businessCustomerType').value));
        const {navigateSupport, navigateTo} = this.prepareNavigation();
        this.navigate(navigateSupport, navigateTo);
    }

    private prepareNavigation() {
        const navigateTo:BusinessCustomerNavOption = this.formGroup.get('businessCustomerType').value === BusinessCustomerType.Next
            ? BusinessCustomerNavOptionName.LICENSE
            : BusinessCustomerNavOptionName.SUMMARY;
        const navigateSupport:string = this.distinguishService.support ?
            `/${RoutePathName.USER}` : RoutePathName.DEFAULT;
        return {navigateSupport, navigateTo};
    }

    private navigate(navigateSupport:string, navigateTo:string) {
        this.router.navigate([`${navigateSupport}/${RoutePathName.REGISTRATION}/${navigateTo}`]);
    }

    private createForm() {
        this.formGroup = this.formBuilder.group({
            businessCustomerType: ['', Validators.required]
        });
    }

}
