import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';

/**
 * Service to Handle Errors
 */
@Injectable({
  providedIn: 'root'
})
export class ErrorHandlerUtilsService {

  /**
   *
   * @param {Router} router
   */
  constructor(private router:Router) {
  }

  /**
   * If the provided error is an instance of HttpErrorResponse and the status code is 404 this function redirects
   * to route specified by the provided redirectCommands. Redirects are relative to the provided currentRoute.
   *
   * @param error                         The error to handle
   * @param {ActivatedRoute} currentRoute The current route
   * @param {any[]} redirectCommands      The commands used when redirecting
   *
   * @returns {boolean} true if a redirect has been initialized, false otherwise
   */
  public handleNotFoundError(error:any, currentRoute:ActivatedRoute, redirectCommands:any[]):boolean {
    if (error instanceof HttpErrorResponse && error.status === 404) {
      this.router.navigate(redirectCommands, {
        relativeTo: currentRoute,
        replaceUrl: true
      });

      return true;
    }

    return false;
  }
}
