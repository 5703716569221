<div class="step-group d-flex flex-column flex-md-row justify-content-md-between my-3 my-md-5">
    <ng-container *ngFor="let titleIcon of titleIcons; let i = index">
        <div class="step d-flex flex-row flex-md-column my-2 my-md-0">
            <img class="mr-2 mr-md-0 mb-2" [src]="imageSrc(titleIcon[1])" [alt]="titleIcon[1]">
            <span class="title d-block align-self-center text-md-center text-secondary font-weight-bold">
                {{ titleIcon[0] | translate}}
            </span>
        </div>
        <div *ngIf="i !== (titleIcons.length-1)" class="line-horizontal flex-fill d-none d-md-block"></div>
    </ng-container>
</div>