<div class="row my-2" >
    <h3>{{'PRODUCTS.IDENTIFICATION.objectFormTitle' | translate}}</h3>
    <p>{{'PRODUCTS.IDENTIFICATION.objectFormLead'| translate}}</p>
</div>
<form [formGroup]="productForm">
    <ng-container *ngIf="customerType === CustomerType.Commercial;else product">
        <ng-template [ngTemplateOutlet]="nameAndType"></ng-template>
    </ng-container>
    <ng-template #product>
        <ng-template [ngTemplateOutlet]="number"></ng-template>
    </ng-template>

    <!-- NAME TYPE -->
    <ng-template #nameAndType>
        <!-- OBJECT NAME -->
        <div class="row">
            <div class="col-sm px-0">
                <sl-search-slot
                        id="object-name" 
                        controlName="objectName" 
                        controlValue="objectName" 
                        [parentForm]="productForm"
                        (submitSelectedValue)="objectChosen($event)" 
                        [searchOff]="searchOff"
                        placeholder="{{'PRODUCTS.IDENTIFICATION.PLACEHOLDER.objectName' | translate}}"
                        searchType="userObject">
                </sl-search-slot>
            </div>
        </div>

        <!-- OBJECT TYPE -->
        <div class="row">
            <div class="col-sm px-0">
                <!-- TYPE -->
                <sl-form-item [formGroup]="productForm" controlName="objectType" [inputType]="FormInputType.DROPDOWN"
                [placeholderText]="'PRODUCTS.IDENTIFICATION.LABELS.objectType'" [labelText]="'PRODUCTS.IDENTIFICATION.LABELS.objectType'"
                [dropdownValues]="formSelectionValues.objectType "></sl-form-item>
                <!-- END TYPE -->
            </div>
        </div>

        <!-- NUMBER RANGE -->
        <div class="row">
            <div class="col-sm px-0">
                        <sl-form-item [formGroup]="productForm" controlName="numberRange" 
                        [inputType]="FormInputType.DROPDOWN"
                        [placeholderText]="'PRODUCTS.IDENTIFICATION.LABELS.numberRanges'" 
                        [labelText]="'PRODUCTS.IDENTIFICATION.LABELS.numberRanges'"
                        [helperText]="'PRODUCTS.IDENTIFICATION.POPOVER_TEXTS.numberRanges'"
                        [dropdownValues]="formSelectionValues.totalDetectors"></sl-form-item>
            </div>
        </div>
     <!-- END NUMBER RANGE -->
    </ng-template>

    <!-- NUMBER -->
    <ng-template #number>
        <div class="row">
            <div class="col-sm px-0">
                <sl-form-item [inputType]="FormInputType.NUMBER" [formGroup]="productForm" controlName="number"
                [labelText]="'PRODUCTS.IDENTIFICATION.LABELS.number'" 
                [placeholderText]="'PRODUCTS.IDENTIFICATION.LABELS.number'"
                [helperText]="'PRODUCTS.IDENTIFICATION.HELPERTEXT.numberDetectorsInObject'">
            </sl-form-item>
            </div>
        </div>
    </ng-template>
     <!-- END NUMBER -->
</form>
