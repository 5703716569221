import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';

/**
 * This component is used to navigate to previous steps/certain pages in the portal
 */
@Component({
    selector: 'sl-back-navigation',
    templateUrl: './back-navigation.component.html',
    styles: [],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class BackNavigationComponent implements OnInit {
    @Input() buttonText:string;
    @Input() route:string;
    @Input() icon:string;
    @Input() tabindex:number;
    @Input() classes:string = 'ml-0 ml-md-n3';

    /**
     * This component requires Router and Location
     * @param {Router} router
     * @param {TranslateService} translate
     * @param {Location} location
     */
    constructor(
        private router:Router,
        private translate:TranslateService,
        private location:Location
    ) {
    }

    /**
     * @ignore
     */
    ngOnInit() {
        this.setValues();
    }

    /**
     * This function routes to the provided route. If no route has been provided, it routes one step back
     */
    public goToRouteProvidedOrOneStepBack() {

        if (this.route) {
            this.router.navigate([this.route]);
        } else {
            this.location.back();
        }
    }

    /**
     * This function sets the different @Input() values if none has been set
     */
    private setValues() {
        if (!this.buttonText) {
            this.buttonText = this.translate.instant('GENERAL.BUTTONS.prevStep');
        }
        if (!this.icon) {
            this.icon = 'fa-arrow-left';
        }
    }
}
