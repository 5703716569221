import { Component, OnInit, ChangeDetectionStrategy, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { Subject } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { switchMap, takeUntil } from 'rxjs/operators';
import { ReclamationBase } from '../../../shared/models/evaluation.model';
import { MetaObject, ReclamationService, UserService, UtilsService } from '../../../shared/services';
import { User } from '../../../shared/models/user.model';

/**
 * Meta Model for a Reclamation
 */
interface ReclamationMeta extends MetaObject {
    meta?:{
        total:number;
        user:User;
    };
}

@Component({
    selector: 'sl-reclamation-list',
    templateUrl: './reclamation-list.component.html',
    styles: [],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ReclamationListComponent implements OnInit, OnDestroy {

    private readonly SUPPORT_PAGE_LENGTH = 10;
    private readonly REKLA_PAGE_LENGTH = 25;

    public loading:boolean;
    public list:ReclamationBase[] = undefined;
    public userId:string;
    public collectionSize:number;
    public page:number = 1;
    public pageLength:number;
    public username:string;
    public isSupportApp:boolean;

    private _unsubscribe = new Subject();

    constructor(private reclamationService:ReclamationService,
                private userService:UserService,
                private CD:ChangeDetectorRef,
                private router:Router,
                private Utils:UtilsService,
                private Route:ActivatedRoute) {
    }

    /**
     * @ignore
     */
    ngOnInit():void {
        this.getReclamationsForCurrentPage();
    }

    /**
     * @ignore
     */
    ngOnDestroy():void {
        this._unsubscribe.next();
        this._unsubscribe.complete();
    }

    /**
     * Pagination navigation
     */
    public onPageChange():void {
        this.navigate();
    }

    /**
     * Navigate to a different page in the List
     */
    private navigate():void {
        const routerParams:any = {};

        this.Utils.addPageToRouterParams(routerParams, this.Route.parent.queryParams, this.page);

        this.router.navigate([], {
            relativeTo: this.Route,
            queryParams: routerParams
        });
    }

    /**
     * On reclamation click
     * @param reclamation
     */
    public onReclamationClick(reclamation:ReclamationBase) {
        this.isSupportApp ? this.router.navigate(['/user/reclamationsdetail/' + reclamation.id]) : this.router.navigate([reclamation.id], {
            relativeTo: this.Route
        });
    }

    /**
     * Get the reclamations for the current page
     */
    private getReclamationsForCurrentPage():void {
        this.Route.queryParams.pipe(switchMap(queryParams => {
            this.page = Number(queryParams.p || 1);
            this.userId = this.Route.snapshot.params.id;
            this.isSupportApp = !!this.userId;
            this.pageLength = this.isSupportApp ? this.SUPPORT_PAGE_LENGTH : this.REKLA_PAGE_LENGTH;
            this.loading = true;
            return this.userId
                ? this.userService.getReclamations(this.userId, this.pageLength, this.page)
                : this.reclamationService.getReclamationList(this.pageLength, this.page);
        }), takeUntil(this._unsubscribe))
            .subscribe((reclamations:ReclamationBase[] & ReclamationMeta) => {
                this.list = reclamations;
                if (reclamations.$meta) {
                    this.collectionSize = reclamations.$meta.total;
                    this.username = reclamations.$meta.user ? reclamations.$meta.user.username : null;
                }
                this.loading = false;
                this.CD.markForCheck();
            }, () => {
                this.loading = false;
                this.CD.markForCheck();
            });
    }


}
