<div *ngIf="dropDownValues" class="container">
    <div class="col-12 col-md-10 mx-auto">
        <div class="row flex-column mt-3">
            <sl-back-navigation class="mt-2"
                                [buttonText]="'RECLAMATION_START.DETECTOR_RECLAMATION.backLinkText' | translate"
                                [icon]="'fa-arrow-left'">
            </sl-back-navigation>
        </div>
        <div *ngIf="showBanner" class="row py-3">
            <sl-info-banner #infoBanner class="w-100" [bannerType]="bannerType" [textKey]="bannerTextKey">
            </sl-info-banner>
        </div>
        <div *ngIf="isWarrantyCase" class="row py-3">
            <sl-info-banner class="w-100" [bannerType]="InfoBannerType.INFO" [textKey]="'PRODUCTS.IDENTIFICATION.WARRANTY.infoText'"></sl-info-banner>
        </div>
        <div class="row flex-column my-3">
            <h1>{{'PRODUCTS.SUMMARY.title' | translate}}</h1>
            <p class="lead">
                {{'PRODUCTS.SUMMARY.subTitle' | translate}}
            </p>
        </div>
    </div>
    <div class="col-12 col-md-10 mx-auto">
        <!-- without serials -->
        <ng-container *ngIf="enterSerialsLater">
            <ng-container *ngIf="validProductsExist">
                <div class="row flex-column my-3">
                </div>
                <div class="row" *ngFor="let product of products">
                    <sl-product-list-item class="product-list-item" [dropDownValues]="dropDownValues"
                        [detectorOptions]="detectorOptions" [product]="product" [showErrorMessage]="false"
                        [enterSerialsLater]="enterSerialsLater" (delete)="deleteProduct($event)"
                        (update)="updateNoSerialProduct($event, product)">
                    </sl-product-list-item>
                </div>
            </ng-container>

            <ng-container *ngIf="invalidProductsExist">
                <div class="row flex-column my-3">
                    <h3>{{'PRODUCTS.SUMMARY.INVALID_PRODUCTS.title' | translate}}</h3>
                    <p>{{'PRODUCTS.SUMMARY.INVALID_PRODUCTS.text' | translate}}</p>
                </div>
                <div class="row" *ngFor="let product of invalidProducts">
                    <sl-product-list-item class="product-list-item" [product]="product" [showErrorMessage]="true"
                        [detectorOptions]="detectorOptions" [enterSerialsLater]="enterSerialsLater"
                        [dropDownValues]="dropDownValues" (delete)="deleteProduct($event)"
                        (update)="updateNoSerialProduct($event, product)">
                    </sl-product-list-item>
                </div>
            </ng-container>

        </ng-container>
        <!-- end without serials -->

        <!-- with serials -->
        <ng-container *ngIf="!enterSerialsLater">
            <ng-container *ngIf="viableValidProducts.length > 0">
                <div class="row flex-column my-3">
                </div>
                <div class="row" *ngFor="let product of viableValidProducts; let i = index">
                    <sl-product-list-item class="product-list-item" [product]="product"
                        [dropDownValues]="dropDownValues" [showErrorMessage]="false"
                        [enterSerialsLater]="enterSerialsLater" (delete)="deleteProduct($event)"
                        (update)="updateProduct($event, product, i)">
                    </sl-product-list-item>
                </div>
            </ng-container>

            <ng-container *ngIf="viableInvalidProducts.length > 0">
                <div class="row flex-column my-3">
                    <h3>{{'PRODUCTS.SUMMARY.INVALID_PRODUCTS.title' | translate}}</h3>
                    <p>{{'PRODUCTS.SUMMARY.INVALID_PRODUCTS.text' | translate}}</p>
                </div>
                <div class="row" *ngFor="let product of viableInvalidProducts; let i = index">
                    <sl-product-list-item class="product-list-item" [product]="product"
                        [dropDownValues]="dropDownValues" [showErrorMessage]="false"
                        [enterSerialsLater]="enterSerialsLater" (delete)="deleteProduct($event)"
                        (update)="updateProduct($event, product, i)">
                    </sl-product-list-item>
                </div>
            </ng-container>

            <ng-container *ngIf="viableCorruptedProducts.length > 0">
                <div class="row flex-column my-3">
                    <h3>{{'PRODUCTS.SUMMARY.CORRUPTED_PRODUCTS.title' | translate}}</h3>
                    <p>{{'PRODUCTS.SUMMARY.CORRUPTED_PRODUCTS.text' | translate}}</p>
                </div>
                <div class="row" *ngFor="let product of viableCorruptedProducts; let i = index">
                    <sl-product-list-item class="product-list-item" [product]="product"
                        [dropDownValues]="dropDownValues" [showErrorMessage]="false"
                        [enterSerialsLater]="enterSerialsLater" (delete)="deleteProduct($event)"
                       
                        (update)="updateProduct($event, product, i)">
                    </sl-product-list-item>
                </div>
            </ng-container>
        </ng-container>
        <!-- end with serials -->

        <div class="row mt-5" *ngIf="!addMore">
            <span (click)="toggleAddMore()" class="btn btn-tertiary btn-mobile-block">
                {{'PRODUCTS.SUMMARY.identification_in_summary_title' | translate}}
            </span>
        </div>
    </div>

    <div *ngIf="addMore" class="mt-5">
        <ng-container *ngIf="!enterSerialsLater; else noSerials">
            <sl-edit-container [title]="'PRODUCTS.SUMMARY.editTitle'" [hasAction]="true" [hasCancel]="true"
                [action]="addSerialsAction" (cancel)="toggleAddMore()">
                <sl-serial-entry-form #addMoreSerialsForm class="w-100" [dropDownValues]="dropDownValues"
                    [isEditMode]="true" [isSingleMode]="false">
                </sl-serial-entry-form>
            </sl-edit-container>
        </ng-container>

        <ng-template #noSerials>
            <sl-edit-container [title]="'PRODUCTS.SUMMARY.editTitle'" [hasAccept]="true" [hasCancel]="true"
                (accept)="addNoSerialProduct()" (cancel)="toggleAddMore()">
                <sl-no-serials-main-form #addMoreNoSerialsForm class="w-100" [detectorOptions]="detectorOptions"
                    [formSelectionValues]="dropDownValues"></sl-no-serials-main-form>
            </sl-edit-container>
        </ng-template>
    </div>

    <div class="col-12 col-md-10 mx-auto mt-4">
        <!-- ADDITIONAL INFORMATION -->
        <div class="row mt-1">
            <sl-textarea [controlName]="'additionalInfo'" [formGroup]="formGroup"
                (onChange)="onChangeCustomerTransactionNumber($event)" [label]="textAreaLabel"
                [helpText]="textAreaHelp | translate"
                [placeholder]="'PRODUCTS.EVALUATION.PLACEHOLDER.caseNumber' | translate">
            </sl-textarea>
        </div>

        <div class="row mt-4">
            <sl-busy-btn *ngIf="!isWarrantyCase" class="w-100" [classes]="'btn btn-primary btn-mobile-block'"
                [action]="actionProceed">
                <span>{{'PRODUCTS.SUMMARY.BUTTONS.checkReclamation' | translate}}</span>
            </sl-busy-btn>
            <sl-busy-btn *ngIf="isWarrantyCase" class="w-100" [classes]="'btn btn-primary btn-mobile-block'"
                [action]="actionProceedWarranty">
                <span>{{'PRODUCTS.SUMMARY.BUTTONS.warrantyCheck' | translate}}</span>
            </sl-busy-btn>
        </div>
    </div>
</div>
