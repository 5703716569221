<div class="container">
    <div class="col-12 mx-auto">
        <div class="row mt-5 pl-1">
            <ng-container *ngIf="isSupportApp; else reklaHeader">
                <div class="col-12 col-xl-8 pb-2 pb-md-0 px-3">
                    <h1>{{'USER.RECLAMATIONS.title' | translate: {user: username || ''} }}</h1>
                </div>
                <div class="col-12 col-xl-4 px-3">
                    <button class="btn btn-tertiary btn-truncate float-xl-right"
                            [routerLink]="['/produkte/start', userId]">
                        <span>{{'USER.RECLAMATIONS.BUTTONS.newReclamtion' | translate}}</span>
                    </button>
                </div>
            </ng-container>
    
            <ng-template #reklaHeader>
                <h1>{{'ACCOUNT.RECLAMATIONS.title' | translate}}</h1>
            </ng-template>
        </div>
    
        <div class="mt-4 pl-1">
            <div *ngIf="!loading" class="table-responsive">
                <sl-reclamations-table *ngIf="list"
                                       [list]="list"
                                       [isSupportApp]="isSupportApp"
                                       (linkClicked)="onReclamationClick($event)"
                                       [linkToDetail]="true">
                </sl-reclamations-table>
    
                <div class="d-flex justify-content-between p-2">
                    <ngb-pagination *ngIf="list?.length"
                                    [(page)]="page"
                                    [collectionSize]="collectionSize"
                                    [rotate]="true"
                                    [maxSize]="5"
                                    (pageChange)="onPageChange()"
                                    [pageSize]="pageLength"></ngb-pagination>
                </div>
            </div>
        </div>
        <div class="mt-4 pl-1">
            <sl-back-navigation [route]="isSupportApp ? '/user/details/'+userId : '/produkte/start'"
                                [buttonText]="isSupportApp
                                ? ('USER.RECLAMATIONS.BUTTONS.back' | translate)
                                : ('ACCOUNT.BUTTONS.back' | translate)"></sl-back-navigation>
        </div>
    </div>
</div>
