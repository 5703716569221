import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { FormControl, FormGroup } from '@angular/forms';
import { UtilsService } from './utils.service';
import { environment } from '../../../../environments/environment';
import {
    CheckTypes,
    Detector,
    DetectorReclamationTypeRequest,
    FormSelectionObjects,
    FormSelectionValues, MassSerialItem,
    Product,
    ProductExaminationData, ReclamationCompleteData,
    SecondSerialTextValue,
    SerialNumberCheck,
    SerialReclamationTypeRequest,
    CheckTypeResponse,
    Feedback,
    ReclamationDetailed,
    ReclamationMeta,
    ReclamationSubmissionWithDetectors,
    ReclamationSubmissionWithSerials,
    StrictHttpParams,
    User,
    MassSerialCheck, CustomerType
} from '../models';
import { ApiResponse } from '../models/api.model';
import { concatMap, map, startWith, take } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngrx/store';
import { State } from '../../../reducers/root/root.reducer';
import * as ProductsAction from '../reducers/products/products.actions';
import { DistinguishSupportService } from './distinguish-support.service';


/**
 * Service the assists with forms
 */
@Injectable({
    providedIn: 'root'
})
export class FormDataService {
    public readonly PLACEHOLDER_IMG_URL: string = 'assets/placeholder-img.jpg';

    /**
     *
     * @param {HttpClient} Http
     * @param {Store<State>} store
     * @param {DistinguishSupportService} distinguishService
     * @param {TranslateService} translateService
     * @param {UtilsService} Utils
     */
    constructor(private Http:HttpClient,
                private store:Store<State>,
                private distinguishService:DistinguishSupportService,
                private translateService:TranslateService,
                private Utils:UtilsService) {
    }

    /**
     * Get the Dropdown values for the Product Form
     */
    public getFormSelectionValues():Observable<FormSelectionValues> {
        return this.Http.get<FormSelectionValues>('assets/form-values.json');
    }

    /**
     * Check to see if the Serial Number that the user entered is valid
     *
     * @param serialNum
     * @param recaptcha
     * @param extended
     */
    public checkSerialNumberIsValid(serialNum:string, recaptcha?:string, extended?:boolean):Observable<SerialNumberCheck> {
        let params = new StrictHttpParams();
        if (recaptcha) {
            params = params.set('recaptcha-response', recaptcha);
        }
        if (extended) {
            params = params.set('extendedInfo', 'true');
        }
        return this.Http.get<SerialNumberCheck>(this.Utils.replace(environment.api.product.serialCheck, serialNum), {params: params});
    }

    public checkMassSerials(serials: string, recaptcha?: string, extended: boolean = false): Observable<MassSerialCheck> {
        let params = new StrictHttpParams();
        if (recaptcha) {
            params = params.set('recaptcha-response', recaptcha);
        }
        if (extended) {
            params = params.set('extendedInfo', 'true');
        }
        return this.Utils.stepIntoData(
            this.Http.post<ApiResponse<MassSerialCheck>>(environment.api.product.serialMassCheck, serials, {params: params})
        );
    }

    /**
     * Build the Url so the user can see the Image
     *
     * @param articleId
     */
    public buildImageURL(articleId:string):string {
        return this.Utils.replace(environment.api.image.imageURL, articleId);
    }

    /**
     * Get the Text for the 2nd Serial Number
     */
    public getSecondSerialFieldText():Observable<SecondSerialTextValue> {
        return this.Http.get<SecondSerialTextValue>('assets/second-serial-text.json');
    }

    /**
     * Figure out what Reclamation Type the User qualifies for
     *
     * @param productData
     * @param recaptcha
     * @param customerTransactionNumber
     * @param withoutSerials
     */
    public calculateReclamationType(productData:Product[], customerTransactionNumber:string, recaptcha?:string, withoutSerials?:boolean):Observable<CheckTypeResponse> {
        let params;
        if (recaptcha) {
            params = new StrictHttpParams().set('recaptcha-response', recaptcha);
        }

        return this.Utils.stepIntoData(
            this.Http.post<ApiResponse<CheckTypeResponse>>(
                environment.api.submission.evaluation,
                withoutSerials ? this.prepareProductDataWithoutSerialNumbersForSending(productData, customerTransactionNumber)
                    : this.prepareProductDataForSending(productData, customerTransactionNumber), {params}
            )
        );
    }

    /**
     * Figure out what Reclamation Type the User qualifies for
     *
     * @param productData
     * @param userId
     * @param customerTransactionNumber
     * @param withoutSerials
     */
    public calculateUserReclamationType(productData:Product[], userId:string, customerTransactionNumber:string, withoutSerials?:boolean) {
        return this.Utils.stepIntoData(
            this.Http.post<ApiResponse<CheckTypeResponse>>(
                this.Utils.replace(environment.api.user.evaluation, userId),
                withoutSerials ? this.prepareProductDataWithoutSerialNumbersForSending(productData, customerTransactionNumber)
                    : this.prepareProductDataForSending(productData, customerTransactionNumber)
            )
        );
    }

    /**
     * Submit the reclamation that the user has entered
     *
     * @param reclamationData
     * @param recaptcha
     */
    public submitReclamation(reclamationData:ReclamationSubmissionWithDetectors | ReclamationSubmissionWithSerials,
                             recaptcha?:string):Observable<ReclamationDetailed> {
        let params;
        if (recaptcha) {
            params = new StrictHttpParams().set('recaptcha-response', recaptcha);
        }
        return this.Utils.stepIntoData(
            this.Http.post<ApiResponse<ReclamationDetailed>>(environment.api.submission.reclamation, reclamationData, {params})
        );
    }

    /**
     * Submit the reclamation that the user has entered
     *
     * @param reclamationData
     * @param recaptcha
     */
    public submitWarrantyReclamation(
        reclamationData: Product[],
        customerTransactionNumber: string,
        customerType: CustomerType,
        recaptcha?: string
    ): Observable<ReclamationDetailed> {
        let params;
        if (recaptcha) {
            params = new StrictHttpParams().set('recaptcha-response', recaptcha);
        }
        return this.Utils.stepIntoData(
            this.Http.post<ApiResponse<ReclamationDetailed>>(
                environment.api.submission.reclamation,
                {
                    ...this.prepareProductDataForSending(reclamationData, customerTransactionNumber),
                    reclamationType: 'warranty',
                    customerType
                },
                { params }
            )
        );
    }

    public submitUserReclamation(reclamationData:ReclamationSubmissionWithSerials | ReclamationSubmissionWithDetectors,
                                 userId:string):Observable<ReclamationDetailed> {
        return this.Utils.stepIntoData(
            this.Http.post<ApiResponse<ReclamationDetailed>>(this.Utils.replace(environment.api.user.reclamation, userId), reclamationData)
        );
    }

    public submitRegistration(user:User):Observable<User> {
        return this.Http.post<User>(environment.api.user.registration, user);
    }

    public getProductListToDisplay(products:Product[]):Product[] {
        const shownProductList:Product[] = [];
        products.forEach(product => {
            shownProductList.push(this.productArrayValue(product, product.productTitle, product.serialNumber, false));
            if (product.secondProductTitle) {
                shownProductList.push(this.productArrayValue(product, product.secondProductTitle, product.secondSerialNumber, true));
            }
        });
        return shownProductList;
    }

    private productArrayValue(product:Product, title:string, serial:string, isSecondaryProduct:boolean):Product {
        return {
            productIndex: product.productIndex,
            productTitle: title,
            productType: product.productType,
            serialNumber: serial,
            returnReason: product.returnReason,
            objectName: product.objectName,
            objectType: product.objectType,
            supplySource: product.supplySource,
            numberRange: product.numberRange,
            number: product.number,
            valid: isSecondaryProduct ? product.secondSerialNumberValid : product.valid,
            isSecondaryProduct: isSecondaryProduct,
            groupType: product.groupType,
            installationYear: product.installationYear,
            count: product.count,
            viability: product.viability
        };
    }

    private prepareProductDataForSending(productData:Product[], customerTransactionNumber:string):SerialReclamationTypeRequest {
        const dataToSend:ProductExaminationData[] = productData.map((product:Product) => ({
            serialNumber: product.serialNumber,
            valid: product.valid,
            supplySource: product.supplySource,
            objectName: product.objectName,
            objectType: product.objectType,
            returnReason: product.returnReason,
            numberRange: product.numberRange,
            number: product.number
        }));

        return {
            checkType: CheckTypes.serials,
            customerTransactionNumber: customerTransactionNumber,
            supplySource: productData[0]?.supplySource,
            objectName: productData[0]?.objectName,// send objectName with first serial to match backend api
            numberRange: productData[0]?.numberRange,
            number: productData[0]?.number,
            serials: dataToSend
        };
    }

    private prepareProductDataWithoutSerialNumbersForSending(productData:Product[], customerTransactionNumber:string):DetectorReclamationTypeRequest {
        const dataToSend:Detector[] = productData.map((product:Product) => ({
            installationYear: product.installationYear,
            groupType: product.groupType,
            count: product.count,
            valid: product.valid,
            objectName: product.objectName,
            objectType: product.objectType,
            returnReason: product.returnReason,
            numberRange: product.numberRange,
            number: product.number
        }));

        return {
            checkType: CheckTypes.detectors,
            customerTransactionNumber: customerTransactionNumber,
            supplySource: productData[0].supplySource,
            objectName: productData[0].objectName,// send objectName with first serial to match backend api
            numberRange: productData[0].numberRange,
            number: productData[0].number,
            detectors: dataToSend
        };
    }

    public sendFeedback(feedback:Feedback):Observable<any> {
        return this.Utils.stepIntoData(this.Http.post<any>(environment.api.user.feedback, feedback));
    }

    public sendFeedbackShown():Observable<any> {
        return this.Utils.stepIntoData(this.Http.post<any>(environment.api.user.feedbackShown, {}));
    }

    /**
     * Set the values in the Form to those from the User's Account Details, and empties the others
     *
     * @param userData
     * @param form
     */
    public setFormValues(userData:User, form:FormGroup):void {
        for (const key of Object.keys(form.controls)) {
            if (form.controls[key] && userData.hasOwnProperty(key)) {
                form.controls[key].setValue(userData[key]);
            } else if (form.controls[key] && userData.hasOwnProperty(key) === false) {
                form.controls[key].setValue(null);
            }
            if (key === 'address' || key === 'city' || key === 'zip' || key === 'country') {
                for (const addressKey in userData['addressInformation']) {
                    if (form.controls[addressKey] && userData['addressInformation'].hasOwnProperty(addressKey)) {
                        form.controls[addressKey].setValue(userData['addressInformation'][addressKey]);
                    }
                }
            }
        }
        if (form.contains('salesChannel')) {
            form.controls['salesChannel'].setValue(null);
        }
        if (form.contains('subSegment')) {
            form.controls['subSegment'].setValue(null);
        }
    }

    /**
     * Check to see if there are any invalid forms
     *[
     * @returns void
     */
    public invalidProductForms(products:Product[]):boolean {
        const invalidProductForms = products.filter((product) => !product.isValid);
        return invalidProductForms && invalidProductForms.length > 0;
    }

    /**
     * Check whether or not the whole Form is valid or not
     *
     * @param {FormGroup} formGroup
     */
    public validateAllFormFields(formGroup:FormGroup) {
        Object.keys(formGroup.controls).forEach(field => {
            const control = formGroup.get(field);
            if (control instanceof FormControl) {
                control.markAsTouched({onlySelf: true});
            } else if (control instanceof FormGroup) {
                this.validateAllFormFields(control);
            }
        });
    }

    /**
     * Get the dropdown values for the Product Form
     */
    public getDropdowns(customerType:CustomerType):Observable<FormSelectionObjects> {
        const transObj = this.populateTranslations(customerType);
        const emitImmediatlyAndOnLangChange:Observable<any> = this.translateService.onTranslationChange.pipe(startWith(1));

        return emitImmediatlyAndOnLangChange.pipe(concatMap(() => {
            return this.translateService.get(Object.values(transObj))
                .pipe(
                    map(translations => {
                        const formSelectionValues:FormSelectionObjects = {};
                        Object.keys(transObj).forEach(key => {
                            formSelectionValues[key] = translations[transObj[key]];
                        });
                        this.store.dispatch(new ProductsAction.SaveProductDropdowns(formSelectionValues));
                        return formSelectionValues;
                    })
                );
        }));
    }

    /**
     * Populate the dropdown values of the form
     *
     * @private
     */
    private populateTranslations(customerType:CustomerType) {
        return {
            supplySource: this.distinguishService.support
                ? `PRODUCTS.IDENTIFICATION.SELECTION_VALUES.PURCHASE.${customerType}`
                : 'PRODUCTS.IDENTIFICATION.SELECTION_VALUES.PLACE_OF_PURCHASE',
            reasonOfReturn: this.distinguishService.support ?
                'PRODUCTS.IDENTIFICATION.SELECTION_VALUES.REASON_OF_RETURN.' + customerType :
                'PRODUCTS.IDENTIFICATION.SELECTION_VALUES.REASON_OF_RETURN',
            objectType: 'PRODUCTS.IDENTIFICATION.SELECTION_VALUES.OBJECT_TYPES',
            totalDetectors: 'PRODUCTS.IDENTIFICATION.SELECTION_VALUES.TOTAL_DETECTORS',
            groupType: 'PRODUCTS.IDENTIFICATION.SELECTION_VALUES.PRODUCT_GROUP'
        };
    }

    public sendSerials(data:ReclamationCompleteData[], id:string, noWarn?:boolean,  onlyValidate: boolean = false):Observable<any> {
        let params = new StrictHttpParams();
            params = params.set('noWarn', noWarn ? 'true' : 'false');
            params = params.set('onlyValidate', onlyValidate ? 'true' : 'false');
        
        return this.Utils.stepIntoData(
            this.Http.post<ApiResponse<ReclamationDetailed>>(
                this.Utils.replace(environment.api.reclamation.complete, id),
                data,
                {params}
            ));
    }

    public retrieveReclamation(reclamationId:string):Observable<ReclamationDetailed & ReclamationMeta> {
        return this.Utils.stepIntoData(
            this.Http.get<ApiResponse<ReclamationDetailed & ReclamationMeta>>(this.Utils.replace(environment.api.reclamation.thankYou, reclamationId))
        );
    }

    public markControlsTouched(formGroup:FormGroup) {
        Object.keys(formGroup.controls).forEach(field => {
            const control = formGroup.get(field);
            if (control.errors?.required || (control.errors && !control.errors.required && control.value !== '' && control.value !== null)) {
                control.markAsTouched({onlySelf: true});
            }
        });
    }

    public setFormValuesFromStore(formGroup:FormGroup, storedData:any) {
        Object.keys(formGroup.controls).forEach(field => {
            if (storedData[field] !== null) {
                const control = formGroup.get(field);

                control.setValue(storedData[field]);
            }
        });
    }

    public isValid(form:FormGroup, valid:boolean) {
        if (form && !form.valid) {
            this.markFieldsAsTouched(form);
            valid = false;
        }
        return valid;
    }

    public markFieldsAsTouched(form) {
        Object.keys(form.controls).forEach(field => {
            const control = form.get(field);
            if (control.errors?.required || (control.errors && !control.errors.required && control.value !== '' && control.value !== null)) {
                control.markAsTouched({onlySelf: true});
            }
        });
    }
}
