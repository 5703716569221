import { Injectable } from '@angular/core';
import {
    ActivatedRouteSnapshot,
    CanActivate,
    CanActivateChild,
    CanLoad,
    Route,
    Router,
    RouterStateSnapshot,
    UrlSegment, UrlTree
} from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { LoginAuthGuard } from './login-auth.guard';
import { LangUtilsService, UserService, CustomerType } from 'shared-lib';

/**
 * Service to find out whether or not a user can access certain parts of the site if they are not logged in
 */
@Injectable()
export class AuthGuard implements CanActivate, CanActivateChild, CanLoad {

    /**
     *
     * @param {UserService} userService
     * @param {Router} router
     * @param {LangUtilsService} langUtils
     */
    constructor(private userService:UserService, private router:Router, private langUtils:LangUtilsService) {
    }

    /**
     * Verifies that a user is logged in and returns true otherwise redirect to login/register page and return false.
     *
     * @param {ActivatedRouteSnapshot} route Current route
     *
     * @returns {boolean} See description
     */
    canActivate(route:ActivatedRouteSnapshot):Observable<boolean> | Promise<boolean> | boolean {
        return this.userService.checkUserStatus(null, true)
            .pipe(map(response => {
                    const currentUser = this.userService.currentUserStatusValue;
                    if (currentUser && currentUser.loggedIn) {
                        this.langUtils.doGetTranslations(currentUser, true);
                    }
                    return response;
                }
            ));
    }

    /**
     * Checks if a user can access a child component/route if they are not logged in (they must be)
     *
     * @param route
     *
     * @returns {Observable<boolean> | boolean}
     */
    canActivateChild(route:ActivatedRouteSnapshot, state:RouterStateSnapshot):Observable<boolean> | boolean | UrlTree {
        if (state.url === '/produkte/start' && this.userService.currentUserStatusValue?.customerType === CustomerType.Private) {
            return this.router.parseUrl(LoginAuthGuard.DEFAULT_PRIVATE_LOGGED_IN_ROUTE);
        }
        return this.userService.checkUserStatus(null, true);
    }

    /**
     * See notes in LoginAuthGuard for more info on this implementation
     *
     * @param route
     * @param segments
     *
     * @returns {Observable<boolean> | Promise<boolean> | boolean}
     */
    canLoad(route:Route, segments:UrlSegment[]):Observable<boolean> | Promise<boolean> | boolean {
        const isRequestToLogin:boolean = !segments || !segments.length;
        const registrationIncomplete = sessionStorage.getItem('registrationIncomplete');
        return this.userService.checkUserStatus(
            isRequestToLogin
                ? registrationIncomplete
                ? LoginAuthGuard.COMPLETE_REGISTRATION
                : LoginAuthGuard.DEFAULT_LOGGED_IN_ROUTE
                : LoginAuthGuard.DEFAULT_LOGGED_OUT_ROUTE, !isRequestToLogin
        );
    }
}
