<ng-container>
    <div class="row mb-3 mt-5">
        <h3>{{"PRODUCTS.CONFIRMATION.CUSTOMERINFORMATION.ADDRESSTITLES." + type | translate}}</h3>
    </div>
    <div class="row">
        {{address?.company}}
    </div>
    <div class="row">
        <span>
            {{address?.address}}
        </span>
        <span *ngIf="address?.address2">
            , {{address.address2}}
        </span>
    </div>
    <div class="row">
        {{address?.zip}} {{address?.city}}
    </div>
</ng-container>
