<div class="container">
    <div class="col-12 col-md-10 mx-auto">
        <div class="row">
            <sl-back-navigation [buttonText]="'RECLAMATION_START.DETECTOR_RECLAMATION.backLinkText' | translate">
            </sl-back-navigation>
        </div>

        <div class="row py-3">
            <h1>
                <ng-container *ngIf="showWelcomeTitle; else normalTitle">
                    {{LoginPage.title_after_register| translate}}
                </ng-container>
                <ng-template #normalTitle>
                    {{LoginPage.heading| translate}}
                </ng-template>
            </h1>
        </div>
        <div class="row">
            <p class="lead">
                {{LoginPage.description | translate}}
            </p>
        </div>

        <div class="row py-3">
           <p class="spacer-right-left">
              {{LoginPage.noAccount | translate}} 
            </p> 
            <u class="btn-link" [routerLink]="'/'"> {{LoginPage.register | translate}} </u>
      </div>

        <div *ngIf="showBanner" class="row py-3">
            <sl-info-banner class="w-100" [bannerType]="bannerType" [textKey]="bannerTextKey"></sl-info-banner>
        </div>

        <div class="row">
            <!-- form -->
            <sl-login-form-control class="w-100" [redirectRoute]='redirectRoute'></sl-login-form-control>
            <!-- end form -->
        </div>

        <div class="row pt-3 " [innerHTML]="getSmallText()"></div>
    </div>
</div>