import * as actions from './user.actions';
import { UserActionTypes } from './user.actions';
import { User } from '../../models';

export const userFeatureKey = 'user';

export type State = User;

export const initialUserState:State = null;

export function reducer(state = initialUserState, action:actions.UserActions):State {
    switch (action.type) {
        case UserActionTypes.SAVE_USER_DETAILS:
            return {
                ...state,
                ...action.payload
            };

        case UserActionTypes.DELETE_USER_DETAILS:
            return initialUserState;

        default:
            return state;
    }
}
