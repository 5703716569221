import { Component, OnInit, ChangeDetectionStrategy, ViewChild, ElementRef } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { GeniusPlusOfferImages, PaymentIcons } from '../../models';

declare const window: {
  _paq: any;
} & Window;

@Component({
  selector: 'sl-genius-port-offer-modal',
  templateUrl: './genius-port-offer-modal.component.html',
  styles: [
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class GeniusPortOfferModalComponent implements OnInit {
  public readonly GeniusPlusOfferImages = GeniusPlusOfferImages;

  @ViewChild('offerModal', {static: true}) offerModal:ElementRef;

  _paq;

  /**
   *
   * @param modalService
   */
  constructor(private modalService:NgbModal) {
    this._paq = window._paq = window._paq || [];
  }

  ngOnInit(): void {
    this.showModal();
  }

  public navigateToAmazon() {
    this._paq.push(['trackEvent', 'checkoutEmpfehlung', 'clickAmazon']);
    window.open('https://www.amazon.de/dp/B07BNVS26B?maas=maas_adg_D0347E8EF540A00C0E83F49722716DE4_afap_abs&ref_=aa_maas&tag=maas&th=1', '_blank');
  }

  public navigateToConrad() {
    this._paq.push(['trackEvent', 'checkoutEmpfehlung', 'clickConrad']);
    window.open('https://www.conrad.de/de/p/hekatron-genius-port-funk-zentrale-vernetzbar-netzbetrieben-1673806.html', '_blank');
  }

  private showModal(): void {
    this._paq.push(['trackEvent', 'checkoutEmpfehlung', 'viewOverlay']);
    this.modalService.open(this.offerModal, {
      centered: true,
      ariaLabelledBy: 'modal-basic-title',
      size: 'lg'
    });
  }
}
