import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
    AddressDetailsComponent,
    AlertModalComponent,
    BackNavigationComponent,
    BusyBtnComponent,
    ContactComponent,
    DetailedCostListComponent,
    EditContainer,
    EmailAddressComponent,
    EmailTelComponent,
    FormItemComponent,
    HeaderComponent,
    IconTextBulletComponent,
    InfoBanner,
    InfoOutletComponent,
    NoSerialsFormComponent,
    NoSerialsMainFormComponent,
    PaymentDetailsComponent,
    PopoverComponent,
    PreventCursorKeysDirective,
    ProductFormObjectGroupComponent,
    ProductFormSupplySourceComponent,
    ProductListItemComponent,
    ProductsClaimedComponent,
    ProductsReplacedComponent,
    ProductTypeLabelComponent,
    PurchasedItemsComponent,
    QRCodeButton,
    ReclamationDetailComponent,
    ReclamationsTableComponent,
    ReportedDetectorsList,
    ReportedSerialsList,
    ScannerComponent,
    SearchSlotComponent,
    SerialEntryForm,
    SerialMassInputComponent,
    SerialNumberComponent,
    StatusColumnComponent,
    StepsInfoComponent,
    SupportContactDetailsComponent,
    TextareaComponent,
    TileComponent
} from './components';
import { NgbAccordionModule, NgbDropdownModule, NgbModalModule, NgbPopoverModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FeedbackModalComponent } from './components/feedback-modal/feedback-modal.component';
import { FocusDirective } from './directives/focus.directive';
import { NumberFormatPipe } from './pipes';
import { SweetAlert2Module } from "@sweetalert2/ngx-sweetalert2";
import { GeniusPortOfferModalComponent } from './components/genius-port-offer-modal/genius-port-offer-modal.component';

@NgModule({
    declarations: [
        BackNavigationComponent,
        BusyBtnComponent,
        EmailTelComponent,
        PopoverComponent,
        HeaderComponent,
        BackNavigationComponent,
        ReclamationsTableComponent,
        ScannerComponent,
        InfoOutletComponent,
        ReclamationDetailComponent,
        ProductsClaimedComponent,
        TextareaComponent,
        SearchSlotComponent,
        PreventCursorKeysDirective,
        ProductsReplacedComponent,
        EmailAddressComponent,
        SerialNumberComponent,
        FeedbackModalComponent,
        SerialMassInputComponent,
        SupportContactDetailsComponent,
        NumberFormatPipe,
        PurchasedItemsComponent,    
        IconTextBulletComponent,
        TileComponent,
        FocusDirective,
        StepsInfoComponent,
        FormItemComponent,
        InfoBanner,
        EditContainer,
        QRCodeButton,
        ProductListItemComponent,
        NoSerialsMainFormComponent,
        NoSerialsFormComponent,
        ProductFormSupplySourceComponent,
        ProductFormObjectGroupComponent,
        SerialEntryForm,
        ProductTypeLabelComponent,
        PaymentDetailsComponent,
        AddressDetailsComponent,
        ReportedSerialsList,
        DetailedCostListComponent,
        ReportedDetectorsList,
        ContactComponent,
        StatusColumnComponent,
        AlertModalComponent,
        GeniusPortOfferModalComponent
    ],
    imports: [
        CommonModule,
        FormsModule,
        NgbModalModule,
        NgbPopoverModule,
        ReactiveFormsModule,
        RouterModule,
        TranslateModule,
        NgbDropdownModule,
        NgbAccordionModule,
        SweetAlert2Module
    ],
    exports: [
        BackNavigationComponent,
        BusyBtnComponent,
        EmailTelComponent,
        PopoverComponent,
        TranslateModule,
        HeaderComponent,
        BackNavigationComponent,
        ReclamationsTableComponent,
        ReclamationDetailComponent,
        ScannerComponent,
        InfoOutletComponent,
        ProductsClaimedComponent,
        TextareaComponent,
        PreventCursorKeysDirective,
        SearchSlotComponent,
        ProductsReplacedComponent,
        EmailAddressComponent,
        SerialNumberComponent,
        FeedbackModalComponent,
        SerialMassInputComponent,
        SupportContactDetailsComponent,
        NumberFormatPipe,
        PurchasedItemsComponent,
        IconTextBulletComponent,
        TileComponent,
        StepsInfoComponent,
        FormItemComponent,
        InfoBanner,
        EditContainer,
        QRCodeButton,
        ProductListItemComponent,
        NoSerialsMainFormComponent,
        NoSerialsFormComponent,
        ProductFormSupplySourceComponent,
        ProductFormObjectGroupComponent,
        SerialEntryForm,
        ProductTypeLabelComponent,
        PaymentDetailsComponent,
        AddressDetailsComponent,
        ReportedSerialsList,
        DetailedCostListComponent,
        ReportedDetectorsList,
        ContactComponent,
        StatusColumnComponent,
        AlertModalComponent,
        GeniusPortOfferModalComponent
    ]
})
export class SharedModule {
}
