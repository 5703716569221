<div class="col-12 col-md-10 mx-auto">
    <div class="row my-3 pb-2" *ngIf="isSupport">
        <div class="col-6">
            <h3>{{'ACCOUNT.MANAGEMENT.LABEL.Pisa-Kundennummer' | translate}}</h3>
            <span>{{user?.extSystemId || '-'}}</span>
        </div>
    </div>
    <div class="row my-3 pb-2" *ngIf="isSupport">
        <div class="col-6">
            <h3>{{'ACCOUNT.MANAGEMENT.LABEL.businessPartnerNumber' | translate}}</h3>
            <span>{{user?.businessPartnerNumber || '-'}}</span>
        </div>
    </div>
    <div class="row my-3 pb-2">
        <div class="col-6" *ngIf="user?.customerType === CustomerType.Commercial">
            <h3>{{'ACCOUNT.MANAGEMENT.LABEL.customerNumber' | translate}}</h3>
            <span>{{customerNumberToShow || '-'}}</span>
        </div>
        <div class="d-flex justify-content-end" *ngIf="canEdit"
            [ngClass]="{'col-6': user?.customerType === CustomerType.Commercial, 'col-12': user?.customerType === CustomerType.Private }">
            <button class="btn rp-btn-link-black" [disabled]="!user" (click)="editForm()"><i
                    class="fa fa-pencil mr-2"></i>{{'ACCOUNT.BUTTONS.edit' | translate}}
            </button>
        </div>
    </div>
    <ng-container *ngIf="(user?.customerType === CustomerType.Commercial) && isSupport">
        <div class="row my-3 pb-2 d-flex align-items-center">
            <div class="col-12">
                <h3>{{'ACCOUNT.MANAGEMENT.LABEL.customerType' | translate}}</h3>
            </div>
        </div>

        <div class="row my-3">
            <div class="col-6">
                <p class="font-weight-bold">{{'ACCOUNT.MANAGEMENT.LABEL.installer' | translate}}</p>
                <span>{{isInstaller}}</span>
            </div>
            <div class="col-6">
                <p class="font-weight-bold">{{'ACCOUNT.MANAGEMENT.LABEL.hasLicense' | translate}}</p>
                <span>{{hasLicense}}</span>
            </div>
        </div>
    </ng-container>


    <div class="row my-3 pb-2 d-flex align-items-center">
        <div class="col-12">
            <h3>{{'ACCOUNT.MANAGEMENT.LABEL.address' | translate}}</h3>
        </div>
    </div>

    <div class="row my-3">
        <div class="col-12" *ngIf="user?.customerType === CustomerType.Commercial">
            <p class="font-weight-bold">{{'ACCOUNT.MANAGEMENT.LABEL.company' | translate}}</p>
            <span>{{user?.company}}</span>
        </div>
    </div>
    <div class="row my-3">
        <div class="col-12">
            <p class="font-weight-bold">{{'ACCOUNT.MANAGEMENT.LABEL.salutation' | translate}}</p>
            <span>{{user?.salutation === 0 ? ('REGISTRATION.FORM.SALUTATION.mr' | translate) : ''}}
                {{user?.salutation === 1 ? ('REGISTRATION.FORM.SALUTATION.mrs' | translate) : ''}}
                {{user?.salutation === undefined ? '-' : ''}}</span>
        </div>
    </div>
    <div class="row my-3">
        <div class="col-6">
            <p class="font-weight-bold">{{'ACCOUNT.MANAGEMENT.LABEL.firstname' | translate}}</p>
            <span>{{user?.firstname}}</span>
        </div>
        <div class="col-6">
            <p class="font-weight-bold">{{'ACCOUNT.MANAGEMENT.LABEL.lastname' | translate}}</p>
            <span>{{user?.lastname}}</span>
        </div>
    </div>
    <div class="row my-3">
        <div class="col-12">
            <p class="font-weight-bold">{{'ACCOUNT.MANAGEMENT.LABEL.street' | translate}}</p>
            <span>{{user?.addressInformation.address}}</span>
        </div>
    </div>
    <div class="row my-3">
        <div class="col-12">
            <p class="font-weight-bold">{{'ACCOUNT.MANAGEMENT.LABEL.additionalInfo' | translate}}</p>
            <span>
                <ng-container *ngIf="user?.addressInformation?.address2; else dash">
                    {{user?.addressInformation?.address2}}
                </ng-container>
            </span>
        </div>
    </div>
    <div class="row my-3">
        <div class="col-6">
            <p class="font-weight-bold">{{'ACCOUNT.MANAGEMENT.LABEL.zip' | translate}}</p>
            <span>{{user?.addressInformation.zip}}</span>
        </div>
        <div class="col-6">
            <p class="font-weight-bold">{{'ACCOUNT.MANAGEMENT.LABEL.city' | translate}}</p>
            <span>{{user?.addressInformation.city}}</span>
        </div>
    </div>
    <div class="row my-3">
        <div class="col-12">
            <p class="font-weight-bold">{{'ACCOUNT.MANAGEMENT.LABEL.country' | translate}}</p>
            <span>{{user?.addressInformation.country}}</span>
        </div>
    </div>
    <div class="row my-3">
        <div class="col-12">
            <p class="font-weight-bold">{{'ACCOUNT.MANAGEMENT.LABEL.email' | translate}}</p>
            <span>{{user?.email}}</span>
        </div>
    </div>
    <div class="row my-3">
        <div class="col-12">
            <p class="font-weight-bold">{{'ACCOUNT.MANAGEMENT.LABEL.phoneNr' | translate}}</p>
            <span>
                <ng-container *ngIf="user?.phone; else dash">
                    {{user?.phone}}
                </ng-container>
            </span>
        </div>
    </div>


</div>

<ng-template #dash>
    <span class="ml-2">
        -
    </span>
</ng-template>
