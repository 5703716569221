


      <sl-form-item  
      [inputType]="FormInputType.TEXT" 
      [formGroup]="parentForm"
      [controlName]="controlName" 
      [labelText]="'PRODUCTS.IDENTIFICATION.LABELS.objectName'"
      [placeholderText]="placeholder"
      >
      </sl-form-item>

    <ul class="list-group m-0 resultList" [hidden]="!isVisible">
        <ng-container *ngIf="searchType === 'userObject'">
            <li *ngFor="let item of items; let i=index"
                class="list-group-item"
                [class.active]="activeNode === i">
                <span class=" d-block" (click)="onSubmitSelectedValue(item)">{{item.objectName}}</span>
            </li>
        </ng-container>

        <li *ngIf="items?.length == 0" class="list-group-item no-data">
            <small>{{'PRODUCTS.IDENTIFICATION.SEARCH_SLOT.noData' | translate}}</small>
        </li>
    </ul>


