import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'sl-info-outlet',
    templateUrl: './info-outlet.component.html',
    styles: [],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class InfoOutletComponent implements OnInit {

    @Input() title:string;
    @Input() type: 'warning' | 'info' = 'info';

    constructor() {
    }

    ngOnInit():void {
    }

}
