import { API, HOST, PATH, processObjRec } from './common';

/**
 * Constants used for Production Environment
 */
export const environment = {
    production: true,
    base: HOST + PATH,
    path: PATH,
    api: API
};

/**
 * Combine Consts
 */
processObjRec(environment.api, HOST + PATH);
