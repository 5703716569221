import {
    RegisterAccount,
    RegisterAddress,
    BusinessCustomerType, CustomerType, ExternalData
} from '../../models';
import * as fromRegUtil from './register.reducer.util';
import * as RegistrationAction from './register.actions';
import { getSalesChannel } from './register.reducer.util';

export interface Registration {
    customerType:CustomerType;
    account:RegisterAccount;
    address:RegisterAddress;
    businessCustomerType:BusinessCustomerType;
    externalData:ExternalData
}

export interface State {
    registrationData:Registration;
}


export const initialState:State = {
    registrationData:
        {
            customerType: null,
            account: null,
            address: null,
            businessCustomerType: null,
            externalData: null
        }
};

export function RegistrationReducer(state:State = initialState, action:RegistrationAction.RegistrationAction):State {
    switch (action.type) {
        case RegistrationAction.ADD_USER_TYPE:
            return {
                ...state,
                registrationData: fromRegUtil.getNewCustomerType(state, action)
            };
        case RegistrationAction.ADD_ACCOUNT:
            return {
                ...state,
                registrationData: fromRegUtil.getNewAccount(state, action)
            };
        case RegistrationAction.ADD_ADDRESS:
            return {
                ...state,
                registrationData: fromRegUtil.getNewAddress(state, action)
            };
        case RegistrationAction.ADD_BUSINESS_CUSTOMER_TYPE:
            return {
                ...state,
                registrationData: fromRegUtil.getNewBusinessCustomerTypeOption(state, action)
            };
        case RegistrationAction.ADD_SALES_CHANNEL:
            return {
                ...state,
                registrationData: fromRegUtil.getSalesChannel(state, action)
            };
        case RegistrationAction.ADD_USER_INFO:
            return {
                ...state, registrationData: action.payload
            };
        case RegistrationAction.DELETE_ACCOUNT:
            return {
                ...state,
                registrationData: fromRegUtil.getDeletedAccount(state)
            };
        case RegistrationAction.DELETE_ADDRESS:
            return {
                ...state,
                registrationData: fromRegUtil.getDeletedAddress(state)
            };
        case RegistrationAction.DELETE_USER_TYPE:
            return {
                ...state,
                registrationData: fromRegUtil.getDeletedUserType(state)
            };
        case RegistrationAction.DELETE_ALL:
            return {
                ...state,
                registrationData: fromRegUtil.getDeletedRegistrationForm(state)
            };
        default:
            return state;
    }
}