import { Component, OnInit, ChangeDetectionStrategy, OnDestroy, Input } from '@angular/core';
import { FormControl, Validators, FormGroup } from '@angular/forms';
import {
    FormInputType,
    LoginData,
    FormDataService,
    InfoBannerType,
    UtilsService,
    UserService,
    RecaptchaService
} from '../../../../shared';
import { tap, takeUntil, mergeMap } from 'rxjs/operators';
import { EMPTY, Observable, Subject } from 'rxjs';

@Component({
    selector: 'sl-anonymous-login-form',
    templateUrl: './anonymous-login-form.component.html',
    styleUrls: []
})
export class AnonymousLoginFormComponent implements OnInit, OnDestroy {
    @Input() redirectRoute:string;
    private unsubscribe$ = new Subject<any>();
    public InputType = FormInputType;
    public emailForm:FormGroup;
    public bannerTextKey:string;
    public bannerType:InfoBannerType;
    public showBanner:boolean;
    public requestGuestLoginAction:Observable<unknown>;

    constructor(private utilsService:UtilsService,
                private userService:UserService,
                public Recaptcha:RecaptchaService,
                private formDataService:FormDataService) {
    }

    ngOnDestroy():void {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    ngOnInit():void {
        this.createForm();
        this.guestLogin();
    }

    private guestLogin() {
        this.requestGuestLoginAction = this.utilsService.createActionObs(() => {
            return this.Recaptcha.getToken('login', true).pipe(
                mergeMap(response =>
                    this.userService.loginAndUpdateStoredData(AnonymousLoginFormComponent.getLoginObj(this.emailForm.get('email').value, response), true)
                ),
                this.makeLoginResponse(),
                takeUntil(this.unsubscribe$));

        });
    }

    private createForm() {
        this.emailForm = new FormGroup(AnonymousLoginFormComponent.getEmailFormControl());
    }

    private static getEmailFormControl() {
        return {
            email: new FormControl(null, {
                updateOn: 'change',
                validators: [Validators.required, Validators.email]
            })
        };
    }

    private makeLoginResponse() {
        return tap(
            () => {
                this.userService.restoreSavedRoute(this.redirectRoute);
            },
            (err) => {
                this.setLoginError(err);
            }
        );
    }

    private static getLoginObj(email:string, response:string):LoginData {
        return {
            username: email,
            reCaptchaResponse: response
        };
    }

    private setLoginError(err:any) {
        this.setBannerState();
        if (AnonymousLoginFormComponent.isEmailAlreadyInUse(err)) {
            this.bannerTextKey = 'ERRORS.ERROR_USER_EXISTS.text', 'ERRORS.ERROR_USER_EXISTS.title';
        } else {
            this.bannerTextKey = 'GENERAL.VALIDATION_ERRORS.guestLoginFailed';
        }
    }

    private static isEmailAlreadyInUse(err:any):boolean {
        return err.error && err.error.errors[0].code === 'ERROR_USER_EXISTS';
    }

    private setBannerState():void {
        this.bannerType = InfoBannerType.DANGER;
        this.showBanner = true;
    }

    public submit() {
        if (!this.emailForm.valid) {
            this.formDataService.markControlsTouched(this.emailForm);
            return;
        }
    }

}
