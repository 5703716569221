import * as productActions from './products.actions';
import { KeyValuePair, Product, DropdownValue, ProductsState } from '../../models';

export type State = ProductsState;

/**
 * Set the initial State
 */
export const initialState:ProductsState = {
    products: [],
    reasonOfReturn: {},
    objectType: [],
    totalDetectors: {},
    groupType: {},
    supplySource: {},
    enterSerialsLater: false,
    globalReasonSet: false,
    orderNumber: null,
    customerNumber: null
};

/**
 * Reducer function that Saves Product List, Dropdowns, Updates and Deletes Products
 *
 * @param state
 * @param action
 */
export function reducer(state = initialState, action:productActions.ProductsActions):ProductsState {
    let newState:ProductsState;
    switch (action.type) {
        case productActions.SAVE_PRODUCT_LIST:
            newState = Object.assign({}, state, {
                products: action.payload
            });
            break;
        case productActions.SAVE_PRODUCT_DROPDOWNS:
            newState = Object.assign({}, state, {
                reasonOfReturn: action.payload.reasonOfReturn,
                objectType: action.payload.objectType,
                totalDetectors: action.payload.totalDetectors,
                groupType: action.payload.groupType,
                supplySource: action.payload.supplySource
            });
            break;
        case productActions.UPDATE_PRODUCT:
            newState = Object.assign({}, state, {});
            break;
        case productActions.DELETE_PRODUCT:
            newState = Object.assign({}, state, {});
            break;
        case productActions.DELETE_ALL_PRODUCTS:
            newState = Object.assign({}, state, {
                products: []
            });
            break;
        case productActions.ENTER_SERIALS_LATER:
            newState = Object.assign({}, state, {
                enterSerialsLater: action.payload
            });
            break;
        case productActions.SET_GLOBAL_RETURN_REASON:
            newState = Object.assign({}, state, {
                globalReasonSet: action.payload
            });
            break;
        case productActions.SAVE_MASS_SERIALS_CLAIM:
            newState = Object.assign({}, state, {
                products: action.payload.products,
                customerNumber: action.payload.customerNumber,
                orderNumber: action.payload.orderNumber
            });
            break;
        default:
            return state;
    }

    return JSON.parse(JSON.stringify(newState));
}
