<div class="container">
    <div class="col-12 col-md-10 mx-auto my-3">
        <ng-container *ngIf="(reclamationDetails || showIntermediateFeedback) else error">
            <div class="row my-3">
                <h1>
                    {{showIntermediateFeedback
                    ? ('PRODUCTS.CONFIRMATION.titleSimplified' | translate)
                    : ('PRODUCTS.CONFIRMATION.title' | translate)}}
                </h1>
                <p class="lead">
                    <span>{{'PRODUCTS.CONFIRMATION.lead1' | translate}}</span>
                    <span *ngIf="!showIntermediateFeedback">{{'PRODUCTS.CONFIRMATION.lead2' | translate}}</span>
                </p>
            </div>

            <div class="row py-3" *ngIf="!showIntermediateFeedback">
                <sl-info-banner *ngIf="reclamationDetails && reclamationDetails?.transactionNumber" class="w-100"
                                [bannerType]="bannerType.INFO"
                                [boldTextKey]="'PRODUCTS.CONFIRMATION.bannerTransaction'"
                                [text]="reclamationDetails?.transactionNumber">
                </sl-info-banner>
            </div>

            <div class="row py-3" *ngIf="reclamationDetails && reclamationDetails?.containsSerialsToBeReturned
            && reclamationDetails?.customerType === CustomerType.Commercial">
                <sl-info-banner class="w-100"
                                [bannerType]="bannerType.INFO"
                                [textKey]="'PRODUCTS.CONFIRMATION.containsSerialsToBeReturned'">
                </sl-info-banner>
            </div>

            <div class="row my-3">
                <ng-container *ngFor="let titleDescIcon of iconTextBullets">
                    <sl-icon-text-bullet class="w-100" [titleDescIcon]="titleDescIcon"></sl-icon-text-bullet>
                </ng-container>
            </div>

            <ng-container *ngTemplateOutlet="buttons"></ng-container>

            <ng-container *ngIf="!showIntermediateFeedback">

                <div class="row mt-5">
                    <h2>{{"PRODUCTS.CONFIRMATION.paymentTitle" | translate}}</h2>
                </div>

                <sl-reported-serials-list *ngIf="reclamationDetails?.serials"
                                          [serials]="reclamationDetails?.serials"></sl-reported-serials-list>
                <sl-reported-detectors-list *ngIf="reclamationDetails?.detectors"
                                            [detectors]="reclamationDetails?.detectors"></sl-reported-detectors-list>

                <sl-cost-product-list [detectors]="reclamationDetails?.detectorsToExchange"
                                      [free]="true"></sl-cost-product-list>
                <sl-cost-product-list [detectors]="reclamationDetails?.detectorsToBuy"
                                      [free]="false"></sl-cost-product-list>

                <div class="shipping-information">
                    <div class="row mt-5">
                        <h3>{{"PRODUCTS.CONFIRMATION.SHIPPINGINFORMATION.title" | translate}}</h3>
                    </div>
                    <div class="row my-3 justify-content-between">
                        <div>{{"PRODUCTS.CONFIRMATION.SHIPPINGINFORMATION.standard-shipping" | translate}}</div>
                        <div class="font-weight-bold">{{"PRODUCTS.CONFIRMATION.ABBREVATIONS.free" | translate}}</div>
                    </div>
                </div>

                <sl-address-details [address]="reclamationDetails?.addressInformation"
                                    [type]="'customer'"></sl-address-details>

                <sl-address-details [address]="reclamationDetails?.billingAddressInformation"
                                    [type]="'billing'"></sl-address-details>

                <sl-payment-details [paymentInformation]="reclamationDetails?.paymentInformation"></sl-payment-details>
            </ng-container>

        </ng-container>
    </div>
</div>
<!-- {{reclamation$ |async}} -->
<sl-feedback-modal [feedbackShown]="feedbackShown"></sl-feedback-modal>

<sl-genius-port-offer-modal *ngIf="advertisingGeniusPort"></sl-genius-port-offer-modal>

<ng-template #buttons>
    <div class="row my-3">
        <button *ngIf="currentUser?.loggedIn"
                class="btn btn-secondary btn-mobile-block mr-sm-2 mb-2 no-vertical-alignment"
                (click)="onMyReclamation()">
            <span>{{"PRODUCTS.CONFIRMATION.BUTTONS.myReclamations" | translate}}</span>
        </button>
        <button class="btn btn-mobile-block mr-sm-2 mb-2 no-vertical-alignment"
                [ngClass]="{'btn-tertiary': currentUser?.loggedIn, 'btn-secondary': !currentUser?.loggedIn}"
                (click)="startNewReclamation()">
            <span>{{"PRODUCTS.CONFIRMATION.BUTTONS.addReclamation" | translate}}</span>
        </button>
    </div>
</ng-template>

<ng-template #error>
    <div class="row my-3">
        <sl-info-banner class="w-100" [bannerType]="bannerType.DANGER"
                        [textKey]="errormessage">
        </sl-info-banner>
    </div>

    <ng-container *ngTemplateOutlet="buttons"></ng-container>
</ng-template>
