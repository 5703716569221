<ng-container *ngIf="formCreated">
    <form (ngSubmit)="onSubmit()" [formGroup]="productForm" id="no-serials-form-{{productIndex}}">
        <!-- PRODUCT GROUP -->
        <div class="row">
            <p class="lead">
                {{"PRODUCTS.IDENTIFICATION.LABELS.groupType" | translate}}
            </p>
        </div>
        <div class="row">
            <sl-form-item [formGroup]="productForm" [controlName]="'groupType'" [inputType]="FormInputType.RADIO"
                          [placeholderText]="'PRODUCTS.IDENTIFICATION.PLACEHOLDER.groupType'"
                          [selectionValues]="detectorOptions"></sl-form-item>
        </div>

        <!-- INSTALLATION YEAR -->
        <div class="row">
            <div class="col-sm px-0">
                <sl-form-item [formGroup]="productForm" [controlName]="'installationYear'"
                              [inputType]="FormInputType.NUMBER"
                              [placeholderText]="'PRODUCTS.IDENTIFICATION.PLACEHOLDER.installationYear'"
                              [labelText]="'PRODUCTS.IDENTIFICATION.LABELS.installationYear'"></sl-form-item>
            </div>
        </div>

        <!-- COUNT -->
        <div class="row">
            <div class="col-sm px-0">
                <sl-form-item [formGroup]="productForm" [controlName]="'count'" [inputType]="FormInputType.NUMBER"
                              [helperText]="'PRODUCTS.IDENTIFICATION.POPOVER_TEXTS.count'"
                              [placeholderText]="'PRODUCTS.IDENTIFICATION.PLACEHOLDER.count'"
                              [labelText]="'PRODUCTS.IDENTIFICATION.LABELS.count'"></sl-form-item>
            </div>
        </div>

        <div *ngIf="productIndex < 1">
            <!--SUPPLY SOURCE -->
            <div class="row">
                <div class="col-sm px-0">
                    <sl-form-item [inputType]="FormInputType.DROPDOWN"
                                  [formGroup]="productForm"
                                  [controlName]="'supplySource'"
                                  [labelText]="'PRODUCTS.IDENTIFICATION.LABELS.supplySource'"
                                  [placeholderText]="'PRODUCTS.IDENTIFICATION.LABELS.supplySource'"
                                  [dropdownValues]="formSelectionValues.supplySource">
                    </sl-form-item>
                </div>
            </div>
            <!--OBJECT NAME TYPE NUMBER-->
            <sl-product-form-object-group
                    [searchOff]="searchOff"
                    [formSelectionValues]="formSelectionValues"
                    [product]="product"
                    [address]="address"
                    [customerType]="customerType"
            ></sl-product-form-object-group>
        </div>

        <!--IDENTIFY MORE PRODUCTS-->
        <div class="mt-3 row">
            <div class="col-12" *ngIf="totalProducts > 0">
                <button [swal]="deleteSwal" class="btn btn-tertiary">
                    {{'PRODUCTS.IDENTIFICATION.BUTTONS.removeProduct' | translate}}
                </button>

                <swal
                    #deleteSwal
                    (confirm)="deleteProduct()"
                    [focusCancel]="true"
                    [showCancelButton]="true"
                    text="{{'PRODUCTS.IDENTIFICATION.REMOVE_PRODUCT.text' | translate}}"
                    title="{{'PRODUCTS.IDENTIFICATION.REMOVE_PRODUCT.title' | translate}}">
                </swal>
            </div>
        </div>
    </form>
</ng-container>
