import { Directive, HostBinding, HostListener } from '@angular/core';

@Directive({
  selector: '[slFocus]'
})
export class FocusDirective {
  @HostBinding('style.borderColor') borderColor: string = '#ddd';
  @HostListener('mouseenter') mouseover() {
    this.borderColor = '#2F6BAF';
  }
  @HostListener('mouseleave') mouseleave() {
    this.borderColor = '#ddd';
  }
  constructor() { }

}
