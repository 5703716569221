import {Component, EventEmitter, OnDestroy, OnInit, Output} from '@angular/core';
import {
    BarcodeFormat,
    BinaryBitmap,
    BrowserMultiFormatReader,
    DecodeHintType, HTMLCanvasElementLuminanceSource,
    HybridBinarizer,
    LuminanceSource
} from '@zxing/library';
import {AbstractControl, FormBuilder, FormGroup} from '@angular/forms';
import {DeviceChosenService, UtilsService} from '../../services';

const decodeHints: Map<DecodeHintType, any> = new Map();
decodeHints.set(DecodeHintType.POSSIBLE_FORMATS, [BarcodeFormat.DATA_MATRIX]);
decodeHints.set(DecodeHintType.TRY_HARDER, true);

/**
 * Extends the BrowserMultiFormatReader to scan normal and inverted QR Codes
 */
class CustomReader extends BrowserMultiFormatReader {
    private invertImage: boolean = false;

    createBinaryBitmap(mediaElement: HTMLImageElement) {
        this.drawImageOnCanvas(mediaElement, {
            sx: 0,
            sy: 0,
            sWidth: mediaElement.naturalWidth,
            sHeight: mediaElement.naturalHeight,
            dx: 0,
            dy: 0,
            dWidth: mediaElement.naturalWidth,
            dHeight: mediaElement.naturalHeight,
        }, this.getCaptureCanvasContext(mediaElement));
        let luminanceSource: LuminanceSource = new HTMLCanvasElementLuminanceSource(this.getCaptureCanvas());
        if (this.invertImage) {
            luminanceSource = luminanceSource.invert();
            this.invertImage = false;
        } else {
            this.invertImage = true;
        }
        const hybridBinarizer = new HybridBinarizer(luminanceSource);
        return new BinaryBitmap(hybridBinarizer);
    }
}

/**
 * Component that handles Scanning QR Codes
 */
@Component({
    selector: 'sl-scanner',
    templateUrl: './scanner.component.html',
    styles: []
})
export class ScannerComponent implements OnInit, OnDestroy {
    public customReader: CustomReader;
    public videoDevices: MediaDeviceInfo[];
    public videoDeviceForm: FormGroup;
    public videoDeviceIsReady: boolean = false;
    public showForm: boolean = false;
    public showNotReadableError: boolean = false;

    @Output() endScanner = new EventEmitter();
    @Output() scannedSuccesful = new EventEmitter();
    @Output() devicesNotFound = new EventEmitter();

    /**
     * Getter for the sourceSelect control of the videoDeviceForm
     */
    get sourceSelectControl(): AbstractControl {
        return this.videoDeviceForm.get('sourceSelect');
    }

    /**
     * Getter that finds out if the user's device is a mobile one or not
     */
    get isMobileDevice(): boolean {
        return this.utils.detectMobileDevice();
    }

    /**
     *
     * @param {FormBuilder} FB
     * @param {DeviceChosenService} DeviceChosen
     * @param {UtilsService} utils
     */
    constructor(private FB: FormBuilder,
                private DeviceChosen: DeviceChosenService,
                private utils: UtilsService) {
    }

    /**
     * @ignore
     */
    ngOnInit(): void {
        this.customReader = new CustomReader(decodeHints);
        this.startCustomCodeReader();
    }

    /**
     * Resets the code reader to the initial state. Cancels any ongoing barcode scanning from video or camera.
     */
    ngOnDestroy(): void {
        this.customReader.reset();
    }

    /**
     * Start listening for available video input devices and if present start decoding.
     */
    public startCustomCodeReader(): void {
        this.customReader
            .listVideoInputDevices()
            .then(
                (videoInputDevices: MediaDeviceInfo[]) => {
                    this.videoDevices = videoInputDevices;

                    if (!this.videoDevices) {
                        this.videoDeviceIsReady = false;
                        this.devicesNotFound.emit();
                    } else {
                        let target: string;
                        if (this.DeviceChosen.getCurrentlyChosenDevice() !== null) {
                            target = this.DeviceChosen.getCurrentlyChosenDevice();
                        } else {
                            target = this.videoDevices.length > 1 && this.utils.detectMobileDevice() ?
                                // assumption that on mobile devices backcamera is listed after front camera
                                this.videoDevices[1].deviceId :
                                this.videoDevices[0].deviceId;
                            this.DeviceChosen.newDeviceChosen(target);
                        }

                        this.createForm(target);
                        this.startDecoding(target);
                    }
                }
            )
            .catch(() => {
                this.devicesNotFound.emit();
            });
    }

    /**
     * Start decoding with the provided deviceId. If scan is successful, emit the extracted serialnumber to the ProductFormComponent.
     * @param {string} deviceId
     */
    private startDecoding(deviceId: string) {
        this.videoDeviceIsReady = true;

        this.customReader.decodeFromVideoDevice(deviceId, 'video',
            result => {
                if (!result) {
                    return;
                }
                this.scannedSuccesful.emit(result.getText());
            })
            .then(() => {
                },
                (rej) => {
                    if (rej instanceof DOMException) {
                        this.showNotReadableError = rej.name === 'NotReadableError';
                    }
                }
            );
    }

    /**
     * Creates the form for video source selection and sets the provided deviceId as selected source.
     * @param {string} deviceId
     */
    private createForm(deviceId: string): void {
        this.videoDeviceForm = this.FB.group({
            sourceSelect: [deviceId]
        });
        this.showForm = true;
        this.sourceSelectControlChanged();
    }

    /**
     * Resets the current customReader instance to initial state, creates the form with changed source and starts decoding with it.
     */
    private sourceSelectControlChanged(): void {
        this.sourceSelectControl.valueChanges.subscribe(
            deviceId => {
                this.DeviceChosen.newDeviceChosen(deviceId);
                this.showNotReadableError = false;
                this.customReader.reset();
                this.createForm(deviceId);
                this.startDecoding(deviceId);
            }
        );
    }
}
