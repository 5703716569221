import { Injectable } from '@angular/core';
import { isNumeric } from 'rxjs/internal-compatibility';
import { ToastsService } from './toasts.service';
import { AbstractControl, FormControl } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UtilsService } from './utils.service';


@Injectable({
    providedIn: 'root'
})
export class ScannerService {

    constructor(private ModalService:NgbModal,
                private Utils:UtilsService,
                private Toast:ToastsService) {
    }

    /**
     * Start scanning code
     * @param {any} content
     */
    public startScanner(content:any):void {
        this.ModalService.open(
            content,
            {
                centered: true,
                ariaLabelledBy: 'modal-title',
                windowClass: this.Utils.detectMobileDevice() ? 'mobile-scanner-modal' : ''
            }
        );
    }

    /**
     * checks if code is valid datamatrix code
     * @param text
     */
    public isValidDatamatrixCodeStructure(text:string):boolean {
        const regexp = new RegExp(/(^[^|]{1,16}\|[^|]{1,16}\|\d{0,3}\|\d{1,10}\|(\d{6})?\|(\d{6})?\|([^|]*\|){4}[^|]*\|?$)/);
        return regexp.test(text);
    }

    /**
     * returns serial number from dmc
     * @param {string} scanningResult
     */
    public extractSerialNumberFromDataMatrixCode(scanningResult:string):string {
        const resultParts:string [] = scanningResult.split('|');
        return resultParts[3];
    }

    /**
     * determines whether a text is a serial number
     * @param {string} text
     */
    public isSerialNumber(text:string):boolean {
        return isNumeric(text) && text.length > 0 && text.length <= 10;
    }

    /**
     * On successfully scanning result,
     * checks if result is a valid serial number or is a valid DCM that contains a serial number and if yes,
     * saves this serial number in the serial number control
     * then ends scanner
     * @param {string} result
     * @param {AbstractControl} control
     */
    public onScannerSuccess(result:string, control:AbstractControl):void {
        const isValidDatamatrixCodeStructure = this.isValidDatamatrixCodeStructure(result);
        if (isValidDatamatrixCodeStructure) {
            const serialNumber = this.extractSerialNumberFromDataMatrixCode(result);
            control.setValue(serialNumber);
            this.onEndScanner();
            return;
        }
        const supposedSerialNumber = result;
        if (this.isSerialNumber(supposedSerialNumber)) {
            control.setValue(supposedSerialNumber);
            this.onEndScanner();
            return;
        }
        this.Toast.showWarning(
            'PRODUCTS.IDENTIFICATION.SCANNER.ERRORS.formatNotSupported',
            '',
            {timeOut: 7000}
        );
        this.onEndScanner();
    }

    /**
     * Close scanner modal window.
     */
    public onEndScanner() {
        if (this.ModalService.hasOpenModals()) {
            this.ModalService.dismissAll();
        }
    }

    /**
     * Show text that no device was found and after three seconds hide message.
     */
    public onDevicesNotFound(showNoScanDeviceFound:boolean) {
        this.onEndScanner();
        showNoScanDeviceFound = true;
        setTimeout(() => {
            showNoScanDeviceFound = false;
        }, 3000);
    }
}
