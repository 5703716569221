<div class="container">
    <div class="col-12 col-md-10 mx-auto">
        <div class="row flex-column my-3">
            <h1>{{'GENERAL.SERIAL_MASS_CLAIM.CONFIRMATION.title' | translate}}</h1>
            <p class="lead">
                {{'GENERAL.SERIAL_MASS_CLAIM.CONFIRMATION.lead' | translate}}
            </p>
        </div>
        <div class="row flex-column my-3" *ngIf="orderNumber">
            <h3>{{'GENERAL.SERIAL_MASS_CLAIM.CONFIRMATION.orderNumber' | translate:{orderNumber: orderNumber} }}</h3>
        </div>
        <div class="row flex-column my-3" *ngIf="customerNumber">
            <h3>{{'GENERAL.SERIAL_MASS_CLAIM.CONFIRMATION.customerNumber' | translate:{customerNumber: customerNumber} }}</h3>
        </div>
        <div class="row flex-column mt-2 mb-5">
            <div class="col-12 px-0">
                <span class="pr-2" *ngFor="let product of products; let i=index">
                    <ng-container *ngIf="products.length === 1; else more">
                        {{product.serialNumber}}
                    </ng-container>

                    <ng-template #more>
                        <ng-container *ngIf="(i+1) < products.length; else last">
                            {{product.serialNumber}},
                        </ng-container>
                        <ng-template #last>
                            {{product.serialNumber}}
                        </ng-template>
                    </ng-template>
                    
                </span>
            </div>
        </div>

        <div class="row my-3">
            <button class="btn btn-secondary btn-mobile-block mr-sm-2 mb-2 no-vertical-alignment" (click)="onMyReclamation()">
                <span>{{'PRODUCTS.CONFIRMATION.BUTTONS.myReclamations' | translate}}</span>
            </button>
            <button class="btn btn-tertiary btn-mobile-block mr-sm-2 mb-2 no-vertical-alignment" (click)="onStartNewReclamation()">
                <span>{{'PRODUCTS.CONFIRMATION.BUTTONS.newReclamation' | translate}}</span>
            </button>
        </div>
    </div>
</div>
