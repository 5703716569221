import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ProductsState, UserService, CustomerType } from '../../../shared';
import { Store } from '@ngrx/store';
import { State } from '../../../../reducers/root/root.reducer';
import { take } from 'rxjs/operators';
import { Observable } from 'rxjs';
import * as ProductsAction from '../../../shared/reducers/products/products.actions';

@Component({
    selector: 'sl-reclamation-start',
    templateUrl: './reclamation-start.component.html',
    styles: [],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ReclamationStartComponent implements OnInit {

    public userId:string = undefined;
    public reclamationsWithoutSerialsEnabled:boolean;
    public massClaimAllowed:boolean;
    private enterSerialsLater:boolean;
    private products$:Observable<ProductsState>;
    businessUser: boolean;

    public CustomerType = CustomerType;
    
    get customerType(): CustomerType {
        return this.userService.currentUserStatusValue.customerType;
    }

    constructor(private router:Router,
                private route:ActivatedRoute,
                private store:Store<State>,
                private userService:UserService) {
        this.reclamationsWithoutSerialsEnabled = this.userService.currentUserStatusValue.reclamationsWithoutSerialsEnabled;
        this.massClaimAllowed = this.userService.currentUserStatusValue.massClaimAllowed;
        this.products$ = this.store.select('products');
    }

    ngOnInit():void {
        this.redirectGuests();
        this.products$.pipe(take(1)).subscribe((products) => {
            this.enterSerialsLater = products.enterSerialsLater;
        });
        this.userId = this.route.snapshot.params.id;
    }

    private redirectGuests() {
        if (this.userService.currentUserStatusValue.customerType === CustomerType.Guest) {
            this.proceedToProductIdentification();
        }

        this.businessUser = this.userService.currentUserStatusValue.customerType === CustomerType.Commercial;

    }

    public proceedToProductIdentification():void {
        if (this.enterSerialsLater) {
            this.store.dispatch(new ProductsAction.EnterSerialsLaterAction(false));
        }
        this.store.dispatch(new ProductsAction.DeleteAllProductAction());
        this.userId
            ? this.router.navigateByUrl('/produkte/identifikation/' + this.userId)
            : this.router.navigate(['/produkte', 'identifikation']);
    }

    public proceedToSimpleIdentifikation(): void {
        this.store.dispatch(new ProductsAction.DeleteAllProductAction());
        this.router.navigate([this.userId ? '/produkte/identifizierung-vereinfacht/' + this.userId : '/produkte/identifizierung-vereinfacht']);
    }

    public proceedToSerialnumbersCallOrder():void {
        this.router.navigate(['/produkte', 'seriennummern-abrufauftrag']);
    }

}
