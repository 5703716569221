<div class="row">
  <sl-form-item [formGroup]="productForm" [controlName]="'groupType'" [inputType]="FormInputType.RADIO"
                [placeholderText]="'PRODUCTS.IDENTIFICATION.PLACEHOLDER.groupType'"
                [selectionValues]="detectorOptions"></sl-form-item>
</div>

<!-- INSTALLATION YEAR -->
<div class="row">
  <div class="col-sm px-0">
      <sl-form-item [formGroup]="productForm" [controlName]="'installationYear'"
                    [inputType]="FormInputType.NUMBER"
                    [placeholderText]="'PRODUCTS.IDENTIFICATION.PLACEHOLDER.installationYear'"
                    [labelText]="'PRODUCTS.IDENTIFICATION.LABELS.installationYear'"></sl-form-item>
  </div>
</div>

<!-- COUNT -->
<div class="row">
  <div class="col-sm px-0">
      <sl-form-item [formGroup]="productForm" [controlName]="'count'" [inputType]="FormInputType.NUMBER"
                    [helperText]="'PRODUCTS.IDENTIFICATION.POPOVER_TEXTS.count'"
                    [placeholderText]="'PRODUCTS.IDENTIFICATION.PLACEHOLDER.count'"
                    [labelText]="'PRODUCTS.IDENTIFICATION.LABELS.count'"></sl-form-item>
  </div>
</div>


  <!--SUPPLY SOURCE -->
  <div class="row">
      <div class="col-sm px-0">
          <sl-form-item [inputType]="FormInputType.DROPDOWN"
                        [formGroup]="productForm"
                        [controlName]="'supplySource'"
                        [labelText]="'PRODUCTS.IDENTIFICATION.LABELS.supplySource'"
                        [placeholderText]="'PRODUCTS.IDENTIFICATION.LABELS.supplySource'"
                        [dropdownValues]="formSelectionValues.supplySource">
          </sl-form-item>
      </div>
  </div>