import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../shared';
import { TranslateModule } from '@ngx-translate/core';
import { EmailAndPassinputComponent } from './components/email-and-passinput/email-and-passinput.component';
import { CustomerTypeSelectionComponent } from './components/customer-type-selection/customer-type-selection.component';
import { AddressFormComponent } from './components/address/address-form/address-form.component';
import { AddressRegistration } from './components/address/address-registration.component';
import { BusinessUserTypeComponent } from './components/business-user-type/business-user-type.component';
import { BusinessLicenseComponent } from './components/business-license/business-license.component';
import { CustomerAccountCreationComponent } from './components/customer-account-creation/customer-account-creation.component';
import { AccountFormComponent } from './components/customer-account-creation/account-form/account-form.component';
import { RegistrationSummaryComponent } from './components/registration-summary/registration-summary.component';
import { RegistrationSummaryAddressComponent } from './components/registration-summary/registration-summary-address/registration-summary-address.component';
import { RegistrationSummaryUserProfileComponent } from './components/registration-summary/registration-summary-user-profile/registration-summary-user-profile.component';
import { LoginComponent } from './components/login/login.component';
import { LoginFormControlComponent } from './components/login/login-form-control/login-form-control.component';
import { ForgotPassModalComponent } from './components/login/forgot-pass-modal/forgot-pass-modal.component';
import { SalesChannelComponent } from './components/sales-channel/sales-channel.component';
import { RegistrationSummaryBusinessAccountComponent } from './components/registration-summary/registration-summary-business-account/registration-summary-business-account.component';
import { RouterModule } from '@angular/router';

@NgModule({
    declarations: [
        EmailAndPassinputComponent,
        CustomerTypeSelectionComponent,
        AddressRegistration,
        AddressFormComponent,
        BusinessUserTypeComponent,
        BusinessLicenseComponent,
        CustomerAccountCreationComponent,
        AccountFormComponent,
        RegistrationSummaryComponent,
        RegistrationSummaryAddressComponent,
        RegistrationSummaryUserProfileComponent,
        RegistrationSummaryBusinessAccountComponent,
        LoginComponent,
        LoginFormControlComponent,
        ForgotPassModalComponent,
        SalesChannelComponent, 
  ],
    entryComponents:[ForgotPassModalComponent],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        SharedModule,
        TranslateModule,
        RouterModule
    ],
    exports: [
        TranslateModule,
        EmailAndPassinputComponent,
        CustomerTypeSelectionComponent,
        AddressRegistration,
        AddressFormComponent,
        BusinessUserTypeComponent,
        CustomerAccountCreationComponent,
        AccountFormComponent,
        RegistrationSummaryComponent,
        RegistrationSummaryAddressComponent,
        RegistrationSummaryUserProfileComponent,
        RegistrationSummaryBusinessAccountComponent,
        LoginComponent,
        LoginFormControlComponent,
        ForgotPassModalComponent,
        SalesChannelComponent,
    ]

})
export class RegistrationModule {
}
