import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Observable, of, Unsubscribable } from 'rxjs';
import { LoginStatus, RecaptchaStatus } from '../models';
import { UserService } from './user.service';
import { debounceTime, distinctUntilChanged, filter, map, mergeMap } from 'rxjs/operators';
import { fromPromise } from 'rxjs/internal-compatibility';

declare global {
    interface Window {
        grecaptcha:{
            execute(siteKey:string, params:{ action:string; }):Promise<string>;
        };
    }
}

/**
 * Service to assist with the Recaptcha to make sure the user is not a bot
 */
@Injectable({providedIn: 'root'})
export class RecaptchaService implements OnDestroy {
    private readonly _reCaptchaElemId = 'recaptcha-jssdk';

    private recaptchaStatus:BehaviorSubject<RecaptchaStatus> = new BehaviorSubject<RecaptchaStatus>(null);
    private recaptchaStatusVal:RecaptchaStatus;

    private recaptchaScriptPresent:boolean;
    private currentSiteKey:string;

    private _subUserStatus:Unsubscribable;
    private _subRecaptchaStatus:Unsubscribable;

    public getCurrentRecaptchaStatus():Observable<RecaptchaStatus> {
        return this.recaptchaStatus.asObservable().pipe(filter(v => !!v));
    }

    /**
     *
     * @param {UserService} User
     */
    constructor(private User:UserService) {
        const $user = !sessionStorage.getItem('currentUser') ? this.User.currentLoggedInStatus() : of(null);
        $user.pipe(mergeMap((status) => {
            return this.setCurrentUserStatus(status);
        }), mergeMap(userStatus => {
            this.setRecaptchaStatus(userStatus);
            return this.getCurrentRecaptchaStatus();
        })).subscribe(status => {
            if (!this.recaptchaScriptPresent && status && status.enabled) {
                this.currentSiteKey = status.siteKey;
                this.addRecaptchaScript();
            } else if (this.recaptchaScriptPresent && status && !status.enabled) {
                this.removeRecaptchaScript();
            } else if (status && status.enabled && this.currentSiteKey !== status.siteKey) {
                this.removeRecaptchaScript();
                this.currentSiteKey = status.siteKey;
                this.addRecaptchaScript();
            } else {
                this.addRecaptchaScript();
            }
        });
    }

    /**
     * @ignore
     */
    ngOnDestroy():void {
        this._subUserStatus.unsubscribe();
        this._subRecaptchaStatus.unsubscribe();
    }

    /**
     * Check to see if the Recaptcha is enabled
     *
     * @param obs
     */
    public isRecaptchaEnabled(obs:true):Observable<boolean>;
    public isRecaptchaEnabled(obs:false):boolean;
    public isRecaptchaEnabled(obs:boolean):Observable<boolean> | boolean {
        if (obs) {
            return this.getCurrentRecaptchaStatus().pipe(
                map(v => v.enabled)
            );
        }
        return this.recaptchaStatusVal ? this.recaptchaStatusVal.enabled : false;
    }

    /**
     * Get the Site Key
     *
     * @param obs
     */
    public getSiteKey(obs:true):Observable<string>;
    public getSiteKey(obs:false):string;
    public getSiteKey(obs:boolean):Observable<string> | string {
        if (obs) {
            return this.getCurrentRecaptchaStatus().pipe(
                map(v => v.siteKey)
            );
        }
        return this.recaptchaStatusVal ? this.recaptchaStatusVal.siteKey : null;
    }

    /**
     * Get the Recaptcha token
     *
     * @param action
     */
    public getToken(action, isLogin?:boolean):Observable<string> {
        if (!this.isRecaptchaEnabled(false) || !window.grecaptcha) {
            return of(null);
        }
        const $obs = isLogin ? this.User.currentLoggedInStatus() : of(null);
        return $obs.pipe(mergeMap((status) => {
                return this.setCurrentUserStatus(status);
            }), mergeMap(userStatus => {
                this.setRecaptchaStatus(userStatus);
                return this.getSiteKey(true);
            }),
            distinctUntilChanged(),
            filter(siteKey => !!siteKey),
            mergeMap(siteKey => window.grecaptcha
                ? fromPromise(window.grecaptcha.execute(siteKey, {action: action}))
                : of(null))
        );
    }

    /**
     * Add the Recaptcha Script
     */
    private addRecaptchaScript():void {
        if (this.recaptchaScriptPresent) {
            return;
        }
        const siteKey = this.currentSiteKey;
        (function(d, s, id, obj) {
            let js, fjs = d.getElementsByTagName(s)[0];
            if (d.getElementById(id)) {
                return;
            }
            js = d.createElement(s);
            js.id = id;
            js.src = 'https://www.google.com/recaptcha/api.js?render=' + siteKey;
            fjs.parentNode.insertBefore(js, fjs);
        }(document, 'script', this._reCaptchaElemId, this));
        this.recaptchaScriptPresent = true;
    }

    /**
     * Remove the Recaptcha Script
     */
    private removeRecaptchaScript():void {
        if (!this.recaptchaScriptPresent) {
            return;
        }
        const reCaptcha = document.getElementById(this._reCaptchaElemId);
        if (reCaptcha) {
            reCaptcha.remove();
            this.recaptchaScriptPresent = false;
        }
    }

    private setCurrentUserStatus(status):Observable<LoginStatus> {
        if (status) {
            sessionStorage.setItem('currentUser', JSON.stringify(status));
        }
        return this.User.getCurrentUserStatus();
    }

    private setRecaptchaStatus(userStatus) {
        const reCaptcha = userStatus?.reCaptcha;
        if (!this.recaptchaStatusVal && reCaptcha
            || this.recaptchaStatusVal && reCaptcha && (this.recaptchaStatusVal.enabled !== reCaptcha.enabled
                || reCaptcha.enabled && this.recaptchaStatusVal.siteKey !== reCaptcha.siteKey)) {
            this.recaptchaStatusVal = reCaptcha;
            this.recaptchaStatus.next(this.recaptchaStatusVal);
        }
    }
}
