<div class="row">
    <div class="col-12 col-md-10 mx-auto d-flex justify-content-between">
        <div>
            <div>
                <h3>{{'REGISTRATION.SUMMARY_PAGE.BUSINESS_ACCOUNT.title' | translate}}</h3>
            </div>
            <p *ngIf="businessCustomerType">
                {{'REGISTRATION.BUSINESS_CUSTOMER_TYPE.' + businessCustomerType + '.summary' | translate}}
            </p>
            <ng-container *ngIf="externalData">
                <p *ngIf="externalData.salesChannel">
                    {{'REGISTRATION.FORM.SALES_CHANNEL.' + externalData.salesChannel | translate}}
                </p>
                <p *ngIf="externalData.subSegment">
                    {{'REGISTRATION.FORM.SUB_SEGMENT.' + externalData.subSegment | translate}}
                </p>
            </ng-container>
        </div>
        <div>
            <button (click)="goToEdit()" class="btn p-2">
                <i class="fa fa-pencil text-blue"></i>
            </button>
        </div>
    </div>
</div>