import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppComponent } from './app.component';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { ReactiveFormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { CommonModule } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';
import { SharedModule } from './shared/shared.module';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
import { HvgContactComponent } from './components/hvg-contact/hvg-contact.component';
import { DeviceChosenService } from './services/device-chosen.service';
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { CustomTranslateLoader } from './services/custom-translate.loader';
import { defaultLang } from '../environments/common';
import { MenuItemsService } from './services/menu-items.service';
import { AuthGuard } from './services/auth.guard';
import { LoginAuthGuard } from './services/login-auth.guard';
import { environment } from '../environments/environment';
import { DistinguishSupportService, NgrxConfigModule, SharedLibModule, XHttpClient } from 'shared-lib';
import { PasswordResetComponent } from './registration/components/password-reset/password-reset.component';
import { EmptyLogoutComponent } from './empty-logout/empty-logout.component';

/**
 * Create the Translate Loader
 *
 * @param http
 */
export function createTranslateLoader(http:XHttpClient) {
    return new CustomTranslateLoader(http);
}

@NgModule({
    declarations: [
        AppComponent,
        HvgContactComponent,
        PasswordResetComponent,
        EmptyLogoutComponent
    ],
    imports: [
        CommonModule,
        BrowserAnimationsModule,
        BrowserModule,
        HttpClientModule,
        NgrxConfigModule,
        ReactiveFormsModule,
        SharedModule,
        AppRoutingModule,
        ToastrModule.forRoot({
            timeOut: 3000,
            positionClass: 'toast-bottom-right',
            closeButton: true
        }),
        SweetAlert2Module.forRoot(),
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: (createTranslateLoader),
                deps: [HttpClient]
            }
        }),
        SharedLibModule.forRoot(environment.base)
    ],
    providers: [
        DeviceChosenService,
        DistinguishSupportService,
        MenuItemsService,
        {provide: CustomTranslateLoader, useExisting: TranslateLoader},
        AuthGuard,
        LoginAuthGuard
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
    private language:string = defaultLang;

    constructor(private Translate:TranslateService, private Distinguish:DistinguishSupportService) {
        this.Translate.setDefaultLang(this.language);
        this.Translate.use(this.language);
        this.Distinguish.support = false;

    }
}
