import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { InfoBannerType } from '../../../shared/models/info-banner.model';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { State } from '../../../../reducers/root/root.reducer';
import * as EvaluationAction from '../../../shared/reducers/evaluation/evaluation.actions';
import { Observable } from 'rxjs';
import {
  DistinguishSupportService,
  productActions,RoutePathName,
  User
} from '../../../shared';
@Component({
  selector: 'sl-contact-support',
  templateUrl: './contact-support.component.html',
  styleUrls: [],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ContactSupportComponent implements OnInit {
  public transBase = 'PRODUCTS.WARRANTY_CONFIRMATION.';
  public infoBannerBoldTextKey: string;
  public infoBannerTextKey: string;
  public showBanner: boolean = true;
  public infoBannerType = InfoBannerType.DANGER;
  public currentUser: User;
  public userId: string;
  private user$: Observable<User>;
  constructor(private router: Router,
    public distinguishService: DistinguishSupportService,
    private store: Store<State>,
  ) { }

  ngOnInit(): void {
    this.initialize();
  }

  private initialize() {
    this.setInfoBanner();
    this.getState();
    this.getCurrentUser();
  }

 

  private getState() {
    this.user$ = this.store.select('user');
    
  }

  private getCurrentUser() {
    this.user$.subscribe(user => {
      this.userId = user ? user.id : null;
    });
  }

  public setInfoBanner() {
    this.infoBannerBoldTextKey = 'PRODUCTS.EVALUATION.infoBannerBold';
    this.infoBannerTextKey = 'PRODUCTS.EVALUATION.infoBannerDanger';
  }

  public userReclamations() {
    this.store.dispatch(new productActions.DeleteAllProductAction());
    this.store.dispatch(new EvaluationAction.DeleteReclamationAction());
    this.router.navigate([this.distinguishService.support
        ? `${RoutePathName.USER}/${RoutePathName.RECLAMATIONS}/${this.userId}`
        : `${RoutePathName.SERVICE_MESSAGE}`
    ]);
  }
  
  public newReclamation() {
    this.store.dispatch(new productActions.DeleteAllProductAction());
    this.store.dispatch(new EvaluationAction.DeleteReclamationAction());
    this.router.navigate([this.distinguishService.support
        ? `${RoutePathName.PRODUCT}/${RoutePathName.START}/${this.userId}`
        : `${RoutePathName.PRODUCT}/${RoutePathName.START}`
    ]);
}
}
