import { Component } from '@angular/core';
import { LoginPageLang, UtilsService, InfoBannerType, RecaptchaService } from '../../../shared';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute } from '@angular/router';
import { interval } from 'rxjs';
import { takeWhile, take } from 'rxjs/operators';

@Component({
    selector: 'sl-login',
    templateUrl: './login.component.html'
})
export class LoginComponent extends LoginPageLang {
    public readonly redirectRoute:string = '/produkte/identifikation';
    public bannerType:InfoBannerType;
    public showBanner:boolean = false;
    public bannerTextKey:string;
    public showWelcomeTitle:boolean = false;

    private readonly anchorMiddleSpacer:string = 'class=\"spacer-right-left\"';
    private readonly privacyPolicyUrl:string = 'https://policies.google.com/privacy?hl=de-DE&fg=1';
    private readonly termsOfUse:string = 'https://policies.google.com/terms?hl=de-DE&fg=1';

    constructor(
        private translateService:TranslateService,
        private utilsService:UtilsService,
        private route:ActivatedRoute,
        private reCaptchaService:RecaptchaService) {
        super();
        this.getUrlParams();
    }

    getSmallText() {
        if (this.reCaptchaService.isRecaptchaEnabled(false)) {
            const toReplace = this.translateService.instant(this.LoginPage.small_text);
            const replace0 = `<a class="text-secondary" ${this.anchorMiddleSpacer} href=${this.privacyPolicyUrl}>${this.translateService.instant(this.LoginPage.privacy_policy)}</a>`;
            const replace1 = `<a class="text-secondary" ${this.anchorMiddleSpacer} href=${this.termsOfUse}>${this.translateService.instant(this.LoginPage.terms_of_use)}</a>`;
            const replaced = this.utilsService.replaceText(toReplace, [replace0, replace1]);
            return `<span class = "small">${replaced}</span>`;
        } else {
            return '';
        }
    }

    public getUrlParams() {
        this.route.queryParamMap.pipe(take(1)).subscribe(params => {
            if (params) {
                let stopCondition = false;
                switch (params.get('emailConfirm')) {
                    case 'activated':
                        stopCondition = this.onActiveEmail(stopCondition);
                        this.showWelcomeTitle = true;
                        break;
                    case 'expired':
                        stopCondition = this.onExpiredEmail(stopCondition);
                        this.showWelcomeTitle = false;
                        break;
                }
            }
        });
    }

    private onExpiredEmail(stopCondition:boolean) {
        interval(0).pipe(takeWhile(() => !stopCondition)).subscribe(() => {
            stopCondition = true;
            this.makeBannerMessage(InfoBannerType.DANGER, 'LOGIN.emailConfirmExpired');
        });
        return stopCondition;
    }

    private onActiveEmail(stopCondition:boolean) {
        interval(0).pipe(takeWhile(() => !stopCondition)).subscribe(() => {
            stopCondition = true;
            this.makeBannerMessage(InfoBannerType.SUCCESS, 'REGISTRATION.success');
        });
        return stopCondition;
    }

    private makeBannerMessage(type:InfoBannerType, lang:string) {
        this.setBannerState(type);
        this.bannerTextKey = lang;
    }

    private setBannerState(type:InfoBannerType):void {
        this.bannerType = type;
        this.showBanner = true;
    }

}
