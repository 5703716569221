import { Component, OnInit, ChangeDetectionStrategy, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import {
    CheckTypeResponse,
    CustomerType,
    DistinguishSupportService,
    FormDataService,
    InfoBannerType,
    MassSerialCheck,
    MassSerialItem,
    Product,
    ProductService,
    ProductsState,
    ProductValidityEnum,
    ProductViabilityName,
    RecaptchaService,
    RoutePathName,
    SerialEntryForm,
    SerialMassClaim,
    SerialMassClaimResponse,
    User,
    UserDetailsService,
    UserService,
    UtilsService,
    ValidationService
} from '../../../shared';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { State } from '../../../../reducers/root/root.reducer';
import { Observable, of, Subject } from 'rxjs';
import { catchError, map, mergeMap, take, takeUntil, tap } from 'rxjs/operators';
import { NavigationUtilsService } from '../../../shared/services/navigation-utils.service';
import { FormControl, FormGroup } from '@angular/forms';
import * as ProductsAction from '../../../shared/reducers/products/products.actions';
import { HttpErrorResponse } from '@angular/common/http';
import { SerialService } from '../../../shared/services/serial/serial.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as EvaluationAction from '../../../shared/reducers/evaluation/evaluation.actions';

@Component({
    selector: 'sl-retrieval-order-list',
    templateUrl: './retrieval-order-list.component.html',
    styles: []
})
export class RetrievalOrderListComponent implements OnInit, OnDestroy {
    @ViewChild('infoBanner', {static: false, read: ElementRef}) banner:ElementRef;
    @ViewChild('addMoreSerialsForm') addMoreSerialEntryForm:SerialEntryForm;
    @ViewChild('serialsAlreadyClaimedWarning', {static: true}) serialsAlreadyClaimedWarning:ElementRef;

    public products:Product[] = [];
    public invalidProducts:Product[];
    public unfilteredProducts:Product[] = [];
    public orderNumber:string;
    public bannerTextKey:string;
    public bannerType:InfoBannerType;
    public showBanner:boolean = false;
    public addMore:boolean = false;
    public InfoBannerType = InfoBannerType;
    public user:User;
    public actionProceed:Observable<SerialMassClaimResponse>;
    public submitAlthoughWarnedAction:Observable<SerialMassClaimResponse>;
    public addSerialsAction:Observable<any>;
    public requestResult:SerialMassClaimResponse;
    public collectionSize:number;
    public page:number = 1;
    public pageLength:number = 20;
    public alreadyClaimedSerials:string[] = [];
    public formGroup:FormGroup;

    private products$:Observable<ProductsState | any>;
    private customerNumber:string;
    private _unsubscribe = new Subject();

    get viableValidProducts():Product[] {
        return this.unfilteredProducts.filter(product => product.viability === ProductViabilityName.VALID);
    }

    get paginatedViableValidProducts():Product[] {
        const startAt = this.page === 1 ? 0 : ((this.page - 1) * this.pageLength);
        return this.viableValidProducts.splice(startAt, this.pageLength);
    }

    get viableInvalidProducts():Product[] {
        return this.unfilteredProducts.filter(product => product.viability === ProductViabilityName.INVALID);
    }

    get viableCorruptedProducts():Product[] {
        return this.unfilteredProducts.filter(product => product.viability === ProductViabilityName.CORRUPTED);
    }

    constructor(private router:Router,
                private route:ActivatedRoute,
                private formDataService:FormDataService,
                private store:Store<State>,
                private utils:UtilsService,
                private recaptchaService:RecaptchaService,
                private validationService:ValidationService,
                private distinguishSupportService:DistinguishSupportService,
                private userDetailsService:UserDetailsService,
                private navigationUtils:NavigationUtilsService,
                private userService:UserService,
                private serialService:SerialService,
                private modal:NgbModal) {
        this.products$ = this.store.select('products');
        this.actionProceed = this.utils.createActionObs(() => this.proceedToNext());
        this.submitAlthoughWarnedAction = this.utils.createActionObs(() => this.proceedToNext(true));
        this.addSerialsAction = this.utils.createActionObs(() => this.addSerials());
    }

    ngOnInit():void {
        this.getProductFromStore();

        if (this.distinguishSupportService.support) {
            this.userDetailsService
                .getUserFromStoreOrLoadUserUseRoute(this.route, 'id')
                .pipe(takeUntil(this._unsubscribe))
                .subscribe((user:User) => {
                    this.user = user;
                });
        } else {
            this.user = JSON.parse(sessionStorage.getItem('currentUser'));
        }
    }

    /**
     * @ignore
     */
    ngOnDestroy():void {
        this._unsubscribe.next();
        this._unsubscribe.complete();
    }

    public toggleAddMore() {
        this.addMore = !this.addMore;
    }

    public updateProduct(serialEntryForm:FormGroup, originalProduct:Product, index:number) {
        this.recaptchaService.getToken('serials/check').pipe(
            take(1),
            tap(() => {
                this.showBanner = false;
                this.checkFieldValidations(serialEntryForm);
                this.validationService.checkForDuplicates(serialEntryForm, this.unfilteredProducts, originalProduct, originalProduct.productIndex, index);
            }),
            mergeMap(response => {
                const serials = serialEntryForm.value?.serial;
                return this.formDataService.checkMassSerials(serials, response, true);
            }))
            .subscribe(
                (res) => {
                    const serialItem = res.valid[0] || res.corrupted[0] || res.invalid[0];
                    const viabilityType = res.valid[0] ? ProductViabilityName.VALID : (res.corrupted[0] ? ProductViabilityName.CORRUPTED : ProductViabilityName.INVALID);
                    this.unfilteredProducts = this.unfilteredProducts
                        .map(product => product.serialNumber === originalProduct.serialNumber ?
                            this.createUpdatedProduct(serialItem, viabilityType, originalProduct) : product);

                    this.store.dispatch(new ProductsAction.SaveProductsAction(this.unfilteredProducts));
                },
                (error) => {
                    this.setInfoBanner(error);
                });
    }

    /**
     * Delete product from the list and then update the Store
     *
     * @param {Product} productToDelete
     */
    public deleteProduct(productToDelete:Product) {
        this.unfilteredProducts.splice(productToDelete.productIndex, 1);
        this.unfilteredProducts.forEach((p, i) => {
            p.productIndex = i;
        });
        this.store.dispatch(new ProductsAction.SaveProductsAction(this.unfilteredProducts));
    }

    /**
     * Navigates the user to the next step in the Reclamation process
     *
     * @returns Observable<ReclamationType>
     */
    private proceedToNext(warningHasBeenShown?:boolean):Observable<SerialMassClaimResponse> {
        const observable:Observable<SerialMassClaimResponse> = warningHasBeenShown
            ? this.serialService.massClaimSerialNumbers(this.collectFormData(), true, 'ERROR_INVALID_SERIAL')
            : this.serialService.massClaimSerialNumbers(this.collectFormData(), false, 'ERROR_INVALID_SERIAL');
        return observable.pipe(
            map((res:SerialMassClaimResponse) => {
                this.requestResult = res;
                this.router.navigate([`/${RoutePathName.PRODUCT}/${RoutePathName.RETRIEVAL_ORDER_CONFIRMATION}`]);
            }),
            catchError((err:HttpErrorResponse) => {
                const errCode = err?.error?.errors[0]?.code;
                const errDetail = err?.error?.errors[0]?.detail;
                this.setInfoBanner(err.error.errors[0]);
                if (errCode === 'ERROR_INVALID_SERIAL') { // = part of input is valid serial number
                    this.showWarning(errDetail);
                }
                return of(null);
            })
        );
    }

    private collectFormData():SerialMassClaim {
        let serials:string = '';
        this.viableValidProducts.forEach(product => {
            serials += product.serialNumber + ' ';
        })
        return {
            serialsString: serials,
            orderNumber: this.orderNumber,
            customerNumber: this.customerNumber
        };
    }

    private getProductFromStore() {
        this.products$
            .pipe(takeUntil(this._unsubscribe))
            .subscribe((products) => {
                this.unfilteredProducts = products.products;
                this.orderNumber = products.orderNumber;
                this.customerNumber = products.customerNumber;
                if (!products.products || products.products.length <= 0) {
                    this.user?.customerType === CustomerType.Commercial
                        ? this.router.navigate(['/produkte', 'start'])
                        : this.router.navigate(['/produkte', 'auswahl-reklamation']);
                }
            });
    }

    private createProductArray(serials:MassSerialCheck, products:Product[], serialEntryForm):Product[] {
        Object.keys(serials).forEach(viabilityType => {
            products = serials[viabilityType].map((serial:MassSerialItem, index:number) => ({
                isValid: viabilityType === 'valid',
                productImg: serial.article?.hasImage ? this.formDataService.buildImageURL(serial.article?.id) : this.formDataService.PLACEHOLDER_IMG_URL,
                productIndex: index,
                productTitle: serial.article?.type,
                serialNumber: serial.serial,
                serialNumberIsChecked: true,
                valid: serial.valid,
                viability: viabilityType
            })).concat(products);
        });

        return products;
    }

    private createUpdatedProduct(serial:MassSerialItem, viabilityType:ProductViabilityName, originalProduct:Product):Product {
        return {
            isValid: viabilityType === 'valid',
            productImg: serial.article?.hasImage ? this.formDataService.buildImageURL(serial.article?.id) : this.formDataService.PLACEHOLDER_IMG_URL,
            productIndex: originalProduct.productIndex,
            productTitle: serial.article?.type,
            serialNumber: serial.serial,
            serialNumberIsChecked: true,
            valid: serial.valid,
            viability: viabilityType
        };
    }

    private checkFieldValidations(form:FormGroup) {
        let valid = true;
        valid = this.formDataService.isValid(form, valid);
        if (!valid) {
            throw new Error('Validation');
        }
    }

    private setInfoBanner(error) {
        switch (error.code) {
            case 'Duplicate':
                this.bannerTextKey = 'PRODUCTS.IDENTIFICATION.ERRORS.duplicates';
                this.bannerType = InfoBannerType.INFO;
                this.showBanner = true;
                break;
            case 'Validation':
                // Dont do anything
                break;
            case 'ERROR_INVALID_SERIAL':
                this.bannerTextKey = 'PRODUCTS.SUMMARY.invalidInput';
                this.bannerType = InfoBannerType.DANGER;
                this.showBanner = true;
                break;
            case 'ERROR_NO_VALID_SERIALS':
                this.bannerTextKey = 'GENERAL.VALIDATION_ERRORS.noValidSerials';
                this.bannerType = InfoBannerType.DANGER;
                this.showBanner = true;
                break;
            case 'ERROR_NO_SERIALS':
                this.bannerTextKey = 'GENERAL.VALIDATION_ERRORS.noSerials';
                this.bannerType = InfoBannerType.DANGER;
                this.showBanner = true;
                break;
            case 'ERROR_INVALID_INPUT':
                if(error.detail.includes('orderNumber')) {
                    this.bannerTextKey = 'GENERAL.VALIDATION_ERRORS.invalidOrderNumber';
                }else {
                    this.bannerTextKey = 'PRODUCTS.SUMMARY.invalidInput';
                }
                this.bannerType = InfoBannerType.DANGER;
                this.showBanner = true;
                break;
            case 'ERROR_MAX_SERIAL_REACHED':
                this.bannerTextKey = 'GENERAL.VALIDATION_ERRORS.max';
                this.bannerType = InfoBannerType.DANGER;
                this.showBanner = true;
                break;
            default:
                this.bannerTextKey = 'PRODUCTS.IDENTIFICATION.ERRORS.general';
                this.bannerType = InfoBannerType.DANGER;
                this.showBanner = true;
        }
        setTimeout(() => {
            this.banner?.nativeElement.scrollIntoView({behavior: 'smooth'});
        }, 300);
    }

    private showWarning(alreadyClaimedSerials:string):void {
        this.alreadyClaimedSerials = alreadyClaimedSerials.split(',');
        this.showModal();
    }

    private showModal() {
        this.modal.open(this.serialsAlreadyClaimedWarning, {
            centered: true,
            ariaLabelledBy: 'modal-basic-title'
        });
    }

    public cancelModal() {
        this.modal.dismissAll('done');
    }

    private addSerials() {
        return this.recaptchaService.getToken('serials/check').pipe(
            take(1),
            tap(() => {
                this.showBanner = false;
                this.checkFieldValidations(this.addMoreSerialEntryForm.serialEntryForm);
                this.validationService.checkForDuplicates(this.addMoreSerialEntryForm.serialEntryForm, this.unfilteredProducts);
            }),
            mergeMap(response => {
                const serials = this.addMoreSerialEntryForm.serialEntryForm.value?.serials;
                return this.formDataService.checkMassSerials(serials, response, true);
            }),
            tap(res => {
                    const products = this.createProductArray(res, this.unfilteredProducts, this.addMoreSerialEntryForm?.serialEntryForm);
                    this.unfilteredProducts = products;
                    this.store.dispatch(new ProductsAction.SaveProductsAction(products));
                    this.addMoreSerialEntryForm.serialEntryForm.reset();
                    this.toggleAddMore();
                },
                (error) => {
                    this.setInfoBanner(error);
                }),
            takeUntil(this._unsubscribe));
    }
}
