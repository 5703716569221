import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild, ElementRef } from '@angular/core';
import { FormArray} from '@angular/forms';
import {
    DistinguishSupportService,
    FormDataService,
    RecaptchaService,
    ReclamationService,
    UtilsService,
    Product,
    SerialEntryForm,
    CustomerType,
    FormSelectionObjects,
    UserDetailsService, User, ProductsState
} from '../../../shared';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { concatMap, mergeMap, take, takeUntil, tap, switchMap } from 'rxjs/operators';
import { Observable, Subject, of } from 'rxjs';

import { ReclamationDetailed } from '../../../shared/models/evaluation.model';
import {
    MassSerialCheck,
    MassSerialItem,
} from '../../../shared/models/product.model';

import { Store } from '@ngrx/store';
import { State } from '../../../../reducers/root/root.reducer';
import * as ProductsAction from '../../../shared/reducers/products/products.actions';
import { NavigationUtilsService } from '../../../shared/services/navigation-utils.service';
import { InfoBannerType } from '../../../shared/models/info-banner.model';
export enum NextNavReclamationSerials {
    REC_SERIAL_SUPPORT = 'user/aufgezeichnete-seriennummern',
    REC_SERIAL_REKLA = 'servicemeldungen/aufgezeichnete-seriennummern'
}
@Component({
    selector: 'sl-reclamation-serials',
    templateUrl: './reclamation-serials.component.html',
    styles: [],
    changeDetection: ChangeDetectionStrategy.OnPush
})

export class ReclamationSerialsComponent implements OnInit, OnDestroy {
    @ViewChild('infoBanner', {static: false, read: ElementRef}) banner:ElementRef;
    @ViewChild(SerialEntryForm) serialEntry:SerialEntryForm;

    public reclamationNumber:string;
    public serialsMissing:number;
    public loadingData:boolean;
    public isReklaApp:boolean;
    public InfoBannerType = InfoBannerType
    public dropDownValues:Observable<FormSelectionObjects>;
    public products:Product[] = [];
    public isWarrantyCase:boolean;
    public bannerTextKey: string;
    public bannerType: InfoBannerType;
    public showBanner: boolean = false;
    public checkSerialsAndProceed:Observable<any>;
    public customerType:CustomerType;
    private products$:Observable<ProductsState>;
    private _unsubscribe = new Subject();
    private user:User;
    private recObjName: string;
    private recObjType: number;
   

    constructor(private Reclamation:ReclamationService,
                private Route:ActivatedRoute,
                public FormData:FormDataService,
                private Utils: UtilsService,
                private navigationUtils:NavigationUtilsService,
                private router:Router,
                private recaptchaService: RecaptchaService,
                private store:Store<State>,
                private userDetails:UserDetailsService,
                private CD: ChangeDetectorRef,
                public distinguishSupportService: DistinguishSupportService) {
                this.initialize();
    }

    private initialize() {
        this.getReclamationFromBackend().pipe(switchMap(() => {     
            this.setupNextButton();
            this.getUserDetails();
            this.getProductFromStore();
            return of()
        })).subscribe();
    }

    private setupNextButton() {
        this.checkSerialsAndProceed = this.Utils.createActionObs(() => {
            return this.prepareCheckSerialsAndProceed();
        });
    }

    ngOnInit(): void {
        this.loadingData = true;
        this.isReklaApp = !this.distinguishSupportService.support;
    }

    private getReclamationFromBackend() {
       return this.Route.params.pipe(
            concatMap((params: Params) => this.Reclamation.getReclamation(params.id)),
            tap((reclamation: ReclamationDetailed) => {
                this.loadingData = false;
                this.parseReclamationObj(reclamation);
                this.CD.markForCheck();
            }),
            takeUntil(this._unsubscribe))
           
    }

    private parseReclamationObj(reclamation: ReclamationDetailed) {
        this.reclamationNumber = this.Reclamation.calculateReclamationNumber(reclamation);
        this.serialsMissing = this.Reclamation.calculateSerialNumbersMissing(reclamation);
        this.customerType = reclamation.customerType;
        this.recObjName = reclamation.objectName,
            this.recObjType = reclamation.objectType        
    }

    private getProductFromStore() {
        this.products$ = this.store.select('products');
        this.products$.pipe(take(1)).subscribe((products) => {
            this.products = products?.products;
        });
    }

    ngOnDestroy():void {
        this._unsubscribe.next();
        this._unsubscribe.complete();
    }

    private getUserDetails() {
        this.dropDownValues = this.FormData.getDropdowns(this.customerType);
    }

    public prepareCheckSerialsAndProceed() {
        return this.recaptchaService.getToken('serials/check').pipe(
            take(1),
            tap(() => {
                this.validate();
            }),
            mergeMap(response => {
                return this.makeBackEndCall(response);
            }),
            tap(res => {
                this.collectProductsAndNavigate(res);
            },
                (error) => {
                    this.setInfoBanner(error);
                }),
            takeUntil(this._unsubscribe));
    }

    private collectProductsAndNavigate(res: MassSerialCheck) {
        const products = this.createProductArray(
            res,
            this.serialEntry.serialEntryForm.value
        );
        this.store.dispatch(new ProductsAction.SaveProductsAction(products)); // todo needs fix for support
        const nextRoute: string = this.distinguishSupportService.support ? NextNavReclamationSerials.REC_SERIAL_SUPPORT : NextNavReclamationSerials.REC_SERIAL_REKLA
        this.router.navigate([this.navigationUtils.addIdToRoute(nextRoute, this.Route)]);
    }

    private makeBackEndCall(response: string) {
        const serials = this.serialEntry.serialEntryForm.value?.serials;
        return this.FormData.checkMassSerials(serials, response, true);
    }

    private validate() {
        this.checkFieldValidations();
        this.checkForDuplicates();
        this.checkIfMaxNumberOfSerialsEntered();
    }

    private setInfoBanner(error:Error) {
        switch (error.message) {
            case 'Duplicate':
                this.bannerTextKey = 'PRODUCTS.IDENTIFICATION.ERRORS.duplicates';
                this.bannerType = InfoBannerType.INFO;
                this.showBanner = true;
                break;
            case 'Guest exceeded':
                this.bannerTextKey = 'PRODUCTS.IDENTIFICATION.ERRORS.guest';
                this.bannerType = InfoBannerType.DANGER;
                this.showBanner = true;
                break;
                case 'More than Max serials':
                    this.bannerTextKey = 'PRODUCTS.IDENTIFICATION.ERRORS.max';
                    this.bannerType = InfoBannerType.DANGER;
                    this.showBanner = true;
                    break;
            case 'Validation':
                // Dont do anything
                break;
            default:
                this.bannerTextKey = 'PRODUCTS.IDENTIFICATION.ERRORS.general';
                this.bannerType = InfoBannerType.DANGER;
                this.showBanner = true;
        }
        setTimeout(() => {
            this.banner?.nativeElement.scrollIntoView({behavior: 'smooth'});
        }, 300);
    }

    private checkFieldValidations() {
        let valid = true;
        valid = this.FormData.isValid(this.serialEntry.serialEntryForm, valid);
        if (!valid) {
            throw new Error('Validation');
        }
    }

    private checkForDuplicates() {
        const serials:string = this.serialEntry.serialEntryForm.value?.serials;
        let matches = serials?.match(/[\d|\w]+/ig);
        // If duplicates exist
        if (matches.filter((item, index) => matches.indexOf(item) !== index).length > 0) {
            throw new Error('Duplicate');
        }
    }

    private checkIfMaxNumberOfSerialsEntered() {
        const serials:string = this.serialEntry.serialEntryForm.value?.serials;
        let matches = serials?.match(/[\d|\w]+/ig);
        // If max number of serials entered
        matches.forEach((_, index) => {
            if (index > (this.serialsMissing-1)) {
                throw new Error('More than Max serials');
            }
        });
        
    }

    private createProductArray(serials:MassSerialCheck, serialForm):Product[] {
        let fullProductList = [];
        Object.keys(serials).forEach(viabilityType => {
            fullProductList = serials[viabilityType].map((serial:MassSerialItem, index:number) => ({
                isValid: viabilityType === 'valid',
                productImg: serial?.article?.hasImage ? this.FormData.buildImageURL(serial.article?.id) : this.FormData.PLACEHOLDER_IMG_URL,
                productIndex: index,
                objectName: this.recObjName,
                objectType: this.recObjType,
                productTitle: serial?.article?.type,
                returnReason: serialForm?.returnReason,
                serialNumber: serial?.serial,
                serialNumberIsChecked: true,
                valid: serial?.valid,
                viability: viabilityType
            })).concat(fullProductList);
        });
        return fullProductList;
    }
}
