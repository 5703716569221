import { Component, Input, OnInit } from '@angular/core';
import { AddressInformation } from '../../models/user.model';

@Component({
    selector: 'sl-address-details',
    templateUrl: './address-details.component.html',
    styles: []
})
export class AddressDetailsComponent implements OnInit {
    @Input() address: AddressInformation;
    @Input() type: 'customer' | 'billing';

    constructor() { }
    
    ngOnInit(): void {
    }
}