import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { CustomerType, FormInputType, FormSelectionObjects, Product } from '../../models';


@Component({
    selector: 'sl-serial-entry-form',
    templateUrl: './serial-entry-form.component.html',
    styles: []
})
export class SerialEntryForm implements OnInit {
    @Input() isSingleMode:boolean = false;
    @Input() isEditMode:boolean = false;
    @Input() showSource:boolean = true;
    @Input() showReturnReason:boolean = true;
    @Input() products?:Product[];
    @Input() product?:Product;
    @Input() dropDownValues:FormSelectionObjects;


    public serialEntryForm:FormGroup;

    public CustomerType = CustomerType;


    get FormInputType() {
        return FormInputType;
    }

    get serialEntryControl() {
        return this.serialEntryForm.get('serials');
    }

    constructor(private formBuilder:FormBuilder) {
    }

    ngOnInit():void {
        this.createFormControls();
    }

    private createFormControls() {
        const prodValue = this.isSingleMode
            ? this.product?.serialNumber
            : this.products?.map(product => product.serialNumber).join('\ ');
        const serialControlName = this.isSingleMode ? 'serial' : 'serials';
        const prod = this.product
            ? this.product
            : this.products
                ? this.products[0]
                : null;
        this.serialEntryForm = this.formBuilder.group({
            [serialControlName]: [prodValue, Validators.required]
        });
        if (this.showSource) {
            this.serialEntryForm.addControl('supplySource',
                new FormControl(prod?.supplySource, Validators.required)
            );
        }
        if (this.showReturnReason) {
            this.serialEntryForm.addControl('returnReason',
                new FormControl(prod?.returnReason, Validators.required)
            );
        }
    }
}
