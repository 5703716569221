<div *ngIf="!isEditMode" class="user-profile row">
    <div class="col-12 col-md-10 mx-auto d-flex justify-content-between">
        <div>
            <div>
                <h3>{{summaryPage.userProfile| translate}}</h3>
            </div>
            <div>
                {{account.firstName }} {{account.lastName }}
            </div>
            <div>
                {{account.email }}
            </div>
        </div>
        <div *ngIf="registrationIncomplete === null || registrationIncomplete === 'false'">
            <button (click)="toggleEdit()" class="btn p-2">
                <i [ngStyle]="{'fontSize': '20px'}"
                    class="fa fa-pencil text-blue"></i>
            </button>
        </div>
    </div>
</div>

<div *ngIf="isEditMode" class="edit-profile row py-4 bg-light">
    <div class="col-12 col-md-10 mx-auto">
        <sl-account-form (cancel)="toggleEdit()" (save)="saveAccount($event)" [calledFrom]="RegistrationComponents.SUMMARY"
            [storedAccountData]="account"></sl-account-form>
    </div>
</div>