
  <div *ngIf="showBanner" class="py-3">
    <sl-info-banner class="w-100" [bannerType]="bannerType" [textKey]="bannerTextKey"></sl-info-banner>
  </div>
  <ng-container *ngIf="!forgotPasswordSubmitted; else emailSent">
      <div class="modal-header">
          <h4 class="modal-title" id="modal-basic-title">{{'LOGIN.FOROGT_PASSWORD.modalTitle' | translate}}</h4>
      </div>
      <div class="modal-body">
          <p>
              {{modalText | translate}}
          </p>
          <form >
              <div class="row py-3">
                  <div class="col-12">
                      <!-- email -->
                      <div class="form-row align-items-end mt-0 mb-2">
                          <div class="col-12">
                              <label class="w-100" for="usermail">
                                         <sl-form-item [inputType]="inputType.TEXT" [formGroup]="emailForm" [controlName]="'email'" [labelText]="'Email'"  [helperText]="LoginPage.insert_email">
                                         </sl-form-item>
                              </label>
                          </div>
                      </div>
                  </div>
                
              </div>
          </form>
      </div>
      <div class="modal-footer">
          <div class="w-100 d-flex justify-content-start">
              <button (click)="ngbModal.dismissAll()"
                      class="btn btn-outline-primary mr-2"
                      tabindex="1"
                      type="button">
                  <span>{{'GENERAL.BUTTONS.cancel' | translate}}</span>
              </button>
              <sl-busy-btn
                      [action]="forgotPasswordAction"
                      [disabled]="emailForm.invalid"
                      [tabindex]="0"
                      [type]="'submit'">
                  <span>
                      {{'GENERAL.BUTTONS.send' | translate}}
                  </span>
              </sl-busy-btn>
          </div>
      </div>
  </ng-container>

  <ng-template #emailSent>
      <div class="modal-header">
          <h4 class="modal-title" id="modal-basic-title-submitted">{{'LOGIN.FOROGT_PASSWORD.confirmationHeader' | translate}}</h4>
      </div>
      <div class="modal-body">
          <p>
              {{'LOGIN.FOROGT_PASSWORD.confirmationText' | translate}}
          </p>
      </div>
      <div class="modal-footer">
          <div class="w-100 d-flex justify-content-start">
              <button (click)="ngbModal.dismissAll()"
                      class="btn btn-primary mr-2"
                      tabindex="1"
                      type="button">
                  <span>{{'GENERAL.BUTTONS.close' | translate}}</span>
              </button>
          </div>
      </div>
  </ng-template>

