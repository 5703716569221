import { Component, ChangeDetectionStrategy } from '@angular/core';
import {
    CustomerType,
    SVGIconNames,
    TitleIcon,
    DistinguishSupportService
} from '../../../shared';
import { Store } from '@ngrx/store';
import { Router } from '@angular/router';
import { StepsInfoLang } from '../../../shared/models/util.model';
import * as fromRoot from '../../../../reducers/root/root.reducer';
import * as RegistrationAction from '../../../shared/reducers/registration/register.actions';

@Component({
    selector: 'sl-customer-type-selection',
    templateUrl: './customer-type-selection.component.html',
    styleUrls: [],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class CustomerTypeSelectionComponent extends StepsInfoLang {
    public CustomerType = CustomerType;
    public titleIcons:TitleIcon[] =
        [
            [this.stepsInfo.createAccount, SVGIconNames.USER_ACCOUNT],
            [this.stepsInfo.recordDeftDetect, SVGIconNames.DETECTORS_CLAIM],
            [this.stepsInfo.determCompens, SVGIconNames.DETECTORS_SELECT],
            [this.stepsInfo.exchangeReceived, SVGIconNames.USER_DELIVERY]
        ];

    constructor(
        private router:Router,
        private distinguishService:DistinguishSupportService,
        private store:Store<fromRoot.State>
    ) {
        super();
    }

    public setCustomerType(customerType:CustomerType) {
        this.store.dispatch(new RegistrationAction.DeleteAllFormData());
        this.store.dispatch(new RegistrationAction.AddUserTypeFormData(customerType));
        if (this.distinguishService.support) {
            this.router.navigate([`/user/registration/user`]);
        } else {
            this.router.navigate([`/registration/user`]);
        }
    }
}
