import { Component, Input, OnInit } from '@angular/core';
import { Serial } from '../../models/product.model';

@Component({
    selector: 'sl-reported-serials-list',
    templateUrl: './reported-serials-list.component.html',
    styles: []
})
export class ReportedSerialsList implements OnInit {
    @Input() serials: Serial[];

    constructor() { }

    ngOnInit(): void {
    }
}