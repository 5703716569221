<div class="container">
    <div class="col-12 col-md-10 mx-auto">
        <div class="row">
            <sl-back-navigation [buttonText]="'RECLAMATION_START.DETECTOR_RECLAMATION.backLinkText' | translate">
            </sl-back-navigation>
        </div>

        <div *ngIf="showBanner" class="row py-3">
            <sl-info-banner class="w-100" [bannerType]="bannerType" [textKey]="bannerTextKey"></sl-info-banner>
        </div>

        <div class="row py-3">
            <h1>{{summaryPage.heading| translate}}</h1>
        </div>
        <div class="row">
            <p class="lead">
                {{summaryPage.description| translate}}
            </p>
        </div>
    </div>
    <div class="col-12">
        <!-- business account -->
        <div class="row py-3" *ngIf="customerType === CustomerType.Commercial">
            <sl-registration-summary-business-account class="w-100" [externalData]="externalData"
                [businessCustomerType]="businessCustomerType"></sl-registration-summary-business-account>
        </div>
        <!-- end business account -->

        <!-- edit account -->
        <div class="row py-3">
            <sl-registration-summary-user-profile class="w-100" [account]="storedAccountData"
                [registrationIncomplete]="registrationIncomplete">
            </sl-registration-summary-user-profile>
        </div>
        <!-- end edit account -->

        <!-- edit address -->
        <div class="row py-3">
            <sl-registration-summary-address class="w-100" [address]="storedAddressData" [customerType]="customerType"
                [registrationIncomplete]="registrationIncomplete">
            </sl-registration-summary-address>
        </div>
        <!-- end edit address -->
    </div>
    <div class="col-12 col-md-10 mx-auto">
        <!-- data protection text -->
        <div class="row py-3 small" *ngIf="!regIncomplete">
            <span>
                {{summaryPage.smallText| translate}}
                <a target="_blank" href="https://www.hekatron-brandschutz.de/datenschutz">{{summaryPage.dataProtection |
                    translate}}</a>
            </span>
        </div>
        <!-- end data protection text -->

        <!-- agree to data protection -->
        <div *ngIf="!distinguishSupportService.support && !regIncomplete"
            class="agree-to-data-privacy-check row pt-3 d-flex flex-nowrap">
            <div class="my-auto pt-2">
                <form>
                    <sl-form-item [inputType]="InputType.CHECKBOX" [formGroup]="dataPrivacyForm"
                        [controlName]="'checked'" [isInnerHtmlLabel]="true" [selectionValues]="getSelectionValue()">
                    </sl-form-item>
                </form>
            </div>
        </div>
        <!-- end agree to data protection -->


        <div class="row pt-3">
            <sl-busy-btn [action]="registerAction" [tabindex]="0" [type]="'submit'">
                <span>
                    {{buttonText() | translate}}
                </span>
            </sl-busy-btn>
        </div>

    </div>
</div>

<ng-template #customerNumberWarning let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title-submitted"> {{'USER.CREATION.CUSTOMER_NUMBER_WARNING.title' |
            translate}}</h4>
    </div>
    <div class="modal-body">
        <p>
            {{'USER.CREATION.CUSTOMER_NUMBER_WARNING.text' | translate}}
        </p>
    </div>
    <div class="modal-footer">
        <div class="w-100 d-flex justify-content-start">
            <button (click)="cancel()" class="btn btn-primary mr-2" tabindex="1" type="button">
                <span>{{'USER.CREATION.CUSTOMER_NUMBER_WARNING.cancel' | translate}}</span>
            </button>
            <sl-busy-btn [action]="forceUserCreationAction" [type]="'submit'">
                <span>{{'USER.CREATION.CUSTOMER_NUMBER_WARNING.confirm' | translate}}</span>
            </sl-busy-btn>
        </div>
    </div>
</ng-template>
