import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';

@Component({
  selector: 'sl-tile',
  templateUrl: './tile.component.html',
  styles: [
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TileComponent implements OnInit {

  @Input() titleText:string;
  @Input() mainText:string;
  @Input() routeUrl:string;
  @Input() biggerTitle:boolean;

  constructor() { }

  ngOnInit(): void {
  }

}
