<div class="container">
    <div class="col-12 col-md-10 mx-auto">
        <div class="row py-3">
            <h1>{{'PRODUCTS.WARRANTY_CHOICE.TOP.title' | translate}}</h1>
        </div>
        <div class="row py-3">
            <p>{{'PRODUCTS.WARRANTY_CHOICE.TOP.text' | translate}}</p>
        </div>
        <div class="row pt-3 no-gutters">
            <div class="col-12">
                <sl-tile [titleText]="'PRODUCTS.WARRANTY_CHOICE.WARRANTY.title'" [mainText]="'PRODUCTS.WARRANTY_CHOICE.WARRANTY.text'"
                          (click)="proceedToProductIdentification()" [biggerTitle]="true"></sl-tile>
            </div>
        </div>
        <div class="row py-3 pb-3 no-gutters">
            <div class="col-12">
                <sl-tile [titleText]="'PRODUCTS.WARRANTY_CHOICE.NO_WARRANTY.title'" [mainText]="'PRODUCTS.WARRANTY_CHOICE.NO_WARRANTY.text'"
                         [routeUrl]="'/produkte/auswahl-reklamation'"></sl-tile>
            </div>
        </div>
        <div class="row flex-column">
            <h5>{{'PRODUCTS.WARRANTY_CHOICE.BOTTOM.title' | translate}}</h5>
            <p class="py-2">{{'PRODUCTS.WARRANTY_CHOICE.BOTTOM.text' | translate}}</p>
            <p>
                <a class="text-secondary" href="mailto:{{'PRODUCTS.WARRANTY_CHOICE.BOTTOM.email' | translate}}">
                    {{'PRODUCTS.WARRANTY_CHOICE.BOTTOM.email'| translate}}
                </a>
            </p>
            <p>
                <a class="link-undecorated" href="tel:{{'PRODUCTS.WARRANTY_CHOICE.BOTTOM.telephone' | translate}}">
                    {{'PRODUCTS.WARRANTY_CHOICE.BOTTOM.telephone' | translate}}
                </a>
            </p>
        </div>
    </div>
</div>
