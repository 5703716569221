import {
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
    TemplateRef,
    ViewChild
} from '@angular/core';
import { Product, ProductViabilityName, FormSelectionObjects, CheckboxOption } from '../../models';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormGroup } from '@angular/forms';
import { NoSerialsMainFormComponent } from '../no-serials-form/no-serials-main-form/no-serials-main-form.component';
import { SerialEntryForm } from '../serial-entry-form/serial-entry-form.component';

@Component({
    selector: 'sl-product-list-item',
    templateUrl: './product-list-item.component.html'
})
export class ProductListItemComponent implements OnInit {
    @ViewChild(SerialEntryForm) serialEntry: SerialEntryForm;
    @ViewChild(NoSerialsMainFormComponent) addMoreNoSerialsForm: NoSerialsMainFormComponent;
    @Input() product: Product;
    @Input() detectorOptions: CheckboxOption[];
    @Input() showErrorMessage: boolean;
    @Input() enterSerialsLater: boolean;
    @Input() dropDownValues: FormSelectionObjects;
    @Input() editMode: boolean;
    @Input() showSource: boolean = true;
    @Input() showReturnReason:boolean = true;
    @Output() delete: EventEmitter<Product> = new EventEmitter<Product>();
    @Output() update: EventEmitter<FormGroup> = new EventEmitter<FormGroup>();

    public ProductViabilityName = ProductViabilityName;

    constructor(private modalService: NgbModal) {
    }

    ngOnInit(): void {
    }

    public open(content: TemplateRef<any>, product: Product) {
        this.modalService.open(content, { centered: true, ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
            if (result === 'delete') {
                this.delete.emit(product);
            }
        }, () => {
        });
    }

    public toggleEdit() {
        this.editMode = !this.editMode;
    }

    public updateProduct() {
        if (!this.enterSerialsLater) {
            this.update.emit(this.serialEntry.serialEntryForm);
        } else {
            this.update.emit(this.addMoreNoSerialsForm.productForm);
        }
    }
}
