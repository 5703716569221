import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SubmissionRoutingModule } from './submission-routing.module';
import { EvaluationComponent } from './components/evaluation/evaluation.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from '../shared';
import { RegistrationModule } from '../registration';

@NgModule({
    declarations: [
        EvaluationComponent
    ],
    imports: [
        CommonModule,
        FormsModule,
        SharedModule,
        ReactiveFormsModule,
        SubmissionRoutingModule,
        RegistrationModule
    ]
})
export class SubmissionModule {
}
