<div class="container">
    <div class="col-12 col-sm-10 mx-auto d-flex flex-column h-100"
         [ngClass]="{'col-lg-8': !comingFromRekla}">
        <div class="row mt-4">
            <sl-back-navigation [buttonText]="'RECLAMATION_START.DETECTOR_RECLAMATION.backLinkText' | translate"
                [icon]="'fa-arrow-left'">
            </sl-back-navigation>
        </div>

        <div *ngIf="showBanner" class="row my-3">
            <sl-info-banner #infoBanner class="w-100" [bannerType]="bannerType" [textKey]="bannerTextKey">
            </sl-info-banner>
        </div>
        <div class="row my-3">
            <h1>{{'GENERAL.SERIAL_MASS_CLAIM.title' | translate }}</h1>
            <p class="lead">{{'GENERAL.SERIAL_MASS_CLAIM.lead' | translate }}</p>
        </div>
        <form [formGroup]="form">
                <!-- SERIAl NUMBERS -->
                <div class="row flex-nowrap">
                    <div class="col-9 pl-0">
                        <sl-form-item [inputType]="FormInputType.TEXTAREA" [formGroup]="form"
                                      [controlName]="'serialNumbers'"
                                      [labelText]="'GENERAL.SERIAL_MASS_CLAIM.SERIAL_NUMBERS.label'"
                                      [isInnerHtmlHelper]="true"
                                      [placeholderText]="'GENERAL.SERIAL_MASS_CLAIM.SERIAL_NUMBERS.placeholder'"
                                      [helperText]="'GENERAL.SERIAL_MASS_CLAIM.SERIAL_NUMBERS.popoverText' | translate">
                        </sl-form-item>
                    </div>
                    <div class="qr-container col-3 col-md-offset-12 pt-2 pl-1 pr-0 px-md-1">
                        <sl-qr-code-button [serialEntryControl]="serialNumbersControl"></sl-qr-code-button>
                    </div>
                </div>
                <!-- END SERIAl NUMBERS -->

                <!-- PISA CUSTOMER NUMBER -->
                <div class="row align-items-end my-3" *ngIf="!comingFromRekla">
                    <div class="col-12 px-0">
                        <sl-form-item [inputType]="FormInputType.TEXT" [formGroup]="form"
                                      [controlName]="'customerNumber'"
                                      [labelText]="'GENERAL.SERIAL_MASS_CLAIM.CUSTOMER_NUMBER.label'"
                                      [isInnerHtmlHelper]="true"
                                      [placeholderText]="'GENERAL.SERIAL_MASS_CLAIM.CUSTOMER_NUMBER.placeholder'">
                        </sl-form-item>
                    </div>
                </div>
                <!-- END PISA CUSTOMER NUMBER -->

                <!-- ORDER NUMBER -->
                <div class="row align-items-end my-3">
                    <div class="col-12 px-0">
                        <sl-form-item [inputType]="FormInputType.TEXT" [formGroup]="form"
                                      [controlName]="'orderNumber'"
                                      [labelText]="'GENERAL.SERIAL_MASS_CLAIM.ORDER_NUMBER.label'"
                                      [isInnerHtmlHelper]="true"
                                      [placeholderText]="'GENERAL.SERIAL_MASS_CLAIM.ORDER_NUMBER.placeholder'">
                        </sl-form-item>
                    </div>
                </div>
                <!-- END ORDER NUMBER -->
            <div class="row mt-2">
                <sl-busy-btn [action]="submitAction"
                             [classes]="'w-100'"
                             [type]="'submit'">
                    <span>{{'GENERAL.SERIAL_MASS_CLAIM.submitButton' | translate}}</span>
                </sl-busy-btn>
            </div>
        </form>
    </div>
</div>