import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { mergeMap, takeUntil } from 'rxjs/operators';
import { State } from '../../../../reducers/root/root.reducer';
import { EvaluationState, ReclamationDetailed, ReclamationMeta } from '../../../shared/models/evaluation.model';
import { CustomerType, LoginStatus, User } from '../../../shared/models/user.model';
import {
    DistinguishSupportService,
    FormDataService,
    InfoBannerType,
    ReclamationService,
    TitleDescIcon
} from '../../../shared';
import * as ProductAction from '../../../shared/reducers/products/products.actions';
import * as EvaluationAction from '../../../shared/reducers/evaluation/evaluation.actions';

/**
 * Here is where the user will be shown the confirmation of their Reclamation with necessary info on
 * what data the user provided
 */
@Component({
    selector: 'sl-shop-confirmation',
    templateUrl: './shop-confirmation.component.html',
    styles: []
})
export class ShopConfirmationComponent implements OnInit, OnDestroy {
    public userId: string;
    public currentUser: LoginStatus
    public reclamationDetails: ReclamationDetailed;
    public iconTextBullets: TitleDescIcon[];
    public feedbackShown: boolean = true;
    public errormessage: string;
    public CustomerType = CustomerType;
    public bannerType = InfoBannerType;
    public showIntermediateFeedback: boolean = false;
    public advertisingGeniusPort: boolean;

    private reclamationId: string;
    private user$: Observable<User>;
    private endSubscriptions: Subject<void> = new Subject<void>();

    /**
     * This component requires Store<State>, FormDataService, Router, UserService
     * @param {Store<State>} store
     * @param {FormDataService} formDataService
     * @param {ActivatedRoute} route
     * @param {Router} router
     * @param {ChangeDetectorRef} changeDetectorRef
     * @param {DistinguishSupportService} distinguishService
     * @param {ReclamationService} reclamationService
     */
    constructor(private store: Store<State>,
                private formDataService: FormDataService,
                private route: ActivatedRoute,
                private router: Router,
                private changeDetectorRef: ChangeDetectorRef,
                private reclamationService: ReclamationService,
                public distinguishService: DistinguishSupportService,) {
        this.user$ = this.store.select('user');
    }

    /**
     * Get the state of the Evaluation from the Store and populate the relevant information from it
     * Find out whether the user has seen the survey modal before and if they have not then show it
     */
    ngOnInit() {
        this.setupIconTextBullets();
        this.user$.pipe(
            mergeMap(user => {
                this.userId = user ? user.id : null;
                this.currentUser = JSON.parse(sessionStorage.getItem('currentUser'));
                return this.route.queryParams;
            }),
            mergeMap(params => {
                this.reclamationId = params.id;
                return this.distinguishService.support
                    ? this.reclamationService.getReclamation(this.reclamationId)
                    : this.formDataService.retrieveReclamation(this.reclamationId);
            }),
            takeUntil(this.endSubscriptions)
        ).subscribe((evaluation) => {
                const evaluations = evaluation as ReclamationDetailed & ReclamationMeta;
                if (evaluations.$meta) {
                    this.advertisingGeniusPort = !this.distinguishService.support && evaluation.customerType === CustomerType.Private
                        ? evaluations.$meta.advertisingGeniusPort : false;
                    this.feedbackShown = !this.advertisingGeniusPort ? evaluations.$meta.feedbackShown : true;
                    this.changeDetectorRef.markForCheck();
                }

                this.evaluationDecision(evaluation);
            },
            (error) => this.setError(error)
        );
    }

    /**
     * @ignore
     */
    ngOnDestroy(): void {
        this.store.dispatch(new ProductAction.EnterSerialsLaterAction(false));
        this.store.dispatch(new ProductAction.DeleteAllProductAction());

        this.endSubscriptions.next();
        this.endSubscriptions.complete();
        this.endSubscriptions.unsubscribe();
    }

    public onMyReclamation() {
        this.router.navigate(['/servicemeldungen']);
    }

    /**
     * If the user presses the button to start a new Reclamation then delete all the
     * info in the store and direct them to the product form page
     */
    public startNewReclamation(): void {
        this.store.dispatch(new ProductAction.EnterSerialsLaterAction(false));
        this.store.dispatch(new ProductAction.DeleteAllProductAction());
        this.store.dispatch(new EvaluationAction.DeleteReclamationAction());
        this.navigateUser();
    }

    private evaluationDecision(evaluation: EvaluationState | ReclamationDetailed) {
        if ('reclamationResponse' in evaluation && evaluation.reclamationResponse && evaluation.reclamationResponse.id !== '') {
            this.reclamationDetails = evaluation.reclamationResponse;
        } else if (evaluation && this.reclamationId) {
            this.reclamationDetails = (<ReclamationDetailed>evaluation);
        } else {
            return this.navigateUser();
        }
    }

    private navigateUser() {
        if (this.distinguishService.support) {
            this.router.navigate(['/user']);
        } else {
            this.router.navigate(['/produkte/start/']);
        }
    }

    private setError(err) {
        if (err?.status === 400) {
            if (this.currentUser?.loggedIn) {
                this.errormessage = "PRODUCTS.CONFIRMATION.ERRORS.link-expired"
            } else {
                this.errormessage = "PRODUCTS.CONFIRMATION.ERRORS.link-expired-no-login"
            }
        } else {
            this.showIntermediateFeedback = true;
            if (this.currentUser?.loggedIn) {
                this.errormessage = "PRODUCTS.CONFIRMATION.ERRORS.no-such-reclamation"
            } else {
                this.errormessage = "PRODUCTS.CONFIRMATION.ERRORS.no-such-reclamation-no-login"
            }
        }
    }

    private setupIconTextBullets() {
        this.iconTextBullets = [
            ["PRODUCTS.CONFIRMATION.BULLETPOINTS.TITLES.email-confirmation", "PRODUCTS.CONFIRMATION.BULLETPOINTS.DESCRIPTION.email-confirmation", "ic-mail-confirm"],
            ["PRODUCTS.CONFIRMATION.BULLETPOINTS.TITLES.shipping", "PRODUCTS.CONFIRMATION.BULLETPOINTS.DESCRIPTION.shipping", "ic-user-delivery"],
            ["PRODUCTS.CONFIRMATION.BULLETPOINTS.TITLES.returns", "PRODUCTS.CONFIRMATION.BULLETPOINTS.DESCRIPTION.returns", "ic-detectors-claim"],
            ["PRODUCTS.CONFIRMATION.BULLETPOINTS.TITLES.questions", "PRODUCTS.CONFIRMATION.BULLETPOINTS.DESCRIPTION.questions", "ic-user-support"],
        ]
    }
}
